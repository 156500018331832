import React, { useState, useEffect, useRef } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import ScrollToTopButton from "../Components/ScrollToTopButton";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Games from '../json/games.json'
import moneyformat from "../Utility/moneyFormat";

import { DownloadTableExcel } from 'react-export-table-to-excel';



const P2pTransactions = () => {

    const tableRef = useRef(null);
    const [totalPage, setTotalPage] = useState(0)
    const [input, setInput] = useState({
        tableLength: "100",
        opr_id: "",
        dateFilter: "recent",
        transaction_type: "",
        searchPlayer: "",
        from: "",
        to: "",
        type: ""
    });

    const [loading, setLoading] = useState(false)
    const [pageNo, setPageNo] = useState(1);
    const [playerData, setPlayerData] = useState();
    const [skeletonActive, setSkeletonActive] = useState(false);
    const [Search, setSearch] = useState("");

    useEffect(() => {
        setSkeletonActive(true)
        const fetchData = async () => {
            const opr_id = localStorage.getItem('opr_id')
            const response = await axios.get(

                process.env.REACT_APP_API_PATH +
                `p2p-transactions?limit=${input.tableLength}&pageNo=${pageNo}&dateFilter=${input.dateFilter}&searchPlayer=${input.searchPlayer}&from=${input.from}&to=${input.to}&operator_id=${opr_id}&type=${input.type}`,
                {
                    headers: {
                        admin_auth_token: localStorage.getItem("omsToken"),
                        opr_auth_token: localStorage.getItem("sessionToken"),
                    }
                }
            );
            console.log(response.data);
            setSkeletonActive(false)
            setPlayerData(response.data);
            setTotalPage(response?.data?.totalPage)

        }
        // };
        fetchData();
    }, [input, pageNo]);

    console.log(skeletonActive)


    const handelChange = (e) => {

        // if (e.target.name === 'type') {
        //   // alert("HI")
        //   setInput((prevInput) => ({
        //     ...prevInput,
        //     searchPlayer: ""
        //   }));
        //   setSearch('')
        // }

        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setPageNo(1);
    }
    const handleChange = (ex) => {

        setInput({
            ...input,
            searchPlayer: ex
        })
        setPageNo(1);
    }


    const DateTimeFormat = (inputDate) => {

        var parsedDate = new Date(inputDate);

        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const day = parsedDate.getDate();
        const month = monthNames[parsedDate.getMonth()];
        const year = parsedDate.getFullYear();
        const hours = parsedDate.getHours();
        const minutes = parsedDate.getMinutes();
        const seconds = parsedDate.getSeconds();
        const ampm = hours >= 12 ? 'pm' : 'am';

        const formattedDate = `${day} ${month} ${year}, ${hours}:${minutes}:${seconds} ${ampm}`;

        // console.log(formattedDate);
        return formattedDate
    }


    return (
        <div>

            <div className="right-sidebar">
                <div className="das-bg-block w-">
                    <div className="d-flex justify-content-between align-items-baseline">
                        <h1
                            className="gamehistoryheading title_h1 same_heading"
                            style={{ width: "unset" }}
                        >
                            P2p Transaction
                        </h1>
                        {/* <DownloadTableExcel
                            filename="Txn data"
                            sheet="users"
                            currentTableRef={tableRef.current}
                        >

                            <button className="nav-link db-btn" > Export excel </button>

                        </DownloadTableExcel> */}
                    </div>

                    <div className="container-fluid bg">
                        <div className="row user-select mt-2">



                            <div
                                className="col-lg-3 col-md-3 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-2"
                            >
                                <div className="form-group">
                                    <select name="dateFilter" value={input.dateFilter} onChange={handelChange}>
                                        <option value="recent">Recent</option>
                                        <option value="today">Today</option>
                                        <option value="yesterday">Yesterday</option>
                                        <option value="current_week">Current Week</option>
                                        <option value="last_week">Last Week</option>
                                        <option value="current_month">Current Month</option>
                                        <option value="last_month">Last Month</option>
                                        <option value="date_range">Date Range</option>
                                    </select>
                                </div>
                                {input.dateFilter === "date_range" && (<div id="target">
                                    <div className="date">
                                        <p>
                                            <label>From</label>
                                            <input type="date" id="date" name="from" value={input.from} onChange={handelChange} />
                                            <label>To</label>
                                            <input type="date" id="date" name="to" value={input.to} onChange={handelChange} />
                                        </p>
                                    </div>
                                </div>)}

                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-2">
                                <div className="form-group">
                                    <select name="type" value={input.type} onChange={handelChange}>
                                        <option value="">Type</option>

                                        <option value="sender">Sender</option>

                                        <option value="receiver">Receiver</option>


                                        {/* <option value="unsettled">Unsettled</option> */}
                                    </select>
                                </div>

                            </div>



                            <div
                                className="col-lg-3 col-md-3 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-2"
                            >

                            </div>

                            <div
                                className="col-lg-3 col-md-3 col-sm-6 col-12 col-xs-4 d-flex mt-lg-0 mt-md-0 mt-2"
                            >
                                <div className="input-group  input-group-sm">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for...."
                                        aria-describedby="basic-addon1"
                                        style={{ height: "33.5px !important" }}
                                        name="searchPlayer"

                                        onChange={(e) => setSearch(e.target.value)}
                                        value={Search}
                                    />
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text"
                                            id="basic-addon1"
                                            style={{ padding: "9px !important" }}
                                        >
                                            <i className="fa-solid fa-magnifying-glass" onClick={() => handleChange(Search)}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer"
                >
                    <div className="table-player bank-details w-100">
                        <div className="w-100 overflow-auto">
                            <table className="table data-table w-100" ref={tableRef}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        {/* <th>Transaction Id</th> */}
                                        <th>Sender Name / Code </th>
                                        <th>Receiver Name / Code</th>
                                        <th>Sender OB</th>
                                        <th>Amount</th>
                                        <th>Sender CB</th>
                                        <th>Status</th>
                                        <th>Receiver OB</th>
                                        <th>Receiver CB</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {playerData?.data?.length === 0 ? <tr role="row">
                                        <td colSpan={15}>Data Not Found!</td>
                                    </tr> : playerData?.data?.map((doc, index) => (
                                        <tr role="row" key={index}>

                                            <td style={{ whiteSpace: "nowrap" }}>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : DateTimeFormat(doc?.createdAt)}</td>
                                            {/* <td style={{ whiteSpace: "nowrap" }}>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : doc?._id}</td> */}


                                            <td className="text-start" style={{ whiteSpace: "nowrap" }}>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : <>{doc?.sender_nickname} / {doc?.sender_id} </>}</td>
                                            <td className="text-start" style={{ whiteSpace: "nowrap" }}>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : <>{doc?.receiver_nickname} / {doc?.receiver_id} </>}</td>
                                            <td className="text-end" style={{ whiteSpace: "nowrap" }}>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : moneyformat(doc?.sender_opening_bal)}</td>
                                            <td className="text-end" style={{ whiteSpace: "nowrap" }}>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : moneyformat(doc?.amount)}</td>
                                            <td className="text-end" style={{ whiteSpace: "nowrap" }}>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : moneyformat(doc?.sender_closing_bal)}</td>

                                            <td style={{ whiteSpace: "nowrap" }}>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : (doc?.transfer_status === 0 ? <>sent</> : (doc?.transfer_status === 1 ? <>Claimed</> : doc?.transfer_status === 3 ? <>Rolled back</> : <>--</>))}</td>
                                            <td className="text-end" style={{ whiteSpace: "nowrap" }}>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : moneyformat(doc?.receiver_opening_bal)}</td>
                                            <td className="text-end" style={{ whiteSpace: "nowrap" }}>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : moneyformat(doc?.receiver_closing_bal)}</td>

                                            {/* <td>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : doc.game_transaction_id ? doc.game_transaction_id : doc.transaction_details.transaction_id}</td>
                                            {(input.type === 'p2p' || input.type === 'admin') ? <td>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : doc?.player_name}</td> : null}

                                          
                                            {(input.type !== 'p2p' && input.type !== 'admin' && input.type !== "Nongame") ? <td style={{ whiteSpace: "nowrap" }}>{!skeletonActive ? Games[doc?.game_code] : <Skeleton highlightColor="#9a9da1"></Skeleton>}</td> : null}
                                            <td>{!skeletonActive ? <>{doc?.player_id}  </> : <Skeleton highlightColor="#9a9da1"></Skeleton>}</td>
                                            <td>{!skeletonActive ? <>    {doc?.player_code}</> : <Skeleton highlightColor="#9a9da1"></Skeleton>}</td>
                                            

                                            <td>{!skeletonActive ? moneyformat(doc?.opening_wallet_bal) : <Skeleton highlightColor="#9a9da1"></Skeleton>}</td> */}

                                            {/* {skeletonActive ? <td> <Skeleton highlightColor="#9a9da1"></Skeleton> </td> : <td>{
                                                <p className="mb-0" style={{ color: (doc.closing_wallet_bal - doc.opening_wallet_bal) >= 0 ? "green" : "red" }}>{doc.transaction_type === "2" ? <>+</> : <>-</>}{moneyformat(doc.closing_wallet_bal - doc.opening_wallet_bal)}</p>}
                                            </td>}

                                            <td>{!skeletonActive ? moneyformat(doc?.closing_wallet_bal) : <Skeleton highlightColor="#9a9da1"></Skeleton>} </td>
                                            
                                            {(input.type !== 'p2p' && input.type !== 'admin' && input.type !== "Nongame") ? <td>{!skeletonActive ? doc?.transaction_details?.session_id : <Skeleton highlightColor="#9a9da1"></Skeleton>}</td> : null}
                                            <td>{skeletonActive ? <Skeleton highlightColor="#9a9da1"></Skeleton> : doc?.transaction_details?.remarks}</td> */}
                                        </tr>
                                    ))}


                                </tbody>
                            </table>
                        </div>

                        <div className="pagination pagination-text row justify-content-between align-items-center">
                            <div className="w-auto">
                                <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                                    <select
                                        aria-controls="DataTables_Table_0"
                                        name="tableLength" value={input.tableLength}
                                        onChange={handelChange}
                                        className="pag-select-block me-1"
                                    >
                                        <option value="10">10</option>
                                        <option value="25" defaultValue={true}>25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> Records per page
                                    {/* Page {pageNo} of {playerData && playerData.totalPage}{" "} */}
                                    {/* off items {input.tableLength * playerData?.totalPage} */}
                                    {/* <label>
                    Rows per page{" "}
                    <select
                      aria-controls="DataTables_Table_0"
                      name="tableLength" value={input.tableLength}
                      onChange={handelChange}
                    >
                      <option value="10">10</option>
                      <option value="25" defaultValue={true}>25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </label> */}
                                </div>
                            </div>

                            <div className="w-auto">
                                Showing {pageNo}-{input?.tableLength}{" "}
                                of {input.tableLength * playerData?.totalPage} items
                            </div>

                            <div className="w-auto">
                                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                                    <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                        className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                        <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                                    </a>
                                    <a onClick={() => setPageNo(pageNo - 1)}
                                        style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                        className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                        {/* <i className="fa-solid fa-chevron-left"></i> Previous */}
                                        <img src="img/left-arrow.png" width="20" />
                                    </a>
                                    <span className="d-flex align-items-center">
                                        {/* <a
                      className="paginate_button current"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="1"
                      tabIndex="0"
                    > */}

                                        <select className="pag-select-block"
                                            aria-controls="DataTables_Table_0"
                                            name="pagination" value={pageNo}
                                            // onChange={handelChange}
                                            // onChange={() => setPageNo(pageNo + 1)}
                                            onChange={(e) => setPageNo(Number(e.target.value))}
                                        >
                                            {Array.from({ length: totalPage }, (_, index) => (
                                                <option key={index + 1} value={index + 1} >
                                                    {index + 1}
                                                </option>
                                            ))}



                                        </select> of {playerData?.totalPage}
                                        {/* {pageNo} */}
                                        {/* </a> */}
                                    </span>
                                    {/* {playerData && pageNo < playerData.totalPage && */}
                                    <a onClick={() => setPageNo(pageNo + 1)}
                                        style={{ pointerEvents: `${playerData && pageNo < playerData?.totalPage ? 'auto' : 'none'}` }}
                                        className={`${playerData && pageNo < playerData?.totalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                                        <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                                    </a>
                                    {/* } */}

                                    <a onClick={() => setPageNo(playerData?.totalPage)} style={{ pointerEvents: `${playerData && pageNo < playerData?.totalPage ? 'auto' : 'none'}` }}
                                        className={`${playerData && pageNo < playerData?.totalPage ? '' : 'opacity-25'} border-0`}>
                                        {/* <i className="fa-solid fa-chevron-right"></i> */}
                                        <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* <div className="pagination row justify-content-between align-items-center">
                            <div className="w-auto">
                                <div className="dataTables_info dataTables_length">
                                    {pageNo} of {playerData && playerData.totalPage}{" "}
                                    <label>
                                        Rows per page{" "}
                                        <select

                                            aria-controls="DataTables_Table_0"
                                            name="tableLength"
                                            value={input.tableLength}
                                            onChange={handelChange}
                                        >
                                            <option value="10">10</option>
                                            <option value="25" defaultValue={true}>
                                                25
                                            </option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="w-auto">
                                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                                    {pageNo > 1 && (
                                        <a onClick={() => setPageNo(pageNo - 1)}>
                                            <i className="fa-solid fa-chevron-left"></i>
                                        </a>
                                    )}
                                    <span>
                                        <a
                                            className="paginate_button current"
                                            aria-controls="DataTables_Table_0"
                                            data-dt-idx="1"
                                            tabIndex="0"
                                        >
                                            {pageNo}
                                        </a>
                                    </span>
                                    {playerData && playerData.totalPage > pageNo && (<a onClick={() => setPageNo(pageNo + 1)}>
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </a>)}
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <!------RIGHT SIDE CONTENT END-------> */}
            </div>
            {/* </div> */}
        </div>
    );
};

export default P2pTransactions;
