import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";


const ChatSupport = () => {
    const opr_id = localStorage.getItem("opr_id");
    const [type, setType] = useState('');
    const [number, setNumber] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [requestBodyModal, setRequestBodyModal] = useState(false);
    const [responseModal, setResponseModal] = useState(false);
    const [requestBodyData, setRequestBodyData] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [filter, setFilter] = useState('GMS');
    const [headerModal, setHeaderModal] = useState(false);
    const [headerData, setHeaderData] = useState(null);
    const [totalPage, setTotalPage] = useState('');
    const [addSupportModal, setAddSupportModal] = useState(false);

    const [data, setData] = useState([]);
    const [limit, setLimit] = useState('100');

    const fetchData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}fetch-support?operator_code=${opr_id}&page=${pageNo}&limit=${limit}`);

            if (res?.data?.subCode) {
                setData(res?.data?.data?.support || []);
            } else {
                console.log("No data returned from API.");
            }

        } catch (error) {
            console.error("Error fetching support data:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); 

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_PATH}add-support`, {
                type: type,
                operator_code: opr_id,
                data: number
            });

            if (res?.data?.status) {
                alert(res?.data?.message)
                setAddSupportModal(false);
                fetchData();
                setNumber('');
                setType('');
            } else {
                alert(res?.data?.message);
            }

        } catch (error) {
            console.error("Error Adding support:", error);
        }
    };

    const handleToggle = async (status, type, id) => {
        const newStatus = !status;  
    
        try {
            const res = await axios.patch(
                `${process.env.REACT_APP_API_PATH}update-support-status`,
                {
                    operator_code: opr_id,
                    type: type,
                    status: newStatus,
                    id: id
                }
            );
    
            if (res?.data?.status) {
                alert(res?.data?.message);
                 fetchData();  
            } else {
                alert(res?.data?.message);
            }
        } catch (error) {
            console.error("Error toggling status:", error);
        }
    };
    




    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>

            <div>


                <div className="right-sidebar">
                    <div className="das-bg-block w-">
                        <div className="container-fluid">
                            <div className="row justify-content-between">
                                <div className="col-md-3">
                                    <h1 className="gamehistoryheading title_h1 same_heading text-start">
                                        Chat Support
                                    </h1>

                                </div>

                                <div className="w-auto">
                                    <a className="nav-link db-btn ms-2 ms-md-0" style={{ cursor: 'pointer' }}
                                        onClick={() => { setAddSupportModal(true) }}>
                                        <i className="fa-solid fa-circle-plus me-1"></i>
                                        Add Support
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper no-footer top-table"
                    >

                    </div>
                    <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper no-footer"
                    >
                        <div className="table-player">
                            <div className='table-list-mb'>
                                <table className="table data-table">
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>ID</th>
                                            <th>Support Type</th>
                                            <th>Data</th>
                                            <th>Action</th>
                                            {/* <th>createdAt</th>
                                            <th>updatedAt</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length === 0 ? (
                                            <tr>
                                                <td colSpan="6">No Data Found</td>
                                            </tr>
                                        ) : (
                                            data.map((e, idx) => (
                                                <tr key={idx}>
                                                    <td>{e.status ? (
                                                        <span className="d-block rounded-pill mx-auto" style={{ width: '10px', height: '10px', background: 'green' }}></span>
                                                    ) : (
                                                        <span className="d-block rounded-pill mx-auto" style={{ width: '10px', height: '10px', background: 'red' }}></span>
                                                    )}</td>
                                                    <td>{e._id}</td>
                                                    <td>{e.support_type}</td>
                                                    <td>{e.data}</td>
                                                    <td>
                                                        <button
                                                            className={`nav-link db-btn ${e?.status ? 'text-white' : ''}`}
                                                            style={{
                                                                background: `${e?.status ? 'green' : ''}`, minWidth: '70px'
                                                            }}
                                                            onClick={() => handleToggle(e?.status, e?.support_type, e._id)}
                                                        >
                                                            {e?.status ? 'Active' : 'Inactive'}
                                                        </button>
                                                    </td>
                                                    {/* <td>{new Date(e.createdAt).toLocaleString()}</td>
                                                    <td>{new Date(e.updatedAt).toLocaleString()}</td> */}
                                                </tr>
                                            ))
                                        )}
                                    </tbody>

                                </table>
                            </div>




                        </div>
                    </div>
                </div>

            </div>

            <Modal show={addSupportModal} onHide={() => setAddSupportModal(false)} centered scrollable>
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Add Support</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formSupportType">
                            <Form.Label className="d-block text-start">Support Type</Form.Label>
                            <Form.Select value={type} onChange={(e) => setType(e.target.value)}
                                required>
                                <option value="">Select Type</option>
                                <option value="whatsApp">WhatsApp</option>
                                <option value="telegram">Telegram</option>
                                <option value="email">Email</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formContactData">
                            <Form.Label className="d-block text-start">{type === 'email' ? 'Email Address' : 'Phone Number'}</Form.Label>
                            <Form.Control
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                type={type === 'email' ? 'email' : 'tel'}
                                placeholder={type === 'email' ? 'Enter Email address' : 'Enter Mobile Number'}
                                maxLength={type === 'email' ? undefined : 10}
                                required
                            />

                        </Form.Group>
                        <Button variant="primary" type="submit">Submit</Button>
                    </Form>

                </Modal.Body>
            </Modal>

        </>
    );
};

export default ChatSupport;