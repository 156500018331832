import React from "react";


const SignupLog = () => {
    return (
        <>
            
                <div className="right-sidebar">
                    <div className="das-bg-block d-flex justify-content-between">
                        <div className="das-title">
                            <h1 className="gamehistoryheading title_h1 same_heading">Signup Log</h1>
                        </div>
                        <div className='row pe-2' style={{width:'40%'}}>

                            
                            <div className="col-lg-6 col-md-6 col-6 mt-lg-0 pe-1 col-xs-1 user-select user-selectinput d-flex align-items-center gap-1">
                                <input type="Date" value="" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-6 mt-lg-0 ps-1  col-xs-1 user-select user-selectinput d-flex align-items-center gap-1">
                                <input type="Date" value="" />
                            </div>



                        </div>
                    </div>
                    <div>
                        

                        <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                        <table className="table data-table   table-borderless">
                        <thead>
                            <tr>
                                <th>Device ID</th>
                                <th>User Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>--</td>
                                <td>--</td>
                            </tr>
                        </tbody>
                    </table></div>

                        </div>

                    </div>
                </div>
            </>
    );
};

export default SignupLog;