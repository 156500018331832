import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

export default function SubAdmin() {
    const [show, setShow] = useState(false);
    const [imgUrl, setImgUrl] = useState('');
    const [data, setData] = useState([]);
    const [formAllData, setFormAllData] = useState({
        role_id: '',
        role_name: '',
        username: '',
        password: ''
    })

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormAllData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };



    const fetchData = async () => {
        const sessionToken = localStorage.getItem('sessionToken')
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_PATH}getSubAdmins`, {
                headers: {
                    authorization: `Bearer ${sessionToken}`
                }
            },
            );
            console.log("🚀 ~ fetchData ~ response:", response.data)
            setData(response.data.data)
        } catch (error) {
            console.error("There was an error with the API request:", error);
            setData([]);
        }
    };


    const uploadData = async (event) => {
        event.preventDefault();
        const payload = {
            username: formAllData.username,
            password: formAllData.password,
            role_id: formAllData.role_id,
            role_name: formAllData.role_name,
        }
        const sessionToken = localStorage.getItem('sessionToken')
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PATH}createSubAdmin`, payload,
                {
                    headers: {
                        authorization: `Bearer ${sessionToken}`
                    }
                },
            );
            alert("created");
            fetchData();
            setShow(false);

        } catch (error) {
            console.error("Error uploading data", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Sub Admins</h1>
                    </div>
                    <div>
                        <button className="nav-link db-btn"
                            onClick={handleShow}>
                            <i className="fa-solid fa-plus"></i> Create SubAdmin
                        </button>
                    </div>
                </div>

                <div>
                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <div className="table-list-mb">
                                <table className="table data-table table-borderless">
                                    <thead>
                                        <tr>
                                            <th>Username</th>
                                            <th>Role Name</th>
                                            <th>Role ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 ? (
                                            [...data].reverse().map((e, idx) => (
                                                <tr key={idx}>
                                                    <td>{e.username}</td>
                                                    <td>{e.role_name}</td>
                                                    <td>{e.role_id}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr><td colSpan="7" className="text-center">No data found</td></tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Fill Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={uploadData}>
                        <Row>
                            <Col md="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-start d-block">Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Username"
                                        name="username"
                                        required
                                        value={formAllData.username}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-start d-block">password</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="password"
                                        name="password"
                                        required
                                        value={formAllData.password}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-start d-block">Role ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Role ID"
                                        name="role_id"
                                        required
                                        value={formAllData.role_id}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-start d-block">Role Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Role Name"
                                        name="role_name"
                                        required
                                        value={formAllData.role_name}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <div className="text-center change_password_btn_block w-100">
                            <Button
                                variant="primary"
                                className="mt-3 change_password_btn btn btn-primary"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>


            <div
                className="modal fade add-btn-modal"
                id="image"
                data-bs-keyboard="true"
                tabIndex={-1}
                aria-labelledby="BackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div
                        className="modal-content manual_width "
                    >
                        {/* <h5 className="modal-title">KYC Details</h5> */}
                        <button
                            className="close-btn"
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <span><i className="fa-solid fa-xmark"></i></span>
                        </button>

                        <div className="modal-body container p-0">
                            <div className="row">
                                <div className="p-4">
                                    <img
                                        src={imgUrl}
                                        alt="front_img"
                                        className="img-fluid rounded"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
