import React, { useState } from "react";
import { Link } from "react-router-dom";


const ManageCampaign = () => {
    const [activeToggle, setActiveToggle] = useState(false);
    const toggleButton = () => {
        setActiveToggle(!activeToggle);
    }
    return (
        <>

            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Manage Campaign</h1>
                    </div>
                    <div>
                    <Link className="nav-link db-btn  " to="/add-campaign"><i className="fa-solid fa-circle-plus"></i>Add Campaign</Link>
                    </div>

                </div>
                <div>
                    <div className='container-fluid bg'>
                        <div className='row user-select mt-2'>

                            <div className="col-lg-4 col-md-3  col-6  col-xs-12">
                                <div className="form-group">
                                    <select name="" id="" className="w-100 ">
                                        <option value="-1">All</option>
                                        <option value="5">Login</option>
                                        <option value="0">Signup</option>
                                        <option value="2">Deposit</option>
                                        <option value="1">Referral</option>
                                        <option value="3">Play/Spend/Win</option>
                                        <option value="4">Rack Back - Poker</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-3  col-6  col-xs-12">
                                <div className="form-group">
                                    <select name="" id="" className="w-100 ">
                                        <option value="2">Running</option>
                                        <option value="1">Created</option>
                                        <option value="3">Expired</option>
                                        <option value="0">Inactive</option>
                                        <option value="4">Unpublish</option>

                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-3 user-select col-6 mt-lg-0  col-xs-1">
                                <div className="input-group  input-group-sm">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for...."
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        name="search"
                                        defaultValue=""
                                    />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </span>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>

                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <table className="table data-table   table-borderless">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Campaign Name</th>
                                        <th>Code</th>
                                        <th>Category</th>
                                        <th> How it will be credited</th>
                                        <th> Campaign For</th>
                                        <th>Rack Back %</th>
                                        <th>Start From</th>
                                        <th>Valid till</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={activeToggle && 'action-btn-open'}>
                                        <td>
                                        <div className="btn btn-danger btn-sm no_events">Unpublished</div>
                                        </td>
                                        <td>get 30 percent cashback</td>
                                        <td>DF0F6FFA2</td>
                                        <td>Deposit</td>
                                        <td>Auto credit into wallet</td>
                                        <td></td>
                                        <td>30.00</td>
                                        <td>2023-04-11 03:34:00</td>
                                        <td>2023-04-14 03:34:00</td>
                                        <td className="position-relative dropdowncaret">
                        <button
                          className="action btn"
                          onClick={toggleButton}
                          id="dropdown"
                        >
                          <i className="fa-solid fa-caret-down"></i>
                        </button>
                        <div className="action-btn actin-payment-btn-sc" style={{width:' 100px',minWidth:' 140px'}}>
                          <div className="item-3 d-flex flex-wrap">
                            <button className="w-100">
                            <i className="fa fa-pencil icons_img"></i>
                              <p> Edit</p>
                            </button>
                            <button className="w-100">
                            <i className="fa fa-ban icons_img" aria-hidden="true"></i>
                              <p> Inactive</p>
                            </button>
                            <button className="w-100">
                            <i className="fa fa-trash"></i>
                              <p> Inactive</p>
                            </button>
                            <button className="w-auto d-d-inline-block">
                            <i className="fa fa-play-circle icons_img" aria-hidden="true"></i>
                              <p> Beneficial Report</p>
                            </button>
                          </div>
                          
                        </div>
                      </td>
                                    </tr>
                                </tbody>
                            </table></div>

                    </div>

                </div>
            </div>

        </>
    );
};

export default ManageCampaign;