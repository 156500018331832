import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

export default function GameList() {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        const sessionToken = localStorage.getItem("sessionToken");
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}gameList`, {
                headers: {
                    opr_auth_token: sessionToken,
                },
            });
            setData(res?.data?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleToggle = async (status, code) => {
        const oprid = localStorage.getItem("opr_id");
        const sessionToken = localStorage.getItem("sessionToken");
        const newStatus = status === 'live' ? 'offline' : 'live';

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_PATH}toggle-game-status`,
                {
                    opr_id: oprid,
                    game_code: code,
                    operational_status: newStatus,
                },
                {
                    headers: {
                        opr_auth_token: sessionToken,
                    }
                }
            );

            if (res?.data?.status === 200) {
                alert(res?.data?.message);
                fetchData();
            } else {
                alert(res?.data?.message);
            }

        } catch (error) {
            console.error("Error toggling game status:", error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Game List</h1>
                    </div>

                </div>

                <div>
                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <div className="table-list-mb">
                                <table className="table data-table table-borderless">
                                    <thead>
                                        <tr>
                                            <th>Game Name</th>
                                            <th>Game Code</th>
                                            <th>Operational Status</th>
                                            <th>Priority</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 ? (
                                            data.map((game) => (
                                                <tr key={game?._id}>
                                                    <td className="text-start">
                                                        {game?.game_status === 'true' ? (
                                                            <span className="active_status d-inline-block"></span>
                                                        ) : (
                                                            <span className="inactive_status d-inline-block"></span>
                                                        )}
                                                        {game?.game_name}
                                                    </td>
                                                    <td>{game?.game_code}</td>

                                                    <td>{game?.operational_status}</td>
                                                    <td>{game?.priority}</td>
                                                    <td>
                                                        <button
                                                            className={`nav-link db-btn ${game?.operational_status === 'live' ? 'text-white' : ''}`}
                                                            style={{
                                                                background: `${game?.operational_status === 'live' ? 'green' : ''}`, minWidth: '70px'
                                                            }}
                                                            onClick={() => handleToggle(game?.operational_status, game?.game_code)}
                                                        >
                                                            {game?.operational_status === 'live' ? 'Active' : 'Inactive'}
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4">No Data Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
