import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import { useNavigate } from "react-router-dom";

const Deposite = () => {

  const navigate = useNavigate();
  const [input, setInput] = useState({
    tableLength: "100",
    filterByStatus: "pending",
    date: "recent",
    searchPlayer: "",
    from: "",
    to: ''
  });

  const [pageNo, setPageNo] = useState(1);
  const [playerData, setPlayerData] = useState();
  const [data, setData] = useState();
  const [totalPage, setTotalpage] = useState(0);
  const [formData, setFormData] = useState({
    amount_type: "full-amount",
    amount: "",
    partial_amount: "0",
    remark: "",
    searchPlayer: "",
  });
  const [hoveredRowData, setHoveredRowData] = useState();
  // console.log("🚀 ~ file: Deposite.js:23 ~ Deposite ~ hoveredRowData:", hoveredRowData)

  const [error, setError] = useState({
    amount: '',
    remark: ""
  });
  const [aproveBtnLoader, setAproveBtnLoader] = useState(false)
  const [declineBtnLoader, setDeclineBtnLoader] = useState(false)

  const limit = 20;
  //   const pageNo = 2;

  useEffect(() => {
    const fetchData = async () => {
      // if(localStorage.getItem("opr_id")){
      //   const response = await axios.get(
      //     process.env.REACT_APP_API_PATH +
      //       `depositeRequest?limit=${input.tableLength}&pageNo=${pageNo}&status=${input.filterByStatus}&dateFilter=${input.date}&opr_id=${localStorage.getItem("opr_id")}`
      //   );
      //   console.log(response.data);
      //   setPlayerData(response.data);
      // }
      // else{
      const response = await axios.get(
        process.env.REACT_APP_API_PATH +
        `depositeRequest?limit=${input.tableLength}&pageNo=${pageNo}&status=${input.filterByStatus}&dateFilter=${input.date}&searchPlayer=${input.searchPlayer}&from=${input.from}&to=${input.to}`,
        {
          headers: {
            admin_auth_token: localStorage.getItem("omsToken"),
            opr_auth_token: localStorage.getItem("sessionToken"),
          },
        }
      );
      console.log(response.data);
      setPlayerData(response.data);
      setTotalpage(response?.data?.totalPage)
    };
    // };
    fetchData();
  }, [input.tableLength, pageNo, input]);

  const handelChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setPageNo(1);
  };

  const handleModelData = (document) => {
    setData(document);
    console.log(document);
    // setFormData({
    //   amount_type: "full-amount",
    //   amount: document.amount,
    //   partial_amount: "0",
    //   remark: "",
    // });
    setFormData({
      amount_type: "partial-amount",
      amount: "",
      partial_amount: "",
      remark: "Deposit by QR",
    });
  };

  const handleFormData = (e) => {
    if (e.target.value === "full-amount") {
      setFormData({
        ...formData,
        amount: data.amount,
        amount_type: e.target.value,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        // partial_amount: e.target.amount
      });
    }
  };

  function handleKeyPress(event) {
    if (event.charCode >= 48 && event.charCode <= 57) {
      // console.log(event.target.value)
    } else {
      event.preventDefault();
    }
  }

  // console.log(formData, "4164");

  const handleApproveBtn = async () => {
    if (
      formData &&
      formData.amount_type === "partial-amount" &&
      (parseInt(formData.amount) < 1 || !formData.amount)
    ) {
      setError({
        amount: "amount must be greater than 1"
      });
    } else if (
      formData &&
      formData.remark &&
      (formData.amount_type === "full-amount" || parseInt(formData.amount) > 0)
    ) {
      setAproveBtnLoader(true)
      try {
        const response = await axios.post(
          process.env.REACT_APP_TRANSACTION_API + "transaction/deposit-confirm",
          {
            user_id: localStorage.getItem("login_id"),
            user_type: "123456",
            module: 'deposit',
            deposit_method: data.deposit_method,
            amount: data.amount,
            amount_type: formData.amount_type,
            partial_amount:
              formData.amount_type === "full-amount"
                ? 0
                : parseInt(formData.amount),
            operator_id: data.opr_id,
            player_id: data.player_id,
            payment_gateway: "manual",
            transaction_id: data.transaction_details?.transaction_id,
            _id: data._id,
            // token: localStorage.getItem("sessionToken"),
            country: "+91",
            remarks: formData.remark,
          }, {
          headers: {
            token: localStorage.getItem("sessionToken")
          }
        }
        );
        console.log(response);
        if (response.data.status === true) {
          alert("Trasaction successful");
          window.location.reload();
        } else if (response.data.status === false && response.data.message === "invaild Token") {
          alert("Invaild Token, please login again");
          localStorage.clear();
          navigate("/");
        } else {
          alert("Invalid data");
        }
        setAproveBtnLoader(false)
      } catch (err) {
        console.log(err);
        alert("Internal server error! Plaese try again after some time");
        setAproveBtnLoader(false)
      }
    } else {
      // alert("please fill all details");
      setError({
        remark: "*remark is required"
      })
    }
  };

  console.log("error", error)

  const handleDeclineBtn = async () => {
    if (formData && formData.remark) {
      setDeclineBtnLoader(true)
      try {
        const response = await axios.post(
          process.env.REACT_APP_TRANSACTION_API + "transaction/deposit-reject",
          {
            user_id: localStorage.getItem("login_id"),
            user_type: "123456",
            module: 'deposit',
            deposit_method: data.deposit_method,
            // amount: data.amount,
            // amount_type: formData.amount_type,
            // partial_amount: formData.amount_type ==="full-amount"? 0:formData.amount,
            operator_id: data.opr_id,
            player_id: data.player_id,
            payment_gateway: "manual",
            transaction_id: data.transaction_details?.transaction_id,
            _id: data._id,
            token: localStorage.getItem("omsToken"),
            country: "+91",
            remarks: formData.remark,
          }, {
          headers: {
            token: localStorage.getItem("sessionToken")
          }
        }
        );
        console.log(response);
        if (response.data.status === true) {
          alert("decline successful");
          window.location.reload();
        } else {
          alert("invalid data");
        }
        setDeclineBtnLoader(false)
      } catch (err) {
        console.log(err.message);
        alert("internal servver error ! plaese try again after some time");
        setDeclineBtnLoader(false)
      }
    } else {
      // alert("please fill all details");
      setError({
        remark: "*remark is required"
      })
    }
  };
  console.log(input, "inputData");

  const hoverRemark = (remark, index) => {
    setHoveredRowData({
      remark: remark,
      index: index,
    });
  };

  const DateTimeFormat = (inputDate) => {

    var parsedDate = new Date(inputDate);

    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const day = parsedDate.getDate();
    const month = monthNames[parsedDate.getMonth()];
    const year = parsedDate.getFullYear();
    const hours = parsedDate.getHours();
    const minutes = parsedDate.getMinutes();
    const seconds = parsedDate.getSeconds();
    const ampm = hours >= 12 ? 'pm' : 'am';

    const formattedDate = `${day} ${month} ${year}, ${hours}:${minutes}:${seconds} ${ampm}`;

    // console.log(formattedDate);
    return formattedDate
  }

  return (
    <div>
      {/* <Header />
      <div className="full-page">
        <LeftSideBar indexx={0} /> */}
      <div className="right-sidebar">
        <div className="das-bg-block w-100">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <h1 className="gamehistoryheading title_h1 same_heading text-start">
                  Deposit Request
                </h1>
              </div>
              <div className="col-md-8">
                <div className="row user-select">
                  <div className="col-md-2" />
                  <div className="col-md-3">
                    <div className="form-group">
                      <select
                        name="filterByStatus"
                        value={input.filterByStatus}
                        onChange={handelChange}
                      >
                        <option value="all">All</option>
                        <option value="approve">Approved</option>
                        {/* <option value="">Failed</option> */}
                        <option value="pending">Pending</option>
                        {/* <option value="" selected="selected">
                            {" "}
                            Auto Approved
                          </option> */}
                        <option value="rejected">Rejected</option>
                        {/* <option value="">Not Completed</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2 mt-md-0">
                    <div className="form-group">
                      <select
                        name="date"
                        onChange={handelChange}
                        className="w-100"
                        value={input.date}
                      >
                        <option value="recent">Recent</option>
                        <option value="today">Today</option>
                        <option value="current_week">Current Week</option>
                        <option value="last_week">Last Week</option>
                        <option value="current_month">Current Month</option>
                        <option value="last_month">Last Month</option>
                        <option value="last_six_month">Last 6 Month</option>
                        <option value="date_range">Date Range</option>
                      </select>
                    </div>
                    {input.date === "date_range" && (<div id="target">
                      <div className="date">
                        <p>
                          <label>From</label>
                          <input type="date" id="date" name="from" value={input.from} onChange={handelChange} />
                          <label>To</label>
                          <input type="date" id="date" name="to" value={input.to} onChange={handelChange} />
                        </p>
                      </div>
                    </div>)}
                  </div>
                  <div className="col-md-4 form-group mt-2 mt-md-0">
                    <div className="input-group  input-group-sm">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search for...."
                        aria-describedby="basic-addon1"
                        style={{ height: "33.5px !important" }}
                        name="searchPlayer"
                        value={input.searchPlayer}
                        onChange={handelChange}
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                          style={{ padding: "9px !important" }}
                        >
                          <i className="fa-solid fa-magnifying-glass" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="DataTables_Table_0_wrapper"
          className="dataTables_wrapper no-footer top-table"
        >
          <div className="table-player">
            <table className="table data-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Total Amount</th>
                  <th>Amount Approved</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          id="DataTables_Table_0_wrapper"
          className="dataTables_wrapper no-footer"
        >
          <div className="table-player">
            <div className="table-list-mb">
              <table className="table data-table">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Player Name</th>
                    <th>Player Code</th>
                    <th>Transaction ID</th>
                    <th>Payment Method</th>
                    <th>Req Amount</th>
                    <th>Approved Amount</th>
                    {/* <th>Remark</th> */}
                    <th>Action</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {playerData ? (
                    playerData.data.map((doc, index) => (
                      <tr key={index}>
                        <td>
                          {doc.deposit_status === 0 ? (
                            <img src="img/circular-clock.png" width="18" />
                          ) : doc.deposit_status === 1 ? (
                            <img src="img/check.png" width="18" />
                          ) : (
                            <img src="img/cross.png" width="18" />
                          )}
                        </td>
                        <td>
                          {/* {new Date(doc.createdAt).toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                          })} */}
                          {doc.createdAt && DateTimeFormat(doc.createdAt)}
                        </td>
                        <td>{doc?.player_name}</td>
                        <td>{doc?.player_code}</td>
                        <td>{doc?.transaction_details?.transaction_id}</td>
                        <td>{doc?.deposit_method}</td>
                        <td className="text-end">{doc?.amount}</td>
                        <td className="text-end">{doc?.approved_amount}</td>
                        {/* <td>
                          {doc && doc.login_type === 'email' ? (<i className="fa-solid fa-envelope m-1"></i>) : doc.login_type === "social" ? (<i className="fa-solid fa-user m-1"></i>) : doc.login_type === "guest" ? (<i className="fa-solid fa-user m-1"></i>) : doc.login_type === 'mobile' ? (<i className="fa-solid fa-phone m-1"></i>) : null}{doc?.login_id}
                        </td> */}

                        {/* <td>{doc.remarks}</td> */}

                        {/* <td
                          className="position-relative bankDetailsTd"
                          onMouseEnter={() => hoverRemark(doc.remarks, index)}
                          onMouseLeave={() => setHoveredRowData("")}
                        >
                          {doc.remarks && doc.remarks.slice(0, 5)}
                          {doc.remarks && doc.remarks.length > 5 ? "..." : ""}
                          <ul className="list-group position-absolute top-100 bankdetails-block">
                            {hoveredRowData &&
                              hoveredRowData.remark &&
                              hoveredRowData.index === index && (
                                <li className="list-group-item">
                                  {hoveredRowData.remark}
                                </li>
                              )}
                          </ul>
                        </td> */}
                        <td td >
                          <button
                            className="btn-info reset-btn green-bg-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => handleModelData(doc)}
                            disabled={doc.deposit_status === 0 ? false : true}
                          >
                            Approve
                          </button>
                        </td>

                        <td> <button
                          className="reset-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#TxnImageModal"
                          onClick={() => handleModelData(doc)}

                        >
                          View
                        </button></td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={28}>
                        <span>
                          <Loader />
                        </span>
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content">
                  <div
                    className="modal-header"
                  >
                    <h5 className="modal-title fs-5" id="exampleModalLabel">
                      Transaction
                    </h5>
                    <button
                      className="close-btn bg-transparent border-0"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span><i className="fa-solid fa-xmark" style={{ fontSize: '22px' }}></i></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {data && (
                      <div className="mb_modal">

                        <div className="">
                          <span>Login Id : </span> <strong>{data && data.login_type === 'email' ? (<i className="fa-solid fa-envelope m-1"></i>) : data.login_type === "social" ? (<i className="fa-solid fa-user m-1"></i>) : data.login_type === "guest" ? (<i className="fa-solid fa-user m-1"></i>) : data.login_type === 'mobile' ? (<i className="fa-solid fa-phone m-1"></i>) : null}{data?.login_id}</strong>
                        </div>
                        <div>
                          <span>Operator Id : </span>
                          <strong>{data.opr_id}</strong>
                        </div>
                        <div>
                          <span>Amount : </span>
                          <strong>{data.amount}</strong>
                        </div>
                        <div>
                          <span>Deposite Method : </span>{" "}
                          <strong>{data.deposit_method}</strong>
                        </div>
                        <div>
                          <span>transaction Reference Id : </span>
                          <strong>{data.transaction_details?.transaction_id}</strong>
                        </div>
                        <div>
                          <span>Payment Gateway : </span><strong>manual</strong>
                        </div>
                        {/* <div>
                            <span>Country : </span>+91{" "}
                          </div> */}
                        <div>
                          Amount Type :
                          <input
                            type="radio"
                            name="amount_type"
                            value="partial-amount"
                            checked={
                              formData.amount_type === "partial-amount"
                            }
                            onChange={handleFormData}
                          />
                          Custom Amount
                          <input
                            type="radio"
                            name="amount_type"
                            value="full-amount"
                            checked={formData.amount_type === "full-amount"}
                            onChange={handleFormData}
                          />
                          Full Amount
                        </div>
                        <div className="row m-0 ps-2 pe-2">
                          <div className="col-md-4 d-inline mx-0 text-start">
                            Enter Amount :
                          </div>
                          <div className="col-md-8 d-inline mx-0">
                            <input
                              className="w-100 me-0 ms-0 form-control"
                              type="number"
                              name="amount"
                              value={formData.amount}
                              onChange={handleFormData}
                              onKeyPress={handleKeyPress}
                              min={1}
                              disabled={
                                formData.amount_type === "full-amount"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="error text-start d-block"
                              style={{
                                color: "rgb(172, 37, 37)",
                                fontSize: "12px", textTransform: 'capitalize'
                              }}
                            >
                              {error.amount}
                            </span>
                          </div>
                        </div>
                        <div className="row m-0 ps-2 pe-2">
                          <div className="col-md-4 d-inline mx-0 text-start">
                            Remark*
                          </div>
                          <div className="col-md-8 d-inline mx-0">
                            <input
                              className="w-100 me-0 ms-0 form-control"
                              type="text"
                              name="remark"
                              // value={formData.remark}
                              value={"Deposit by QR"}
                              disabled
                            // onChange={handleFormData}
                            />
                            <span
                              className="error text-start d-block"
                              style={{
                                color: "rgb(172, 37, 37)",
                                fontSize: "12px", textTransform: 'capitalize'
                              }}
                            >
                              {error.remark}
                            </span>
                          </div>
                        </div>
                        <div className="md-btn">
                          <button
                            className="btn btn-success btn-sm loaderBtnsize"
                            onClick={handleApproveBtn}
                            disabled={aproveBtnLoader ? true : false}
                          >
                            {aproveBtnLoader ? <Loader /> : "Approve"}
                          </button>
                          <button
                            className="btn btn-danger btn-sm loaderBtnsize"
                            onClick={handleDeclineBtn}
                            disabled={declineBtnLoader ? true : false}
                          >
                            {declineBtnLoader ? <Loader /> : "Rejected"}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>


            <div
              className="modal fade"
              id="TxnImageModal"
              tabIndex="-1"
              aria-labelledby="eTxnImageModallLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content">
                  <div
                    className="modal-header"
                  >
                    <h5 className="modal-title fs-5" id="eTxnImageModallLabel">
                      Transaction Image
                    </h5>
                    <button
                      className="close-btn bg-transparent border-0"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span><i className="fa-solid fa-xmark" style={{ fontSize: '22px' }}></i></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <img src={data?.txn_image}></img>
                  </div>

                </div>
              </div>
            </div>
















            <div className="pagination pagination-text row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                  <select
                    aria-controls="DataTables_Table_0"
                    name="tableLength" value={input.tableLength}
                    onChange={handelChange}
                    className="pag-select-block me-1"
                  >
                    <option value="10">10</option>
                    <option value="25" defaultValue={true}>25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select> Records per page
                </div>
              </div>

              <div className="w-auto">
                Showing {pageNo}-{input?.tableLength}{" "}
                of {playerData?.data?.length} items
              </div>

              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                  <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                  <a onClick={() => setPageNo(pageNo - 1)}
                    style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    {/* <i className="fa-solid fa-chevron-left"></i> Previous */}
                    <img src="img/left-arrow.png" width="20" />
                  </a>
                  <span className="d-flex align-items-center">

                    <select className="pag-select-block"
                      aria-controls="DataTables_Table_0"
                      name="pagination" value={pageNo}
                      onChange={(e) => setPageNo(Number(e.target.value))}
                    >
                      {Array.from({ length: totalPage }, (_, index) => (
                        <option key={index + 1} value={index + 1} >
                          {index + 1}
                        </option>
                      ))}



                    </select> of {totalPage}
                  </span>

                  <a onClick={() => setPageNo(pageNo + 1)}
                    style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                    className={`${pageNo < totalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                    <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                  </a>
                  {/* } */}

                  <a onClick={() => setPageNo(totalPage)} style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                    className={`${pageNo < totalPage ? '' : 'opacity-25'} border-0`}>
                    {/* <i className="fa-solid fa-chevron-right"></i> */}
                    <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                </div>
              </div>
            </div>

            {/* <div className="pagination row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length">
                  {pageNo} of {playerData && playerData.totalPage}{" "}
                  <label>
                    Rows per page{" "}
                    <select
                      aria-controls="DataTables_Table_0"
                      name="tableLength"
                      value={input.tableLength}
                      onChange={handelChange}
                    >
                      <option value="10">10</option>
                      <option value="25" defaultValue={true}>
                        25
                      </option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                  {pageNo > 1 && (
                    <a onClick={() => setPageNo(pageNo - 1)}>
                      <i className="fa-solid fa-chevron-left"></i>
                    </a>
                  )}
                  <span>
                    <a
                      className="paginate_button current"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="1"
                      tabIndex="0"
                    >
                      {pageNo}
                    </a>
                  </span>
                  {playerData && pageNo < playerData.totalPage && (
                    <a onClick={() => setPageNo(pageNo + 1)}>
                      <i className="fa-solid fa-chevron-right"></i>
                    </a>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div >
      {/* </div> */}
    </div >
  );
};

export default Deposite;
