import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
// import TableHeader from "./TableHeader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";
import $ from "jquery";
import LoadingBar from "react-top-loading-bar";
import Skeleton from "react-loading-skeleton";
import { Modal } from "react-bootstrap";
const VIPPlayerManagement = () => {
  const [input, setInput] = useState({
    tableLength: "100",
    opr_id: "",
    from: "",
    to: ""
  })
  const navigate = useNavigate();

  const [pageNo, setPageNo] = useState(1)
  const [playerData, setPlayerData] = useState();
  const [activetoggle, setActiveToggle] = useState();
  const [parentSelectedOptions, setParentSelectedOptions] = useState({
    sortBy: { label: 'Sign Up', value: 'signup_date' },
    orderBy: { img: "../img/dec.png", value: "Descending" },
    filterByDate: '',
    filter: 'allPlayer',
    search: ""
  });
  const [playerId, setPlayerId] = useState();
  const [subWallet, setSubWallet] = useState();
  const [dropdownActive, setDropdownActive] = useState(
    Array(playerData && playerData.data?.length).fill(false)
  );
  const [data, setData] = useState();
  const [playerAccountDetails, setPlayerAccountDetails] = useState();
  const [addChips, setAddChips] = useState({
    add_amount: ""
  })
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false)
  const [playerIdMatch, setPlayerIdMatch] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [errorVip, setErrorVip] = useState('');
  const [success, setSuccess] = useState('');
  const [playercodeData, setPlayercodeData] = useState([]);
  const [playerCode, setPlayerCode] = useState('');
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true)

  // const [refresh,setRefresh] = useState(false);

  $(document).ready(function () {

    // Hide dropdown menu when clicking anywhere outside of it
    $(document).click(function (e) {
      if (!$(e.target).closest('.dropdown1').length) {
        $(".dropdown1 dd ul").hide();
      }
    });

    // Hide dropdown menu when clicking on an item inside the dropdown
    $(".dropdown1 dd ul li a").on('click', function () {
      $(".dropdown1 dd ul").hide();
    });

    // Gamelist Selection
    $('.gamelist li').click(function () {
      $(this).addClass('active').siblings().removeClass('active');
      var getValue = $(this).find('label').text();
      $('.multiSel').text(getValue);
    });

    // Orderlist Selection
    $('.orderlist li').click(function () {
      $(this).addClass('active-over').siblings().removeClass('active-over');
      var getSrc = $(this).find('img').attr('src');
      $('.multiSel1').attr('src', getSrc);
    });

    $('.orderlist li').click(function () {
      $(this).addClass('active').siblings().removeClass('active');
    });
  });

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible((prevVisible) => !prevVisible);
  };

  const handleSortOptionChange = (value) => {
    setParentSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      sortBy: value,
    }));
  };

  const handleOrderOptionChange = (value) => {
    setParentSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      orderBy: value,
    }));
  };

  const handleFilter = (e) => {
    // console.log(value,"++++++++++++++")
    setParentSelectedOptions({
      ...parentSelectedOptions,
      filterByDate: e.target.value,
    })
  }

  const handleSearch = (e) => {
    setParentSelectedOptions({
      ...parentSelectedOptions,
      search: e.target.value,
    })
  }

  useEffect(() => {
    if (playerData) {
      setActiveToggle(Array(playerData?.data?.length).fill(false));
      setDropdownActive(Array(playerData?.data?.length).fill(false));
    }
  }, [playerData]);


  const toggleButton = (index, id, doc) => {
    setDropdownActive(Array(playerData.data.length).fill(false))
    setActiveToggle((dataa) => {
      const Toggledata = dataa.map((value, i) => (i === index ? !value : false));
      return Toggledata;
    });
    setPlayerId(id);
    setPlayerAccountDetails(doc);
    setAddChips({
      add_amount: ""
    })
    setError()
  };
  const limit = 20;
  //   const pageNo = 2;

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {

      const response = await axios.get(
        process.env.REACT_APP_API_PATH +
        `VipPlayerManagement?limit=${input.tableLength}&pageNo=${pageNo}&sortBy=${parentSelectedOptions.sortBy.value}&orderBy=${parentSelectedOptions.orderBy.value}&dateFilter=${parentSelectedOptions.filterByDate}&player=${parentSelectedOptions.search}&from=${input.from}&to=${input.to}&filter=${parentSelectedOptions.filter}`,
        {
          headers: {
            admin_auth_token: localStorage.getItem("omsToken"),
            opr_auth_token: localStorage.getItem("sessionToken")
          }
        }
      );
      //setAllPlayers(response.data.data || []);
      console.log(response.data, 'responseData');
      if (response.data) {
        setPlayerData(response.data);
        setLoading(false)
        if (response.data.message) {
          setLoading(false)
          alert(response.data.message)
        }
      }
    }
    fetchData();
  }, [input, pageNo, parentSelectedOptions, triggerFetch]);

  const handelChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value
    })
    setPageNo(1);
  }

  // Function to handle receiving the selectedOptions value from TableHeader
  const handleSelectedOptionsChange = (e) => {
    console.log("🚀 ~ handleSelectedOptionsChange ~ e.target.value:", e.target.value)
    // setParentSelectedOptions(e.target.value);
    setParentSelectedOptions({
      ...parentSelectedOptions,
      filter: e.target.value,
    })
  };

  const handleSubWallet = (data, index) => {
    setSubWallet(data);
    setActiveToggle(Array(playerData.data.length).fill(false))

    setDropdownActive((dataa) => {
      const Toggledata = dataa.map((value, i) =>
        i === index ? !value : false
      );
      return Toggledata;
    });
  };

  const handlePlayerInfo = async (id) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_PATH + `playerInfo?id=${id}`)
      console.log(response.data);
      setData(response.data)
    } catch (error) {
      console.log(error.msg)
    }
  }

  const handlechipsModelChange = async (e) => {
    setAddChips({
      ...addChips,
      [e.target.name]: e.target.value
    })
  }

  const AddChips = async (id) => {
    try {
      console.log("🚀 ~ file: VIPPlayerManagement.js:197 ~ AddChips ~ addChips:", addChips)
      if (addChips.add_amount === "" || parseInt(addChips.add_amount) <= 0) {
        setError("please enter valid amount or greater than 0")
      }
      else {
        const response = await axios.post(
          process.env.REACT_APP_TRANSACTION_API + "transaction/deposit-confirm",
          {
            user_id: localStorage.getItem("login_id"),
            user_type: "123456",
            module: 'deposit',
            deposit_method: "BO-transfer",
            amount: parseInt(addChips.add_amount),
            amount_type: "",
            partial_amount: "",
            operator_id: localStorage.getItem("opr_id"),
            player_id: playerAccountDetails?._id,
            payment_gateway: "manual",
            transaction_id: "",
            _id: "0",
            country: "+91",
            remarks: "",
          }, {
          headers: {
            token: localStorage.getItem("sessionToken")
          }
        }
        );
        console.log(response);
        if (response.data.status === true) {
          alert("Trasaction successful");
          window.location.reload();
        } else {
          alert("invalid data");
        }
      }
      //     const response = await axios.post(process.env.REACT_APP_API_PATH + "chips-transfer", {
      //       id: playerAccountDetails?._id,
      //       add_amount: parseInt(addChips.add_amount)
      //     })
      //     console.log("🚀 ~ file: VIPPlayerManagement.js:197 ~ AddChips ~ response:", response)
      //     if (response.data.status === true) {
      //       alert(response.data.message)
      //       window.location.reload();
      //     }
      //     else {
      //       alert("invalid details")
      //     }
      //   }
    } catch (error) {
      alert("internal server error")
    }
  }

  const DateTimeFormat = (inputDate) => {

    var parsedDate = new Date(inputDate);

    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const day = parsedDate.getDate();
    const month = monthNames[parsedDate.getMonth()];
    const year = parsedDate.getFullYear();
    const hours = parsedDate.getHours();
    const minutes = parsedDate.getMinutes();
    const seconds = parsedDate.getSeconds();
    const ampm = hours >= 12 ? 'pm' : 'am';

    const formattedDate = `${day} ${month} ${year}, ${hours}:${minutes}:${seconds} ${ampm}`;

    // console.log(formattedDate);
    // 2023-12-26T10:18:03.042Z
    return formattedDate;
  }

  // useEffect(() => {
  //   const handleDocumentClick = (e) => {
  //     if (!e.target.closest('#dropdownBtn') && !e.target.closest('#dropdown')) {
  //       setDropdownActive(Array(playerData && playerData.data?.length).fill(false));
  //     }
  //   };

  //   document.addEventListener('click', handleDocumentClick);

  //   return () => {
  //     document.removeEventListener('click', handleDocumentClick);
  //   };
  // }, []);

  console.log(parentSelectedOptions)
  console.log(playerId);

  useEffect(() => {
    if (playerCode) {
      const playerCodeFetchData = async () => {
        try {
          const opr_id = localStorage.getItem('opr_id')
          const response = await axios.get(
            `${process.env.REACT_APP_API_PATH}playerManagement?player=${playerCode}&opr_id=${opr_id}`,
            {
              headers: {
                admin_auth_token: localStorage.getItem("omsToken"),
                opr_auth_token: localStorage.getItem("sessionToken")
              }
            }
          );


          console.log(response.data, 'player code data');


          if (response.data && response.data.data && response.data.data.length > 0) {
            setPlayercodeData(response.data.data[0]);
          } else {
            setPlayercodeData(null);
            setErrorVip('No player found.');
          }
        } catch (error) {
          console.error('Error fetching player data:', error);
          setErrorVip('Failed to fetch player data.');
        }
      };

      playerCodeFetchData();
    }
  }, [playerCode]);



  const handleSave = async () => {
    if (!promoCode) {
      setErrorVip('Enter promo code');
      setSuccess('');
      return;
    }

    if (playercodeData) {
      console.log(playercodeData._id, 'playercodeData._id')
      console.log(playercodeData.operator_details.operator_id, 'playercodeData.operator_details.operator_id')
      console.log(promoCode, 'promoCode')
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_TRANSACTION_API}redeem/subscription`,
          {
            player_id: playercodeData._id,
            operator_id: playercodeData.operator_details.operator_id,
            promo_code: promoCode
          },
          {
            headers: {
              admin_auth_token: localStorage.getItem("omsToken"),
              opr_auth_token: localStorage.getItem("sessionToken")
            }
          }
        );

        if (response.data?.status) {
          setSuccess(response.data.message);
          setErrorVip('');
          setTriggerFetch(prevState => !prevState);
          setShow(false);
        } else {
          setErrorVip(response.data.message);
          setSuccess('');
        }
      } catch (error) {
        console.error('Error saving VIP status:', error);
        setErrorVip('An error occurred. Please try again.');
        setSuccess('');
      }
    } else {
      setErrorVip('No matching player found.');
      setSuccess('');
    }
  };

  const handleRemoveVip = async (playerCode) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_PATH}remove-vip`,
        {
          "playerCode": playerCode
        },
        {
          headers: {
            admin_auth_token: localStorage.getItem("omsToken"),
            opr_auth_token: localStorage.getItem("sessionToken")
          }
        }
      );
      if (response.data.data.status) {
        alert(response.data.data.message);
        setTriggerFetch(prevState => !prevState);
      }
    } catch (error) {
      alert('error');
    }
  }

  return (
    <div>
      <>  <div className="right-sidebar">
        <div className="das-bg-block w-100">
          <div className="d-flex justify-content-between align-items-baseline">
            <h1
              className="gamehistoryheading title_h1 same_heading"
              style={{ width: "unset" }}
            >
              Player Management
              <small>Vip Player </small>
            </h1>
            <a className="nav-link db-btn" onClick={handleShow}>
              <i className="fa-solid fa-circle-plus"></i>
              Add VIP
            </a>
          </div>
          {/* <TableHeader onSelectedOptionsChange={handleSelectedOptionsChange}/> */}
          <div className="container-fluid bg">
            <div className="row user-select mt-2">
              <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <select name="filter" id="" value={parentSelectedOptions.filter} className="w-100" onChange={handleSelectedOptionsChange}>
                    {/* <option value="">
                      Active Player
                    </option> */}
                    {/* <option value="">In Active Player</option>
                    <option value="">Not approved Player</option>
                    <option value="">Flagged Player</option>
                    <option value="">Blocked Player</option>
                    <option value="">Deleted Player</option> */}
                    <option value="vipplayer" >VIP Player</option>
                    {/* <option value="networkPlayer">Network Player</option>
                    <option value="nonNetWorkPlayer">Non Network Player</option> */}
                  </select>
                </div>
              </div>
              <div
                className="col-1 d-lg-block  d-none p-0 text-end"
                style={{ marginTop: "6px" }}
              >
                <span className="showoff_responsive">Created on</span>
              </div>
              <div className="col-lg-2  col-md-3 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                <div className="form-group">
                  <select name="filterByDate" className="w-100" onChange={handleFilter} value={parentSelectedOptions.filterByDate}>
                    {/* <option value="">All</option> */}
                    <option value="recent">
                      Recent
                    </option>
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="current_week">Current Week</option>
                    <option value="last_week">Last Week</option>
                    <option value="current_month">Current Month</option>
                    <option value="last_month">Last Month</option>
                    <option value="last_six_month">Last 6 Months</option>
                    <option value="date_range">Date Range</option>
                  </select>
                </div>
                {parentSelectedOptions.filterByDate === "date_range" && (<div id="target">
                  <div className="date">
                    <p>
                      <label>From</label>
                      <input type="date" id="date" name="from" value={input.from} onChange={handelChange} />
                      <label>To</label>
                      <input type="date" id="date" name="to" value={input.to} onChange={handelChange} />
                    </p>
                  </div>
                </div>)}
              </div>

              <div
                className="col-1 d-lg-block  d-none text-start"
                style={{ marginTop: "6px" }}
              >
                <span className="showoff_responsive">Sort</span>
              </div>
              <div
                className="col-lg-2 col-md-3 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-2"
                id="ml-10"
              >
                <dl className="dropdown1">
                  <dt>
                    <a href="#" className="text-start" onClick={toggleDropdown}>
                      <i className="fa-solid fa-chevron-down icon"></i>
                      {/* <!-- <span className="hida"></span> --> */}
                      <span className="multiSel">{parentSelectedOptions.sortBy.label}</span>
                      <img
                        className="multiSel1"
                        src={parentSelectedOptions.orderBy.img}
                        alt=""
                      />
                    </a>
                  </dt>

                  <dd>
                    <div className="mutliSelect">
                      <ul
                        className="text-start"
                        style={{ display: dropdownVisible ? "block" : "none" }}
                      >
                        <div className="gamelist">
                          <span>Sort By</span>
                          <li className={parentSelectedOptions.sortBy.value === "signup_date" ? "active" : null}>
                            <input
                              className="position-absolute invisible"
                              id="signup"
                              type="checkbox"
                              value="Sign Up"
                              onChange={() => handleSortOptionChange({ label: "Sign Up", value: "signup_date" })}
                            />
                            <label htmlFor="signup">
                              <i className="fa-solid fa-check"></i> Sign Up
                            </label>
                          </li>
                          <li >
                            <input
                              className="position-absolute invisible"
                              id="lg"
                              type="checkbox"
                              value="Last Login"
                              onChange={() => handleSortOptionChange({ label: "Last Login", value: "last_login" })}
                            />
                            <label htmlFor="lg">
                              <i className="fa-solid fa-check"></i> Last Login
                            </label>
                          </li>
                          <li   >
                            <input
                              id="username"
                              type="checkbox"
                              value="Username"
                              onChange={() => handleSortOptionChange({ label: "Username", value: "display_name" })}
                              className="position-absolute invisible"
                            />
                            <label htmlFor="username">
                              <i className="fa-solid fa-check"></i>
                              Username
                            </label>
                          </li>
                          <li>
                            <input
                              id="balance"
                              type="checkbox"
                              value="Balance"
                              onChange={() => handleSortOptionChange({ label: "Balance", value: "wallet_balance" })}
                              className="position-absolute invisible"
                            />
                            <label htmlFor="balance">
                              <i className="fa-solid fa-check"></i>
                              Balance
                            </label>
                          </li>
                          {/* <li>
                              <input
                                id="ingame"
                                type="checkbox"
                                value=""
                                onChange={()=>handleSortOptionChange({label:"In Game",value:""})}
                                className="position-absolute invisible"
                              />
                              <label htmlFor="ingame">
                                <i className="fa-solid fa-check"></i> In Game
                              </label>
                            </li> */}
                          {/* Add more gamelist items as needed */}
                        </div>
                        <div className="orderlist">
                          <span>Order By</span>
                          <li onClick={() => handleOrderOptionChange({ img: "../img/ace.png", value: "Ascending" })}>
                            {/* <input
                                id="ace"
                                type="checkbox"
                                value="../img/ace.png"
                                onChange={handleOrderOptionChange}
                              /> */}
                            <img style={{ display: "none" }} src="../img/ace.png" alt="img"></img>
                            <label htmlFor="ace" >Ascending</label>
                            <i
                              className="fa-solid fa-check"
                              style={{ marginTop: "5px" }}
                            ></i>
                          </li>
                          <li onClick={() => handleOrderOptionChange({ img: "../img/dec.png", value: "Descending" })} className={parentSelectedOptions.orderBy.value === "Descending" ? "active" : null}>
                            {/* <input
                                id="dec"
                                type="checkbox"
                                value="../img/dec.png"
                                onChange={handleOrderOptionChange}
                              /> */}
                            <img style={{ display: "none" }} src="../img/dec.png" alt="img"></img>
                            <label htmlFor="dec"> Descending</label>
                            <i
                              className="fa-solid fa-check"
                              style={{ marginTop: "5px" }}
                            ></i>
                          </li>
                          {/* Add more orderlist items as needed */}
                        </div>
                      </ul>
                    </div>
                  </dd>
                </dl>
              </div>
              {/* <!-- <div className="col-lg-1 d-flex justify-content-end">
                                    <div className=" sort-btn"><img src="../img/ace.png" alt=""></div>
                                </div> --> */}
              <div
                className="col-lg-4 col-md-3 col-sm-6 col-12 col-xs-4 d-flex mt-lg-0 mt-md-0 mt-2"
                id="ml-33"
              >
                <div className="input-group  input-group-sm">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for...."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ height: "33.5px !important" }}
                    name="search"
                    value={parentSelectedOptions.search}
                    onChange={handleSearch}
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="basic-addon1"
                      style={{ padding: "9px !important" }}
                    >
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div
          id="DataTables_Table_0_wrapper"
          className="dataTables_wrapper no-footer"
        >
          <div className="table-player">
            <table className="table data-table" >
              <thead>
                <tr>
                  {/* <th>
                  <input type="checkbox" name="" id="" />
                </th> */}
                  <th>VIP</th>
                  <th>Nick Name</th>
                  {/* <th>Active Since</th> */}
                  <th>Active Till</th>
                  <th>Player Code</th>
                  {/* <th>Nickname</th> */}
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {
                  playerData?.data?.map((doc, index) => (
                    <tr
                      className={`odd ${activetoggle && activetoggle[index]
                        ? "action-btn-open"
                        : ""
                        }`}
                      role="row"
                      key={index}
                    >

                      {/* {loading?<td><Skeleton/></td>:  <td onClick={() => handlePlayerInfo(doc._id)} data-bs-toggle="modal"
                        data-bs-target="#playerInfoModal" style={{ textAlign: "start", marginLeft: "10px" }}>
                        {doc && doc.login_type === 'email' ? (<i className="fa-solid fa-envelope ms-1"></i>) : doc.login_type === "social" ? (<i className="fa-solid fa-user ms-1"></i>) : doc.login_type === "guest" ? (<i className="fa-solid fa-user ms-1"></i>) : (<i className="fa-solid fa-phone ms-1"></i>)
                        }&nbsp;
                        
                        {doc.mobile_number}{doc.email_id}{doc.guest_id ? 'Guest' : null}
                        
                      </td>} */}
                      <td>
                        <img src="img/crown.png" width={30} />
                      </td>
                      {loading ? <td style={{ textAlign: 'center' }}><Skeleton /></td> : <td style={{ textAlign: 'center' }}>
                        <span>{doc?.display_name}</span>
                      </td>}
                      {/* {loading?<td><Skeleton/></td>: <td>{DateTimeFormat(doc?.player_promocodes?.used_promocodes?.redeemed_on)}</td>} */}
                      {/* {loading?<td><Skeleton/></td>:  <td className="position-relative ">
                        <span>
                          <i class="fa-solid fa-circle-info m-2" id="dropdownBtn" onClick={() => handleSubWallet(doc.sub_wallet, index)}></i>
                        </span>
                        {dropdownActive[index] && (<ul className="list-group position-absolute top-100 bankdetails-block modaldrowp drowp">
                          <div>
                            <li className="list-group-item">
                              Bonus : {subWallet.bonus}
                            </li>
                            <li className="list-group-item">
                              Deposit : {subWallet.deposit}
                            </li>
                            <li className="list-group-item">
                              In Game : {subWallet.inGame}
                            </li>

                          </div>
                          <div>
                            <li className="list-group-item">
                              Lock : {subWallet.lock}
                            </li>
                            <li className="list-group-item">
                              Promo : {subWallet.promo}
                            </li>
                            <li className="list-group-item">
                              Referral : {subWallet.referral}
                            </li>

                          </div>
                          <div>
                            <li className="list-group-item">
                              Transfer : {subWallet.transfer}
                            </li>
                            <li className="list-group-item">
                              Withdrawable : {subWallet.withdrawable}
                            </li>
                          </div>
                          
                        </ul>)}
                      </td>} */}
                      {loading ? <td><Skeleton /></td> : <td>{DateTimeFormat(doc?.subscription_validity) === ("NaN undefined NaN, NaN:NaN:NaN am" || "NaN undefined NaN, NaN:NaN:NaN pm") ? null : DateTimeFormat(doc?.subscription_validity)}</td>}
                      {loading ? <td><Skeleton /></td> : <td>
                        <span>{doc?.player_code}</span>
                      </td>}
                      {/* {loading ? <td><Skeleton /></td> : <td>
                        <span>{doc?.display_name}</span>
                      </td>} */}
                      {/* <td>
                      <span>{doc.last_login ? new Date(doc.last_login).toLocaleString(
                        "en-IN",
                        {
                          timeZone: "Asia/Kolkata",
                        }
                      ) === "Invalid Date" ? null : new Date(doc.last_login).toLocaleString(
                        "en-IN",
                        {
                          timeZone: "Asia/Kolkata",
                        }
                      ) : "-"}</span>
                    </td> */}
                      {/* <td>
                        {doc.mobile_number ? <span>91{doc.mobile_number}</span>: ""}
                      </td>
                      <td>
                        <span>{doc.email_id ? doc.email_id : "-"}</span>
                      </td> */}

                      {loading ? <td><Skeleton /></td> : <td className="position-relative">
                        <button onClick={() => handleRemoveVip(doc?.player_code)} className="nav-link db-btn">
                          <i className="fa-solid fa-trash me-1"></i>
                          Remove
                        </button>

                        {/* <button
                          className="action btn"
                          onClick={() => toggleButton(index, doc._id, doc)}
                          id="dropdown"
                        >
                          <i className="fa-solid fa-caret-down"></i>
                        </button>
                        <div className="action-btn actin-payment-btn-sc" style={{ minWidth: '160px' }}>
                          <div className="item-3">
                            <button onClick={() => handleRemoveVip(doc?.player_code)}>
                              <i className="fa-solid fa-trash"></i>
                              <p>Remove VIP</p>
                            </button>
                          </div>
                        </div> */}

                      </td>}
                    </tr>
                  ))}
              </tbody>
            </table>
            {/* player info Model */}
            <div
              className="modal fade"
              id="playerInfoModal"
              tabIndex={-1}
              aria-labelledby="tableAddModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-xl modal-dialog-scrollable text-start">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="tableAddModalLabel">
                      <strong>VIP Player Info</strong>
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <div className="row user_summery mt-2">
                      <div className="col-lg-12 mt-3">
                        <div className="info-card" style={{ height: "199px" }}>
                          <div className="info-header">
                            <h6>Account Info</h6>
                          </div>
                          <div className="uer-info">
                            <form action="">
                              <div className="row">
                                <div className="col-md-2 col-4">
                                  <label htmlFor="">Player ID</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.mobile_number}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4">
                                  <label htmlFor="">Username</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.display_name}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4">
                                  <label htmlFor="">Account Status</label>
                                  <br />
                                  <label htmlFor="" className="approved">
                                    Online
                                  </label>
                                </div>
                                <div className="col-md-2 col-4">
                                  <label htmlFor="">Affiliate ID</label>
                                  <label htmlFor="" className="not-edit">
                                    -
                                  </label>
                                </div>
                                <div className="col-md-2 col-4">
                                  <label htmlFor="">Partner</label>
                                  <label htmlFor="" className="not-edit">
                                    -
                                  </label>
                                </div>
                                <div className="col-md-2 col-4">
                                  <label htmlFor="">Refferal Code</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.player_code}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Balance</label>
                                  <label htmlFor="" className="not-edit">
                                    {/* {data && data.data.wallet_balance.toFixed(2)} */}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Bonus</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.bonus}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Deposit</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.deposit}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">In Game</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.inGame}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Lock</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.lock}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Promo</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.promo}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Referral</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.referral}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Transfer</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.transfer}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Withdrawable</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.withdrawable}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Transfer Restriction</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.transfer_restriction}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Levels</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data?.data?.level}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Exp Points</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data?.exp_point}
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-card">
                          <div className="info-header">
                            <h6>Personal Information</h6>
                          </div>
                          <div className="uer-info">
                            <form action="">
                              <div className="row align-items-center">
                                <div className="col-3">
                                  {data?.data?.subscription === true && <img src={"img/vip-01.png"} className="" />}
                                  <img src={data?.data?.profile_pic_url?.url === "" ? "img/user2.png" : data?.data?.profile_pic_url?.url} className="user-profile-icon" />
                                </div>
                                <div className="col-9">
                                  <div className="row">

                                    <div className="col-5">
                                      <label htmlFor="">Login Id</label>
                                      <label htmlFor="" className="not-edit">
                                        {data && data.data.mobile_number}{data && data.data.email_id}{data && data?.data.guest_id ? 'Guest' : null}
                                      </label>
                                    </div>
                                    <div className="col-5">
                                      <label htmlFor="">Nick Name</label>
                                      <label htmlFor="" className="not-edit">
                                        {data && data.data.display_name}
                                      </label>
                                    </div>

                                    <div className="col-2  mt-3 mt-md-0">
                                      <label htmlFor="">Gender</label>
                                      <label htmlFor="" className="not-edit">
                                        -
                                      </label>
                                    </div>

                                    <div className="col-3 mt-3 mt-md-0">
                                      <label htmlFor="">Date of Birth</label>
                                      <label htmlFor="" className="not-edit">
                                        -
                                      </label>
                                    </div>
                                    <div className="col-3 mt-3 mt-md-0">
                                      <label htmlFor="">Code</label>
                                      <label htmlFor="" className="not-edit">
                                        {data && data.data.player_code}
                                      </label>
                                    </div>
                                    <div className="col-3 mt-3 mt-md-0">
                                      <label htmlFor="">Referral By</label>
                                      <label htmlFor="" className="not-edit">
                                        {data && data.data.reffered_by}
                                      </label>
                                    </div>


                                  </div>
                                </div>


                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-lg-0  mt-3">
                        <div className="info-card">
                          <div className="info-header">
                            <h6>Contact Info</h6>
                          </div>
                          <div className="uer-info">
                            <form action="">
                              <div className="row">
                                <div className="col-md-4 col-6">
                                  <label htmlFor="">E-mail</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.email_id}
                                  </label>
                                </div>
                                <div className="col-md-4 col-6 form-group">
                                  <label htmlFor="">Phone</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.mobile_number}
                                  </label>
                                </div>
                                <div className="col-md-4 col-6 form-group mt-3 mt-md-0">
                                  <label htmlFor="">Address</label>
                                  <label htmlFor="" className="not-edit">
                                    -
                                  </label>
                                </div>
                                <div className="col-md-4 col-6 mt-3 mt-md-0">
                                  <label htmlFor="">City</label>
                                  <label htmlFor="" className="not-edit">
                                    -
                                  </label>
                                </div>
                                <div className="col-md-4 col-6 mt-3 mt-md-0">
                                  <label htmlFor="">Country</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.country_code === "+91" && "India"}
                                  </label>
                                </div>
                                <div className="col-md-4 col-6 mt-3 mt-md-0">
                                  <label htmlFor="">Zip Code</label>
                                  <label htmlFor="" className="not-edit">
                                    -
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="info-card">
                          <div className="info-header">
                            <h6>Device Details</h6>
                          </div>
                          <div className="uer-info">
                            <form action="">
                              <div className="row">
                                <div className="col-12">
                                  <label htmlFor="">Device Details</label>
                                  <label htmlFor="" className="not-edit">
                                    {data?.data?.signup_device_details?.device_id}
                                  </label>
                                </div>
                                {/* <div className="col-md-4 col-6 form-group">
                            <label htmlFor="">Total Wagered</label>
                            <label htmlFor="" className="not-edit">
                              -
                            </label>
                          </div> */}
                                {/* <div className="col-md-4 col-6 form-group mt-3 mt-md-0">
                            <label htmlFor="">Exclude from Bonus</label>
                            <br />
                            <input type="checkbox" defaultChecked="" />
                          </div> */}
                                {/* <div className="col-md-4 col-6 mt-3 mt-md-0">
                            <label htmlFor="">Player category </label>
                            <label htmlFor="" className="not-edit">
                              -
                            </label>
                          </div> */}
                                <div className="col-md-6 col-6 mt-3 mt-md-0">
                                  <label htmlFor="">Verification</label>
                                  <label htmlFor="" className="not-edit">
                                    -
                                  </label>
                                </div>
                                <div className="col-md-6 col-6 mt-3 mt-md-0">
                                  <label htmlFor="">Date Registered</label>
                                  <label htmlFor="" className="not-edit">
                                    -
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="info-card">
                          <div className="info-header">
                            <h6>Social Preferences</h6>
                          </div>
                          <div className="uer-info">
                            <form action="">
                              <div className="row">
                                <div className="col-6">
                                  <label htmlFor="">Subscribed to Email</label>
                                  <br />
                                  <input type="checkbox" defaultChecked="" />
                                </div>
                                <div className="col-6">
                                  <label htmlFor="">
                                    Subscribed to Internal Message
                                  </label>
                                  <br />
                                  <input type="checkbox" defaultChecked="" />
                                </div>
                                <div className="col-6 mt-3 mt-md-0">
                                  <label htmlFor="">
                                    Subscribed to Internal Message
                                  </label>
                                  <br />
                                  <input type="checkbox" defaultChecked="" />
                                </div>
                                <div className="col-6 mt-3 mt-md-0">
                                  <label htmlFor="">Subscribed to Phone Call</label>
                                  <br />
                                  <input type="checkbox" />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                    </div>
                    {/* <div className="col-12 data-table overflow-visible py-2">

                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* player info model end */}
            {/* Chips Transfer Model */}
            <div
              className="modal fade"
              id="chipTransferModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-scrollable trans-modal modal-dialog-centered">
                <div className="modal-content">
                  <div
                    className="modal-header"
                    style={{ background: "#eceff4 !important" }}
                  >
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Transfer Chips
                    </h1>
                    <button
                      className="close-btn cb"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span>x</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="mb_modal">
                      <div>
                        <span>Login Id : </span> {playerAccountDetails?.mobile_number}
                      </div>
                      <div>
                        <span>Name : </span> {playerAccountDetails?.display_name}
                      </div>
                      <div className="row m-0 ps-2 pe-2">
                        <div className="col-md-4 d-inline mx-0 text-start">
                          Enter Chips :
                        </div>
                        <div className="col-md-8 d-inline mx-0">
                          <input
                            className="w-100 me-0 ms-0"
                            type="number"
                            name="add_amount" value={addChips.add_amount} onChange={handlechipsModelChange}
                          />
                          <span
                            className="error text-start d-block"
                            style={{
                              color: "rgb(172, 37, 37)",
                              fontSize: "10px",
                            }}
                          >
                            {error}
                          </span>
                        </div>
                      </div>
                      <br></br>
                      <div className="md-btn">
                        <button
                          className="btn btn-success btn-sm"
                          // onClick={() => AddChips(playerAccountDetails?._id)}
                          onClick={() => navigate("/transfer-chips", { player: playerAccountDetails })}
                        >
                          Transfer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* chip transfer model end */}
            <div className="pagination row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length">
                  {pageNo} of {playerData && playerData.totalPage}{" "}
                  <label>
                    Rows per page{" "}
                    <select
                      aria-controls="DataTables_Table_0"
                      name="tableLength" value={input.tableLength}
                      onChange={handelChange}
                    >
                      <option value="10">10</option>
                      <option value="25" defaultValue={true}>25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                  {pageNo > 1 && (<a onClick={() => setPageNo(pageNo - 1)}>
                    <i className="fa-solid fa-chevron-left"></i>
                  </a>)}
                  <span>
                    <a
                      className="paginate_button current"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="1"
                      tabIndex="0"
                    >
                      {pageNo}
                    </a>
                  </span>
                  {playerData && pageNo < playerData.totalPage &&
                    <a onClick={() => setPageNo(pageNo + 1)}>
                      <i className="fa-solid fa-chevron-right"></i>
                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Make Vip Player */}

        <Modal show={show} onHide={handleClose} className="vipaddModal" size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="text-center modal-title h4">Promo Code Redemption</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row fund_transfer_form_block">
                <div className="mb-2 col-md-6">
                  <label className="text-start d-block form-label" htmlFor="formPlayerCode">
                    Player Code
                  </label>
                  <input
                    name="playerCode"
                    placeholder="Enter player ID"
                    type="text"
                    id="formPlayerCode"
                    className="form-control"
                    value={playerCode}
                    onChange={(e) => setPlayerCode(e.target.value)}
                  />
                </div>
                <div className="mb-2 col-md-6">
                  <label className="text-start d-block form-label" htmlFor="formPromoCode">
                    Promo Code
                  </label>
                  <input
                    name="promocode"
                    placeholder="Enter promo code"
                    type="text"
                    id="formPromoCode"
                    className="form-control"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                </div>
                {playercodeData && Object.keys(playercodeData).length > 0 ? (
                  <>
                    <div className="fund_transfer_form p-0">
                      <div className="col-md-12 bal_mid_row responsive-clear bg-light">
                        <div className="row align-items-center">
                          <div className="col-sm-8 col-xs-6 no-padding-responsive ">
                            <div className="form-group">
                              <label htmlFor="" className="small_bal_label">
                                <span>Player Code :</span> {playercodeData.player_code || ''}
                              </label>
                            </div>
                            <div className="form-group">
                              <label htmlFor="" className="small_bal_label">
                                <span>Nickname :</span> {playercodeData.display_name || ''}
                              </label>
                            </div>
                            <div className="form-group">
                              <label htmlFor="" className="small_bal_label">
                                <span>Login Id :</span> {playercodeData.guest_id ? "Guest" : (playercodeData.mobile_number || playercodeData.email_id)}
                              </label>
                            </div>
                            <div className="form-group">
                              <label htmlFor="" className="small_bal_label">
                                <span>Unique Id :</span> {playercodeData._id || ''}
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-4 col-xs-12 no-padding-responsive ">
                            <div className="form-group">
                              <div className="bal_div">
                                <label htmlFor="">Balance </label>
                                <label>{playercodeData.wallet_balance?.toFixed(2) || ''}</label>
                                <label htmlFor="" id="credit">
                                  &nbsp;
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </>
                ) : (
                  ''
                )}


              </div>
              <div className="w-100 text-center mt-3">
                <span id="chip_msg" className="errmsg" style={{ fontSize: '16px', color: 'red' }}>{errorVip}</span>
                <span id="chip_msg" className="errmsg" style={{ color: 'green', fontSize: '16px' }}>{success}</span>
              </div>
              <button
                type="button"
                className="btnall mt-3 btn btn-primary"
                onClick={handleSave}
              >
                Save
              </button>
            </form>
          </Modal.Body>

        </Modal>





      </>
    </div >
  );
};

export default VIPPlayerManagement;
