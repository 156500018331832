import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

export default function PaymentLogs() {
    const opr_id = localStorage.getItem("opr_id");
    const [pageNo, setPageNo] = useState(1);
    const [requestBodyModal, setRequestBodyModal] = useState(false);
    const [responseModal, setResponseModal] = useState(false);
    const [requestBodyData, setRequestBodyData] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [filter, setFilter] = useState('Deposit');
    const [headerModal, setHeaderModal] = useState(false);
    const [headerData, setHeaderData] = useState(null);
    const [callbackData, setCallbackData] = useState(null);
    const [callbackModal, setCallbackModal] = useState(false);
    const [totalPage, setTotalPage] = useState('');
    const [search, setSearch] = useState('');

    const [data, setData] = useState([]);
    const [limit, setLimit] = useState('100');
    const [input, setInput] = useState({
        date: "recent",
        from: "",
        to: ''
    });

    const handelChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const fetchData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}payment-log?operator_code=${opr_id}&pageNo=${pageNo}&limit=${limit}&filter=${filter}&search=${search}&dateFilter=${input.date}&from=${input.from}&to=${input.to}`,
                {
                    headers: {
                        opr_auth_token: localStorage.getItem("sessionToken"),
                    }
                });

            if (res?.data?.status) {
                setTotalPage(res?.data?.data?.totalPage);
                setData(res?.data?.data?.data);
            }

        } catch (error) {
            console.error("Error fetching role data:", error);
        }
    };

    const copyToClipboard = (data, closeModal) => {
        navigator.clipboard.writeText(JSON.stringify(data, null, 2)).then(() => {
            alert("Copied to clipboard!");
            closeModal();
        }).catch(err => {
            alert("Failed to copy to clipboard!");
            console.error(err);
        });
    };

    const handleRequestBody = (requestBodyData) => {
        setRequestBodyData(requestBodyData);
        setRequestBodyModal(true);
    };

    const handleResponse = (responseData) => {
        setResponseData(responseData);
        setResponseModal(true);
    };

    const handleHeader = (headerData) => {
        setHeaderData(headerData);
        setHeaderModal(true);
    };


    const handleCallback = (headerData) => {
        setCallbackData(headerData);
        setCallbackModal(true);
    };

    const handleSearch = () => {
        fetchData();
    }

    useEffect(() => {
        fetchData();
    }, [pageNo, limit, filter, input]);

    const DateTimeFormat = (inputDate) => {

        var parsedDate = new Date(inputDate);

        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const day = parsedDate.getDate();
        const month = monthNames[parsedDate.getMonth()];
        const year = parsedDate.getFullYear();
        const hours = parsedDate.getHours();
        const minutes = parsedDate.getMinutes();
        const seconds = parsedDate.getSeconds();
        const ampm = hours >= 12 ? 'pm' : 'am';

        const formattedDate = `${day} ${month} ${year}, ${hours}:${minutes}:${seconds} ${ampm}`;

        // console.log(formattedDate);
        return formattedDate
    }

    return (
        <>
            <div>
                <div className="right-sidebar">
                    <div className="das-bg-block w-">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3">
                                    <h1 className="gamehistoryheading title_h1 same_heading text-start">
                                        Payment Logs
                                    </h1>
                                </div>
                                <div className="col-md-9">
                                    <div className="row user-select justify-content-end">
                                        <div className="col-md-3 mt-md-0">
                                            <div className="form-group">
                                                <select onChange={(e) => setFilter(e.target.value)}>
                                                    <option value="Deposit">Deposit</option>
                                                    <option value="Withdraw">Withdraw</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mt-2 mt-md-0">
                                            <div className="form-group">
                                                <select
                                                    name="date"
                                                    onChange={handelChange}
                                                    className="w-100"
                                                    value={input.date}
                                                >
                                                    <option value="recent">Recent</option>
                                                    <option value="today">Today</option>
                                                    <option value="current_week">Current Week</option>
                                                    <option value="last_week">Last Week</option>
                                                    <option value="current_month">Current Month</option>
                                                    <option value="last_month">Last Month</option>
                                                    <option value="last_six_month">Last 6 Month</option>
                                                    <option value="date_range">Date Range</option>
                                                </select>
                                            </div>
                                            {input.date === "date_range" && (<div id="target">
                                                <div className="date">
                                                    <p>
                                                        <label>From</label>
                                                        <input type="date" id="date" name="from" value={input.from} onChange={handelChange} />
                                                        <label>To</label>
                                                        <input type="date" id="date" name="to" value={input.to} onChange={handelChange} />
                                                    </p>
                                                </div>
                                            </div>)}
                                        </div>
                                        <div className="col-md-3 form-group mt-2 mt-md-0">
                                            <div className="input-group  input-group-sm">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search for...."
                                                    aria-describedby="basic-addon1"
                                                    name="searchPlayer"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                />
                                                <div className="input-group-prepend">
                                                    <span
                                                        className="input-group-text"
                                                        id="basic-addon1"
                                                        style={{ padding: "9px !important" }}
                                                        onClick={() => handleSearch()}
                                                    >
                                                        <i className="fa-solid fa-magnifying-glass" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper no-footer"
                    >
                        <div className="table-player">
                            <div className="table-list-mb">
                                <table className="table data-table">
                                    <thead>
                                        <tr>
                                            <th>Callback Received</th>
                                            <th>Sent Time</th>
                                            <th>Transaction ID</th>
                                            <th>Payment Gateway</th>
                                            <th>Callback Received Time</th>
                                            <th>Callback</th>
                                            <th>Header</th>
                                            <th>Request</th>
                                            <th>Response</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length === 0 ? (
                                            <tr>
                                                <td colSpan="9">No payment logs available</td>
                                            </tr>
                                        ) : (
                                            data.map((e, idx) => (
                                                <tr key={idx}>
                                                    <td className="text-center">
                                                        {e?.callback_received ? <img src="img/check.png" width="18" /> : <img src="img/cross.png" width="18" />}
                                                    </td>
                                                    <td>{e?.request_sent_time && DateTimeFormat(e?.request_sent_time)}</td>
                                                    <td>{e?.transaction_id}</td>
                                                    <td>{e?.payment_gateway}</td>
                                                    <td>{e?.callback_received_time && DateTimeFormat(e?.callback_received_time)}</td>
                                                    <td>
                                                        {e?.callback && typeof e?.callback === 'object' && Object.keys(e?.callback).length > 0 && (
                                                            <button
                                                                className="nav-link db-btn"
                                                                type="button"
                                                                onClick={() => handleCallback(e?.callback)}
                                                            >
                                                                <i className="fa-regular fa-eye p-0 border-0 bg-transparent"></i> View
                                                            </button>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {e?.header && typeof e?.header === 'object' && Object.keys(e?.header).length > 0 && (
                                                            <button
                                                                className="nav-link db-btn"
                                                                type="button"
                                                                onClick={() => handleHeader(e?.header)}
                                                            >
                                                                <i className="fa-regular fa-eye p-0 border-0 bg-transparent"></i> View
                                                            </button>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {e?.request && typeof e?.request === 'object' && Object.keys(e?.request).length > 0 && (
                                                            <button
                                                                className="nav-link db-btn"
                                                                type="button"
                                                                onClick={() => handleRequestBody(e?.request)}
                                                            >
                                                                <i className="fa-regular fa-eye p-0 border-0 bg-transparent"></i> View
                                                            </button>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {e?.response && typeof e?.response === 'object' && Object.keys(e?.response).length > 0 && (
                                                            <button
                                                                className="nav-link db-btn"
                                                                type="button"
                                                                onClick={() => handleResponse(e?.response)}
                                                            >
                                                                <i className="fa-regular fa-eye p-0 border-0 bg-transparent"></i> View
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>

                                </table>
                            </div>

                            {data.length > 0 && <div className="pagination pagination-text row justify-content-between align-items-center">
                                <div className="w-auto">
                                    <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                                        <select
                                            aria-controls="DataTables_Table_0"
                                            name="tableLength" value={limit}
                                            onChange={(e) => setLimit(e.target.value)}
                                            className="pag-select-block me-1"
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select> Records per page
                                    </div>
                                </div>

                                <div className="w-auto">
                                    Showing {pageNo}{" "}of {limit} items
                                </div>

                                <div className="w-auto">
                                    <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                                        <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                            className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                            <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                                        </a>
                                        <a onClick={() => setPageNo(pageNo - 1)}
                                            style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                            className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                            <img src="img/left-arrow.png" width="20" />
                                        </a>
                                        <span className="d-flex align-items-center">

                                            <select className="pag-select-block"
                                                aria-controls="DataTables_Table_0"
                                                name="pagination" value={pageNo}
                                                onChange={(e) => setPageNo(Number(e.target.value))}
                                            >
                                                {Array.from({ length: totalPage }, (_, index) => (
                                                    <option key={index + 1} value={index + 1} >
                                                        {index + 1}
                                                    </option>
                                                ))}



                                            </select> of {totalPage}
                                        </span>

                                        <a onClick={() => setPageNo(pageNo + 1)}
                                            style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                                            className={`${pageNo < totalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                                            <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                                        </a>


                                        <a onClick={() => setPageNo(totalPage)} style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                                            className={`${pageNo < totalPage ? '' : 'opacity-25'} border-0`}>

                                            <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>
            </div>

            {/* Request Body Modal */}
            <Modal show={requestBodyModal} onHide={() => setRequestBodyModal(false)} centered scrollable>
                <Modal.Header closeButton className="py-2 position-relative">
                    <Modal.Title>Request Body</Modal.Title>
                    <i className="fa-solid fa-copy copy_json_content position-absolute"
                        title="Copy"
                        onClick={() => copyToClipboard(requestBodyData, () => setRequestBodyModal(false))}></i>
                </Modal.Header>
                <Modal.Body>
                    <pre>{JSON.stringify(requestBodyData, null, 2)}</pre>
                </Modal.Body>
            </Modal>

            {/* CallBack Modal */}
            <Modal show={callbackModal} onHide={() => setCallbackModal(false)} centered scrollable>
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>CallBack</Modal.Title>
                    <i className="fa-solid fa-copy copy_json_content position-absolute"
                        title="Copy"
                        onClick={() => copyToClipboard(callbackData, () => setCallbackModal(false))}></i>
                </Modal.Header>
                <Modal.Body>
                    <pre>{JSON.stringify(callbackData, null, 2)}</pre>
                </Modal.Body>
            </Modal>

            {/* Response Modal */}
            <Modal show={responseModal} onHide={() => setResponseModal(false)} centered scrollable>
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Response Data</Modal.Title>
                    <i className="fa-solid fa-copy copy_json_content position-absolute"
                        title="Copy"
                        onClick={() => copyToClipboard(responseData, () => setResponseModal(false))}></i>
                </Modal.Header>
                <Modal.Body>
                    <pre>{JSON.stringify(responseData, null, 2)}</pre>
                </Modal.Body>
            </Modal>

            {/* Header Modal */}
            <Modal show={headerModal} onHide={() => setHeaderModal(false)} centered scrollable>
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Header Data</Modal.Title>
                    <i className="fa-solid fa-copy copy_json_content position-absolute"
                        title="Copy"
                        onClick={() => copyToClipboard(headerData, () => setHeaderModal(false))}></i>
                </Modal.Header>
                <Modal.Body>
                    <pre>{JSON.stringify(headerData, null, 2)}</pre>
                </Modal.Body>
            </Modal>
        </>
    );
};
