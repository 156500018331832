import React from "react";


const AIManagement = () => {
    return (
        <>

            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">AI Management</h1>
                    </div>
                    <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                        <p className="m-0">Unpublish</p>
                        <p className="m-0">Send Out</p>
                        <p className="m-0">Claim Back</p>
                        <button className="nav-link db-btn disabled"><i class="fa-solid fa-plus"></i></button>
                    </div>
                </div>
                <div>
                    <div className='container-fluid bg'>
                        <div className='row user-select mt-2'>

                            <div className="col-lg-3 col-md-3  col-6  col-xs-12">
                                <div className="form-group">
                                    <select name="" id="" className="w-100 ">
                                        <option value="" selected="">
                                            All
                                        </option>
                                        <option value="">Option</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3  col-6 mt-lg-0  col-xs-1">
                                <div className="form-group">
                                    <select name="" id="" className="w-100">
                                        <option value="" selected="">
                                            Ludo
                                        </option>
                                        <option value="">Rummy</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3  col-6 mt-lg-0  col-xs-1">
                                <div className="form-group">
                                    <select name="" id="" className="w-100">
                                        <option value="" selected="">
                                            Normal
                                        </option>
                                        <option value="">Normal</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 user-select col-6 mt-lg-0  col-xs-1">
                                <div className="input-group  input-group-sm">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for...."
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        name="search"
                                        defaultValue=""
                                    />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </span>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>

                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <table className="table data-table   table-borderless">
                                <thead>
                                    <tr>
                                        <th><input type="checkbox" /></th>
                                        <th>Profile Pic</th>
                                        <th>Username</th>
                                        <th>Display Name</th>
                                        <th>Bot Name</th>
                                        <th>Game</th>
                                        <th>Agent</th>
                                        <th>Bot Type</th>
                                        <th>Real Point</th>
                                        <th>In Game</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><input type="checkbox" /></td>
                                        <td>
                                            <img className="table-user-icon" src="img/user2.png" />
                                        </td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                    </tr>
                                </tbody>
                            </table></div>

                    </div>

                </div>
            </div>
        </>
    );
};

export default AIManagement;