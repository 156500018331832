import React from "react";


const LoginLog = () => {
    return (
        <>
            
            <div className="right-sidebar">
                    <div className="das-bg-block d-flex align-items-center justify-content-between">
                        <div className="das-title">
                            <h1 className="gamehistoryheading title_h1 same_heading">Message</h1>
                        </div>
                        <div>
                        <button className="nav-link db-btn disabled"><i className="fa-solid fa-plus"></i> Message</button>
                        <button className="nav-link db-btn disabled"><i className="fa-solid fa-trash"></i> Delete</button>
                        </div>
                    </div>

                    <div className='container-fluid bg'>
                        <div className='row user-select mt-2'>

                            <div className="col-lg-3 col-md-3 col-6 mb-2 mb-md-0 pe-1">
                                <div className="form-group">
                                    <select name="" id="" className="w-100 ">
                                        <option value="" selected="">
                                            Login
                                        </option>
                                        <option value="">Login</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-6 mt-lg-0 mb-2 mb-md-0 col-xs-1 user-select user-selectinput d-flex align-items-center gap-1 ps-1">
                                <input type="Date" value="" />
                            </div>
                            <div className="col-lg-3 col-md-3 col-6 mt-lg-0  col-xs-1 user-select user-selectinput d-flex align-items-center gap-1 pe-1">
                                <input type="Date" value="" />
                            </div>
                            
                            <div className="col-lg-3 col-md-3 user-select col-6 mt-lg-0  col-xs-1 ps-1">
                                <div className="input-group  input-group-sm">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for...."
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        name="search"
                                        defaultValue=""
                                    />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </span>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>

                    <div>
                        

                        <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <div className="table-list-mb">
                        <table className="table data-table   table-borderless">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Player Name</th>
                                <th>Mobile NO</th>
                                <th>IP Address</th>
                                <th>Device ID</th>
                                <th>Log Type</th>
                                <th>Log Status</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td> 
                                <td>--</td>
                                <td>--</td>
                            </tr>
                        </tbody>
                    </table></div>
                    </div>

                    

                        </div>

                    </div>
                </div>
            </>
    );
};

export default LoginLog;