import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

export default function GameIcon() {
    const [data, setData] = useState([]);
    const [lgShow, setLgShow] = useState(false);
    const [gameEdit, setGameEdit] = useState(null);
    const [img, setImg] = useState('');

    const fetchData = async () => {
        const sessionToken = localStorage.getItem("sessionToken");
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}gameList`, {
                headers: {
                    opr_auth_token: sessionToken,
                },
            });
            setData(res?.data?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleToggle = async (game) => {
        setLgShow(true);
        setGameEdit(game);
    };

    const handleImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setImg(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleSaveChanges = async () => {
        if (gameEdit) {
            console.log(gameEdit.game_icon, 'gameEdit.game_icon');
            const formData = new FormData();
            formData.append('opr_id', localStorage.getItem('opr_id'));
            formData.append('key_to_change', 'game_icon');
            formData.append('game_code', gameEdit?.game_code);
            formData.append('game_icon', document.getElementById('fileInput').files[0]);
            console.log(document.getElementById('fileInput').files[0], 'filefile');
            

            const sessionToken = localStorage.getItem("sessionToken");
            try {
                const res = await axios.patch(
                    `${process.env.REACT_APP_API_PATH}patchGameList`,
                    formData,
                    {
                        headers: {
                            opr_auth_token: sessionToken,
                        },
                    }
                );
                if (res?.data?.status === 200) {
                    alert(res?.data?.message);
                    setLgShow(false);
                } else {
                    alert(res?.data?.message);
                }
            } catch (error) {
                console.error("Error saving changes:", error);
            }
        }
    };


    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Game List</h1>
                    </div>

                </div>

                <div>
                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <div className="table-list-mb">
                                <table className="table data-table table-borderless">
                                    <thead>
                                        <tr>
                                            <th>Game Name</th>
                                            <th>Game Code</th>
                                            <th>Operational Status</th>
                                            <th>Priority</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 ? (
                                            data.map((game) => (
                                                <tr key={game?._id}>
                                                    <td className="text-start">
                                                        {game?.game_status === 'true' ? (
                                                            <span className="active_status d-inline-block"></span>
                                                        ) : (
                                                            <span className="inactive_status d-inline-block"></span>
                                                        )}
                                                        {game?.game_name}
                                                    </td>
                                                    <td>{game?.game_code}</td>

                                                    <td>{game?.operational_status}</td>
                                                    <td>{game?.priority}</td>
                                                    <td>
                                                        <button
                                                            className={`nav-link db-btn`}
                                                            onClick={() => handleToggle(game)}
                                                        >
                                                            Config
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4">No Data Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Game</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light">
                    {gameEdit && (
                        <div className="row">

                            <div className="col-md-4 mb-2">
                                <Form.Group>
                                    <Form.Label className="text-start d-block">Game Name</Form.Label>
                                    <Form.Control
                                        className="bg-light shadow-none"
                                        type="text"
                                        value={gameEdit?.game_name}
                                        onChange={(e) =>
                                            setGameEdit({ ...gameEdit, game_name: e.target.value })
                                        }
                                        readOnly
                                    />
                                </Form.Group>
                            </div>


                            <div className="col-md-4 mb-2">
                                <Form.Group>
                                    <Form.Label className="text-start d-block">Game Code</Form.Label>
                                    <Form.Control
                                        className="bg-light shadow-none"
                                        type="text"
                                        value={gameEdit?.game_code}
                                        onChange={(e) =>
                                            setGameEdit({ ...gameEdit, game_code: e.target.value })
                                        }
                                        readOnly
                                    />
                                </Form.Group>
                            </div>


                            {/* <div className="col-md-4 mb-2">
                                <Form.Group>
                                    <Form.Label className="text-start d-block">Game Icon</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={gameEdit?.game_icon}
                                        onChange={(e) =>
                                            setGameEdit({ ...gameEdit, game_icon: e.target.value })
                                        }
                                    />
                                </Form.Group>
                            </div> */}


                            <div className="col-md-4 mb-2">
                                <Form.Group>
                                    <Form.Label className="text-start d-block">Game Category</Form.Label>
                                    <Form.Control
                                        className="bg-light shadow-none"
                                        type="text"
                                        value={Object.keys(gameEdit?.game_category).join(', ')}
                                        readOnly
                                    />
                                </Form.Group>
                            </div>


                            <div className="col-md-4 mb-2">
                                <Form.Group>
                                    <Form.Label className="text-start d-block">Operational Status</Form.Label>
                                    <Form.Control
                                        className="bg-light"
                                        type="text"
                                        value={gameEdit?.operational_status}
                                        readOnly
                                    />
                                </Form.Group>
                            </div>


                            <div className="col-md-4 mb-2">
                                <Form.Group>
                                    <Form.Label className="text-start d-block">Provider Name</Form.Label>
                                    <Form.Control
                                        className="bg-light"
                                        type="text"
                                        value={gameEdit?.provider_name}
                                        readOnly
                                    />
                                </Form.Group>
                            </div>


                            <div className="col-md-4 mb-2">
                                <Form.Group>
                                    <Form.Label className="text-start d-block">Game Tags</Form.Label>
                                    <Form.Control
                                        className="bg-light"
                                        type="text"
                                        value={gameEdit?.game_tag.join(', ')}
                                        readOnly
                                    />
                                </Form.Group>
                            </div>


                            {gameEdit.integration_env_config.map((integration, index) => (
                                <div key={integration?._id} className="mb-0">
                                    <div className="row">
                                        <div className="col-md-3 mb-2">
                                            <Form.Label className="text-start d-block">Int Platform</Form.Label>
                                            <Form.Control
                                                className="bg-light"
                                                type="text"
                                                value={integration?.int_platform}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-md-3 mb-2">
                                            <Form.Label className="text-start d-block">Opening Env</Form.Label>
                                            <Form.Control
                                                className="bg-light"
                                                type="text"
                                                value={integration?.opening_env}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-md-3 mb-2">
                                            <Form.Label className="text-start d-block">Orientation</Form.Label>
                                            <Form.Control
                                                className="bg-light"
                                                type="text"
                                                value={integration?.orientation}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-md-3 mb-2">
                                            <Form.Label className="text-start d-block">Url Path</Form.Label>
                                            <Form.Control
                                                className="bg-light"
                                                type="text"
                                                value={integration?.url_path}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}


                            <div className="col-md-4 mb-2">
                                <Form.Group>
                                    <Form.Label className="text-start d-block">Game Subcategory</Form.Label>
                                    <Form.Control
                                        className="bg-light"
                                        type="text"
                                        value={gameEdit?.game_subcategory.join(', ')}
                                        readOnly
                                    />
                                </Form.Group>
                            </div>
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-6 col-md-3">
                                        <Form.Label className="text-start d-block">Game Icon</Form.Label>
                                        <div className="w-100 bg-white border rounded p-2">
                                            <img src={img || gameEdit?.game_icon} className="w-100 img-fluid rounded" />
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <Form.Label className="text-start d-block">Upload Icon</Form.Label>
                                        {/* <div className="w-100 bg-white border rounded p-2 position-relative" style={{ height: 'calc(100% - 36px)' }}> */}
                                        <Form.Control className="w-100 top-0 start-0 end-0 bottom-0"
                                            type="file"
                                            id="fileInput"
                                            onChange={handleImage}
                                            name="qr_code"
                                            accept="image/*"
                                            required />
                                        {/* <label htmlFor="file" className="position-absolute w-100 top-0 start-0 end-0 bottom-0 border-0 bg-transparent d-flex justify-content-center align-items-center text-uppercase fw-media"
                                                style={{ fontSize: '18px', color: '#333', lineHeight: '28px', cursor: 'pointer' }}>Change <br /> Icon</label> */}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>


                <Modal.Footer className="d-flex justify-content-center">
                    <Button
                        variant="primary"
                        onClick={handleSaveChanges}
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}
