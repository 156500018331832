import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Loader from "../Components/Loader";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ChipsTransfer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [errorTransaction, setErrorTransaction] = useState('');

    const [addChips, setAddChips] = useState({
        add_amount: "",
        remarks: "",
        player_name: ""
    })
    const [error, setError] = useState()
    const [isLoaderActive, setIsLoaderActive] = useState(false)
    const [searchPlayerList, setSearchPlayerList] = useState();
    const [playerData, setPlayerData] = useState()

    useEffect(() => {
        if (location.state) {
            setPlayerData(location.state)
        }
    }, [])
    useEffect(() => {

        if (playerData) {
            setAddChips({
                ...addChips,
                player_name: playerData?.display_name
            })
        }
    }, [playerData])

    // const playerData = location.state;
    // console.log("🚀 ~ file: ChipsTransfer.js:8 ~ ChipsTransfer ~ playerData:", playerData)


    const AddChips = async (id) => {
        setIsLoaderActive(true);
        try {
            console.log("🚀 ~ file: PlayerManagement.js:197 ~ AddChips ~ addChips:", addChips)
            if (addChips.add_amount === "" || parseInt(addChips.add_amount) <= 0) {
                setError("please enter valid amount or greater than 0")
                setIsLoaderActive(false);
            }
            else {




                const response = await axios.post(
                    process.env.REACT_APP_TRANSACTION_API + "transaction/deposit-confirm",
                    {
                        user_id: localStorage.getItem("login_id"),
                        user_type: "123456",
                        module: 'deposit',
                        deposit_method: "BO-transfer",
                        amount: parseInt(addChips.add_amount),
                        amount_type: "",
                        partial_amount: "",
                        operator_id: localStorage.getItem("opr_id"),
                        player_id: playerData?._id,
                        payment_gateway: "manual",
                        transaction_id: "",
                        _id: "0",
                        country: "+91",
                        remarks: addChips.remarks,
                    }, {
                    headers: {
                        token: localStorage.getItem("sessionToken")
                    }
                }
                );
                console.log(response);
                if (response.data.status === true) {
                    //alert("Trasaction successful");
                    setErrorTransaction("Trasaction successful");
                    setPlayerData(prevdata => {
                        return {
                            ...prevdata,
                            wallet_balance: prevdata.wallet_balance + parseInt(addChips.add_amount)
                        }
                    });

                } else if (response.data.status === false && response.data.message === "invaild Token") {
                    //alert("Invaild Token, please login again");
                    setErrorTransaction("Invaild Token, please login again");
                    localStorage.clear();
                    navigate("/");
                } else {
                    //alert(response.data.message);
                    setErrorTransaction(response.data.message);
                }
                setIsLoaderActive(false)
            }
        } catch (error) {
            alert("internal server error")
            setIsLoaderActive(false)
        }
    }

    console.log("ggg", playerData)

    const handelChange = (e) => {
        setAddChips({
            ...addChips,
            [e.target.name]: e.target.value
        })
        setSearchPlayerList()
    }

    function handleKeyPress(event) {
        if (event.charCode >= 48 && event.charCode <= 57) {
            // console.log(event.target.value)
        } else {
            event.preventDefault();
        }
    }

    const amountConvertor = (amount) => {
        if (amount.length > 5 && amount.length <= 7) {
            const value = (amount / 100000) || 0;
            const updatedValue = value.toFixed(2) + " Lakh"
            return updatedValue;
        }
        else if (amount.length > 7) {
            const value = (amount / 10000000) || 0;
            const updatedValue = value.toFixed(2) + " Cr."
            return updatedValue;
        }
        else {
            return amount;
        }
    }

    const SearchPlayerList = async () => {
        // if (addChips?.player_name.length > 1 || playerData?.display_name)
        if (addChips?.player_name.length > 1) {
            const response = await axios.get(
                process.env.REACT_APP_API_PATH +
                `playerManagement?limit=25&pageNo=1&player=${addChips.player_name}`,
                {
                    headers: {
                        admin_auth_token: localStorage.getItem("omsToken"),
                        opr_auth_token: localStorage.getItem("sessionToken")
                    }
                }
            );
            console.log(response.data);
            if (response.data) {
                setSearchPlayerList(response.data.data);
                if (response.data.message) {
                    alert(response.data.message)
                }
            }
        }
        else {
            alert('Please enter at least two characters');
        }

    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-sm-8 fund_transfer_form_block">
                    <ul className="nav nav-tabs new_tabs border-0" style={{ marginTop: 30 }}>
                        <li className="no_select active">
                            <a
                                data-bs-toggle="tab"
                                href="#home"
                                id="deposit_change"
                                className="tab_color active"
                                style={{
                                    background: "rgba(42, 53, 0, 0.44)",
                                    transition: "all 1s ease 0s",
                                    color: "rgb(255, 255, 255)"
                                }}
                                aria-expanded="true"
                            >
                                Chips Transfer
                            </a>
                        </li>
                        {/* <li className="no_select">
                            <a
                                data-bs-toggle="tab"
                                id="widthdraw_change"
                                className="tab_color"
                                aria-expanded="false"
                            >
                                Claim Back
                            </a>
                        </li> */}
                    </ul>
                    <div className="tab-content">
                        <div id="home" className="tab-pane fade in active show">
                            <div className="" style={{ padding: 0 }}>
                                <fieldset
                                    className="no_border_field  p-2 fund_transfer_form"
                                    style={{ border: "1px solid #cecece", borderTop: "none" }}
                                >
                                    <div className="row">
                                        <div className="col-md-5 col-sm-5 no-padding-responsive all_set">
                                            <div className="form-group">
                                                <label htmlFor="">User ID </label>
                                            </div>
                                            <div className="form-group">
                                                <div class="input-group  input-group-sm position-relative">
                                                    <input
                                                        type="text"
                                                        className="search form-control input-sm ui-autocomplete-input"
                                                        placeholder="search by Username"
                                                        id="search_player"
                                                        Name="player_name"
                                                        onChange={handelChange}
                                                        value={addChips.player_name}
                                                        autoComplete="off"
                                                        readOnly={location.state ? true : false}
                                                    />
                                                    {location.state ? null : <div class="input-group-prepend"><span class="input-group-text" id="basic-addon1" style={{ minHeight: "35px" }} onClick={SearchPlayerList}><i class="fa-solid fa-magnifying-glass"></i></span></div>}
                                                    <input
                                                        type="hidden"
                                                        placeholder=""
                                                        defaultValue={0}
                                                        className="form-control"
                                                        id="player_list"
                                                        name="player_list"
                                                    />
                                                    <span id="search_err" className="errmsg" />
                                                    {searchPlayerList?.length === 0 && <ul className="ui-autocomplete ui-front ui-menu searchdroupdown ui-widget ui-widget-content" id="ui-id-1" tabIndex="0" style={{ display: "block", maxHeight: "250px", overflow: 'scroll' }}>
                                                        <li>No Record Found</li>
                                                    </ul>}
                                                    {searchPlayerList?.length > 0 && <ul className="ui-autocomplete ui-front ui-menu searchdroupdown ui-widget ui-widget-content" id="ui-id-1" tabIndex="0" style={{ display: "block", maxHeight: "250px", overflow: 'scroll' }}>{searchPlayerList && searchPlayerList?.map((ele, i) => (<li className="ui-menu-item" id="ui-id-9071" tabIndex="-1" onClick={() => { setPlayerData(ele); setSearchPlayerList() }}>{ele.display_name}</li>))}
                                                    </ul>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2"> </div>
                                        <div className="col-md-5 col-sm-5 no-padding-responsive all_set">
                                            <div className="form-group">
                                                <label htmlFor="" className="deposit_withdrwal">
                                                    Amount To Deposit<span className="errmsg_rqd">*</span> :{" "}
                                                </label>
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="chip_money"
                                                    // onkeypress="return isFloat(event)"
                                                    // required="required"
                                                    placeholder="Please Enter Amount"
                                                    // id="chip_money"
                                                    Name="add_amount"
                                                    onChange={handelChange}
                                                    value={addChips.add_amount}
                                                    className="search form-control input-sm"
                                                    onKeyPress={handleKeyPress}
                                                    maxLength="11"
                                                />
                                                {addChips?.add_amount.length > 5 && <div className="text-start p-0 m-0 small-txt"> ({amountConvertor(addChips?.add_amount)})</div>}
                                                <span id="chip_msg" className="errmsg" >{error}</span>
                                            </div>
                                        </div>
                                        {playerData && <div className="col-md-12 bal_mid_row responsive-clear">
                                            <div className="row">
                                                <div className="col-sm-8 col-xs-6 no-padding-responsive ">
                                                    <div className="form-group">
                                                        <label htmlFor="" className="small_bal_label">
                                                            <span>Player Code :</span> {playerData?.player_code}
                                                        </label>
                                                        {/* <label
                                                            htmlFor=""
                                                            id="state_agent"
                                                            className="small_bal_label"
                                                        >
                                                            &nbsp;
                                                        </label> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="" className="small_bal_label">
                                                            <span>Nickname :</span> {playerData?.display_name}
                                                        </label>
                                                        {/* <label
                                                            htmlFor=""
                                                            id="agent"
                                                            className="small_bal_label"
                                                        >
                                                            &nbsp;
                                                        </label> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="" className="small_bal_label">
                                                            <span>Login Id :</span> {playerData?.guest_id ? "Guest" : (playerData?.mobile_number || playerData?.email_id)}
                                                        </label>
                                                        {/* <label
                                                            htmlFor=""
                                                            id="master"
                                                            className="small_bal_label"
                                                        >
                                                            &nbsp;
                                                        </label> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="" className="small_bal_label">
                                                            <span>Unique Id :</span> {playerData?._id}
                                                        </label>
                                                        {/* <label
                                                            htmlFor=""
                                                            id="distributor"
                                                            className="small_bal_label"
                                                        >
                                                            &nbsp;
                                                        </label> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col-xs-12 no-padding-responsive ">
                                                    <div className="form-group">
                                                        <div className="bal_div">
                                                            <label htmlFor="" >Balance </label>
                                                            <label>{playerData?.wallet_balance?.toFixed(2)}</label>
                                                            <label htmlFor="" id="credit">
                                                                &nbsp;
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        <div className="col-md-12 no-padding-responsive responsive-clear">
                                            <div className="form-group">
                                                <label htmlFor="">
                                                    Remark<span className="errmsg_rqd">*</span>{" "}
                                                </label>
                                            </div>
                                            <div className="form-group">
                                                <textarea
                                                    className="form-control search"
                                                    id="remark"
                                                    rows={1}
                                                    name="remarks"
                                                    onChange={handelChange}
                                                    value={addChips.remarks}
                                                />
                                                <span id="remark_msg" className="errmsg" />
                                                <div className="clearfix" />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 hidden" id="message_show">
                                            <div className="jumbotron my_wrapper text-center">
                                                <span
                                                    id="chip_msg_succ"
                                                    className="errmsg"
                                                    style={{ fontSize: 14, color: "forestgreen" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="border-box col-sm-12 text-center email">
                                                <div className="buttons m-t-1">
                                                    <button
                                                        style={{ marginRight: "5px" }}
                                                        type="button"
                                                        className="btn btn-warning my_color"
                                                        // id="save_chip"
                                                        onClick={() => navigate('/player-management')}

                                                    >
                                                        Back  </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-warning my_color"
                                                        // id="save_chip"
                                                        onClick={() => AddChips()}
                                                        disabled={isLoaderActive ? true : false}
                                                    >
                                                        {isLoaderActive ? <Loader /> : 'Send'}                                                    </button>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-warning hidden"
                                                        data-bs-toggle="modal"
                                                        data-target="#deposit_confir"
                                                        id="depo"
                                                    >
                                                        hidden
                                                    </button>
                                                </div>
                                                <p className="text-center mb-0 mt-2">{errorTransaction}</p>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ChipsTransfer