import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
// import TableHeader from "./TableHeader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";
import $ from "jquery";
import LoadingBar from "react-top-loading-bar";
import Skeleton from "react-loading-skeleton";
import ChipsTransfer from "./ChipsTransfer";
import { Col, Form, Modal, Row, Alert } from "react-bootstrap";
import { Button } from "react-scroll";
const PlayerManagement = ({ vipPlayers }) => {
  console.log(vipPlayers, 'vip_players444')
  const [input, setInput] = useState({
    tableLength: "100",
    opr_id: "",
    from: "",
    to: ""
  })
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const [pageNo, setPageNo] = useState(1)
  const [playerData, setPlayerData] = useState();
  console.log("🚀 ~ PlayerManagement ~ playerData:", playerData)
  const [activetoggle, setActiveToggle] = useState();
  const [parentSelectedOptions, setParentSelectedOptions] = useState({
    sortBy: { label: 'Sign Up', value: 'signup_date' },
    orderBy: { img: "../img/dec.png", value: "Descending" },
    filterByDate: '',
    filter: 'nonNetWorkPlayer',
    search: ""
  });
  const [playerId, setPlayerId] = useState();
  const [subWallet, setSubWallet] = useState();
  const [dropdownActive, setDropdownActive] = useState(
    Array(playerData && playerData.data?.length).fill(false)
  );
  const [data, setData] = useState();
  const [playerAccountDetails, setPlayerAccountDetails] = useState();
  const [addChips, setAddChips] = useState({
    add_amount: ""
  })
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false)
  const [Search, setSearch] = useState("");
  const [block, setBlock] = useState({});
  const [totalPage, setTotalPage] = useState(0)


  // const [refresh,setRefresh] = useState(false);

  $(document).ready(function () {

    // Hide dropdown menu when clicking anywhere outside of it
    $(document).click(function (e) {
      if (!$(e.target).closest('.dropdown1').length) {
        $(".dropdown1 dd ul").hide();
      }
    });

    // Hide dropdown menu when clicking on an item inside the dropdown
    $(".dropdown1 dd ul li a").on('click', function () {
      $(".dropdown1 dd ul").hide();
    });

    // Gamelist Selection
    $('.gamelist li').click(function () {
      $(this).addClass('active').siblings().removeClass('active');
      var getValue = $(this).find('label').text();
      $('.multiSel').text(getValue);
    });

    // Orderlist Selection
    $('.orderlist li').click(function () {
      $(this).addClass('active-over').siblings().removeClass('active-over');
      var getSrc = $(this).find('img').attr('src');
      $('.multiSel1').attr('src', getSrc);
    });

    $('.orderlist li').click(function () {
      $(this).addClass('active').siblings().removeClass('active');
    });
  });

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible((prevVisible) => !prevVisible);
  };

  const handleSortOptionChange = (value) => {
    setParentSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      sortBy: value,
    }));
  };

  const handleOrderOptionChange = (value) => {
    setParentSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      orderBy: value,
    }));
  };

  const handleFilter = (e) => {
    setPageNo(1)
    // console.log(value,"++++++++++++++")
    setParentSelectedOptions({
      ...parentSelectedOptions,
      filterByDate: e.target.value,
    })
  }

  const handleSearch = (e) => {
    setPageNo(1)
    console.log("GG", e)
    setParentSelectedOptions({
      ...parentSelectedOptions,
      search: e,
    })
  }

  useEffect(() => {
    if (playerData) {
      setActiveToggle(Array(playerData?.data?.length).fill(false));
      setDropdownActive(Array(playerData?.data?.length).fill(false));
    }
  }, [playerData]);


  const toggleButton = (index, id, doc) => {
    setDropdownActive(Array(playerData.data.length).fill(false))
    setActiveToggle((dataa) => {
      const Toggledata = dataa.map((value, i) => (i === index ? !value : false));
      return Toggledata;
    });
    setPlayerId(id);
    setPlayerAccountDetails(doc);
    setAddChips({
      add_amount: ""
    })
    setError()
  };
  const limit = 20;
  //   const pageNo = 2;

  useEffect(() => {


    fetchData();
  }, [input, pageNo, parentSelectedOptions]);
  const fetchData = async () => {
    const opr_code = localStorage.getItem('opr_id');
    setLoading(true)
    const response = await axios.get(
      process.env.REACT_APP_API_PATH +
      `playerManagement?limit=${input.tableLength}&pageNo=${pageNo}&sortBy=${parentSelectedOptions.sortBy.value}&orderBy=${parentSelectedOptions.orderBy.value}&dateFilter=${parentSelectedOptions.filterByDate}&player=${parentSelectedOptions.search}&from=${input.from}&to=${input.to}&filter=${parentSelectedOptions.filter}&opr_id=${opr_code}`,
      {
        headers: {
          admin_auth_token: localStorage.getItem("omsToken"),
          opr_auth_token: localStorage.getItem("sessionToken")
        }
      }
    );
    console.log(response.data);
    if (response.data && response.data?.data !== 'Invalid token') {
      setPlayerData(response.data);
      setLoading(false)
      setTotalPage(response.data.totalPage)
      if (response.data.message) {
        setLoading(false)
        alert(response.data.message)
      }
    } else if (response.data?.data === 'Invalid token') {
      if (localStorage.getItem("role") === "Operator") {
        navigate("/")
      }
      else if (localStorage.getItem("role") === "Sub Operator") {
        navigate("/sub-operator-login")
      }
      else if (localStorage.getItem("role") === "Affiliate Operator") {
        navigate("/affiliate-operator")
      }
      else {
        navigate("/")
      }
      localStorage.removeItem("login_type")
      localStorage.removeItem("sessionToken");
      localStorage.removeItem("role")
      localStorage.removeItem("omsToken")
      localStorage.removeItem("opr_id");
      localStorage.removeItem("login_id")
    }
  }
  const handelChange = (e) => {


    setInput({
      ...input,
      [e.target.name]: e.target.value
    })
    setPageNo(1);
  }

  // Function to handle receiving the selectedOptions value from TableHeader
  const handleSelectedOptionsChange = (e) => {
    setPageNo(1)
    console.log("🚀 ~ handleSelectedOptionsChange ~ e.target.value:", e.target.value)
    // setParentSelectedOptions(e.target.value);
    setParentSelectedOptions({
      ...parentSelectedOptions,
      filter: e.target.value,
    })
  };

  const handleSubWallet = (data, index) => {
    setSubWallet(data);
    setActiveToggle(Array(playerData.data.length).fill(false))

    setDropdownActive((dataa) => {
      const Toggledata = dataa.map((value, i) =>
        i === index ? !value : false
      );
      return Toggledata;
    });
  };
  const [playerTransactions, setPlayerTransactions] = useState({ data: [], totalPage: 10 });
  const handlePlayerInfo = async (id) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_PATH + `playerInfo?id=${id}`)
      console.log(response.data);
      setData(response.data)

      // Fetch player transactions with only player_id filter
      const transactionsResponse = await axios.get(`${process.env.REACT_APP_API_PATH}transactionDetails`, {
        params: { player_id: id },
        headers: {
          admin_auth_token: localStorage.getItem("omsToken"),
          opr_auth_token: localStorage.getItem("sessionToken"),
        }
      });

      //console.log("Player Transactions:", transactionsResponse.data);

      const transactionsData = transactionsResponse.data.data || [];
      const totalPages = transactionsResponse.data.totalPage !== null ? transactionsResponse.data.totalPage : 10;

      setPlayerTransactions({
        data: transactionsData,
        totalPage: totalPages,
      });


    } catch (error) {
      console.log(error.msg)
    }
  }

  const handlechipsModelChange = async (e) => {
    setAddChips({
      ...addChips,
      [e.target.name]: e.target.value
    })
  }

  const AddChips = async (id) => {
    try {
      console.log("🚀 ~ file: PlayerManagement.js:197 ~ AddChips ~ addChips:", addChips)
      if (addChips.add_amount === "" || parseInt(addChips.add_amount) <= 0) {
        setError("please enter valid amount or greater than 0")
      }
      else {
        const response = await axios.post(
          process.env.REACT_APP_TRANSACTION_API + "transaction/deposit-confirm",
          {
            user_id: localStorage.getItem("login_id"),
            user_type: "123456",
            module: 'deposit',
            deposit_method: "BO-transfer",
            amount: parseInt(addChips.add_amount),
            amount_type: "",
            partial_amount: "",
            operator_id: localStorage.getItem("opr_id"),
            player_id: playerAccountDetails?._id,
            payment_gateway: "manual",
            transaction_id: "",
            _id: "0",
            country: "+91",
            remarks: "",
          }, {
          headers: {
            token: localStorage.getItem("sessionToken")
          }
        }
        );
        console.log(response);
        if (response.data.status === true) {
          alert("Trasaction successful");
          window.location.reload();
        } else {
          alert("invalid data");
        }
      }
      //     const response = await axios.post(process.env.REACT_APP_API_PATH + "chips-transfer", {
      //       id: playerAccountDetails?._id,
      //       add_amount: parseInt(addChips.add_amount)
      //     })
      //     console.log("🚀 ~ file: PlayerManagement.js:197 ~ AddChips ~ response:", response)
      //     if (response.data.status === true) {
      //       alert(response.data.message)
      //       window.location.reload();
      //     }
      //     else {
      //       alert("invalid details")
      //     }
      //   }
    } catch (error) {
      alert("internal server error")
    }
  }

  const DateTimeFormat = (isoString) => {

    const date = new Date(isoString);

    // Extract UTC date components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    // Format as "YYYY-MM-DD HH:mm:ss"
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  // useEffect(() => {
  //   const handleDocumentClick = (e) => {
  //     if (!e.target.closest('#dropdownBtn') && !e.target.closest('#dropdown')) {
  //       setDropdownActive(Array(playerData && playerData.data?.length).fill(false));
  //     }
  //   };

  //   document.addEventListener('click', handleDocumentClick);

  //   return () => {
  //     document.removeEventListener('click', handleDocumentClick);
  //   };
  // }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleSearch(Search);
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [Search]);
  

  console.log(parentSelectedOptions)
  console.log(playerId);
  const formatValue = (value) => {
    if (value >= 10000000) {
      return <> {(value / 10000000).toFixed(1) + 'Cr'}</>;
    } else if (value >= 100000) {
      return <> {(value / 100000).toFixed(1) + 'Lac'}</>;
    }
    // else if (value >= 1000) {
    //   return <> ({(value / 1000).toFixed(1) + 'K'})</>;
    // } 
    else {
      return <>{value}</>;
    }
  };

  const handleClose = () => {
    setShow(false)
  }



  const handleBlock = async (playerId, is_blocked) => {
    console.log(playerId, "block user block id")
    try {
      const identifier = is_blocked ? 'unblock' : 'block';
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}block-player`,
        {
          "playerCode": playerId,
          "identifier": identifier,
        },
        {
          headers: {
            admin_auth_token: localStorage.getItem("omsToken"),
            opr_auth_token: localStorage.getItem("sessionToken")
          }
        }
      );

      if (response.data?.data?.status) {
        setBlock((prevState) => ({
          ...prevState,
          [playerId]: !prevState[playerId]
        }));
        alert(response.data.data.message);
        fetchData();
      }
    } catch (error) {
      console.error('Error blocking/unblocking player:', error);
      alert('An error occurred while updating the block status.');
    }
  };

  const [formData, setFormData] = useState({
    username: "",
    password: ""
  })
  const [errorForPlayerCreation, setErrorForPlayerCreation] = useState('');
  // Handle input changes
  const handleCreatePlayerChange = (e) => {

    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleFormSubmit = async () => {
    setErrorForPlayerCreation('')
    const oprCode = localStorage.getItem('opr_id')
    const { username, password } = formData;

    // Validation
    if (!username || !password) {
      setErrorForPlayerCreation('Username and password cannot be null');
      return;
    }

    if (username.length !== 8) {

      setErrorForPlayerCreation('Username must be exact 8 characters long');
      return;
    }

    if (/\d/.test(username.charAt(0))) {
      setErrorForPlayerCreation('Username cannot start with a number');
      return;
    }

    if (/[A-Z]/.test(username)) {
      setErrorForPlayerCreation('Username cannot contain capital letters');
      return;
    }

    try {
      const createPlayer = await axios.post(`${process.env.REACT_APP_TRANSACTION_API}login/username`, {
        operator_id: oprCode,
        username: formData.username,
        password: formData.password,
        country_code: "+91",
        currency: "INR",
        login_type: "username",
        device_details: "{}",

      })
      if (createPlayer.data.message == "Invalid password" || createPlayer.data.message == "Login successful") {
        setErrorForPlayerCreation('username already taken choose a different');
        return
      }
      if (createPlayer.data.status === true) {
        handleClose()
        alert("player created successfully")
        setFormData({ username: "", password: "" })

        fetchData();
      }
      else {

        alert(createPlayer.data.message)

        console.log("🚀 ~ handleFormSubmit ~ error:", createPlayer)
      }
    } catch (error) {
      console.log("🚀 ~ handleFormSubmit ~ error:", error)


    }
  };

  return (
    <div>
      <>  <div className="right-sidebar">
        <div className="das-bg-block w-100">
          <div className="d-flex flex-wrap justify-content-between align-items-baseline">
            <h1
              className="gamehistoryheading title_h1 same_heading"
              style={{ width: "unset" }}
            >
              Player Management
              <small>Player List</small>
            </h1>
            <a className="nav-link db-btn ms-2 ms-md-0" onClick={() => setShow(true)}>
              <i className="fa-solid fa-circle-plus me-1"></i>
              Add Player
            </a>
          </div>
          {/* <TableHeader onSelectedOptionsChange={handleSelectedOptionsChange}/> */}
          <div className="container-fluid bg">
            <div className="row user-select mt-2">
              <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <select name="filter" id="" value={parentSelectedOptions.filter} className="w-100" onChange={handleSelectedOptionsChange}>
                    {/* <option value="">
                      Active Player
                    </option> */}
                    {/* <option value="">In Active Player</option>
                    <option value="">Not approved Player</option>
                    <option value="">Flagged Player</option>
                    <option value="">Blocked Player</option>
                    <option value="">Deleted Player</option> */}
                    <option value="allPlayer" >All Player</option>
                    <option value="networkPlayer">Network Player</option>
                    <option value="nonNetWorkPlayer">Non Network Player</option>
                    <option value="blockedPlayer">Blocked Player</option>
                  </select>
                </div>
              </div>
              <div
                className="col-1 d-lg-block  d-none p-0 text-end"
                style={{ marginTop: "6px" }}
              >
                <span className="showoff_responsive">Created on</span>
              </div>
              <div className="col-lg-2  col-md-3 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                <div className="form-group">
                  <select name="filterByDate" className="w-100" onChange={handleFilter} value={parentSelectedOptions.filterByDate}>
                    {/* <option value="">All</option> */}
                    <option value="recent">
                      Recent
                    </option>
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="current_week">Current Week</option>
                    <option value="last_week">Last Week</option>
                    <option value="current_month">Current Month</option>
                    <option value="last_month">Last Month</option>
                    <option value="last_six_month">Last 6 Months</option>
                    <option value="date_range">Date Range</option>
                  </select>
                </div>
                {parentSelectedOptions.filterByDate === "date_range" && (<div id="target">
                  <div className="date">
                    <p>
                      <label>From</label>
                      <input type="date" id="date" name="from" value={input.from} onChange={handelChange} />
                      <label>To</label>
                      <input type="date" id="date" name="to" value={input.to} onChange={handelChange} />
                    </p>
                  </div>
                </div>)}
              </div>

              <div
                className="col-1 d-lg-block  d-none text-start"
                style={{ marginTop: "6px" }}
              >
                <span className="showoff_responsive">Sort</span>
              </div>
              <div
                className="col-lg-2 col-md-3 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-2"
                id="ml-10"
              >
                <dl className="dropdown1">
                  <dt>
                    <a href="#" className="text-start" onClick={toggleDropdown}>
                      <i className="fa-solid fa-chevron-down icon"></i>
                      {/* <!-- <span className="hida"></span> --> */}
                      <span className="multiSel">{parentSelectedOptions.sortBy.label}</span>
                      <img
                        className="multiSel1"
                        src={parentSelectedOptions.orderBy.img}
                        alt=""
                      />
                    </a>
                  </dt>

                  <dd>
                    <div className="mutliSelect">
                      <ul
                        className="text-start"
                        style={{ display: dropdownVisible ? "block" : "none" }}
                      >
                        <div className="gamelist">
                          <span>Sort By</span>
                          <li className={parentSelectedOptions.sortBy.value === "signup_date" ? "active" : null}>
                            <input
                              className="position-absolute invisible"
                              id="signup"
                              type="checkbox"
                              value="Sign Up"
                              onChange={() => handleSortOptionChange({ label: "Sign Up", value: "signup_date" })}
                            />
                            <label htmlFor="signup">
                              <i className="fa-solid fa-check"></i> Sign Up
                            </label>
                          </li>
                          <li >
                            <input
                              className="position-absolute invisible"
                              id="lg"
                              type="checkbox"
                              value="Last Login"
                              onChange={() => handleSortOptionChange({ label: "Last Login", value: "last_login" })}
                            />
                            <label htmlFor="lg">
                              <i className="fa-solid fa-check"></i> Last Login
                            </label>
                          </li>
                          <li   >
                            <input
                              id="username"
                              type="checkbox"
                              value="Username"
                              onChange={() => handleSortOptionChange({ label: "Username", value: "display_name" })}
                              className="position-absolute invisible"
                            />
                            <label htmlFor="username">
                              <i className="fa-solid fa-check"></i>
                              Username
                            </label>
                          </li>
                          <li>
                            <input
                              id="balance"
                              type="checkbox"
                              value="Balance"
                              onChange={() => handleSortOptionChange({ label: "Balance", value: "wallet_balance" })}
                              className="position-absolute invisible"
                            />
                            <label htmlFor="balance">
                              <i className="fa-solid fa-check"></i>
                              Balance
                            </label>
                          </li>
                          {/* <li>
                              <input
                                id="ingame"
                                type="checkbox"
                                value=""
                                onChange={()=>handleSortOptionChange({label:"In Game",value:""})}
                                className="position-absolute invisible"
                              />
                              <label htmlFor="ingame">
                                <i className="fa-solid fa-check"></i> In Game
                              </label>
                            </li> */}
                          {/* Add more gamelist items as needed */}
                        </div>
                        <div className="orderlist">
                          <span>Order By</span>
                          <li onClick={() => handleOrderOptionChange({ img: "../img/ace.png", value: "Ascending" })}>
                            {/* <input
                                id="ace"
                                type="checkbox"
                                value="../img/ace.png"
                                onChange={handleOrderOptionChange}
                              /> */}
                            <img style={{ display: "none" }} src="../img/ace.png" alt="img"></img>
                            <label htmlFor="ace" >Ascending</label>
                            <i
                              className="fa-solid fa-check"
                              style={{ marginTop: "5px" }}
                            ></i>
                          </li>
                          <li onClick={() => handleOrderOptionChange({ img: "../img/dec.png", value: "Descending" })} className={parentSelectedOptions.orderBy.value === "Descending" ? "active" : null}>
                            {/* <input
                                id="dec"
                                type="checkbox"
                                value="../img/dec.png"
                                onChange={handleOrderOptionChange}
                              /> */}
                            <img style={{ display: "none" }} src="../img/dec.png" alt="img"></img>
                            <label htmlFor="dec"> Descending</label>
                            <i
                              className="fa-solid fa-check"
                              style={{ marginTop: "5px" }}
                            ></i>
                          </li>
                          {/* Add more orderlist items as needed */}
                        </div>
                      </ul>
                    </div>
                  </dd>
                </dl>
              </div>
              {/* <!-- <div className="col-lg-1 d-flex justify-content-end">
                                    <div className=" sort-btn"><img src="../img/ace.png" alt=""></div>
                                </div> --> */}
              <div
                className="col-lg-4 col-md-3 col-sm-6 col-12 col-xs-4 d-flex mt-lg-0 mt-md-0 mt-2"
                id="ml-33"
              >
                <div className="input-group  input-group-sm">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for...."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ height: "33.5px !important" }}
                    name="search"
                    // value={parentSelectedOptions.search}
                    onChange={(e) => setSearch(e.target.value)}
                    value={Search}

                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="basic-addon1"
                      style={{ padding: "9px !important" }}
                    >
                      <i className="fa-solid fa-magnifying-glass" onClick={() => handleSearch(Search)}></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div
          id="DataTables_Table_0_wrapper"
          className="dataTables_wrapper no-footer"
        >
          <div className="table-player">
            <table className="table data-table" >
              <thead>
                <tr>
                  {/* <th>
                  <input type="checkbox" name="" id="" />
                </th> */}
                  {vipPlayers &&
                    <th>VIP</th>}
                  <th>Login id</th>
                  <th>Nick Name</th>
                  <th>Code</th>
                  <th>Wallet</th>
                  <th>Last Login</th>
                  {/* <th>Mobile No</th>
                  <th>email id</th> */}
                  <th>Signup Date</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {
                  playerData?.data?.map((doc, index) => (
                    <tr
                      className={`odd tx-tr ${activetoggle && activetoggle[index]
                        ? "action-btn-open"
                        : ""
                        }`}
                      role="row"
                      key={index}
                    >
                      {vipPlayers &&
                        <td>
                          {doc.subscription && <img src="img/crown.png" width={30} />}
                        </td>
                      }
                      {/* <td>
                      <input type="checkbox" name="" id="" />
                    </td> */}
                      {loading ? (
                        <td><Skeleton /></td>
                      ) : (
                        <td
                          onClick={() => handlePlayerInfo(doc._id)}
                          data-bs-toggle="modal"
                          data-bs-target="#playerInfoModal"
                          style={{ textAlign: "start", cursor:'pointer' }}
                        >
                          {doc && (doc.login_type === "email" || doc.login_type?.includes('email')) ? (
                            <i className="fa-solid fa-envelope ms-1"></i>
                          ) : doc.login_type === "username" || doc.login_type?.includes('username') ? (
                            <i className="fa-solid fa-user ms-1"></i>
                          ) : doc.login_type === "guest" || doc.login_type?.includes('guest') ? (
                            <i className="fa-solid fa-ghost ms-1"></i>
                          ) : doc.login_type === "mobile" || doc.login_type?.includes('mobile') ? (
                            <i className="fa-solid fa-phone ms-1"></i>
                          ) : (
                            <i className="fa-brands fa-facebook"></i>
                          )}&nbsp;

                          {doc.username}
                          {doc.mobile_number}
                          {doc.email_id}
                          {doc.guest_id ? ((parentSelectedOptions.filter) === 'nonNetWorkPlayer' ? 'Guest' : 'Network Player') : null}
                          {(doc.login_type === "social" || doc.login_type?.includes('social')) ? <>social</> : null}
                        </td>
                      )}
                      {loading ? <td><Skeleton /></td> : <td className="text-start">
                        &nbsp;<span>{(doc.login_type === "username" || doc?.login_type?.includes('username')) ? doc.username : doc.display_name}</span>
                      </td>}
                      {loading ? <td><Skeleton /></td> : <td>{doc.player_code}</td>}
                      {loading ? <td><Skeleton /></td> : <td className="position-relative text-end">
                        <span>{formatValue(doc.wallet_balance)}
                          <i class="fa-solid fa-circle-info m-2" id="dropdownBtn" onClick={() => handleSubWallet(doc.sub_wallet, index)}></i>
                        </span>
                        {dropdownActive[index] && (<ul className="list-group position-absolute top-100 bankdetails-block modaldrowp drowp">
                          <div>
                            <li className="list-group-item">
                              Bonus : {subWallet.bonus}
                            </li>
                            <li className="list-group-item">
                              Deposit : {subWallet.deposit}
                            </li>
                            <li className="list-group-item">
                              In Game : {subWallet.inGame}
                            </li>

                          </div>
                          <div>
                            <li className="list-group-item">
                              Lock : {subWallet.lock}
                            </li>
                            <li className="list-group-item">
                              Promo : {subWallet.promo}
                            </li>
                            <li className="list-group-item">
                              Referral : {subWallet.referral}
                            </li>

                          </div>
                          <div>
                            <li className="list-group-item">
                              Transfer : {subWallet.transfer}
                            </li>
                            <li className="list-group-item">
                              Withdrawable : {subWallet.withdrawable}
                            </li>
                            {/* <li className="list-group-item">
                            Type : DGD
                          </li> */}
                          </div>
                          {/* <div>
                         <li className="list-group-item">
                            Type : DGD
                          </li>
                          <li className="list-group-item">
                            Type : DGD
                          </li>
                         
                         </div> */}
                        </ul>)}
                      </td>}
                      {loading ? <td><Skeleton /></td> : <td>{DateTimeFormat(doc?.last_login) === ("NaN undefined NaN, NaN:NaN:NaN am" || "NaN undefined NaN, NaN:NaN:NaN pm") ? null : DateTimeFormat(doc?.last_login)}</td>}
                      {/* <td>
                      <span>{doc.last_login ? new Date(doc.last_login).toLocaleString(
                        "en-IN",
                        {
                          timeZone: "Asia/Kolkata",
                        }
                      ) === "Invalid Date" ? null : new Date(doc.last_login).toLocaleString(
                        "en-IN",
                        {
                          timeZone: "Asia/Kolkata",
                        }
                      ) : "-"}</span>
                    </td> */}
                      {/* <td>
                        {doc.mobile_number ? <span>91{doc.mobile_number}</span>: ""}
                      </td>
                      <td>
                        <span>{doc.email_id ? doc.email_id : "-"}</span>
                      </td> */}
                      {loading ? <td><Skeleton /></td> : <td>
                        {DateTimeFormat(doc?.signup_date) === ("NaN undefined NaN, NaN:NaN:NaN am" || "NaN undefined NaN, NaN:NaN:NaN pm") ? null : DateTimeFormat(doc?.signup_date)}
                        {/* <span>{doc.signup_date ? new Date(doc.signup_date).toLocaleString(
                        "en-IN", { timeZone: "Asia/Kolkata", }
                      ) === "Invalid Date" ? null : new Date(doc.signup_date).toLocaleString(
                        "en-IN",
                        {
                          timeZone: "Asia/Kolkata",
                        }
                      ) : "-"}</span> */}
                      </td>}
                      {loading ? <td><Skeleton /></td> : <td className="position-relative dropdowncaret">
                        <button
                          className="action btn"
                          onClick={() => toggleButton(index, doc._id, doc)}
                          id="dropdown"
                        >
                          <i className="fa-solid fa-caret-down"></i>
                        </button>
                        <div className="action-btn actin-payment-btn-sc" style={{ minWidth: '160px' }}>
                          <div className="item-3">
                            <button>
                              <i className="fa-solid fa-clock-rotate-left"></i>
                              <p onClick={() => navigate(`/transaction-details`, { state: doc._id })}>Transactions</p>
                            </button>
                            {/* <button>
                              
                              <i className="fa-solid fa-clock-rotate-left"></i>
                              <p>Game History</p>
                            </button> */}
                            <button>
                              <i className="fa-solid fa-money-bill-transfer"></i>
                              <p
                                // data-bs-toggle="modal" data-bs-target="#chipTransferModal"
                                onClick={() => navigate("/transfer-chips", { state: playerAccountDetails })}
                              >Chips Transfer</p>
                            </button>


                            <button>
                              <i className="fa-solid fa-arrow-rotate-left"></i>
                              <p
                                // data-bs-toggle="modal" data-bs-target="#chipTransferModal"
                                onClick={() => navigate("/claim-balance", { state: playerAccountDetails })}
                              >Claim Balance</p>
                            </button>
                            <button onClick={() => handleBlock(doc.player_code, doc.is_blocked)}>
                              <i className="fa-solid fa-user"></i>
                              <p>{doc.is_blocked ? 'Unblock' : 'Block'}</p>
                            </button>
                            {(doc?.login_type === 'username' || doc?.login_type?.includes('username')) && (
                              <button>
                                <i className="fa-solid fa-lock"></i>
                                <p onClick={() => navigate("/player-changePassword", { state: playerAccountDetails })}>
                                  Change password
                                </p>
                              </button>
                            )}


                            {/* <button
                              onClick={() => navigate("/make-vip", {
                                state: { vipPlayerId: doc._id, vipPlayerOperatorId: doc.operator_details.operator_id, playerAccountDetails }
                              })}>
                              <i className="fa-solid fa-user"></i>
                              <p>Make VIP</p>
                            </button> */}
                          </div>
                          {/* <div className="item-3">
                            <button className="pb_dis">
                              <i className="fa-solid fa-laptop"></i>
                              <p>Make Tester</p>
                            </button>
                            <button className="pb_dis">
                              <i className="fa-solid fa-gamepad"></i>
                              <p>Player Club</p>
                            </button>
                          </div> */}
                          {/* <div className="item-3" style={{ borderRight: "none" }}>
                            <button className="pb_dis">
                              <i className="fa-solid fa-circle-user"></i>
                              <p>Change Affiliate</p>
                            </button>
                            <button className="pb_dis">
                              <i className="fa-solid fa-infinity"></i>
                              <p>Flag</p>
                            </button>
                          </div> */}
                        </div>
                      </td>}
                    </tr>
                  ))}
              </tbody>
            </table>
            {/* player info Model */}
            <div
              className="modal fade"
              id="playerInfoModal"
              tabIndex={-1}
              aria-labelledby="tableAddModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-xl modal-dialog-scrollable text-start">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="tableAddModalLabel">
                      <strong>Player Info</strong>
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <div className="row user_summery mt-2">
                      <div className="col-lg-12 mt-3 mb-3">
                        <div className="info-card" style={{ height: "199px" }}>
                          <div className="info-header">
                            <h6>Account Info</h6>
                          </div>
                          <div className="uer-info">
                            <form action="">
                              <div className="row">
                                <div className="col-md-2 col-4">
                                  <label htmlFor="">Player ID</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data._id}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4">
                                  <label htmlFor="">Username</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.display_name}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4">
                                  <label htmlFor="">Account Status</label>
                                  <br />
                                  <label htmlFor="" className="approved">
                                    Online
                                  </label>
                                </div>
                                <div className="col-md-2 col-4">
                                  <label htmlFor="">Affiliate ID</label>
                                  <label htmlFor="" className="not-edit">
                                    -
                                  </label>
                                </div>
                                <div className="col-md-2 col-4">
                                  <label htmlFor="">Partner</label>
                                  <label htmlFor="" className="not-edit">
                                    -
                                  </label>
                                </div>
                                <div className="col-md-2 col-4">
                                  <label htmlFor="">Player Code</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.player_code}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Balance</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.wallet_balance.toFixed(2)}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Bonus</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.bonus}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Deposit</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.deposit}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">In Game</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.inGame}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Lock</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.lock}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Promo</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.promo}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Referral</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.referral}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Transfer</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.transfer}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Withdrawable</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.sub_wallet?.withdrawable}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Transfer Restriction</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.transfer_restriction}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Levels</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data?.data?.level}
                                  </label>
                                </div>
                                <div className="col-md-2 col-4 mt-3 mt-md-0">
                                  <label htmlFor="">Exp Points</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data?.exp_point}
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-card">
                          <div className="info-header">
                            <h6>Personal Information</h6>
                          </div>
                          <div className="uer-info">
                            <form action="">
                              <div className="row align-items-center">
                                <div className="col-md-3">
                                  {data?.data?.subscription === true && <img src={"img/vip-01.png"} className="" />}
                                  <img src={data?.data?.profile_pic_url?.url === "" ? "img/user2.png" : data?.data?.profile_pic_url?.url} className="user-profile-icon" />
                                </div>
                                <div className="col-md-9">
                                  <div className="row">

                                    <div className="col-5">
                                      <label htmlFor="">Login Id</label>
                                      <label htmlFor="" className="not-edit">
                                        {data && data.data.mobile_number}{data && data.data.email_id}{data && data?.data.guest_id ? 'Guest' : null}
                                      </label>
                                    </div>
                                    <div className="col-5">
                                      <label htmlFor="">Nick Name</label>
                                      <label htmlFor="" className="not-edit">
                                        {data && data.data.display_name}
                                      </label>
                                    </div>

                                    <div className="col-2  mt-3 mt-md-0">
                                      <label htmlFor="">Gender</label>
                                      <label htmlFor="" className="not-edit">
                                        -
                                      </label>
                                    </div>

                                    <div className="col-3 mt-3 mt-md-0">
                                      <label htmlFor="">Date of Birth</label>
                                      <label htmlFor="" className="not-edit">
                                        -
                                      </label>
                                    </div>
                                    <div className="col-3 mt-3 mt-md-0">
                                      <label htmlFor="">Player Code</label>
                                      <label htmlFor="" className="not-edit">
                                        {data && data.data.player_code}
                                      </label>
                                    </div>
                                    <div className="col-3 mt-3 mt-md-0">
                                      <label htmlFor="">Referral By</label>
                                      <label htmlFor="" className="not-edit">
                                        {data && data.data.reffered_by}
                                      </label>
                                    </div>


                                  </div>
                                </div>


                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-lg-0  mt-3">
                        <div className="info-card">
                          <div className="info-header">
                            <h6>Contact Info</h6>
                          </div>
                          <div className="uer-info">
                            <form action="">
                              <div className="row">
                                <div className="col-md-4 col-6">
                                  <label htmlFor="">E-mail</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.email_id}
                                  </label>
                                </div>
                                <div className="col-md-4 col-6 form-group">
                                  <label htmlFor="">Phone</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.mobile_number}
                                  </label>
                                </div>
                                <div className="col-md-4 col-6 form-group mt-3 mt-md-0">
                                  <label htmlFor="">Address</label>
                                  <label htmlFor="" className="not-edit">
                                    -
                                  </label>
                                </div>
                                <div className="col-md-4 col-6 mt-3 mt-md-0">
                                  <label htmlFor="">City</label>
                                  <label htmlFor="" className="not-edit">
                                    -
                                  </label>
                                </div>
                                <div className="col-md-4 col-6 mt-3 mt-md-0">
                                  <label htmlFor="">Country</label>
                                  <label htmlFor="" className="not-edit">
                                    {data && data.data.country_code === "+91" && "India"}
                                  </label>
                                </div>
                                <div className="col-md-4 col-6 mt-3 mt-md-0">
                                  <label htmlFor="">Zip Code</label>
                                  <label htmlFor="" className="not-edit">
                                    -
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>


                    </div>

                    <div className="player-manage-tabs mt-3">
                      <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link border-0 active"
                            id="tab7-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#tab7"
                            type="button"
                            role="tab"
                            aria-controls="tab7"
                            aria-selected="false"
                          >
                            Transactions
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link border-0 "
                            id="tab1-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#tab1"
                            type="button"
                            role="tab"
                            aria-controls="tab1"
                            aria-selected="true"
                          >
                            Debit/Credit
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link border-0"
                            id="tab3-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#tab3"
                            type="button"
                            role="tab"
                            aria-controls="tab"
                            aria-selected="false"
                          >
                            User Activity
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link border-0"
                            id="tab4-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#tab4"
                            type="button"
                            role="tab"
                            aria-controls="tab4"
                            aria-selected="false"
                          >
                            Bonus
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link border-0"
                            id="tab6-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#tab6"
                            type="button"
                            role="tab"
                            aria-controls="tab6"
                            aria-selected="false"
                          >
                            Bank Details
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link border-0"
                            id="tab12-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#tab12"
                            type="button"
                            role="tab"
                            aria-controls="tab6"
                            aria-selected="false"
                          >
                            KYC
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link border-0"
                            id="tab10-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#tab10"
                            type="button"
                            role="tab"
                            aria-controls="tab10"
                            aria-selected="false"
                          >
                            Responsible Gaming
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link border-0"
                            id="tab11-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#tab11"
                            type="button"
                            role="tab"
                            aria-controls="tab11"
                            aria-selected="false"
                          >
                            TDS
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content mt-4" id="myTabContent">
                        <div
                          className="tab-pane fade"
                          id="tab1"
                          role="tabpanel"
                          aria-labelledby="tab1-tab"
                        >
                          <div className="table-player">
                            <table className="table data-table">
                              <thead>
                                <tr>
                                  <th>Reference ID</th>
                                  <th>Amount</th>
                                  <th>Balance</th>
                                  <th>Type</th>
                                  <th>Game Type</th>
                                  <th>Bonus</th>
                                  <th>Created ON</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>66a6517c7f60c8392cde</td>
                                  <td>-10</td>
                                  <td>15</td>
                                  <td>Debit</td>
                                  <td>Ludo</td>
                                  <td>0</td>
                                  <td>27/07/2024 10:23</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="tab3"
                          role="tabpanel"
                          aria-labelledby="tab3-tab"
                        >
                          <div className="table-player">
                            <table className="table data-table">
                              <thead>
                                <tr>
                                  <th>Reference ID</th>
                                  <th>Amount</th>
                                  <th>Balance</th>
                                  <th>Type</th>
                                  <th>Game Type</th>
                                  <th>Bonus</th>
                                  <th>Created ON</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>66a6517c7f60c8392cde</td>
                                  <td>-10</td>
                                  <td>15</td>
                                  <td>Debit</td>
                                  <td>Ludo</td>
                                  <td>0</td>
                                  <td>27/07/2024 10:23</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="tab4"
                          role="tabpanel"
                          aria-labelledby="tab4-tab"
                        >
                          <div className="table-player">
                            <table className="table data-table">
                              <thead>
                                <tr>
                                  <th>Reference ID</th>
                                  <th>Amount</th>
                                  <th>Balance</th>
                                  <th>Type</th>
                                  <th>Game Type</th>
                                  <th>Bonus</th>
                                  <th>Created ON</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>66a6517c7f60c8392cde</td>
                                  <td>-10</td>
                                  <td>15</td>
                                  <td>Debit</td>
                                  <td>Ludo</td>
                                  <td>0</td>
                                  <td>27/07/2024 10:23</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="tab6"
                          role="tabpanel"
                          aria-labelledby="tab6-tab"
                        >
                          <div className="table-player">
                            <table className="table data-table">
                              <thead>
                                <tr>
                                  <th>Status</th>
                                  <th>Login Id</th>
                                  <th>Account Holder Name</th>
                                  <th>Account type</th>
                                  <th>Account Details</th>
                                  <th>Ifsc Code</th>
                                  <th>Country</th>
                                  <th>Last Updated</th>
                                  <th>Updated by</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="tab12"
                          role="tabpanel"
                          aria-labelledby="tab6-tab"
                        >
                          <div className="table-player">
                            <table className="table data-table">
                              <thead>
                                <tr>
                                  <th>Status</th>
                                  <th>Login Id</th>
                                  <th>Document type</th>
                                  <th>Document Number</th>
                                  <th>Full Name</th>
                                  <th>Name on document</th>
                                  <th>Attachment</th>
                                  <th>Requested Date</th>
                                  <th>Last Update</th>
                                  <th>Updated by</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade  show active"
                          id="tab7"
                          role="tabpanel"
                          aria-labelledby="tab7-tab"
                        >
                          <div className="table-player">
                            <table className="table data-table">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Login ID</th>
                                  <th>Opening Balance</th>
                                  <th>Closing Balance</th>
                                  <th>Game</th>
                                  <th>Remark</th>
                                  <th>Transaction Id</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {playerTransactions.data.map((doc, index) => (
                                  <tr role="row" key={index}>
                                    {loading ? <td><Skeleton /></td> : <td>
                                      {new Date(doc.createdAt).toLocaleString("en-IN", {
                                        timeZone: "Asia/Kolkata",
                                      })}
                                    </td>}
                                    {loading ? <td><Skeleton /></td> : <td>{doc.login_id}</td>}
                                    {loading ? <td><Skeleton /></td> : <td>{doc.opening_wallet_bal.toFixed(2)}</td>}
                                    {loading ? <td><Skeleton /></td> : <td>{doc.closing_wallet_bal.toFixed(2)} </td>}
                                    {loading ? <td><Skeleton /></td> : <td></td>}
                                    {loading ? <td><Skeleton /></td> : <td>
                                      {doc.transaction_details && doc.transaction_details.remarks}
                                    </td>}
                                    {loading ? <td><Skeleton /></td> : <td>{doc.game_transaction_id ? doc.game_transaction_id : doc.transaction_details.transaction_id}</td>}
                                    {loading ? <td><Skeleton /></td> : <td>
                                      {doc.transaction_type === "1" ? (
                                        <span style={{ color: "#a80000" }}>
                                          {(doc.closing_wallet_bal - doc.opening_wallet_bal).toFixed(2)}
                                        </span>
                                      ) : (
                                        <span style={{ color: "#00c70d" }}>
                                          {(doc.closing_wallet_bal - doc.opening_wallet_bal).toFixed(2)}
                                        </span>
                                      )}
                                    </td>}
                                  </tr>
                                ))}

                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="tab9"
                          role="tabpanel"
                          aria-labelledby="tab9-tab"
                        >
                          <ChipsTransfer />

                        </div>
                        <div
                          className="tab-pane fade"
                          id="tab10"
                          role="tabpanel"
                          aria-labelledby="tab10-tab"
                        >
                          <div className="table-player">
                            <table className="table data-table">
                              <thead>
                                <tr>
                                  <th>Reference ID</th>
                                  <th>Amount</th>
                                  <th>Balance</th>
                                  <th>Type</th>
                                  <th>Game Type</th>
                                  <th>Bonus</th>
                                  <th>Created ON</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>66a6517c7f60c8392cde</td>
                                  <td>-10</td>
                                  <td>15</td>
                                  <td>Debit</td>
                                  <td>Ludo</td>
                                  <td>0</td>
                                  <td>27/07/2024 10:23</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                      </div>
                    </div>

                    {/* <div className="col-12 data-table overflow-visible py-2">

                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* player info model end */}
            {/* Chips Transfer Model */}
            <div
              className="modal fade"
              id="chipTransferModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-scrollable trans-modal modal-dialog-centered">
                <div className="modal-content">
                  <div
                    className="modal-header"
                    style={{ background: "#eceff4 !important" }}
                  >
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Transfer Chips
                    </h1>
                    <button
                      className="close-btn cb"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span>x</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="mb_modal">
                      <div>
                        <span>Login Id : </span> {playerAccountDetails?.mobile_number}
                      </div>
                      <div>
                        <span>Name : </span> {playerAccountDetails?.display_name}
                      </div>
                      <div className="row m-0 ps-2 pe-2">
                        <div className="col-md-4 d-inline mx-0 text-start">
                          Enter Chips :
                        </div>
                        <div className="col-md-8 d-inline mx-0">
                          <input
                            className="w-100 me-0 ms-0"
                            type="number"
                            name="add_amount" value={addChips.add_amount} onChange={handlechipsModelChange}
                          />
                          <span
                            className="error text-start d-block"
                            style={{
                              color: "rgb(172, 37, 37)",
                              fontSize: "10px",
                            }}
                          >
                            {error}
                          </span>
                        </div>
                      </div>
                      <br></br>
                      <div className="md-btn">
                        <button
                          className="btn btn-success btn-sm"
                          // onClick={() => AddChips(playerAccountDetails?._id)}
                          onClick={() => navigate("/transfer-chips", { player: playerAccountDetails })}
                        >
                          Transfer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* chip transfer model end */}
            <div className="pagination pagination-text row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                <select
                      aria-controls="DataTables_Table_0"
                      name="tableLength" value={input?.tableLength}
                      onChange={handelChange}
                      className="pag-select-block me-1"
                    >
                      <option value="10">10</option>
                      <option value="25" defaultValue={true}>25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select> Records per page
                  {/* Page {pageNo} of {playerData && playerData.totalPage}{" "} */}
                  {/* off items {input.tableLength * playerData?.totalPage} */}
                  {/* <label>
                    Rows per page{" "}
                    <select
                      aria-controls="DataTables_Table_0"
                      name="tableLength" value={input.tableLength}
                      onChange={handelChange}
                    >
                      <option value="10">10</option>
                      <option value="25" defaultValue={true}>25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </label> */}
                </div>
              </div>

              <div className="w-auto">
              Showing {pageNo}-{input?.tableLength}{" "}
                of {input.tableLength * playerData?.totalPage} items
              </div>

              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                <a onClick={() => setPageNo(1)} style={{pointerEvents:`${pageNo > 1 ? 'auto' : 'none'}`}}
                  className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                  <a onClick={() => setPageNo(pageNo - 1)}
                    style={{pointerEvents:`${pageNo > 1 ? 'auto' : 'none'}`}}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    {/* <i className="fa-solid fa-chevron-left"></i> Previous */}
                    <img src="img/left-arrow.png" width="20" />
                  </a>
                  <span className="d-flex align-items-center">
                    {/* <a
                      className="paginate_button current"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="1"
                      tabIndex="0"
                    > */}

                    <select className="pag-select-block"
                      aria-controls="DataTables_Table_0"
                      name="pagination" value={pageNo}
                      // onChange={handelChange}
                      // onChange={() => setPageNo(pageNo + 1)}
                      onChange={(e) => setPageNo(Number(e.target.value))}
                    >
                      {Array.from({ length: totalPage }, (_, index) => (
                        <option key={index + 1} value={index + 1} >
                          {index + 1}
                        </option>
                      ))}



                    </select> of {playerData?.totalPage}
                    {/* {pageNo} */}
                    {/* </a> */}
                  </span>
                  {/* {playerData && pageNo < playerData.totalPage && */}
                    <a onClick={() => setPageNo(pageNo + 1)} 
                    style={{pointerEvents:`${playerData && pageNo < playerData?.totalPage ? 'auto' : 'none'}`}}
                  className={`${playerData && pageNo < playerData?.totalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                      <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                    </a>
                   {/* } */}
                  
                  <a onClick={() => setPageNo(playerData?.totalPage)} style={{pointerEvents:`${playerData && pageNo < playerData.totalPage ? 'auto' : 'none'}`}}
                  className={`${playerData && pageNo < playerData?.totalPage ? '' : 'opacity-25'} border-0`}>
                    {/* <i className="fa-solid fa-chevron-right"></i> */}
                    <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* add player modal */}

      <Modal show={show} onHide={() => { handleClose(); setFormData({ username: "", password: "" }); setErrorForPlayerCreation('') }}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            Create Player
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form  >
            {errorForPlayerCreation && <Alert variant="danger">{errorForPlayerCreation}</Alert>}
            <Row>
              <Col className="mb-2">
                <Form.Group controlId="formNickName">
                  <Form.Label className="text-start d-block">Login ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleCreatePlayerChange}
                    placeholder="Enter login ID"
                    required
                  />
                </Form.Group>
              </Col>
              <Col className="mb-2">
                <Form.Group controlId="formLoginId">
                  <Form.Label className="text-start d-block">Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleCreatePlayerChange}
                    placeholder="Enter Password"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              variant="primary"
              className="btnall mt-3 btn btn-primary"
              type="submit"
              onClick={handleFormSubmit}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PlayerManagement;
