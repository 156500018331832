import React from "react";


const ReferralReport = () => {
    return (
        <>

            <div className="right-sidebar">
                <div className="das-bg-block d-flex justify-content-between pe-3">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Referral Report</h1>
                    </div>
                    <div className="col-lg-3 col-md-3 user-select col-6 mt-lg-0  col-xs-1">
                        <div className="input-group  input-group-sm">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search for...."
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                name="search"
                                defaultValue=""
                            />
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fa-solid fa-magnifying-glass" />
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
                <div>

                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <table className="table data-table   table-borderless">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Referral sender</th>
                                        <th>Referral code</th>
                                        <th>Referral receiver</th>
                                        <th>Receiver amount</th>
                                        <th>Referral device</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td className="text-end">--</td>
                                        <td>--</td>
                                        <td>
                                            <button className="btn-info reset-btn">Details</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table></div>



                    </div>

                </div>
            </div>
        </>
    );
};

export default ReferralReport;