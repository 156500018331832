import React from "react";


const Maintenance = () => {
    return (
        <>
            
                <div className="right-sidebar">
                    <div className="das-bg-block d-flex justify-content-between pe-3">
                        <div className="das-title">
                            <h1 className="gamehistoryheading title_h1 same_heading">Maintenance</h1>
                        </div>
                        <div className="user-select d-flex row m-0" style={{width:'50%'}}>
                        <div className="col-lg-6 col-md-6 pe-1 col-6  col-xs-12">
                                <div className="form-group">
                                    <select name="" id="" className="w-100 ">
                                        <option value="" selected="">
                                            Select Game
                                        </option>
                                        <option value="">Poker</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 ps-1 col-6  col-xs-12">
                                <div className="form-group">
                                    <select name="" id="" className="w-100 ">
                                        <option value="" selected="">
                                        Select Client
                                        </option>
                                        <option value="">Client</option>

                                    </select>
                                </div>
                            </div>
                            </div>

                    </div>

                    <div>

                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                        <table className="table data-table   table-borderless">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Mobile</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                
                            </tr>
                        </tbody>
                    </table></div>

                    

                        </div>

                    </div>
                </div>
            </>
    );
};

export default Maintenance;