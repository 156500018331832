import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";


const AddBanner = () => {

    const navigate = useNavigate()
    
    return (
        <>

            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Add Promotional Banner </h1>
                    </div>

                </div>
                <div>
                    <div className='container-fluid bg'>
                        <div className="row">
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Promotion Category*</label>
                                <select className="form-select">
                                <option value="0">--Promotion Category--</option>
							<option value="signup">SignUp</option>	
							<option value="login">Login</option>	
							<option value="lobby">Lobby</option>	
							<option value="game">Game</option>	
							<option value="home">Home</option>	
							<option value="download-app">Download-App</option>	
							<option value="contact-us">Contact-Us</option>	
							<option value="promotion">Promotions</option>	
                                </select>
                            </div>

                            {/* Signup */}
                            

                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Name*</label>
                                <input type="text" />
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Type*</label>
                                <select className="form-select">
                                <option value="video">Video</option>
                                <option value="image">Image</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Url*</label>
                                <input type="text" />
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Start Date*</label>
                               <input type="date" />    
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">End Date*</label>
                                <input type="date" />
                            </div>
                            <div className="col-md-12 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Short Description*</label>
                                <textarea className="w-100"></textarea>
                            </div>

                            <div className="col-12 d-flex justify-content-center mt-3">
                                <button className="btn btn-primary btnall">Save</button>
                                <button className="btn btn-secondary ms-3" style={{minWidth:'100px'}} onClick={() => navigate('/manage-banner')}>Cancel</button>
                            </div>

                            {/* Signup End */}
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default AddBanner;