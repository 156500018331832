import React from "react";


const Referral = () => {
    return (
        <>

            <div className="right-sidebar">
                <div className="das-bg-block">
                    <div className="das-title">
                        <h1 className="text-start gamehistoryheading title_h1 same_heading">Promotion Level Setting</h1>
                    </div>
                </div>
                <div>


                    <div className="container-fluid px-2">

                    <div className="accordion accordion-level" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    Level 1
                                </button>
                            </h2>
                            <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <div className='container-fluid'>
                                        <div className='row mb-0 user-select user-selectinput'>

                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Signup Commission</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 user-select mb-2 col-md-3  col-6 mt-lg-0  col-xs-12 p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Max Reward Invites</label>
                                                    <input type="text" placeholder="1" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 col-md-3  col-6 mt-lg-0  col-xs-12 p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Deposit Direct Invite Commission</label>
                                                    <select name="" id="" className="w-100">
                                                        <option value="" selected="">
                                                            Tournament Name
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Enter Fee</label>
                                                    <input type="text" placeholder="Enter Fee" className="w-100" />
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Spending Direct Invite Commission</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Signup Commission</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">No of Times</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Minimum Claimable</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Total Deposit amount for Upgrade</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Minimum Reffered use for deposit</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Level Upgrade Bonus</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                >
                                    Level 2
                                </button>
                            </h2>
                            <div
                                id="collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <div className='container-fluid'>
                                        <div className='row mb-0 user-select user-selectinput'>

                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Signup Commission</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 user-select mb-2 col-md-3  col-6 mt-lg-0  col-xs-12 p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Max Reward Invites</label>
                                                    <input type="text" placeholder="1" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 col-md-3  col-6 mt-lg-0  col-xs-12 p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Deposit Direct Invite Commission</label>
                                                    <select name="" id="" className="w-100">
                                                        <option value="" selected="">
                                                            Tournament Name
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Enter Fee</label>
                                                    <input type="text" placeholder="Enter Fee" className="w-100" />
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Spending Direct Invite Commission</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Signup Commission</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">No of Times</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Minimum Claimable</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Total Deposit amount for Upgrade</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Minimum Reffered use for deposit</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Level Upgrade Bonus</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                >
                                    Level 3
                                </button>
                            </h2>
                            <div
                                id="collapseThree"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <div className='container-fluid'>
                                        <div className='row mb-0 user-select user-selectinput'>

                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Signup Commission</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 user-select mb-2 col-md-3  col-6 mt-lg-0  col-xs-12 p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Max Reward Invites</label>
                                                    <input type="text" placeholder="1" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 col-md-3  col-6 mt-lg-0  col-xs-12 p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Deposit Direct Invite Commission</label>
                                                    <select name="" id="" className="w-100">
                                                        <option value="" selected="">
                                                            Tournament Name
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Enter Fee</label>
                                                    <input type="text" placeholder="Enter Fee" className="w-100" />
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Spending Direct Invite Commission</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Signup Commission</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">No of Times</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Minimum Claimable</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Total Deposit amount for Upgrade</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Minimum Reffered use for deposit</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-2 user-select col-md-3  col-6  col-xs-12  p-1">
                                                <div className="form-group">
                                                    <label className="text-start d-block mb-1">Level Upgrade Bonus</label>
                                                    <input type="text" placeholder="3.00" className="w-100" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mt-3">
                        <button className="btn btn-primary btnall">Save</button>
                    </div>

                    </div>


                </div>
            </div>
        </>
    );
};

export default Referral;