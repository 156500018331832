import React, { useEffect, useState } from "react";
import $ from "jquery";
import data from "./leftSideBar.json";
import gmsHistoryGames from '../json/gmsHistory.json'
import Hindi from '../json/Hindi.json'
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import ScrollToTopButton from "./ScrollToTopButton";

const LeftSideBar = ({ indexx, setAffSignupURL, setVipPlayers }) => {

  const navigate = useNavigate();

  const opr_id = localStorage.getItem("opr_id");

  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [menuList, setMenuList] = useState();
  const [activeMenus, setActiveMenus] = useState(
    Array(30).fill(false)
  );
  const [url, setUrl] = useState();
  const [toggleGmsHistory, setToggleGmsHistory] = useState(false)
  const [activeScroll, setActiveScroll] = useState(true);
  const [clickedSubmenuIndex, setClickedSubmenuIndex] = useState();


  const handleToggle = (index) => {
    setActiveMenus((prevActiveMenus) => {
      const newActiveMenus = prevActiveMenus?.map((isActive, i) =>
        i === index ? !isActive : false
      );
      return newActiveMenus;
    });
  };
  console.log('window.location.href', window.location.pathname);
  const handleButton = async (page, mainpage, index) => {
    console.log("page", page)
    try {
      setActiveScroll(false)
      const redirect = await axios.post(
        process.env.REACT_APP_TRANSACTION_API +
        `admin/auth/generateOneTime-token`,
        {
          current_session_token: localStorage.getItem("sessionToken"),
          operatorCode: opr_id,
        }
      );
      // console.log(redirect.data);
      if (page === "gamesHistory" || mainpage === "gamesHistory") {
        setToggleGmsHistory(true)
      }
      else {
        setToggleGmsHistory(false)
      }

      if (redirect.data.status === true) {
        // window.open(
        //   process.env.REACT_APP_REDIRECT_URL +
        //     `operatorRemoteLogin?token=${redirect.data.data.one_time_token}&operator_code=${opr_id}&page=${page}`
        // );

        navigate('/gms', {
          state: process.env.REACT_APP_REDIRECT_URL +
            `operatorRemoteLogin?token=${redirect.data.data.one_time_token}&operator_code=${opr_id}&page=${page}`
        })

        setClickedSubmenuIndex(index);

        console.log(
          process.env.REACT_APP_REDIRECT_URL +
          `operatorRemoteLogin?token=${redirect.data.data.one_time_token}&operator_code=${opr_id}&page=${page}`
        );

        //   Add a class to the clicked submenu item
        const submenuItems = document.querySelectorAll('.sub-menu-list li a');
        // submenuItems.forEach((item, itemIndex) => {
        //   if (itemIndex === index) {
        //     item.classList.add('clicked-submenu');
        //   } else {
        //     item.classList.remove('clicked-submenu');
        //   }
        // });
      } else if (redirect.data.status === false) {
        alert("Token is expired, Please Login again");
        localStorage.clear();
        navigate('/')
      }
    } catch (error) {
      alert("internal server error");
    }
  };

  const handleToggleButton = (index) => {

    setActiveScroll(true)
    setClickedSubmenuIndex()
    // const submenuItems = document.querySelectorAll('.sub-menu-list li a');
    //   submenuItems.forEach((item, itemIndex) => {
    //     if (itemIndex === index) {
    //       item.classList.add('clicked-submenu');
    //     } else {
    //       item.classList.remove('clicked-submenu');
    //     }
    //   });
  }

  useEffect(() => {
    const fetchData = async () => {
      const loginType = localStorage.getItem('login_type')
      try {
        // if (loginType === 'subAdmin') {
        const response = await axios.get(
          process.env.REACT_APP_API_PATH + `subAdminMenuList`,
          // 'http://192.168.0.107:5500/subAdminMenuList',
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("sessionToken")}`
            }
          }
        );
        if (response.data?.menu_list?.vip_players) {
          setVipPlayers(response.data.menu_list.vip_players)

        }

        const responseMenuList = response.data.menu_list
        // Group items by menu_group
        const groupedMenu = responseMenuList.reduce((acc, item) => {
          if (item.menu_group !== "independent") {
            if (!acc[item.menu_group]) {
              acc[item.menu_group] = [];
            }
            acc[item.menu_group].push({
              ...item,
              url: item.menu_url,
              menu_item_type: item.menu_type, 
            });
          }
          return acc;
        }, {});

        // Track seen main_menu_titles to ensure uniqueness
        const seenTitles = new Set();

        const menuList = responseMenuList.reduce((acc, permission) => {
          if (permission.menu_group === "independent") {
            // Handle independent menu items directly
            acc.push({
              ...permission,
              main_menu_title: permission.menu_title,
              url: permission.menu_url,
              menu_item_type: permission.menu_type, 
              sub_menu_items: [],
              main_menu_id: permission.menu_id
            });
          } else {
            if (!seenTitles.has(permission.menu_group)) {
              acc.push({
                main_menu_title: permission.menu_group,
                main_menu_id: permission.menu_id,
                url: "#",
                sub_menu_items: groupedMenu[permission.menu_group] || []
              });
              seenTitles.add(permission.menu_group);
            }
          }
          return acc;
        }, []);

        console.log("🚀 ~ menuList ~ menuList:", menuList)


        setMenuList(menuList);
        console.log(response, 'menu listttt');
        return
      } catch (error) {
        console.log("🚀 ~ fetchData ~ error:", error)

      }
      // } 
      // else {
      //   const response = await axios.get(
      //     process.env.REACT_APP_API_PATH + `operatorMenuList`,
      //     {
      //       headers: {
      //         admin_auth_token: localStorage.getItem("omsToken"),
      //         opr_auth_token: localStorage.getItem("sessionToken")
      //       }
      //     }
      //   );
      //   console.log(response.data?.menu_list?.vip_players, 'response left')
      //   if (response.data?.menu_list?.vip_players) {
      //     setVipPlayers(response.data.menu_list.vip_players)

      //   }
      //   console.log(response.data?.menu_list?.bo_menu_items.length);
      //   if (!response.data?.menu_list) return
      //   setMenuList(response.data.menu_list);
      //   if (!response.data?.menu_list?.signup_url) return;
      //   setAffSignupURL(response.data.menu_list.signup_url)

      // };
    }
    if (opr_id) {
      fetchData();
    }
  }, []);

  // logic for toggle 




  // Check if the element has the specific class name 'dashboardo-block-active'
  useEffect(() => {
    document.getElementById('TGB').addEventListener("click", () => {
      setActiveMenus(Array(30).fill(false))
      setActiveMenus(Array(30).fill(false))
    });

  }, [])

  return (
    <>
      <div
        className="dashboardo-block scroller"
        style={{ background: opr_id ? "#2d0512" : "" }}
      >
        <div className="das-menu">
          {/* <!-- <div className="drawer-menu-heading"></div> --> */}
          <div className="das-menu-list">
            <ul>
              {/* <li className="dropdown">
                <a href="dashboard" className={opr_id ? "opr-menu" : ""}>
                  <i className="fa-solid fa-gauge-high"></i>
                  <span>Dashboard</span>
                  <span className="arrow"> */}
              {/* <img src="../img/right.png" width="8"></img> */}
              {/* </span>
                </a>
              </li> */}
              {!opr_id &&
                data &&
                data?.map((menu, index) => (
                  <li
                    key={index}
                    className={`dropdown ${activeMenus[index] || index === indexx
                      ? "dropdown-active"
                      : ""
                      }`}
                  >
                    <a
                      // href="javascript:void(0)"
                      style={{ color: "#fff" }}
                      onClick={() => handleToggle(index)}
                    >
                      <i className={menu.icon_class}></i>
                      <span>{menu.Menu_name}</span>
                      <span className="arrow">
                        <img src="../img/right.png" width="8" alt="Arrow" />
                      </span>
                    </a>
                    <ul
                      className={`sub-menu-list ${activeMenus[index] || index === indexx
                        ? "sub_menu_active"
                        : ""
                        }`}
                    >
                      {menu &&
                        menu.sub_menu?.map((option, subIndex) => (
                          <li key={subIndex}>
                            <NavLink
                              style={({ isActive }) => ({
                                background: isActive ? "rgba(142, 108, 202, 0.4)" : "",
                              })}
                              to={option.href}
                            >
                              {option.name}
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
              {opr_id &&
                menuList &&
                menuList?.map((menu, index) => (
                  <li
                    key={index}
                    className={`dropdown ${activeMenus[index] || index === indexx
                      ? "dropdown-active"
                      : ""
                      }`}
                  >
                    {menu.url === "#" ? (<div className="linkLeftsidebar" style={{ color: "#fff" }}
                      onClick={() => handleToggle(index)}>
                      <i className={menu?.icon_class}></i>
                      <span>{menu?.main_menu_title}</span>
                      <span className="arrow">
                        {menu?.sub_menu_items?.length > 0 ? <img src="../img/right.png" width="8" alt="Arrow" /> : null}
                      </span>
                    </div>) :
                      <NavLink
                        to={menu.url === "#" ? null : menu.url}
                        style={{ color: "#fff" }}
                        onClick={() => handleToggle(index)}
                      >
                        {/* <i className={menu.icon_className}></i> */}
                        <i className={menu?.icon_class}></i>
                        <span>{menu?.main_menu_title}</span>
                        <span className="arrow">
                          {menu?.sub_menu_items?.length > 0 ? <img src="../img/right.png" width="8" alt="Arrow" /> : null}
                        </span>
                      </NavLink>}
                    {console.log(activeMenus[index], ",mdhncfgm")}
                    <ul
                      className={`sub-menu-list ${activeMenus[index] || index === indexx
                        ? "sub_menu_active"
                        : ""
                        }`}
                    >
                      {menuList &&
                        menu?.sub_menu_items?.map((option, subIndex) => (
                          <li key={subIndex}>
                            {option.menu_item_type === "internal" ? (
                              <NavLink
                                style={({ isActive }) => ({
                                  background: isActive ? "rgba(142, 108, 202, 0.4)" : "",
                                })}
                                to={option.url}
                                onClick={() => handleToggleButton()}

                              // className={
                              //   // Add the 'clicked-submenu' class if the index matches the clicked submenu index
                              //   subIndex === clickedSubmenuIndex
                              //     ? "clicked-submenu"
                              //     : ""
                              // }
                              >
                                {Hindi[option.menu_title] ? Hindi[option.menu_title] : option.menu_title}
                              </NavLink>
                            ) : (
                              <>
                                <NavLink
                                  onClick={() => handleButton(option.url, null, option.menu_title)}
                                  // className="highligh_link"
                                  // style={({ isActive }) => ({
                                  //   background: isActive ? "rgba(142, 108, 202, 0.2)" : "",
                                  // })}
                                  className={
                                    // Add the 'clicked-submenu' class if the index matches the clicked submenu index
                                    option?.menu_title === clickedSubmenuIndex
                                      ? "clicked-submenu"
                                      : ""
                                  }
                                >
                                  {Hindi[option.menu_title] ? Hindi[option.menu_title] : option.menu_title}
                                  {/* <i class="fa-solid fa-chevron-right" style={{textAlign:"end"}}></i> */}
                                </NavLink>
                              </>
                            )}
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
              {/* <li
                className={`dropdown ${
                  isDropdownActive ? "dropdown-active" : ""
                }`}
              >
                <a href="javascript:void(0)" onClick={handleToggle}>
                  <i className="fa-solid fa-hand-spock"></i>
                  <span>Live players</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" alt="Arrow" />
                  </span>
                </a>
                <ul
                  className={`sub-menu-list ${
                    isDropdownActive ? "sub_menu_active" : ""
                  }`}
                >
                  <li>
                    <a href="live-player.html">Live players</a>
                  </li>
                </ul>
              </li> */}
              {/* <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-bars-progress"></i>
                  <span>Game management</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="game-summary2.0.html">Game summary 2.0 </a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-user"></i>
                  <span>Player Management</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="manage-player.html">Manage Players</a>
                    <a href="player-bank-details.html">Bank Details</a>
                    <a href="manage-kyc.html">Player KYC</a>
                    <a href="user-group.html">User Groups</a>
                    <a href="kyc-report.html">KYC Report</a>
                    <a href="device-approval.html">Device Approval/Details</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-hand-spock"></i>
                  <span> Live bets</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="live-bets.html"> Live bets</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-person"></i>
                  <span>Affiliate Management</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="affiliate-list.html">Affiliate Management</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-money-bill-1"></i>
                  <span>Finance</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="send-out.html">Send Out</a>
                  </li>
                  <li>
                    <a href="deposite-list.html">Deposit transaction </a>
                  </li>
                  <li>
                    <a href="fund-transaction.html">Fund Transfer</a>
                  </li>
                  <li>
                    <a href="player-deposite-list.html">Player deposit list </a>
                  </li>
                  <li>
                    <a href="claim-back.html">Claim Back</a>
                  </li>
                  <li>
                    <a href="player-withdrawal.html">Player Withdrawal</a>
                  </li>
                  <li>
                    <a href="deposite.html">Deposit</a>
                  </li>
                  <li>
                    <a href="withdraw-list.html">Withdrawal Transaction</a>
                  </li>
                  <li>
                    <a href="revenue-report.html">Revenue Report </a>
                  </li>
                  <li>
                    <a href="player_cashinVscashout.html">Cashin VS Cashout</a>
                  </li>
                  <li>
                    <a href="withdraw-request.html">Withdrawal Request</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-bars-progress"></i>
                  <span>Campaign Management</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="referral-invite-report.html">
                      Referral invite code
                    </a>
                  </li>
                  <li>
                    <a href="referral.html">Referral</a>
                  </li>
                  <li>
                    <a href="manage-campaign.html">Manage campaign</a>
                  </li>
                  <li>
                    <a href="banner.html">Manage Banner</a>
                  </li>
                  <li>
                    <a href="campaign-report.html">Campaign Report</a>
                  </li>
                  <li>
                    <a href="referral-report.html">Referral Report</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-store"></i>
                  <span>Shop items</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="item-list.html">Items</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-users"></i>
                  <span>HR Management</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="staff-management.html">Staff Management </a>
                    <a href="role-management.html">Role Management</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-crown"></i>
                  <span>Vip Points</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="manage-club.html">Manage club</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa fa-cog"></i>
                  <span>Master Setting</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="Game-category.html">Game Category</a>
                  </li>
                  <li>
                    <a href="game-list.html">Game List</a>
                  </li>
                  <li>
                    <a href="manage.html">Manage Maintenance</a>
                  </li>
                  <li>
                    <a href="Manage-version.html">Manage versions </a>
                  </li>
                  <li>
                    <a href="paymethod.html">Active payMethods</a>
                  </li>
                  <li>
                    <a href="language.html">Set language text</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-phone"></i>
                  <span>Support Details</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="support.html">Supports</a>
                  </li>
                  <li>
                    <a href="support-detail.html">Support Details</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-briefcase"></i>
                  <span>Analytics</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="login-history.html">Login History</a>
                  </li>
                  <li>
                    <a href="player-game-log.html">Players Game log</a>
                  </li>
                  <li>
                    <a href="maintenance-log.html">Maintenance log </a>
                  </li>
                  <li>
                    <a href="signup-report.html">Signup log</a>
                  </li>
                  <li>
                    <a href="device-login.html">Device log</a>
                  </li>
                  <li>
                    <a href="loginsignup-report.html">
                      login/signup failed log
                    </a>
                  </li>
                  <li>
                    <a href="rackback-log.html">rack back log</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-chart-column"></i>
                  <span>Turnover Report</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="player-turnover.html">Player Turnover 2.0</a>
                  </li>
                  <li>
                    <a href="state-agent-turnover2.0.html">
                      State Agent Turnover 2.0
                    </a>
                  </li>
                  <li>
                    <a href="master-turnover2.0.html">Master Turnover 2.0</a>
                  </li>
                  <li>
                    <a href="superagent-turnover2.0.html">
                      Super Agent Turnover 2.0
                    </a>
                  </li>
                  <li>
                    <a href="agent-turnover2.0.html">Agent Turnover 2.0</a>
                  </li>
                  <li>
                    <a href="quick-turnover2.0.html">Quick Turnover 2.0</a>
                  </li>
                  <li>
                    <a href="quickturnover-claim2.0.html">
                      Quick Turnover Claim 2.0
                    </a>
                  </li> */}
              {/* <!--   <li>
                            <a href="player-claim-turnover.html">Player Turnover Claim</a>
                        </li> --> */}
              {/* <li>
                    <a href="overall-turnover2.0.html">Overall Turnover 2.0</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-bars-progress"></i>
                  <span>Transaction Report</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="affiliate-trans.html">Affiliate transaction</a>
                  </li> */}
              {/* <!-- <li>
                            <a href="playertransaction.html">Player transaction</a>
                        </li> --> */}
              {/* <li>
                    <a href="accountstatement.html">Account statement</a>
                  </li>
                  <li>
                    <a href="bonus-history.html">Bonus History</a>
                  </li>
                </ul> */}
              {/* </li>
              <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-sharp fa-solid fa-circle-half-stroke"></i>
                  <span>Commission report</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="state-agent-commission2.0.html">
                      State Agent commission 2.0{" "}
                    </a>
                  </li>
                  <li>
                    <a href="master-commission2.0.html">
                      Master commission 2.0{" "}
                    </a>
                  </li>
                  <li>
                    <a href="Super-agent-commission2.0.html ">
                      Super agent commission 2.0{" "}
                    </a>
                  </li>
                  <li>
                    <a href="Agent-commission2.0.html ">
                      Agent commission 2.0{" "}
                    </a>
                  </li>
                </ul>
              </li> */}
              {/* <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-cart-shopping"></i>
                  <span>Purchase report</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="gift-report.html">Gift purchase report </a>
                  </li>
                  <li>
                    <a href="purchase-list.html">Chips purchase report </a>
                  </li>
                </ul>
              </li> */}
              {/* <li className="dropdown">
                <a href="javascript:void(0)">
                  <i className="fa-solid fa-circle-info"></i>
                  <span>Miscellaneous</span>
                  <span className="arrow">
                    <img src="../img/right.png" width="8" />
                  </span>
                </a>
                <ul className="sub-menu-list">
                  <li>
                    <a href="game-rake2.0.html">Game rake 2.0 </a>
                  </li>
                  <li>
                    <a href="ludo-rake2.0.html">Ludo rake 2.0 </a>
                  </li>
                  <li>
                    <a href="teen-patti2.0.html">Teen patti rake 2.0 </a>
                  </li>
                  <li>
                    <a href="rummy-rake2.0.html">Rummy rake 2.0 </a>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="das-footer"></div>
      </div>
      {activeScroll && <ScrollToTopButton />}
    </>
  );
};

export default React.memo(LeftSideBar);
