import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTopButton from "./ScrollToTopButton";
import { useContext } from "react";
import axios from "axios";
import { Form, Button, Modal, Container, Row, Col } from 'react-bootstrap';

const Header = () => {

  const navigate = useNavigate();

  const toggle = () => {
    $(this).toggleClass("menu-toggle-active");
    $(".dashboardo-block").toggleClass("dashboardo-block-active");
    $(".full-page").toggleClass("full-page-active");
    $(".logo-menu").toggleClass("logo-menu-open");
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Admin");
  const [operatorCredit, setOperatorCredit] = useState();
  const [kycCount, setKycCount] = useState();

  const handleLinkClick = (e) => {
    e.preventDefault();
    setDropdownOpen((prev) => !prev);
  };

  const handleListItemClick = (text) => (e) => {
    setSelectedItem(text);
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (localStorage.getItem("role")) {
      setSelectedItem(localStorage.getItem("role"));
    }
    const oprId = localStorage.getItem("opr_id")
    const fetchData = async () => {
      const response = await axios.post(process.env.REACT_APP_API_PATH + "operator/credit", {
        opr_id: oprId
      })
      if (response.data.status === true) {
        setOperatorCredit(response.data.data)
      }
    }
    fetchData();

    const fetchKycUser = async () => {
      const response = await axios.get(
        process.env.REACT_APP_API_PATH +
        `kycDetails?limit=100&status=Pending`,
        {
          headers: {
            admin_auth_token: localStorage.getItem("omsToken"),
            opr_auth_token: localStorage.getItem("sessionToken"),
          },
        }
      );
      setKycCount(response.data.data.length);
    }
    // fetchKycUser()

  }, []);


  const handleLogout = () => {
    localStorage.removeItem("login_type")
    localStorage.removeItem("securityCode")
    if (localStorage.getItem("role") === "Operator") {
      // localStorage.removeItem("sessionToken");
      // localStorage.removeItem("role")
      navigate("/")
    }
    else if (localStorage.getItem("role") === "Sub Operator") {
      // localStorage.removeItem("sessionToken");
      // localStorage.removeItem("role")
      navigate("/sub-operator-login")
    }
    else if (localStorage.getItem("role") === "Affiliate Operator") {
      navigate("/affiliate-operator")
    }
    else {
      navigate("/")
    }
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("role")
    localStorage.removeItem("omsToken")
    localStorage.removeItem("opr_id");
    localStorage.removeItem("login_id")
  }
  const closeAll = () => {
    setChangepasswordModal(false);
    setChangepasswordModalContent(true);
    setForgot(false);
    setEmail('');
    setNewPassword('');
    setRetypePassword('');
    setShowModal(false);
    setModalMessage('');
    setverifyModal(false);
    setOtp('');
    setotpFromApi('');
  }
  const [changepasswordModal, setChangepasswordModal] = useState(false);
  const [changepasswordModalContent, setChangepasswordModalContent] = useState(true);
  const [forgot, setForgot] = useState(false);
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [verifyModal, setverifyModal] = useState(false)
  const [otp, setOtp] = useState('')
  const [otpFromApi, setotpFromApi] = useState('')

  const LoginId = localStorage.getItem('login_id');
  const handleSubmit = async (e) => {
    e.preventDefault();
    //setChangepasswordModal(false);

    if (newPassword !== retypePassword) {
      setModalMessage("Passwords do not match!");
      setShowModal(true);
      return;
    }

    if (!email || !newPassword || !retypePassword) {
      setModalMessage("All fields are required!");
      setShowModal(true);
      return;
    }

    try {


      const response = await axios.post(process.env.REACT_APP_API_PATH + 'createOtp', {
        email,
        LoginId
      });
      console.log("password change response", response)

      if (response?.data?.otp) {
        console.log("  Your new password is ", response.data.otp);
        setotpFromApi(response?.data?.otp)
        setChangepasswordModalContent(false);
        setverifyModal(true)
        setModalMessage("");
      }
      else {
        setShowModal(true)
        setModalMessage("Server Erorr while Creating Otp Please try After some time");
      }
    } catch (error) {
      showModal(true)
      setModalMessage("Server Erorr while Creating Otp Please try After some time");
    }


  };

  const verifyOtp = async () => {

    if (otpFromApi !== otp) {
      setShowModal(true);
      setModalMessage("Otp doesn't match! ");
      return
    }


    try {
      const response = await axios.post(process.env.REACT_APP_API_PATH + 'changeLoginCredentails', {
        email,
        LoginId,
        newPassword
      });
      console.log("password changed", response)
      console.log("Password changed successfully! Your new password is ", response.data.otp);
      setotpFromApi('')
      setOtp('')
      setverifyModal(false)
      setShowModal(true);
      setModalMessage("Password changed successfully");
      setEmail('')
      setNewPassword('')
      setRetypePassword('')

    } catch (error) {
      setModalMessage("Server Erorr while changing password");
    }




  }

  return (
    <>
      <header className="header" style={{ background: selectedItem !== "Admin" ? "#2d0512" : "" }}>
        <div className="header-wrapper">
          <div className="logo-menu">
            {/* <a href="/dashboard" className="ds-logo">
            <img src="../img/logo.png" />
          </a> */}
            <button id="TGB" className="menu-toggle" onClick={toggle}>
              <img src="../img/toggle-bar.png" />
            </button>
            <a href="/player-management" className="ds-logo dummy-text position-relative h-auto mt-0 d-flex align-items-center justify-content-start">
              OMS
              <p className="txt-small position-absolute w-100 text-start p-0 pt-0 mt-0 mb-0 ps-3" style={{ bottom: '-4px' }}><small className="pt-0">{selectedItem}</small></p>
            </a>
            {/* <button className="menu-toggle  d-lg-none d-block">
                <img src="assets/img/right-chevron.png" />
            </button>  */}
            {/* <!-- <a href="#" className="logo-dash-close" style="color: #fff;margin-left: 5px;">
                <img src="assets/img/logo.png" />
            </a> --> */}
          </div>
          <div className="status-game">
            <Link to="/depositerequests" title="Deposit Request" className="kyc-status position-relative">
              <img src="img/upload.png" className="img-fluid" />
              <span className="kyc-status-count" style={{ background: 'green' }}>
                0
              </span>
            </Link>
            <Link to="/withdraw-request" title="Withdrawal Request" className="kyc-status position-relative p-0">
              <img src="img/withdraw1.png" className="img-fluid" />
              <span className="kyc-status-count">
                0
              </span>
            </Link>

            <Link to="/managekyc" title="KYC Status" className="kyc-status position-relative p-0"
            //style={{ width: '32px' }}
            >
              <img src="img/kyc.png" className="img-fluid"
                style={{ position: ' relative', top: ' 0px' }} />
              <span className="kyc-status-count" style={{ background: '#ea8e37', right: '-10px' }}>
                {/* {kycCount > 0 ? kycCount : '0'} */}
                0
              </span>
            </Link>
            <div className="game-blance">
              <span className="">Credits : {operatorCredit?.ggr_credit?.mgp?.toFixed(2)}</span>

            </div>

            <div className="dropdown header-dropdown h-100">
              <span id="logoutDropdown"
                className="js-link dropdown-toggle h-100 d-block"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {localStorage.getItem("opr_name")}
                {/* {localStorage.getItem("opr_name") ? (<br />) : null} */}
                ({localStorage.getItem("role") === "Admin" ? localStorage.getItem("login_id") : localStorage.getItem("opr_id")})
                <i className="fa-solid fa-chevron-right"></i>
              </span>
              <ul className="dropdown-menu js-dropdown-list" aria-labelledby="logoutDropdown">
                <li>
                  {/* <img src="../img/user2.png" /> */}
                  <i className="fa fa-user"></i>
                  My Account
                </li>
                {/* <li>
                <img src="../img/lock.png" />
                <i className="fa fa-lock"></i>
                 Update Security
              </li>
              <li>
                <img src="../img/dollar.png" />
                <i className="fa-solid fa-money-bill-1" aria-hidden="true"></i>
                 Generate Amount
              </li>
              <li>
                <img src="../img/blank-page.png" /> 
                <i className="fa fa-file"></i>
                Set Landing Page
              </li> */}
                <li onClick={() => setChangepasswordModal(true)}>
                  <i className="fa-solid fa-lock" style={{ color: "#B197FC" }}></i>Change Password
                </li>
                <li onClick={handleLogout}>
                  {/* <img src="../img/log-out.png" />  */}

                  <i className="fa-solid fa-arrow-right-from-bracket"></i>Logout
                </li>
              </ul>
              {/* <a href="#" className="js-link" onClick={handleLinkClick} data-bs-toggle="dropdown" aria-expanded="false">
              {selectedItem} <i className="fa fa-chevron-down"></i>
            </a>
            <ul className="js-dropdown-list">
              <li onClick={handleListItemClick("My Account")}>
                <img src="../img/user2.png" /> My Account
              </li>
              <li onClick={handleListItemClick("Update Security")}>
                <img src="../img/lock.png" /> Update Security
              </li>
              <li onClick={handleListItemClick("Generate Amount")}>
                <img src="../img/dollar.png" /> Generate Amount
              </li>
              <li onClick={handleListItemClick("Set Landing Page")}>
                <img src="../img/blank-page.png" /> Set Landing Page
              </li>
              <li onClick={handleListItemClick("Logout")}>
                <img src="../img/log-out.png" /> Logout
              </li>
            </ul> */}
            </div>

          </div>
        </div>
        {/* <ScrollToTopButton/> */}
      </header>

      {/* ChangePassword */}
      <Modal className='change_password_modal change_password_modal_width' show={changepasswordModal} onHide={() => closeAll()} centered>
        <Modal.Header closeButton>
          <Modal.Title>{changepasswordModalContent && 'Change Password'} {verifyModal && 'OTP Verification'} {showModal && 'Response'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='change-password-form'>
          {changepasswordModalContent &&
            <div>
              <p className='mb-3'>Set a strong password to prevent unauthorized access to your account.</p>
              <Form onSubmit={handleSubmit}>
                <Form.Group className='mb-3 d-flex flex-wrap' controlId="formEmail">
                  {/* <Form.Label className="w-100">Your Email ID</Form.Label> */}
                  <Form.Control
                    type="email"
                    placeholder="Your Email ID"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required

                  />
                  {/* <a href="javascript:void(0)" onClick={() => setForgot(true) || setChangepasswordModal(false)} className="forgot_password_link">Forgot Password?</a> */}
                </Form.Group>
                <Form.Group className='mb-3' controlId="formNewPassword">
                  {/* <Form.Label>New Password</Form.Label> */}
                  <Form.Control
                    type="text"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className='mb-3' controlId="formRetypePassword">
                  {/* <Form.Label>Confirm New Password</Form.Label> */}
                  <Form.Control
                    type="text"
                    placeholder="Confirm New Password"
                    value={retypePassword}
                    onChange={(e) => setRetypePassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <div className='text-center change_password_btn_block'>
                  <Button type="submit" className="mt-2 change_password_btn">
                    Submit
                  </Button>
                </div>

              </Form>
            </div>}
          {verifyModal &&
            <Row className="justify-content-center">
              <Col md="8">
                <Form.Group controlId="formRetypePassword">

                  <Form.Control
                    type="text"
                    placeholder="Type Otp send Over mail"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                    className="w-100"
                  />
                </Form.Group>
                <div className='mt-3 text-center'>
                  <Button className='change_password_btn' variant="secondary" onClick={() => verifyOtp()}>
                    Verify
                  </Button>
                </div>
              </Col>
            </Row>
          }
          {showModal &&
            <div className="w-100"><p className='m-0 text-center'>{modalMessage}</p></div>}
        </Modal.Body>

      </Modal>

      {/* <Modal className='change_password_modal' centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='change-password-form'><p className='m-0 text-center'>{modalMessage}</p></Modal.Body>
               
            </Modal> */}


      {/* <Modal className='change_password_modal change_password_modal_width' show={verifyModal} onHide={() => setverifyModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>OTP Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body className='change-password-form'> 
                  
                    </Modal.Body>
                
            </Modal> */}

      {/* Forgot Password */}

      <Modal className='change_password_modal' show={forgot} onHide={() => setForgot(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body className='change-password-form'>
          <p className='mb-3 text-center'>Enter the last password you remember with this account. If it matches, you can continue to sign in.</p>
          {/* <p className='mb-3 text-center'>Enter the one-time password sent to your email address.</p> */}
          {/* <Form onSubmit={handleSubmit}> */}
          <Form.Group className='mb-3 d-flex flex-wrap position-relative' controlId="formEmail">
            {/* <Form.Label className="w-100">Your Email ID</Form.Label> */}
            <div className="position-relative w-100">
              <Form.Control
                type="email"
                placeholder="Your Email ID"
                value="mail.lokeshpareek@mobzway.in"
                required
                className="changeEmail-control"
                readOnly
              />
              <span className="change-email-text position-absolute h-100 end-0 d-flex align-items-center top-0">Change</span>
            </div>
          </Form.Group>
          <Form.Group className='mb-3' controlId="formNewPassword">
            {/* <Form.Label>New Password</Form.Label> */}
            <Form.Control
              type="text"
              placeholder="Password"
              required
            />
          </Form.Group>

          {/* OTP */}

          {/* <Form.Group className='mb-3' controlId="formNewPassword">
                            
                            <Form.Control
                                type="text"
                                placeholder="OTP"
                                required
                            />
                            <a href="javascript:void(0)"  className="forgot_password_link text-start d-block">Resend OTP</a>
                        </Form.Group> */}

          <div className='text-center'>
            <Button type="button" className="mt-2 change_password_btn">
              Verify Password
            </Button>
            {/* <Button type="button" className="mt-2 change_password_btn">
                            Send OTP
                        </Button> */}
          </div>

          {/* </Form> */}
          <div className="mt-4">
            <a href="javascript:void(0)" style={{ fontSize: '16px' }}
              className="forgot_password_link">Continue to reset password</a>

          </div>
        </Modal.Body>

      </Modal>

    </>
  );
};

export default Header;
