import React from "react";


const MessagePage = () => {
    return (
        <>
            
                <div className="right-sidebar">
                    <div className="das-bg-block d-flex align-items-center justify-content-between">
                        <div className="das-title">
                            <h1 className="gamehistoryheading title_h1 same_heading">Message</h1>
                        </div>
                        <div>
                        <button className="nav-link db-btn disabled"><i className="fa-solid fa-plus"></i> Message</button>
                        <button className="nav-link db-btn disabled"><i className="fa-solid fa-trash"></i> Delete</button>
                        </div>
                    </div>
                    <div>
                        

                        <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                        <table className="table data-table   table-borderless">
                        <thead>
                            <tr>
                                <th><input type="checkbox" /></th>
                                <th>Title</th>
                                <th>Message</th>
                                <th>Type</th>
                                <th>Image</th>
                                <th>Sent to</th>
                                <th>Sent On</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td> 
                                <td>--</td>
                            </tr>
                        </tbody>
                    </table></div>

                    

                        </div>

                    </div>
                </div>
            </>
    );
};

export default MessagePage;