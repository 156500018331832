import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

export default function QrCodeGenerator() {
    const [show, setShow] = useState(false);
    const [imgUrl, setImgUrl] = useState('');
    const [data, setData] = useState([]);
    const [formAllData, setFormAllData] = useState({
        operator_code: localStorage.getItem("opr_id"),
        upi_id: '',
        daily_limit: '',
        status: '',
        qr_code: '',
        account_type: 'qr code',
        operation_country: 'india'
    })

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        // if (name === 'qr_code') {
        //     setFormData((prevData) => ({
        //         ...prevData,
        //         [name]: e.target.files[0] // Store the file
        //     }));
        // } else {
        setFormAllData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        // }
    };



    const fetchData = async () => {
        const operatorCode = localStorage.getItem("opr_id");
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_PATH}getDepositOperatorAccounts?operator_code=${operatorCode}`
            );
            console.log(response.data, 'QR data');
            if (response.data.status === 200 && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                setData([]);
            }
        } catch (error) {
            console.error("There was an error with the API request:", error);
            setData([]);
        }
    };


    const uploadData = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('operator_code', formAllData.operator_code);
        formData.append('upi_id', formAllData.upi_id);
        formData.append('daily_limit', formAllData.daily_limit);
        formData.append('status', formAllData.status);
        formData.append('account_type', formAllData.account_type);
        formData.append('operation_country', formAllData.operation_country);
        formData.append('qr_code', document.getElementById('fileInput').files[0]);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PATH}saveOperatorDepositAccount`, formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                },
            );

            if (response.data.status) {
                console.log(response.data, 'Upload response');
                alert(response.data.message);
                fetchData();
                setShow(false);
            }

        } catch (error) {
            console.error("Error uploading data", error);
        }
    }

    useEffect(() => {


        fetchData();
    }, []);

    const handleImage = (url) => {
        setImgUrl(url);
    }

    const handleActive = async (idx) => {
        const operatorCode = localStorage.getItem("opr_id");

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PATH}changeOperatorDepositAccountDetails`, {
                id: idx,
                operator_code: operatorCode,
                status: 'active'
            },
            );

            console.log(response.data, 'active response');
            alert(response.data);

            fetchData();

        } catch (error) {
            console.error("Error uploading data", error);
        }

    }

    return (
        <>
            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Qr Code Generator</h1>
                    </div>
                    <div>
                        <button className="nav-link db-btn"
                            onClick={handleShow}>
                            <i className="fa-solid fa-plus"></i> Qr Code Generator
                        </button>
                    </div>
                </div>

                <div>
                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <div className="table-list-mb">
                                <table className="table data-table table-borderless">
                                    <thead>
                                        <tr>
                                            <th>Account Type</th>
                                            <th>Status</th>
                                            <th>Qr Code</th>
                                            <th>Upi ID</th>
                                            <th>Daily Limit</th>
                                            <th>Created At</th>
                                            <th>Operation Country</th>
                                            {/* <th>Operator Code</th> */}

                                            {/* <th>UpdatedAt</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 ? (
                                            [...data].reverse().map((e, idx) => (
                                                <tr key={idx}>
                                                    <td>{e.account_type}</td>
                                                    <td className="text-center">
                                                        {e.status === 'active' ? (
                                                            <span
                                                                style={{
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    display: 'block',
                                                                    background: 'green',
                                                                    borderRadius: '50%',
                                                                    margin: '0 auto',
                                                                }}
                                                            ></span>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    display: 'block',
                                                                    background: 'red',
                                                                    borderRadius: '50%',
                                                                    margin: '0 auto',
                                                                }}
                                                            ></span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <img src={e.qr_code} alt="QR Code" width="50" height="50"
                                                            className="me-2" />
                                                        <i className="fa-solid fa-eye"
                                                            data-bs-toggle="modal" data-bs-target="#image"
                                                            onClick={() => handleImage(e.qr_code)}
                                                            style={{ cursor: 'pointer' }}></i>

                                                    </td>
                                                    <td>{e.upi_id}</td>
                                                    <td>{e.daily_limit}</td>
                                                    <td>{new Date(e.createdAt).toLocaleString()}</td>
                                                    <td>{e.operation_country}</td>
                                                    <td>
                                                        {e.status === 'active' ? '' :
                                                            <button className="btn-info reset-btn" onClick={() => handleActive(e._id)}>Active</button>
                                                        }</td>
                                                    {/* <td>{e.operator_code}</td> */}
                                                    {/* <td>{new Date(e.updatedAt).toLocaleString()}</td> */}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr><td colSpan="7" className="text-center">No data found</td></tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload QR Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={uploadData}>
                        <Row>
                            <Col md="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-start d-block">Account Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Account Type"
                                        name="account_type"
                                        required
                                        value={formAllData.account_type}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-start d-block">Country</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Country"
                                        name="operation_country"
                                        required
                                        value={formAllData.operation_country}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-start d-block">UPI ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="UPI ID"
                                        name="upi_id"
                                        required
                                        value={formAllData.upi_id}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-start d-block">Daily Limit</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="daily_limit"
                                        required
                                        value={formAllData.daily_limit}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-start d-block">Status</Form.Label>
                                    <Form.Select
                                        name="status"
                                        required
                                        value={formAllData.status}
                                        onChange={handleChange}
                                    >
                                        <option value="" >Select Status</option>
                                        <option value="active" >Active</option>
                                        <option value="inactive">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-start d-block">QR Code</Form.Label>
                                    <Form.Control
                                        type="file"
                                        id="fileInput"
                                        name="qr_code"
                                        accept="image/*"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="text-center change_password_btn_block w-100">
                            <Button
                                variant="primary"
                                className="mt-3 change_password_btn btn btn-primary"
                                type="submit"
                            >
                                Upload
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>


            <div
                className="modal fade add-btn-modal"
                id="image"
                data-bs-keyboard="true"
                tabIndex={-1}
                aria-labelledby="BackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div
                        className="modal-content manual_width "
                    >
                        {/* <h5 className="modal-title">KYC Details</h5> */}
                        <button
                            className="close-btn"
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <span><i className="fa-solid fa-xmark"></i></span>
                        </button>

                        <div className="modal-body container p-0">
                            <div className="row">
                                <div className="p-4">
                                    <img
                                        src={imgUrl}
                                        alt="front_img"
                                        className="img-fluid rounded"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
