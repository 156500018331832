import "../App.css";
import React, { useEffect, useState } from "react";
import { useNavigate, Redirect } from "react-router-dom";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SubAdminLogin = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('sessionToken')) {
            localStorage.getItem('login_type') ? navigate("/withdraw-request") : navigate('/player-management');
        }
    }, [])

    const [formValue, setFormValue] = useState({
        login_id: "",
        password: ""
    });

    const [authenticated, setAuthenticated] = useState(false);
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [error, setError] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (formValue.login_id !== "" && formValue.password !== "") {
                const response = await axios.post(
                    process.env.REACT_APP_API_PATH + "subAdminLogin",
                    formValue
                );
                console.log(response);
                if (response.data.status === true) {
                    localStorage.setItem("role", "Operator")
                    localStorage.setItem("login_id", formValue.login_id)

                    const operator_id = localStorage.getItem('securityCode')
                    if (operator_id && operator_id.trim() !== '') {
                        const response = await axios.post(
                            process.env.REACT_APP_API_PATH + "subAdminAuth",
                            { login_id: formValue.login_id, operator_id }
                        );

                        if (response.data.status === true) {
                            localStorage.removeItem("omsToken")
                            localStorage.setItem("sessionToken", response.data.sessionToken);
                            localStorage.setItem("opr_id", response.data.opr_id);
                            localStorage.setItem("opr_name", response.data.opr_name || "");
                            localStorage.setItem('login_type', "subAdmin")
                            // setAuthenticated(true);
                            response.data.oms_user_type === 'sub_admin' ? navigate("/withdraw-request") : navigate('/player-management');
                        } else {
                            setError("Invalid details");
                        }

                    } else {
                        navigate("/subAdminAuth");
                    }
                } else {
                    setError("Invalid details");
                }
            } else {
                setError("Please fill all the details");
            }
        } catch (error) {
            console.log(error);
            alert(error?.response?.data?.message || "Internal Server Error")
        }
    };

    const handleChange = (event) => {
        console.log(event.target.value);
        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <div className="App" style={{ padding: "0 " }}>
            <div className="container-fluid bg-hero">
                <div className="container">
                    <div className="signin-container">
                        <div className="signin_box">
                            <div className="w-100">
                                <img src="img/logo.png" className="mb-4" alt="logo" />
                                <div className="signin_div w-100">
                                    <form action="">
                                        <div className="signin-title">
                                            <span className="title">Operator - Sign in</span>
                                        </div>
                                        <div className="filed-content">
                                            <input
                                                className="fill-input"
                                                type="text"
                                                name="login_id"
                                                value={formValue.login_id}
                                                placeholder="Email-address or mobile number"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {/* <div className="filed-content">
                                            <input
                                                className="fill-input"
                                                type="text"
                                                name="password"
                                                value={formValue.password}
                                                placeholder="Operator id"
                                                onChange={handleChange}
                                            />
                                        </div> */}
                                        <div className="filed-content">
                                            <input
                                                className="fill-input"
                                                type={isRevealPwd ? "text" : "password"}
                                                name="password"
                                                value={formValue.password}
                                                placeholder="Enter Password"
                                                onChange={handleChange}
                                            />
                                            <div className="icon">
                                                {isRevealPwd ? (
                                                    <FaEyeSlash onClick={() => setIsRevealPwd(false)} />
                                                ) : (
                                                    <FaEye onClick={() => setIsRevealPwd(true)} />
                                                )}
                                            </div>
                                        </div>
                                        <span style={{ color: "red" }}>{error}</span>

                                        <div className="next-btn mt-4">
                                            <button onClick={(e) => handleSubmit(e)}>Next</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="rightside_box">
                            <div className="login-img">
                                <img src="img/login.png.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubAdminLogin;
