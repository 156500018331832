import "../App.css";
import React, { useEffect, useState } from "react";
import { useNavigate, Redirect, Route } from "react-router-dom";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";


const SubAdminAuth = () => {
    const navigate = useNavigate();

    const login_id = localStorage.getItem("login_id")

    useEffect(() => {
        if (!login_id) {
            window.location.replace("/subAdminLogin")
        }
        else if (localStorage.getItem("sessionToken")) {
            window.location.replace("/withdraw-request")
        }
    }, [])

    const [formValue, setformvalue] = useState({
        login_id: localStorage.getItem("login_id"),
        operator_id: "",
    });
    console.log("🚀 ~ SubAdminAuth ~ formValue:", formValue)
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [error, setError] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (formValue.login_id !== "" && formValue.operator_id !== "") {
                const response = await axios.post(
                    process.env.REACT_APP_API_PATH + "subAdminAuth",
                    formValue
                );
                console.log(response);
                if (response.data.status === true) {
                    localStorage.setItem("securityCode", formValue.operator_id)
                    localStorage.removeItem("omsToken")
                    localStorage.setItem("sessionToken", response.data.sessionToken);
                    localStorage.setItem("opr_id", response.data.opr_id);
                    localStorage.setItem("opr_name", response.data.opr_name || "");
                    response.data.oms_user_type === 'sub_admin' && localStorage.setItem('login_type', "subAdmin")
                    response.data.oms_user_type === 'sub_admin' ? navigate("/withdraw-request") : navigate('/player-management');
                } else {
                    setError("Invalid details");
                }
            } else {
                setError("Please fill all the details");
            }
        } catch (error) {
            console.log(error);
            console.log("internal Server Error")
        }
    };

    const handleChange = (event) => {
        console.log(event.target.value);
        setformvalue({
            ...formValue,
            [event.target.name]: event.target.value,
        });
    };
    // useEffect(() => {
    //     if (authenticated) {
    //         return 
    //     }
    // }, [authenticated])
    return (
        <div className="App" style={{ padding: "0 " }}>
            <div className="container-fluid bg-hero">
                <div className="container">
                    <div className="signin-container">
                        <div className="signin_box">
                            <div className="w-100">
                                <img src="img/logo.png" className="mb-4" alt="logo" />
                                <div className="signin_div w-100">
                                    <form action="">
                                        <div className="signin-title">
                                            <span className="title">Sign in</span>
                                            {/* <!-- <p className="p-0">to access cliq</p> --> */}
                                        </div>
                                        {/* <div className="filed-content">
                      <input
                        className="fill-input"
                        type="text"
                        name="login_id"
                        value={formValue.login_id}
                        placeholder="Email-address or mobile number"
                        onChange={handleChange}
                      />
                    </div> */}
                                        <div className="filed-content">
                                            <input
                                                className="fill-input"
                                                type='text'
                                                name="operator_id"
                                                value={formValue.operator_id}
                                                placeholder="Enter Security Code"
                                                onChange={handleChange}
                                            />
                                            <div className="icon">
                                                {/* {isRevealPwd ? (
                                                    <FaEyeSlash onClick={() => setIsRevealPwd(false)} />
                                                ) : (
                                                    <FaEye onClick={() => setIsRevealPwd(true)} />
                                                )} */}
                                            </div>
                                        </div>
                                        {/* <select name="operator_id" value={formValue.operator_id} onChange={handleChange} id="" class="select-box form-control">
                      <option value="10">10</option>
                      <option value="230101">230101</option>
                      <option value="230703">230703</option>
                    </select> */}
                                        <span style={{ color: "red" }}>{error}</span>

                                        <div className="next-btn mt-4">
                                            <button onClick={(e) => handleSubmit(e)}>Sign in</button>
                                        </div>
                                        {/* <!-- <div className="forgot-pass">
                                <a href="#">Forgot Password?</a>
                            </div> --> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="rightside_box">
                            <div className="login-img">
                                <img src="img/login.png.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubAdminAuth;
