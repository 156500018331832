import React, { useState } from "react";
import { Link } from "react-router-dom";


const AddCampaign = () => {
    const [selectedCategory, setSelectedCategory] = useState('1');
    const handleSelectChange = (event) => {
        setSelectedCategory(event.target.value);
    }
    return (
        <>

            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Add Campaign</h1>
                    </div>
                    <div>
                        <Link className="nav-link db-btn  "><i className="fa-solid fa-circle-plus"></i>Add Campaign</Link>
                    </div>

                </div>
                <div>
                    <div className='container-fluid bg'>
                        <div className="row">
                            <div className="col-md-6 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Category Type*</label>
                                <select className="form-select" value={selectedCategory} onChange={handleSelectChange}>
                                    <option value="0">Select Category Type</option>
                                    <option value="1">Signup</option>
                                    <option value="2">Login</option>
                                    <option value="3">Deposit</option>
                                    <option value="4">Referral</option>
                                    <option value="5">Play/Spend/Win</option>
                                    <option value="6">Rack Back - Poker</option>
                                </select>
                            </div>

                            {/* Signup */}
                            {selectedCategory === '1' &&
                                <div className="col-md-6 user-select user-selectinput mb-2">
                                    <label className="form-label text-start d-block">Campaign Name*</label>
                                    <input type="text" placeholder="" />
                                </div>}
                            {selectedCategory === '3' &&
                                <div className="col-md-6 user-select user-selectinput mb-2">
                                    <label className="form-label text-start d-block">Title*</label>
                                    <input type="text" placeholder="" />
                                </div>}
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Valid From*</label>
                                <input type="date" placeholder="" />
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Valid To*</label>
                                <input type="date" placeholder="" />
                            </div>
                            {selectedCategory === '1' &&
                                <div className="col-md-4 user-select user-selectinput mb-2">
                                    <label className="form-label text-start d-block">How much new player will get*</label>
                                    <input type="text" placeholder="Amount" />
                                </div>}

                            {selectedCategory === '3' &&
                                <>
                                    <div className="col-md-4 user-select user-selectinput mb-2">
                                        <label className="form-label text-start d-block">Campaign fo*</label>
                                        <select className="form-select">
                                            <option value="-1">Cash</option>
                                            <option value="0">Bonus</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4 user-select user-selectinput mb-2">
                                        <label className="form-label text-start d-block">Campaign fo*</label>
                                        <select className="form-select">
                                            <option class="applicto_selected" value="" selected="">Select User Type</option>
                                            <option value="8">User Group</option>
                                            <option value="0">New Users</option>
                                            <option value="1">Existing</option>
                                            <option value="2">All</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4 user-select user-selectinput mb-2">
                                        <label className="form-label text-start d-block">No of times*</label>
                                        <select className="form-select">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option selected="selected" value="0">Unlimited</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4 user-select user-selectinput mb-2">
                                        <label className="form-label text-start d-block">Minimum deposit amount*</label>
                                        <input type="text" />
                                    </div>
                                    <div className="col-md-4 user-select user-selectinput mb-2">
                                        <label className="form-label text-start d-block">How much player will get*</label>
                                        <input type="text" />
                                    </div>
                                    <div className="col-md-4 user-select user-selectinput mb-2">
                                        <label className="form-label text-start d-block">Max Amount*</label>
                                        <input type="text" />
                                    </div>
                                    <div className="col-md-4 user-select user-selectinput mb-2">
                                        <label className="form-label text-start d-block">How it will be credit*</label>
                                        <select className="form-select">
                                            <option value="1">Auto Credit</option>
                                            <option value="2">Claim and Credit</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4 user-select user-selectinput mb-2">
                                        <label className="form-label text-start d-block">Is Lock*</label>
                                        <select className="form-select">
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4 user-select user-selectinput mb-2">
                                        <label className="form-label text-start d-block">Is Penalty*</label>
                                        <select className="form-select">
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4 user-select user-selectinput mb-2">
                                        <label className="form-label text-start d-block">Deposit Lock*</label>
                                        <select className="form-select">
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4 user-select user-selectinput mb-2">
                                        <label className="form-label text-start d-block">Is Deposit Penalty*</label>
                                        <select className="form-select">
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </select>
                                    </div>

                                    <div className="col-md-4 user-select user-selectinput mb-2">
                                        <label className="form-label text-start d-block">Code Required*</label>
                                        <select className="form-select">
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </select>
                                    </div>

                                </>}



                                {(['1', '2', '4', ].includes(selectedCategory)) &&
                                <>
                                <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">In which wallet will get*</label>
                                <select className="form-select">
                                    <option value="-1">Cash</option>
                                    <option value="0">Bonus</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Is Lock*</label>
                                <select className="form-select">
                                    <option value="-1">Yes</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Is Penalty*</label>
                                <select className="form-select">
                                    <option value="-1">No</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">How it will be credit*</label>
                                <select className="form-select">
                                    <option class="credit_type" value="-1" selected="">Select Credit Process</option>
                                    <option value="1">Auto Credit</option>
                                    <option value="2">Claim and Credit</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Code Required*</label>
                                <select className="form-select">
                                    <option value="-1">No</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Device Restriction *</label>
                                <select className="form-select">
                                    <option value="-1">Yes</option>
                                    <option value="-1">No</option>
                                </select>
                            </div> </>}

                            {selectedCategory === '5' && 
                            <>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Campaign Name *</label>
                                <input type="text" />
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Campaign For *</label>
                                <select className="form-select">
                                    <option>Select Campaign</option>
                                </select>
                            </div>
                            
                            
                            <div className="col-md-6 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">How player will get*</label>
                                <select className="form-select">
                                    <option>Select</option>
                                </select>
                            </div>
                            <div className="col-md-6 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Is Lock*</label>
                                <select className="form-select">
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            <div className="col-md-6 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Is Penalty*</label>
                                <select className="form-select">
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            
                            <div className="col-md-6 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">How it will credit*</label>
                                <select className="form-select">
                                    <option>Auto credit into wallet</option>
                                </select>
                            </div>
                            </>
                            }

                            {selectedCategory === '6' && 
                            <>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Campaign Name *</label>
                                <input type="text" />
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Campaign For *</label>
                                <select className="form-select">
                                    <option>Select Campaign</option>
                                </select>
                            </div>
                            
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Rack Back %*</label>
                                <input type="date" placeholder="" />
                            </div>
                            <div className="col-md-6 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">How will be created*</label>
                                <select className="form-select">
                                    <option>Auto credit wallet</option>
                                </select>
                            </div>
                            </>
                            }

                            {selectedCategory === '1' &&
                            <>
                            
                            <div className="col-md-6 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Title*</label>
                                <input type="file" />
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Valid From*</label>
                                <input type="date" />
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Valid To*</label>
                                <input type="date" />
                            </div>
                            
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Campaign for*</label>
                                <select className="form-select">
                                <option class="rf_applicto_selected" value="" selected="">Select User Type</option>
										
										<option value="2">All</option>
										<option value="1">Specific Player</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">How many friends can be referred by each player*</label>
                                <select className="form-select">
                                <option class="rf_applicto_selected" value="" selected="">1</option>
										
										<option value="2">2</option>
										<option value="1">Unlimited</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">How the referring player will get*</label>
                                <select className="form-select">
                                <option value="">Select</option>
										
										<option value="1">On deposit by referred person</option>
										<option value="2">On spending by referred person</option>
										<option value="3">On signup by referred person</option>
										<option value="0">None of these</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">How many time referring player will get on deposit*</label>
                                <select className="form-select">
                                <option value="1">First Time</option>
										<option value="0">unlimited</option>
                                </select>
                            </div>
                            
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Is Lock*</label>
                                <select className="form-select">
                                <option value="1">Yes</option>
										<option value="0">No</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Is Penalty*</label>
                                <select className="form-select">
                                <option value="1">Yes</option>
										<option value="0">No</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">How the referred person will get*</label>
                                <select className="form-select">
                                <option value="-1">--Select--</option>
										<option value="1">On signup</option>
										<option value="2">On deposit</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Is Penalty*</label>
                                <select className="form-select">
                                <option value="1">Yes</option>
										<option value="0">No</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Deposit Lock*</label>
                                <select className="form-select">
                                <option value="1">Yes</option>
										<option value="0">No</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Is Deposit Penalty*</label>
                                <select className="form-select">
                                <option value="1">Yes</option>
										<option value="0">No</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">How it will be credit*</label>
                                <select className="form-select">
                                <option value="1">Auto credit into wallet</option>
									<option value="2">Claim &amp; credit in wallet</option>
                                </select>
                            </div>
                            <div className="col-md-4 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Code Required*</label>
                                <select className="form-select">
                                <option value="1">No</option>
									<option value="2">Yes</option>
                                </select>
                            </div>
                            </>
                            }
                            <div className="col-md-12 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Remark*</label>
                                <textarea className="w-100"></textarea>
                            </div>
                            <div className="col-md-6 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block">Upload Promo Image*</label>
                                <input type="file" />
                            </div>
                            <div className="col-md-6 user-select user-selectinput mb-2">
                                <label className="form-label text-start d-block"> Upload banner Image *</label>
                                <input type="file" />
                            </div>

                            <div className="col-12 d-flex justify-content-center mt-3">
                                <button className="btn btn-primary btnall">Save</button>
                                <button className="btn btn-secondary ms-3" style={{ minWidth: '100px' }}>Cancel</button>
                            </div>

                            {/* Signup End */}
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default AddCampaign;