import React from "react";


const ReferralInviteReport = () => {
    return (
        <>

            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Referral Report</h1>
                    </div>

                </div>
                <div>
                    <div className='container-fluid bg'>
                        <div className='row user-select mt-2'>

                            <div className="col-lg-3 col-md-3  col-6  col-xs-12">
                                <div className="form-group">
                                    <select name="" id="" className="w-100 ">
                                        <option value="0">Today</option>
                                        <option value="1">Yesterday</option>
                                        <option value="2">Current Week</option>
                                        <option value="3">Last Week</option>
                                        <option value="4">Current Month</option>
                                        <option value="5">Last Month</option>
                                        <option value="7">Date Range</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-6 mt-lg-0  col-xs-1 user-select user-selectinput d-flex align-items-center gap-1">
                                <input type="date" defaultValue="" />
                            </div>

                            
                            <div className="col-lg-3 col-md-3 col-6 mt-lg-0  col-xs-1 user-select user-selectinput d-flex align-items-center gap-1">
                                <input type="date" defaultValue="" />
                            </div>
                            
                            <div className="col-lg-3 col-md-3 user-select col-6 mt-lg-0  col-xs-1">
                                <div className="input-group  input-group-sm">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for...."
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        name="search"
                                        defaultValue=""
                                    />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </span>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>

                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <table className="table data-table   table-borderless">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Referring</th>
                                        <th>Code</th>
                                        <th>Level</th>
                                        <th>Total Reward Referral</th>
                                        <th>Signup Commission</th>
                                        <th>Direct/Indirect Deposit Comm</th>
                                        <th>Direct/indirect Spending Comm</th>
                                        <th>Direct/Indirect deposits</th>
                                        <th>Claimable Available</th>
                                        <th>Claimed Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>2024-02-23 <br/> 12:21:14</td>
                                        <td>abc123</td>
                                        <td>DF0F6FFA2</td>
                                        <td>Lvel 1</td>
                                        <td>1</td>
                                        <td>5</td>
                                        <td>0/0</td>
                                        <td>0/0</td>
                                        <td>0/0</td>
                                        <td>46.11</td>
                                        <td>-41.11</td>
                                    </tr>
                                </tbody>
                            </table></div>

                    </div>

                </div>
            </div>

        </>
    );
};

export default ReferralInviteReport;