import React from "react";
import { useLocation } from "react-router-dom";
// import Loader from "../../Components/Loader";
import LoadingBar from "react-top-loading-bar";
const GameHistory = () => {

    const location = useLocation();

    const url = location.state;
    console.log("🚀 ~ file: GameHistory.js:9 ~ GameHistory ~ url:", url)


    return (
        <>
            {url ? <iframe className="iframe-mgp" src={url && url} title="Embedded Content"
                width="100%"
                style={{ minHeight: "calc(100vh - 75px)" }}></iframe> : <LoadingBar />}
        </>
    )
}

export default GameHistory;