import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const MakeVip = () => {
    const location = useLocation();
    const { vipPlayerId, vipPlayerOperatorId, playerAccountDetails } = location.state || {};

    const [promoCode, setPromoCode] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [playerData, setPlayerData] = useState();

    useEffect(() => {
        if (location.state) {
            setPlayerData(location.state.playerAccountDetails);
            console.log(location.state.playerAccountDetails, 'dghfdffghf');
        }
    }, [location.state]);

    const handleSave = async () => {
        if (!promoCode) {
            setError('Enter promo code');
            setSuccess('');
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_TRANSACTION_API}redeem/subscription`,
                {
                    player_id: vipPlayerId,
                    operator_id: vipPlayerOperatorId,
                    promo_code: promoCode
                },
                {
                    headers: {
                        admin_auth_token: localStorage.getItem("omsToken"),
                        opr_auth_token: localStorage.getItem("sessionToken")
                    }
                }
            );

            if (response.data?.status) {
                setSuccess(response.data.message);
                setError('');
            } else {
                setError(response.data.message);
                setSuccess('');
            }
        } catch (error) {
            console.error('Error saving VIP status:', error);
            setError('An error occurred. Please try again.');
            setSuccess('');
        }
    };

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-sm-8 fund_transfer_form_block">
                    <ul className="nav nav-tabs new_tabs border-0" style={{ marginTop: 30 }} role="tablist">
                        <li className="no_select active">
                            <a
                                data-bs-toggle="tab"
                                href="#home"
                                id="deposit_change"
                                className="tab_color active"
                                style={{
                                    background: "rgba(42, 53, 0, 0.44)",
                                    transition: "1s",
                                    color: "rgb(255, 255, 255)"
                                }}
                                aria-expanded="true"
                                aria-selected="true"
                                role="tab"
                            >
                                Promo Code Redemption
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div id="home" className="tab-pane fade in active show" role="tabpanel" aria-labelledby="deposit_change">
                            <div style={{ padding: 0 }}>
                                <fieldset
                                    className="no_border_field p-2 fund_transfer_form"
                                    style={{
                                        borderWidth: "medium 1px 1px",
                                        borderStyle: "none solid solid",
                                        borderColor: "currentcolor rgb(206, 206, 206) rgb(206, 206, 206)",
                                        borderImage: "none"
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 ps-0 all_set">
                                            <div className="form-group">
                                                <label htmlFor="search_player">Player Id</label>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group input-group-sm position-relative">
                                                    <input
                                                        type="text"
                                                        className="search form-control input-sm ui-autocomplete-input"
                                                        placeholder="search by Username"
                                                        id="search_player"
                                                        name="player_name"
                                                        autoComplete="off"
                                                        readOnly
                                                        value={vipPlayerId || ''}
                                                    />
                                                    <span id="search_err" className="errmsg" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 pe-0 all_set">
                                            <div className="form-group">
                                                <label htmlFor="promo_code">Promo Code</label>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group input-group-sm position-relative">
                                                    <input
                                                        type="text"
                                                        className="search form-control input-sm ui-autocomplete-input"
                                                        placeholder="Promo Code"
                                                        id="promo_code"
                                                        value={promoCode}
                                                        onChange={(e) => setPromoCode(e.target.value)}
                                                    />
                                                    <span id="search_err" className="errmsg" />
                                                </div>
                                            </div>
                                        </div>

                                        {playerData && (
                                            <div className="col-md-12 bal_mid_row responsive-clear">
                                                <div className="row">
                                                    <div className="col-sm-8 col-xs-6 no-padding-responsive">
                                                        <div className="form-group">
                                                            <label htmlFor="" className="small_bal_label">
                                                                <span>Player Code :</span> {playerData.player_code}
                                                            </label>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="" className="small_bal_label">
                                                                <span>Nickname :</span> {playerData.display_name}
                                                            </label>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="" className="small_bal_label">
                                                                <span>Login Id :</span> {playerData.guest_id ? "Guest" : (playerData.mobile_number || playerData.email_id)}
                                                            </label>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="" className="small_bal_label">
                                                                <span>Unique Id :</span> {playerData._id}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 no-padding-responsive">
                                                        <div className="form-group">
                                                            <div className="bal_div">
                                                                <label htmlFor="">Balance </label>
                                                                <label>{playerData.wallet_balance?.toFixed(2)}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="w-100 text-center my-2">
                                            <span id="chip_msg" className="errmsg" style={{ fontSize: '16px' }}>{error}</span>
                                            <span id="chip_msg" className="errmsg" style={{ color: 'green', fontSize: '16px' }}>{success}</span>
                                        </div>

                                        <div className="col-sm-12">
                                            <div className="border-box col-sm-12 text-center email">
                                                <div className="buttons m-t-1">
                                                    <button
                                                        type="button"
                                                        className="btn btn-warning my_color"
                                                        onClick={handleSave}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MakeVip;
