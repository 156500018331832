import React from "react";


const AppleStore = () => {
    return (
        <>

            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Apple Store</h1>
                    </div>
                    <div>
                        <button className="nav-link db-btn disabled"><i className="fa-solid fa-plus"></i>Items</button>
                        <button className="nav-link db-btn disabled"><i className="fa-solid fa-check"></i>Active</button>
                        <button className="nav-link db-btn disabled"><i className="fa-solid fa-plus"></i>Inactive</button>
                        <button className="nav-link db-btn disabled"><i className="fa-solid fa-trash"></i>Delete</button>
                    </div>
                </div>
                <div>

                    <div className='container-fluid bg'>
                        <div className='row user-select mt-2'>

                            <div className="col-lg-6 col-md-6  col-6  col-xs-12  p-1">
                                <div className="form-group">
                                    <select name="" id="" className="w-100 ">
                                        <option value="" selected="">
                                            All
                                        </option>
                                        <option value="">Option</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-6 user-select col-6 mt-lg-0  col-xs-12 p-1">
                                <div className="input-group  input-group-sm">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for...."
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        name="search"
                                        defaultValue=""
                                    />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </span>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>

                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <table className="table data-table   table-borderless">
                                <thead>
                                    <tr>
                                        <th><input type="checkbox" /></th>
                                        <th>Item Name</th>
                                        <th>Item Key</th>
                                        <th>Item Type</th>
                                        <th>Quantity</th>
                                        <th>Amount</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>

                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                    </tr>
                                </tbody>
                            </table></div>

                    </div>

                </div>
            </div>
        </>
    );
};

export default AppleStore;