import React, { useState } from "react";
import { Link } from "react-router-dom";


const ManageBanner = () => {
    const [activeToggle, setActiveToggle] = useState(false);
    const toggleButton = () => {
        setActiveToggle(!activeToggle);
    }
    return (
        <>

            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Manage Banner</h1>
                    </div>
                    <div>
                        <Link className="nav-link db-btn  " to="/add-banner"><i className="fa-solid fa-circle-plus"></i>Add Banner</Link>
                    </div>

                </div>
                <div>

                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <table className="table data-table   table-borderless">
                                <thead>
                                    <tr>
                                        <th>
                                        
                                        </th>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Game</th>
                                        <th>Type</th>
                                        <th>URL</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Image</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="">
                                        <td>
                                        <i className="fa fa-ban Inactive" aria-hidden="true"></i>
                                        </td>
                                        <td>Banner 1</td>
                                        <td>signup</td>
                                        <td></td>
                                        <td>image</td>
                                        <td></td>
                                        <td> 2020-04-23 23:04:21 </td>
                                        <td>2021-04-23 23:04:23</td>
                                        <td>-</td>
                                        <td className="position-relative dropdowncaret">
                                            <button
                                                className="action btn"
                                                id="dropdown"
                                            >
                                                <i className="fa-solid fa-caret-down"></i>
                                            </button>
                                            <div className="action-btn actin-payment-btn-sc" style={{ width: ' 100px', minWidth: ' 140px' }}>
                                                <div className="item-3 d-flex flex-wrap">
                                                    <button className="w-100">
                                                        <i className="fa fa-pencil icons_img"></i>
                                                        <p> Edit</p>
                                                    </button>
                                                    <button className="w-100">
                                                        <i className="fa fa-ban icons_img" aria-hidden="true"></i>
                                                        <p> Inactive</p>
                                                    </button>
                                                    <button className="w-100">
                                                        <i className="fa fa-trash"></i>
                                                        <p> Inactive</p>
                                                    </button>
                                                    <button className="w-auto d-d-inline-block">
                                                        <i className="fa fa-play-circle icons_img" aria-hidden="true"></i>
                                                        <p> Beneficial Report</p>
                                                    </button>
                                                </div>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table></div>

                    </div>

                </div>
            </div>

        </>
    );
};

export default ManageBanner;