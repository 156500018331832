import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import LoadingBar from "react-top-loading-bar";
import Skeleton from "react-loading-skeleton";
import moment from 'moment';
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const AllAdmin = () => {

    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [error, setError] = useState('');
    const [editRoleName, setEditRoleName] = useState('');
    const [loginId, setLoginId] = useState('');
    const [roleID, setRoleID] = useState([]);

    const sessionToken = localStorage.getItem("sessionToken");
    const handleClose = () => {
        setShow(false);
    };



    const getRoleName = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}getRoleIDs`, {
                headers: {
                    opr_auth_token: sessionToken
                }
            });

            console.log(res?.data);
            setRoleID(res?.data.data);
        } catch (error) {
            console.error("Error fetching role data:", error);
            setError("Failed to fetch role data. Please try again.");
        }
    };

    const getAdminAccounts = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}getAdminAccounts`, {
                headers: {
                    opr_auth_token: sessionToken
                }
            });

            console.log(res?.data);
            setData(res?.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Failed to fetch role data. Please try again.");
        }
    };

    useEffect(() => {
        getAdminAccounts();
        getRoleName();
    }, []);

    const handleEdit = (roleName, username) => {
        setEditRoleName(roleName);
        setLoginId(username);
        setShow(true);
    };

    const handleUpdateRoleName = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.patch(`${process.env.REACT_APP_API_PATH}updateAdminAccount`, {
                login_id: loginId,
                role_name: editRoleName,
            }, {
                headers: {
                    opr_auth_token: sessionToken,
                }
            });

            alert(res?.data?.message);
            setShow(false);
            getAdminAccounts();
        } catch (error) {
            console.error("Error updating admin account:", error);
        }
    };

    const handleBlock = async (isBlocked, username) => {
        try {
            const updatedBlockedStatus = !isBlocked;

            const res = await axios.patch(`${process.env.REACT_APP_API_PATH}updateAdminAccount`, {
                login_id: username,
                isBlocked: updatedBlockedStatus,
            }, {
                headers: {
                    opr_auth_token: sessionToken,
                }
            });

            alert(res?.data?.message);
            setShow(false);
            getAdminAccounts();
        } catch (error) {
            console.error("Error updating admin account:", error);
        }
    };

    const handleDelete = async (username) => {
        try {

            const res = await axios.patch(`${process.env.REACT_APP_API_PATH}updateAdminAccount`, {
                login_id: username,
                isDeleted: true,
            }, {
                headers: {
                    opr_auth_token: sessionToken,
                }
            });

            alert(res?.data?.message);
            setShow(false);
            getAdminAccounts();
        } catch (error) {
            console.error("Error updating admin account:", error);
        }
    };


    return (
        <>
            <div className="right-sidebar">
                <div className="das-bg-block w-">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3">
                                <h1 className="gamehistoryheading title_h1 same_heading text-start">
                                    All Sub Admin
                                </h1>
                            </div>

                        </div>
                    </div>
                </div>

                <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer"
                >
                    <div className="table-player">
                        <div className="w-100">
                            <table className="table data-table">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap">User Name</th>
                                        <th className="text-nowrap">ID</th>
                                        <th className="text-nowrap">Role Name</th>
                                        <th className="text-nowrap">Opr Name</th>
                                        <th className="text-wrap">Action</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {data?.length > 0 ? (data?.map((e, idx) => (
                                        <tr key={idx}>

                                            <td className='text-start'>{e?.isBlocked ? <img src="img/ban-user.png" className="me-1" width="20" /> : ''}{e?.username}</td>
                                            <td className='text-center'>{e?._id}</td>
                                            <td className='text-start'>{e?.role_name}</td>
                                            <td className='text-start'>{e?.opr_name}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button
                                                        className="btn action dropdown-toggle bg-transparent dropdown-toggle-custom"
                                                        type="button"
                                                        id={`dropdownMenuButton${idx}`}
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <i className="fa-solid fa-caret-down"></i>
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-custom" aria-labelledby={`dropdownMenuButton${idx}`}>
                                                        <li>
                                                            <span className="dropdown-item"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    handleEdit(e?.role_name, e?.username);
                                                                }}
                                                            >
                                                                <i className="fa-solid fa-pen"></i> Edit
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="dropdown-item"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    handleBlock(e?.isBlocked, e?.username);
                                                                }}
                                                            >
                                                                <i className="fa-solid fa-user"></i>
                                                                {e?.isBlocked ? "Unblock" : 'Block'}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="dropdown-item"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    handleDelete(e?.username);
                                                                }}
                                                            >
                                                                <i className="fa-solid fa-trash"></i> Delete
                                                            </span>
                                                        </li>
                                                        {/* <li>
                                                            <a className="dropdown-item" href="#">
                                                                <i className="fa-solid fa-user"></i> Admin Permission
                                                            </a>
                                                        </li> */}

                                                    </ul>
                                                </div>

                                            </td>

                                        </tr>
                                    ))
                                    ) : (
                                        <tr>
                                            <td colSpan={12} className="text-center">No Data Found</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>



                    </div>
                </div>
            </div>

            {/* Edit Role Name */}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Edit Role Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleUpdateRoleName}>
                        <Row>
                            <Col md="12">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-start d-block">Role Name</Form.Label>
                                    <select
                                        className="form-control"
                                        name="role_name"
                                        value={editRoleName}
                                        onChange={(e) => setEditRoleName(e.target.value)}
                                    >
                                        <option value="">Select Role Name</option>
                                        {roleID?.map((role, idx) => (
                                            <option key={idx} value={role?.role_name}>
                                                {role?.role_name}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Form.Control
                                        type="text"
                                        placeholder="Account Type"
                                        name="account_type"
                                        required
                                        value={editRoleName}
                                        onChange={(e) => setEditRoleName(e.target.value)}

                                    /> */}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button
                            variant="primary"
                            className="mt-3 change_password_btn btn btn-primary"
                            type="submit"
                            disabled={!editRoleName}
                        >
                            Update
                        </Button>

                    </Form>
                </Modal.Body>
            </Modal>

            {/* Edit Role Name End! */}
        </>
    );
};

export default AllAdmin;
