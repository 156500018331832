import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import { useNavigate } from "react-router-dom";

const WithdrawRequest = () => {

  const navigate = useNavigate();
  const [input, setInput] = useState({
    tableLength: "100",
    sortByStatus: "pending",
    date: "recent",
    searchPlayer: '',
    from: "",
    to: ''
  });

  const [pageNo, setPageNo] = useState(1);
  const [playerData, setPlayerData] = useState();
  const [totalPage, setTotalpage] = useState(0);
  const [data, setData] = useState();
  const [amountEdit, setAmountEdit] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
    remark: "",
  });
  const [error, setError] = useState({
    amount: "",
    remark: ""
  });
  const [isLoaderActive, setIsLoaderActive] = useState(false)
  const [declineBtnLoader, setDeclineBtnLoader] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      // if(localStorage.getItem("opr_id")){
      //   const response = await axios.get(
      //     process.env.REACT_APP_API_PATH +
      //       `withdrawalTransaction?limit=${input.tableLength}&pageNo=${pageNo}&status=${input.sortByStatus}&dateFilter=${input.date}&opr_id=${localStorage.getItem("opr_id")}`
      //   );
      //   console.log(response.data);
      //   setPlayerData(response.data);
      // }else{
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_PATH +
          `withdrawalTransaction?limit=${input.tableLength}&pageNo=${pageNo}&status=${input.sortByStatus}&dateFilter=${input.date}&searchPlayer=${input.searchPlayer}&from=${input.from}&to=${input.to}`,
          {
            headers: {
              admin_auth_token: localStorage.getItem("omsToken"),
              opr_auth_token: localStorage.getItem("sessionToken"),
            },
          }
        );
        console.log(response.data);
        setPlayerData(response.data);
        setTotalpage(response?.data?.totalPage);
      } catch (error) {
        alert(error.message);
      }
    };
    // };
    fetchData();
  }, [input, pageNo]);

  const handelChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setPageNo(1);
  };

  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleModelData = (document) => {
    setData(document);
    // console.log(data, 'modal data');
    // console.log(data.amount, 'modal data');

    console.log(document.amount);
    setFormData({
      amount: document.amount || "",
      remark: "",
    });
  };
  // console.log(data,"modelData")

  const handleApproveBtn = async () => {
    if (formData && !formData.amount) {
      setError({
        amount: "*required"
      });
    }
    else if (formData && formData.amount < 1 && formData.remark) {
      setError({
        amount: "*Amount should be must greater than 1"
      });
    } else if (formData && formData.amount > 0 && formData.remark) {
      setIsLoaderActive(true)
      try {
        const response = await axios.post(
          process.env.REACT_APP_TRANSACTION_API +
          "transaction/withdraw-confirm",
          {
            user_id: localStorage.getItem("login_id"),
            user_type: "123456",
            module: 'transaction',
            payment_gateway: data.payment_gateway,
            withdraw_method: data.withdraw_method,
            amount: formData.amount,
            operator_id: data.opr_id,
            player_id: data.player_id,
            token: localStorage.getItem("omsToken"),
            country: "+91",
            _id: data._id,
            remarks: formData.remark,
            transaction_id: "101",
          },
          {
            headers: {
              token: localStorage.getItem("sessionToken")
            }
          }
        );
        console.log(response);
        setIsLoaderActive(false)
        if (response.data.status === true) {
          alert("withdrawal successful");
          setAmountEdit(false);
          window.location.reload();
        } else if (response.data.status === false && response.data.message === "invaild Token") {
          alert("Invaild Token, please login again");
          localStorage.clear();
        } else if (response.data.status === false) {
          alert(response.data.message);
        }
      } catch (err) {
        console.log(err);
        setIsLoaderActive(false)
        alert("Internal server error! Plaese try again after some time");
      }
    } else {
      // alert("please fill all details");
      setError({
        remark: "Please fill all details"
      })
    }
  };

  function handleKeyPress(event) {
    if (event.charCode >= 48 && event.charCode <= 57) {
      // console.log(event.target.value)
    } else {
      event.preventDefault();
    }
  }

  // console.log(input, "4454");

  const handleDeclineBtn = async () => {
    if (formData && formData.remark) {
      setDeclineBtnLoader(true)
      try {
        const response = await axios.post(
          process.env.REACT_APP_TRANSACTION_API + "transaction/withdraw-reject",
          {
            user_id: localStorage.getItem("login_id"),
            user_type: "123456",
            module: 'transaction',
            withdraw_method: data.withdraw_method,
            operator_id: data.opr_id,
            player_id: data.player_id,
            token: localStorage.getItem("omsToken"),
            country: "+91",
            _id: data._id,
            remarks: formData.remark,
          },
          {
            headers: {
              token: localStorage.getItem("sessionToken")
            }
          }
        );
        console.log(response);
        if (response.data.status === true) {
          alert("decline successful");
          setAmountEdit(false);
          window.location.reload();
        } else if (response.data.status === false && response.data.message === "invaild Token") {
          alert("Invaild Token, please login again");
          localStorage.clear();
          navigate("/");
        } else {
          alert(response.data.message);
        }
        setDeclineBtnLoader(false)
      } catch (err) {
        console.log(err.message);
        alert("internal server error ! plaese try again after some time");
        setDeclineBtnLoader(false)
      }
    } else {
      alert("please fill remark");
    }
  };

  const DateTimeFormat = (inputDate) => {

    var parsedDate = new Date(inputDate);

    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const day = parsedDate.getDate();
    const month = monthNames[parsedDate.getMonth()];
    const year = parsedDate.getFullYear();
    const hours = parsedDate.getHours();
    const minutes = parsedDate.getMinutes();
    const seconds = parsedDate.getSeconds();
    const ampm = hours >= 12 ? 'pm' : 'am';

    const formattedDate = `${day} ${month} ${year}, ${hours}:${minutes}:${seconds} ${ampm}`;

    // console.log(formattedDate);
    return formattedDate
  }

  return (
    <div>

      <div className="right-sidebar">
        <div className="das-bg-block w-">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <h1 className="gamehistoryheading title_h1 same_heading text-start">
                  Withdraw Request
                </h1>
              </div>
              <div className="col-md-8">
                <div className="row user-select">
                  <div className="col-md-4 mb-2 mb-md-0">
                    <div className="form-group">
                      <select
                        id=""
                        className="w-100"
                        name="sortByStatus"
                        value={input.sortByStatus}
                        onChange={handelChange}
                      >
                        <option value="all">All</option>
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                        <option value="rejected">Rejected</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <select
                        name="date"
                        value={input.date}
                        onChange={handelChange}
                        className="w-100"
                      >
                        <option value="recent">Recent</option>
                        <option value="today">Today</option>
                        <option value="current_week">Current Week</option>
                        <option value="last_week">Last Week</option>
                        <option value="current_month">Current Month</option>
                        <option value="last_month">Last Month</option>
                        <option value="last_six_month">Last 6 Month</option>
                        <option value="date_range">Date Range</option>
                      </select>
                    </div>
                    {input.date === "date_range" && (<div id="target">
                      <div className="date">
                        <p>
                          <label>From</label>
                          <input type="date" id="date" name="from" value={input.from} onChange={handelChange} />
                          <label>To</label>
                          <input type="date" id="date" name="to" value={input.to} onChange={handelChange} />
                        </p>
                      </div>
                    </div>)}
                  </div>
                  <div className="col-md-4 form-group mt-2 mt-md-0">
                    <div className="input-group  input-group-sm">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search for...."
                        aria-describedby="basic-addon1"
                        style={{ height: "33.5px !important" }}
                        name="searchPlayer"
                        value={input.searchPlayer}
                        onChange={handelChange}
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                          style={{ padding: "9px !important" }}
                        >
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="DataTables_Table_0_wrapper"
        className="dataTables_wrapper no-footer"
      >
        <div className="table-player  affiliate-list">
          <div className="table-list-mb">
            <table className="table data-table">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Request On</th>
                  <th>Player Name</th>
                  <th>Player Code</th>
                  <th>Wallet balance</th>
                  <th>Amount</th>
                  <th>Pay Method</th>
                  {/* <th>Linked Account</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {playerData ? (
                  playerData.data.map((doc, index) => (
                    <tr key={index}>
                      <td>
                        {doc.withdraw_status === 0 ? (
                          <label
                            htmlFor=""
                            className="approved"
                            id="auto-approved"
                          >
                            Pending
                          </label>
                        ) : doc.withdraw_status === 1 ? (
                          <label htmlFor="" className="approved">
                            Approved
                          </label>
                        ) : (
                          <label htmlFor="" className="unapproved">
                            Rejected
                          </label>
                        )}
                      </td>
                      <td>
                        {/* {new Date(doc.createdAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })} */}
                        {doc.createdAt && DateTimeFormat(doc.createdAt)}
                      </td>
                      {/* <td className="text-start">{doc && doc.login_type === 'email' ? (<i className="fa-solid fa-envelope m-1"></i>) : doc.login_type === "social" ? (<i className="fa-solid fa-user m-1"></i>) : doc.login_type === "guest" ? (<i className="fa-solid fa-user m-1"></i>) : doc.login_type === 'mobile' ? (<i className="fa-solid fa-phone m-1"></i>) : null}{doc?.login_id}</td> */}
                      <td  >{doc?.player_name} </td>
                      <td>{doc?.player_code}</td>
                      <td className="">{doc.opening_wallet_bal.toFixed(2)}</td>
                      <td className="debit ">{doc.amount}</td>
                      <td>
                        {/* {doc.acc_details && doc.acc_details.acc_type}{" "}
                        - {doc.acc_details && doc.acc_details.upi_id} */}
                        {doc.withdraw_method}
                      </td>
                      {/* <td>{doc.acc_details && doc.acc_details.upi_id}</td> */}
                      <td>
                        {doc.withdraw_status === 0 ? (
                          <button
                            className="btn-info reset-btn green-bg-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#addbtnmodal"
                            onClick={() => handleModelData(doc)}
                            disabled={
                              doc.withdraw_status === 0 ? false : true
                            }
                          >
                            {doc.withdraw_status === 1
                              ? "Approved"
                              : doc.withdraw_status === 2
                                ? "Rejected"
                                : "Approve"}
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan={28}>
                      <span>
                        <Loader />
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="pagination pagination-text row justify-content-between align-items-center">
            <div className="w-auto">
              <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                <select
                  aria-controls="DataTables_Table_0"
                  name="tableLength" value={input.tableLength}
                  onChange={handelChange}
                  className="pag-select-block me-1"
                >
                  <option value="10">10</option>
                  <option value="25" defaultValue={true}>25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select> Records per page
              </div>
            </div>

            <div className="w-auto">
              Showing {pageNo}-{input?.tableLength}{" "}
              of {playerData?.data?.length} items
            </div>

            <div className="w-auto">
              <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                  className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                  <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                </a>
                <a onClick={() => setPageNo(pageNo - 1)}
                  style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                  className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                  {/* <i className="fa-solid fa-chevron-left"></i> Previous */}
                  <img src="img/left-arrow.png" width="20" />
                </a>
                <span className="d-flex align-items-center">

                  <select className="pag-select-block"
                    aria-controls="DataTables_Table_0"
                    name="pagination" value={pageNo}
                    onChange={(e) => setPageNo(Number(e.target.value))}
                  >
                    {Array.from({ length: totalPage }, (_, index) => (
                      <option key={index + 1} value={index + 1} >
                        {index + 1}
                      </option>
                    ))}



                  </select> of {totalPage}
                </span>

                <a onClick={() => setPageNo(pageNo + 1)}
                  style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                  className={`${pageNo < totalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                  <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                </a>
                {/* } */}

                <a onClick={() => setPageNo(totalPage)} style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                  className={`${pageNo < totalPage ? '' : 'opacity-25'} border-0`}>
                  {/* <i className="fa-solid fa-chevron-right"></i> */}
                  <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                </a>
              </div>
            </div>
          </div>

          {/* <div className="pagination row justify-content-between align-items-center">
            <div className="w-auto">
              <div className="dataTables_info dataTables_length">
                {pageNo} of {playerData && playerData.totalPage}{" "}
                <label>
                  Rows per page{" "}
                  <select
                    // name="DataTables_Table_0_length"
                    aria-controls="DataTables_Table_0"
                    name="tableLength"
                    value={input.tableLength}
                    onChange={handelChange}
                  >
                    <option value="10">10</option>
                    <option value="25" defaultValue={true}>
                      25
                    </option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
            </div>
            <div className="w-auto">
              <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                {pageNo > 1 && (
                  <a onClick={() => setPageNo(pageNo - 1)}>
                    <i className="fa-solid fa-chevron-left"></i>
                  </a>
                )}
                <span>
                  <a
                    className="paginate_button current"
                    aria-controls="DataTables_Table_0"
                    data-dt-idx="1"
                    tabIndex="0"
                  >
                    {pageNo}
                  </a>
                </span>
                {playerData && pageNo < playerData.totalPage && (
                  <a onClick={() => setPageNo(pageNo + 1)}>
                    <i className="fa-solid fa-chevron-right"></i>
                  </a>
                )}
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* </div> */}
      {/* <!-- Modal --> */}
      <div
        className="modal fade add-btn-modal"
        id="addbtnmodal"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div
            className="modal-content manual_width "
            style={{ borderRadius: "0" }}
          >
            <div
              className="modal-header p-2"
              style={{ background: "#eceff4 !important" }}
            >
              <h5 className="modal-title">Withdrawal Request</h5>
              <button
                className="close-btn"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setAmountEdit(false)}
              >
                <span>x</span>
              </button>
            </div>
            <div className="modal-body container">
              <div className="row">
                {/* <div className="col-md-3 pay_list">
                  <ul type="none" className="p-0">
                    <li>
                      <img src="../img/payumoney.png" alt="" />
                      <input type="checkbox" />
                    </li>
                    <li>
                      <img src="../img/paypal.png" alt="" />
                      <input type="checkbox" />
                    </li>
                    <li>
                      <img src="../img/PhonePe.png" alt="" />
                      <input type="checkbox" />
                    </li>
                    <li>
                      <img src="../img/airtel_logo.png" alt="" />
                      <input type="checkbox" />
                    </li>
                    <li>
                      <img src="../img/grameen.png" alt="" />
                      <input type="checkbox" />
                    </li>
                    <li>
                      <img src="../img/teletalk.png" alt="" />
                      <input type="checkbox" />
                    </li>
                    <li>
                      <img src="../img/payumoney.png" alt="" />
                      <input type="checkbox" />
                    </li>
                    <li>
                      <img src="../img/paypal.png" alt="" />
                      <input type="checkbox" />
                    </li>
                    <li>
                      <img src="../img/PhonePe.png" alt="" />
                      <input type="checkbox" />
                    </li>
                    <li>
                      <img src="../img/airtel_logo.png" alt="" />
                      <input type="checkbox" />
                    </li>
                    <li>
                      <img src="../img/grameen.png" alt="" />
                      <input type="checkbox" />
                    </li>
                    <li>
                      <img src="../img/teletalk.png" alt="" />
                      <input type="checkbox" />
                    </li>
                  </ul>
                </div> */}
                <div className="col-md-6 user_ac_list">
                  <ul type="none" className="p-0">
                    <li>
                      Request Id:
                      <span>{data && data._id}</span>
                    </li>
                    <li>
                      Login Id:
                      <span>{data && data?.login_type === 'email' ? (<i className="fa-solid fa-envelope m-1"></i>) : data?.login_type === "social" ? (<i className="fa-solid fa-user m-1"></i>) : data?.login_type === "guest" ? (<i className="fa-solid fa-user m-1"></i>) : data?.login_type === 'mobile' ? (<i className="fa-solid fa-phone m-1"></i>) : null}{data && data?.login_id}</span>
                    </li>
                    <li>
                      Last Remark:<span>{data && data.remarks}</span>
                    </li>
                    <li>
                      Oprertor Id:
                      <span>{data && data.opr_id}</span>
                    </li>
                    <li>
                      Withdrawal Method:
                      <span>{data && data.withdraw_method}</span>
                    </li>
                    <li>
                      KYC Status:
                      <i
                        className="fa-solid fa-circle-xmark fa-xl"
                        style={{ color: "#df0c0c" }}
                      ></i>
                    </li>
                    <li>
                      Bank Status:
                      <i
                        className="fa-solid fa-circle-check  fa-xl"
                        style={{ color: "#00d123" }}
                      ></i>
                    </li>
                    {/* <li>Holder Name:</li>
                    <li>Bank Name:</li>
                    <li>
                      Account No:
                      <span> </span>
                    </li>
                    <li>IFSC Code:</li>
                    <li>
                      Linked Account:
                      <span>{data && data.acc_details.upi_id}</span>
                    </li> */}
                  </ul>
                </div>
                <div className="col-md-6 use-info-list" id="">
                  <ul type="none" className="p-0">
                    <li>
                      Balance:
                      <span>{data && data.opening_wallet_bal}</span>
                    </li>

                    <li>
                      Requested Amount:
                      <span> {data && data.amount}</span>
                    </li>
                    <li>
                      Requested On:
                      <span>{data && new Date(data.createdAt).toLocaleString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })}</span>
                    </li>
                    {/* <li>
                      Requested By:
                      <span></span>
                    </li> */}
                    <li className="d-block">
                      <div className="row m-0 p-0">
                        <div className="col-md-5 d-inline mx-0 text-start p-0">
                          Approved Amount:
                        </div>
                        <div className="col-md-7 mx-0 pe-0 text-end">
                          <div className="w-100 flex-wrap form-control-w100">
                            <div className="w-100 position-relative">
                              {amountEdit ?
                                <input
                                  className="w-100 me-0 ms-0 form-control"
                                  type="text"
                                  name="amount"
                                  value={formData?.amount}
                                  onChange={handleFormData}
                                  onKeyPress={handleKeyPress}
                                /> :
                                (
                                  <>
                                    <input
                                      className="w-100 me-0 ms-0 form-control bg-light"
                                      type="text"
                                      name="amount"
                                      value={data && data.amount}
                                      readOnly
                                      onChange={handleFormData}
                                      onKeyPress={handleKeyPress}
                                    />
                                    <i className="fa-solid fa-pen position-absolute editBtn"
                                      onClick={() => setAmountEdit(true)}
                                      style={{ cursor: 'pointer' }}></i>
                                  </>
                                )
                              }
                            </div>
                            <div className="w-100">
                              <p
                                style={{
                                  color: " rgb(172, 37, 37)",
                                  fontSize: "10px",
                                  textAlign: "start"
                                }}
                              >
                                {error.amount}
                              </p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="row m-0 p-0 " style={{ display: "contents" }}>
                        <div className="col-md-5 d-inline mx-0 text-start p-0">
                          <p>
                            Remark <span className="star">*</span>
                          </p>
                        </div>
                        <div className="col-md-7 d-inline mx-0 pe-0">
                          <textarea
                            className="w-100 me-0 ms-0 form-control"
                            id=""
                            cols="20"
                            rows="2"
                            name="remark"
                            value={formData?.remark}
                            onChange={handleFormData}
                          ></textarea>
                          <p
                            style={{
                              color: " rgb(172, 37, 37)",
                              fontSize: "10px",
                              textAlign: "start"
                            }}
                          >
                            {error.remark}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="b_btn">
                      <button
                        type="button"
                        className="btn btn-success loaderBtnsize"
                        onClick={handleApproveBtn}
                        disabled={isLoaderActive ? true : false}
                      >
                        {isLoaderActive ? <Loader /> : "Approve"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger loaderBtnsize "
                        onClick={handleDeclineBtn}
                        disabled={declineBtnLoader ? true : false}
                      >
                        {declineBtnLoader ? <Loader /> : "Reject"}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawRequest;
