import axios from "axios";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

export default function GoldProduction() {
    const [playerData, setPlayerData] = useState([]);
    const [activeRow, setActiveRow] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pageNo, setPageNo] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [search, setSearch] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [input, setInput] = useState({
        tableLength: "100",
        opr_id: "",
        from: "",
        to: ""
    })

    const handleSearch = (value) => {
        setSearchValue(value);
    }

    useEffect(() => {
        const fetchData = async () => {
            const opr_code = localStorage.getItem('opr_id');
            setLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_PATH}acc-protection-player?limit=${input.tableLength}&pageNo=${pageNo}&opr_id=${opr_code}&player=${searchValue}`,
                    {
                        headers: {
                            admin_auth_token: localStorage.getItem("omsToken"),
                            opr_auth_token: localStorage.getItem("sessionToken")
                        }
                    }
                );

                if (response) {
                    console.log(response.data, 'response.data_gold');
                    // const filteredPlayers = response.data.data.filter(player =>
                    //     player &&
                    //     player.acc_security &&
                    //     player.acc_security.coin_prot &&
                    //     player.acc_security.coin_prot.status
                    // );
                    setPlayerData(response.data.data.data);
                    setTotalPage(response.data.data.totalPage);
                } else {
                    alert("Unexpected response format");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [searchValue, input, pageNo]);

    const toggleButton = (index) => {
        setActiveRow(activeRow === index ? null : index);
    };

    const handleRemoveVip = async (playerId) => {
        setLoading(true);
        const opr_code = localStorage.getItem('opr_id');
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PATH}remove-acc-protection`, {
                playerId: playerId,
                opr_code: opr_code,
            });


            if (response.data.data.status) {
                alert(response.data.data.message);
                setPlayerData(prevData => prevData.filter(player => player._id !== playerId));
                setActiveRow(null)
            } else {
                alert("Failed to remove VIP.");
            }
        } catch (error) {
            console.error("Error removing VIP:", error);
            alert("Failed to remove VIP.");
        } finally {
            setLoading(false);
        }
    };

    const handelChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
        setPageNo(1);
    }


    return (
        <>
            <div className="right-sidebar">
                <div className="das-bg-block w-100">
                    <div className="d-flex justify-content-between align-items-baseline">
                        <h1 className="gamehistoryheading title_h1 same_heading" style={{ width: "unset" }}>
                            Gold Production
                        </h1>
                        <div className="col-md-3 form-group mt-3 mt-md-0 pe-md-3">
                            <div className="input-group  input-group-sm">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search for...."
                                    aria-describedby="basic-addon1"
                                    name="searchPlayer"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"
                                    onClick={() => handleSearch(search)}>
                                        <i className="fa-solid fa-magnifying-glass" />
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                    <div className="table-player">
                        <table className="table data-table mb-0">
                            <thead>
                                <tr>
                                    <th>Nick Name</th>
                                    <th>Player Code</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>

                            <tbody>{console.log("GG", playerData)}
                                {loading ? (
                                    <tr>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                    </tr>
                                ) : playerData.length > 0 ? (
                                    playerData.map((player, index) => (
                                        <tr key={player._id} className={`position-relative ${activeRow === index ? "action-btn-open" : ""}`}>
                                            <td>{player.display_name}</td>
                                            <td>{player.player_code}</td>
                                            <td className="position-relative">
                                                <button onClick={() => handleRemoveVip(player._id)} className="nav-link db-btn">
                                                    <i className="fa-solid fa-trash me-1"></i>
                                                    Remove
                                                </button>
                                                {/* <button
                                                    className="action btn"
                                                    onClick={() => toggleButton(index)}
                                                    id="dropdown"
                                                >
                                                    <i className="fa-solid fa-caret-down"></i>
                                                </button>
                                                {activeRow === index && (
                                                    <div className="action-btn actin-payment-btn-sc" style={{ minWidth: '130px', top: '100%' }}>
                                                        <div className="item-3">
                                                            <button onClick={() => handleRemoveVip(player._id)}>
                                                                <i className="fa-solid fa-trash"></i>
                                                                <p>Remove Gold Production</p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )} */}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3">No players available.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="container-fluid">

                        <div className="pagination pagination-text row justify-content-between align-items-center">
                            <div className="w-auto">
                                <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                                    <select
                                        aria-controls="DataTables_Table_0"
                                        name="tableLength" value={input.tableLength}
                                        onChange={handelChange}
                                        className="pag-select-block me-1"
                                    >
                                        <option value="10">10</option>
                                        <option value="25" defaultValue={true}>25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> Records per page
                                </div>
                            </div>

                            <div className="w-auto">
                                Showing {pageNo} - {input.tableLength} {" "}of {playerData.length} items
                            </div>

                            <div className="w-auto">
                                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                                    <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                        className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                        <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                                    </a>
                                    <a onClick={() => setPageNo(pageNo - 1)}
                                        style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                        className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                        {/* <i className="fa-solid fa-chevron-left"></i> Previous */}
                                        <img src="img/left-arrow.png" width="20" />
                                    </a>
                                    <span className="d-flex align-items-center">

                                        <select className="pag-select-block"
                                            aria-controls="DataTables_Table_0"
                                            name="pagination" value={pageNo}
                                            onChange={(e) => setPageNo(Number(e.target.value))}
                                        >
                                            {Array.from({ length: totalPage }, (_, index) => (
                                                <option key={index + 1} value={index + 1} >
                                                    {index + 1}
                                                </option>
                                            ))}



                                        </select> of {totalPage}
                                    </span>

                                    <a onClick={() => setPageNo(pageNo + 1)}
                                        style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                                        className={`${pageNo < totalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                                        <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                                    </a>
                                    {/* } */}

                                    <a onClick={() => setPageNo(playerData && playerData.totalPage)} style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                                        className={`${pageNo < totalPage ? '' : 'opacity-25'} border-0`}>
                                        {/* <i className="fa-solid fa-chevron-right"></i> */}
                                        <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}
