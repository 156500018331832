import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import Skeleton from "react-loading-skeleton";

const Managekyc = () => {
  const [input, setInput] = useState({
    tableLength: "100",
    opr_id: "",
    reason: "Blur Image",
    remarks: "",
    searchPlayer: "",
    status: "All",
  });

  const [loading, setLoading] = useState(false)
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [playerData, setPlayerData] = useState();
  const [modelData, setModelData] = useState();
  const [kycDetails, setKycDetails] = useState();
  const [refresh, setRefresh] = useState(false);
  const [image, setImage] = useState();

  const fetchData = async () => {
    // if (localStorage.getItem("opr_id")) {
    //   const response = await axios.get(
    //     process.env.REACT_APP_API_PATH +
    //       `kycDetails?limit=${
    //         input.tableLength
    //       }&pageNo=${pageNo}&opr_id=${localStorage.getItem("opr_id")}`
    //   );
    //   console.log(response.data);
    //   setPlayerData(response.data);
    // } else {
    setLoading(true)
    const response = await axios.get(
      process.env.REACT_APP_API_PATH +
      `kycDetails?limit=${input.tableLength}&pageNo=${pageNo}&searchPlayer=${input.searchPlayer}&status=${input.status}`,
      {
        headers: {
          admin_auth_token: localStorage.getItem("omsToken"),
          opr_auth_token: localStorage.getItem("sessionToken"),
        },
      }
    );
    console.log(response.data);
    setPlayerData(response.data);
    setTotalPage(response.data.totalPage);
    setLoading(false)
  };
  useEffect(() => {
    // };
    fetchData();
  }, [input, pageNo, refresh]);

  const handelChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "tableLength") {
      setPageNo(1);
    }
  };

  const handleModelData = (details) => {
    setModelData(details);
  };

  const handleKycDetails = (details) => {
    setKycDetails(details);
  };

  const handleApproveBtn = async () => {
    const response = await axios.post(
      process.env.REACT_APP_TRANSACTION_API + "confirm/kycdetails",
      {
        user_id: localStorage.getItem("login_id"),
        user_type: "123456",
        module: 'bank',
        operator_code: localStorage.getItem("opr_id"),
        player_id: kycDetails._id,
        status: 1,
        updated_by: localStorage.getItem("role"),
      },
      {
        headers: {
          token: localStorage.getItem("sessionToken")
        }
      }
    );
    // console.log(response.data);
    if (response.data.status === true) {
      setRefresh(!refresh);
      alert("Update Successfully");
      window.location.reload();
    } else {
      alert("Request timeout! please try again");
    }
  };

  const handleDeclineBtn = async () => {
    const response = await axios.post(
      process.env.REACT_APP_TRANSACTION_API + "reject/kycdetails",
      {
        user_id: localStorage.getItem("login_id"),
        user_type: "123456",
        module: 'kyc',
        operator_code: localStorage.getItem("opr_id"),
        player_id: kycDetails._id,
        status: 2,
        updated_by: localStorage.getItem("role"),
        reason: input.reason,
        remarks: input.remarks,
      },
      {
        headers: {
          token: localStorage.getItem("sessionToken")
        }
      }
    );
    console.log(response.data);
    if (response.data.status === true) {
      setRefresh(!refresh);
      alert("Update Successfully");
      window.location.reload();
    } else {
      alert("Request timeout! please try again");
    }
  };

  // console.log(input, "&^UY(*");

  return (
    <div>
      {/* <Header />
      <div className="full-page">
        <LeftSideBar indexx={0} /> */}
      <div className="right-sidebar">
        <div className="das-bg-block w-">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <h1 className="gamehistoryheading title_h1 same_heading text-start ps-0">
                  KYC Documents
                </h1>
              </div>
              <div className="col-md-6">
                <div className="row user-select">
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        name="status"
                        className="w-100"
                        value={input.status}
                        onChange={handelChange}
                      >
                        <option value="All">All</option>
                        <option value="Approved">Approved</option>
                        <option value="Pending">Pending</option>
                        <option value="Rejected">Rejected</option>
                        {/* <option value="">Reset</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <div className="input-group  input-group-sm">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search for...."
                        aria-describedby="basic-addon1"
                        style={{ height: "33.5px !important" }}
                        name="searchPlayer"
                        value={input.searchPlayer}
                        onChange={handelChange}
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                          style={{ padding: "9px !important" }}
                        >
                          <i className="fa-solid fa-magnifying-glass" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="DataTables_Table_0_wrapper"
          className="dataTables_wrapper no-footer"
        >
          <div className="table-player  manage-kyc">
            <div className="table-list-mb">
              <table className="table data-table w-100">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Login Id</th>
                    <th>Document type</th>
                    <th>Document Number</th>
                    <th>Full Name</th>
                    <th>Name on document</th>
                    <th>Attachment</th>
                    <th>Requested Date</th>
                    <th>Last Update</th>
                    <th>Updated by</th>
                    <th>Action</th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {
                    playerData?.data &&
                    playerData?.data.map((doc) => (
                      <tr role="row" key={doc._id}>
                        {loading ? <td><Skeleton /></td> : <td>
                          {console.log(doc.user_kyc_details.length, "important data")}
                          {doc.user_kyc_details[doc.user_kyc_details.length - 1].status === 0 ? (
                            <label htmlFor="" id="auto-approved">
                              Pending
                            </label>
                          ) : doc.user_kyc_details[doc.user_kyc_details.length - 1].status === 1 ? (
                            <label className="approved">Approved</label>
                          ) : (
                            <label className="unapproved">Rejected</label>
                          )}
                        </td>}
                        {loading ? <td><Skeleton /></td> : <td className="text-start">
                          <span className="d-flex align-items-center gap-1">
                            {doc.user_kyc_details.length === 1 ? (
                              "-"
                            ) : (
                              <img src="img/file.png" className="opacity-75" width={16}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() =>
                                  handleModelData(doc.user_kyc_details)
                                }
                                title={`${doc.user_kyc_details.length - 1} more`}
                                style={{ cursor: 'pointer' }} />

                            )}
                            {doc.mobile_number}
                          </span>
                        </td>}
                        {loading ? <td><Skeleton /></td> : <td>{doc.user_kyc_details[doc.user_kyc_details.length - 1].doc_type}</td>}
                        {loading ? <td><Skeleton /></td> : <td>******</td>}
                        {loading ? <td><Skeleton /></td> : <td className="text-start">{doc.display_name}</td>}
                        {loading ? <td><Skeleton /></td> : <td className="text-start">{doc.user_kyc_details[doc.user_kyc_details.length - 1].player_name}</td>}

                        {loading ? <td><Skeleton /></td> : <td>
                          <td
                            data-bs-toggle="modal"
                            data-bs-target="#image"
                            onClick={() =>
                              setImage(doc.user_kyc_details[doc.user_kyc_details.length - 1].front_img)
                            }
                          >
                            <i className="fa-solid fa-eye"></i>
                          </td>
                          {doc.user_kyc_details[doc.user_kyc_details.length - 1].back_img && (
                            <td
                              data-bs-toggle="modal"
                              data-bs-target="#image"
                              onClick={() =>
                                setImage(doc.user_kyc_details[doc.user_kyc_details.length - 1].back_img)
                              }
                            >
                              <i className="fa-solid fa-eye"></i>
                            </td>
                          )}
                        </td>}
                        {loading ? <td><Skeleton /></td> : <td>
                          {doc.user_kyc_details[doc.user_kyc_details.length - 1] &&
                            new Date(
                              parseInt(doc.user_kyc_details[doc.user_kyc_details.length - 1].requested_at)
                            ).toLocaleString()}
                        </td>}
                        {loading ? <td><Skeleton /></td> : <td>-</td>}
                        {loading ? <td><Skeleton /></td> : <td>{doc.user_kyc_details[doc.user_kyc_details.length - 1].updated_by}</td>}
                        {loading ? <td><Skeleton /></td> : <td>
                          {doc.user_kyc_details[doc.user_kyc_details.length - 1].status === 0 ? (
                            <button
                              className="btn-info reset-btn green-bg-btn"
                              style={{ padding: "3px 10px" }}
                              data-bs-toggle="modal"
                              data-bs-target="#review"
                              onClick={() => handleKycDetails(doc)}
                            >
                              Approve
                            </button>
                          ) : (
                            ""
                          )}
                        </td>}
                        {/* {loading?<td><Skeleton/></td>:  <td>
                        {doc.user_kyc_details.length === 1 ? (
                          "-"
                        ) : (
                          <button
                            className="btn-info reset-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() =>
                              handleModelData(doc.user_kyc_details)
                            }
                          >
                            {doc.user_kyc_details.length - 1} more
                          </button>
                        )}
                      </td>} */}
                      </tr>
                    )
                    )
                  }

                  {/* <tr role="row">
                    <td>
                      <label id="auto-approved">Pending</label>
                    </td>
                    <td>30 May 15:13:50</td>
                    <td>rule10</td>
                    <td> AADHAAR Card</td>
                    <td>Kapil</td>
                    <td>**********</td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <label
                        htmlFor=""
                        id="auto-approved"
                        style={{ padding: "3px 10px" }}
                      >
                        Review
                      </label>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            {/* action-part Model */}
            <div
              className="modal fade"
              id="review"
              data-bs-keyboard="true"
              tabIndex={-1}
              aria-labelledby="BackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div
                  className="modal-content"
                >
                  <div
                    className="modal-header"
                  >
                    <h5 className="modal-title">KYC Details</h5>
                    <button
                      className="close-btn bg-transparent border-0"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="fa-solid fa-xmark" style={{ fontSize: '20px' }}></i>
                    </button>
                  </div>
                  <div
                    className="modal-body container"
                  >
                    <div className="row">
                      <div className="col-md-6 docs mb-3 mb-md-0">
                        <div className="doc-img h-auto border rounded p-0 mb-3">
                          <img className="w-auto img-fluid"
                            data-bs-toggle="modal"
                            data-bs-target="#image"
                            onClick={() =>
                              setImage(
                                kycDetails.user_kyc_details[kycDetails.user_kyc_details.length - 1].front_img
                              )
                            }
                            src={
                              kycDetails
                                ? kycDetails.user_kyc_details[kycDetails.user_kyc_details.length - 1].front_img
                                : "/img/kyc-dummy.png"
                            }
                            alt="front_img"
                          />
                        </div>
                        <h4 className="text-start">KYC Details</h4>
                        <div className="doc-details bg-light rounded border">
                          <div className="d-flex align-items-center justify-content-between border-bottom p-2">
                            <label className="p-0 text-black">Document Type:</label>
                            <span className="text-black">
                              {kycDetails &&
                                kycDetails.user_kyc_details[kycDetails.user_kyc_details.length - 1].doc_type}
                            </span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between border-bottom p-2">
                            <label className="p-0 text-black">Name on Doc:</label>
                            <span className="text-black">
                              {kycDetails &&
                                kycDetails.user_kyc_details[kycDetails.user_kyc_details.length - 1].player_name}
                            </span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between p-2">
                            <label className="p-0 text-black">Doc No:</label>
                            <span className="text-black">
                              {kycDetails &&
                                kycDetails.user_kyc_details[kycDetails.user_kyc_details.length - 1].doc_number}
                            </span>
                          </div>
                          {/* <div className="d-flex align-items-center justify-content-between">
                              <label htmlFor="">Comment:</label>
                              <span />
                            </div> */}
                        </div>
                      </div>
                      <div className="col-md-6 docs">
                        <div className="doc-img h-auto border rounded p-0 mb-3">
                          <img className="w-auto img-fluid"
                            data-bs-toggle="modal"
                            data-bs-target="#image"
                            onClick={() =>
                              setImage(
                                kycDetails.user_kyc_details[kycDetails.user_kyc_details.length - 1].back_img
                              )
                            }
                            src={
                              kycDetails
                                ? kycDetails.user_kyc_details[kycDetails.user_kyc_details.length - 1].back_img
                                : ""
                            }
                            alt=""
                          />
                        </div>
                        <h4 className="text-start">Bank Details</h4>
                        <div className="doc-details bg-light border rounded">
                          <div className="d-flex align-items-center justify-content-between p-2 border-bottom">
                            <label className="p-0 text-black">A/C holder Name:</label>
                            <span>-</span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between p-2 border-bottom">
                            <label className="p-0 text-black">
                              UPI:
                              <label>
                                <span>-</span>
                              </label>
                            </label>
                          </div>
                          <div className="d-flex align-items-center justify-content-between p-2 border-bottom">
                            <label className="p-0 text-black">A/C No:</label>
                            <span>-</span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between p-2">
                            <label className="p-0 text-black">IFSC Code:</label>
                            <span>-</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal-footer justify-content-center"
                  >
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleApproveBtn}
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#rejectModel"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* image-model */}
            <div
              className="modal fade add-btn-modal"
              id="image"
              data-bs-keyboard="true"
              tabIndex={-1}
              aria-labelledby="BackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div
                  className="modal-content manual_width "
                >
                  {/* <h5 className="modal-title">KYC Details</h5> */}
                  <button
                    className="close-btn"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span><i className="fa-solid fa-xmark"></i></span>
                  </button>

                  <div className="modal-body container p-0">
                    <div className="row">
                      <div className="p-4">
                        <img
                          src={image ? image : "/img/kyc-dummy.png"}
                          alt="front_img"
                          className="img-fluid rounded"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* image-model end */}
            {/* second-modal */}
            <div
              className="modal fade"
              id="rejectModel"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Rejecte</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="">
                      <label className="form-label d-block text-start">Reason</label>
                      <select
                        className="form-select"
                        onChange={handelChange}
                        name="reason"
                        value={input.reason}
                      >
                        <option value="Blur Image">Blur Image</option>
                        <option value="Invaild Document">
                          Invaild Document
                        </option>
                        <option value="Document are not matched">
                          Document are not matched
                        </option>
                        <option value="Different Details">
                          Different Details
                        </option>
                      </select>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="form-label d-block text-start">Remark</label>
                      <textarea
                        rows={3}
                        placeholder="Message"
                        className="form-control"
                        name="remarks"
                        value={input.remarks}
                        onChange={handelChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="modal-footer border-0 justify-content-center pt-0">
                    <button
                      type="button"
                      className="btn text-white text-capitalize"
                      style={{ background: '#2d0512', minWidth: '100px' }}
                      onClick={handleDeclineBtn}
                    >
                      submit
                    </button>
                    {/* <button type="button" className="btn btn-secondary">
                        Back
                      </button> */}
                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                  </div>
                </div>
              </div>
            </div>
            {/* actin-part model */}
            {/* extra-data model */}
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      KYC Documents
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <table className="table data-table">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Requested Date</th>
                          {/* <th>Username</th> */}
                          <th>Document type</th>
                          <th>Name on document</th>
                          <th>Document Number</th>
                          <th>Last Update</th>
                          <th>Updated by</th>
                          <th>Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modelData &&
                          modelData.map((doc, ind) =>
                            ind === modelData.length - 1 ? null : (
                              <tr role="row" key={ind}>
                                <td>
                                  {doc.status === 1 ? (
                                    <label htmlFor="" className="approved">
                                      Approved
                                    </label>
                                  ) : doc.status === 0 ? (
                                    <label id="auto-approved">
                                      Pending
                                    </label>
                                  ) : (
                                    <label className="unapproved">
                                      Rejected
                                    </label>
                                  )}
                                </td>
                                <td>{new Date(doc.requested_at).toLocaleString()}</td>
                                {/* <td></td> */}
                                <td>{doc.doc_type}</td>
                                <td>{doc.player_name}</td>
                                <td>{doc.doc_number}</td>
                                <td>-</td>
                                <td>{doc.updated_by}</td>
                                {/* <td>
                                    {doc.status === 0 ? (
                                      <label
                                        htmlFor=""
                                        id="auto-approved"
                                        style={{ padding: "3px 10px" }}
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#review"
                                        // onClick={() => handleKycDetails(doc)}
                                      >
                                        Action
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                  </td> */}
                                <td>{doc.reason}</td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div> */}
                </div>
              </div>
            </div>

            <div className="pagination pagination-text row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                  <select
                    aria-controls="DataTables_Table_0"
                    name="tableLength" value={input.tableLength}
                    onChange={handelChange}
                    className="pag-select-block me-1"
                  >
                    <option value="10">10</option>
                    <option value="25" defaultValue={true}>25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select> Records per page
                </div>
              </div>

              <div className="w-auto">
                Showing {pageNo}-{input?.tableLength}{" "}
                of {playerData?.data?.length} items
              </div>

              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                  <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                  <a onClick={() => setPageNo(pageNo - 1)}
                    style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    {/* <i className="fa-solid fa-chevron-left"></i> Previous */}
                    <img src="img/left-arrow.png" width="20" />
                  </a>
                  <span className="d-flex align-items-center">

                    <select className="pag-select-block"
                      aria-controls="DataTables_Table_0"
                      name="pagination" value={pageNo}
                      onChange={(e) => setPageNo(Number(e.target.value))}
                    >
                      {Array.from({ length: totalPage }, (_, index) => (
                        <option key={index + 1} value={index + 1} >
                          {index + 1}
                        </option>
                      ))}



                    </select> of {totalPage}
                  </span>

                  <a onClick={() => setPageNo(pageNo + 1)}
                    style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                    className={`${pageNo < totalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                    <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                  </a>
                  {/* } */}

                  <a onClick={() => setPageNo(totalPage)} style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                    className={`${pageNo < totalPage ? '' : 'opacity-25'} border-0`}>
                    {/* <i className="fa-solid fa-chevron-right"></i> */}
                    <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                </div>
              </div>
            </div>

            {/* <div className="pagination pagination-text row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                  <select
                    aria-controls="DataTables_Table_0"
                    name="tableLength" value={input.tableLength}
                    onChange={handelChange}
                    className="pag-select-block me-1"
                  >
                    <option value="10">10</option>
                    <option value="25" defaultValue={true}>25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select> Records per page
                </div>
              </div>

              <div className="w-auto">
                Showing {pageNo}-{input?.tableLength}{" "}
                of {playerData?.data?.length} items
              </div>

              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                  <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                  <a onClick={() => setPageNo(pageNo - 1)}
                    style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    
                    <img src="img/left-arrow.png" width="20" />
                  </a>
                  <span className="d-flex align-items-center">

                    <select className="pag-select-block"
                      aria-controls="DataTables_Table_0"
                      name="pagination" value={pageNo}
                      onChange={(e) => setPageNo(Number(e.target.value))}
                    >
                      {Array.from({ length: totalPage }, (_, index) => (
                        <option key={index + 1} value={index + 1} >
                          {index + 1}
                        </option>
                      ))}



                    </select> of {totalPage}
                  </span>
                  
                  <a onClick={() => setPageNo(pageNo + 1)}
                    style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                    className={`${pageNo < totalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                    <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                  </a>
                  

                  <a onClick={() => setPageNo(totalPage)} style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                    className={`${pageNo < totalPage ? '' : 'opacity-25'} border-0`}>
                    
                    <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                </div>
              </div>
            </div> */}


            {/* <div className="pagination row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length">
                  {pageNo} of {playerData && playerData.TotalPage}{" "}
                  <label>
                    Rows per page{" "}
                    <select
                      aria-controls="DataTables_Table_0"
                      name="tableLength"
                      value={input.tableLength}
                      onChange={handelChange}
                    >
                      <option value="10">10</option>
                      <option value="25" defaultValue={true}>
                        25
                      </option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                  {pageNo > 1 && (
                    <a onClick={() => setPageNo(pageNo - 1)}>
                      <i className="fa-solid fa-chevron-left"></i>
                    </a>
                  )}
                  <span>
                    <a
                      className="paginate_button current"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="1"
                      tabIndex="0"
                    >
                      {pageNo}
                    </a>
                  </span>
                  {playerData && pageNo < playerData.TotalPage && (
                    <a onClick={() => setPageNo(pageNo + 1)}>
                      <i className="fa-solid fa-chevron-right"></i>
                    </a>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Managekyc;


