import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const PlayerTransaction = () => {
  const { id } = useParams();
 const [loading, setLoading] = useState(false)
  const [input, setInput] = useState({
    tableLength: "100",
    opr_id: "",
    dateFilter: "recent",
    transaction_type: "",
    searchPlayer:""
  });

  const [pageNo, setPageNo] = useState(1);
  const [playerData, setPlayerData] = useState();

  const fetchData = async () => {
    // if(localStorage.getItem("opr_id")){
    //   const response = await axios.get(
    //     process.env.REACT_APP_API_PATH +
    //       `transactionDetails?limit=${input.tableLength}&pageNo=${pageNo}&opr_id=${localStorage.getItem("opr_id")}&dateFilter=${input.dateFilter}&player_id=${id}&transaction_type=${input.transaction_type}`
    //   );
    //   console.log(response.data);
    //   setPlayerData(response.data);
    // }else{
      setLoading(true)
    const response = await axios.get(
      process.env.REACT_APP_API_PATH +
        `transactionDetails?limit=${input.tableLength}&pageNo=${pageNo}&dateFilter=${input.dateFilter}&player_id=${id}&transaction_type=${input.transaction_type}&searchPlayer=${input.searchPlayer}`,
      {
        headers: {
          admin_auth_token: localStorage.getItem("omsToken"),
          opr_auth_token: localStorage.getItem("sessionToken"),
        },
      }
    );
    console.log(response.data,"api response");
    setPlayerData(response.data);
    setLoading(false)
  };
  useEffect(() => {
    // };
    fetchData();
  }, [input, pageNo]);

  const handelChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setPageNo(1);
  };
  console.log(input, "hello");
  console.log(playerData, "playedata")

  return (
    <div>
      {/* <Header />
      <div className="full-page">
        <LeftSideBar /> */}
        {/* <!------LEFT SIDE MENU-END-------> */}
        {/* <!------RIGHT SIDE CONTENT START-------> */}
        <div className="right-sidebar">
          <div className="das-bg-block w-">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <h1 className="gamehistoryheading title_h1 same_heading text-start">
                    Player Transaction
                  </h1>
                </div>
                <div className="col-md-8">
                  <div className="row user-select">
                    <div className="col-md-4">
                      <div className="form-group">
                        <select
                          name="transaction_type"
                          value={input.transaction_type}
                          onChange={handelChange}
                        >
                          <option value="">All</option>
                          <option value="credit">Credit</option>
                          <option value="debit">Debit</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 mt-2 mt-md-0">
                      <div className="form-group">
                        <select
                          name="dateFilter"
                          value={input.dateFilter}
                          onChange={handelChange}
                        >
                          <option value="recent">Recent</option>
                          <option value="today">Today</option>
                          <option value="yesterday">Yesterday</option>
                          <option value="current_week">Current Week</option>
                          <option value="last_week">Last Week</option>
                          <option value="current_month">Current Month</option>
                          <option value="last_month">Last Month</option>
                          <option value="">Date Range</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 form-group mt-2 mt-md-0">
                      <div className="input-group  input-group-sm">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search for...."
                          aria-describedby="basic-addon1"
                          style={{ height: "33.5px !important" }}
                          name="searchPlayer"
                          value={input.searchPlayer}
                          onChange={handelChange}
                        />
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="basic-addon1"
                            style={{ padding: "9px !important" }}
                          >
                            <i className="fa-solid fa-magnifying-glass"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="DataTables_Table_0_wrapper"
            className="dataTables_wrapper no-footer"
          >
            <div className="table-player  bank-details">
              <table className="table data-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Login ID</th>
                    <th>Opening Balance</th>
                    <th>Closing Balance</th>
                    <th>Game</th>
                    <th>Remark</th>
                    <th>Transaction Id</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {playerData?.data &&
                    playerData?.data.map((doc, index) => (
                      <tr role="row" key={index}>
                        {loading?<td><Skeleton/></td>: <td>
                          {new Date(doc.createdAt).toLocaleString(
                            "en-IN",
                            {
                              timeZone: "Asia/Kolkata",
                            }
                          )}
                        </td>}
                        {loading?<td><Skeleton/></td>: <td>{doc.login_id}</td>}
                        {loading?<td><Skeleton/></td>: <td>{doc.opening_wallet_bal.toFixed(2)}</td>}
                        {loading?<td><Skeleton/></td>: <td>{doc.closing_wallet_bal.toFixed(2)} </td>}
                        {loading?<td><Skeleton/></td>: <td></td>}
                        {loading?<td><Skeleton/></td>:  <td>
                          {doc.transaction_details &&
                            doc.transaction_details.remarks}
                        </td>}
                        {loading?<td><Skeleton/></td>:  <td>{doc.game_transaction_id? doc.game_transaction_id : doc.transaction_details.transaction_id}</td>}
                        {loading?<td><Skeleton/></td>:  <td>
                          {doc.transaction_type === "1" ? (
                            <span style={{ color: "#a80000" }}>
                              {(doc.closing_wallet_bal -
                                doc.opening_wallet_bal).toFixed(2)}
                            </span>
                          ) : (
                            <span style={{ color: "#00c70d" }}>
                              {(doc.closing_wallet_bal -
                                doc.opening_wallet_bal).toFixed(2)}
                            </span>
                          )}
                        </td>}
                      </tr>
                    ))}

                  {/* <tr role="row">
                    <td>30 May'23</td>
                    <td>8001533</td>
                    <td>frank3</td>
                    <td>Rummy</td>
                    <td>64,701.80</td>
                    <td>
                      <i
                        className="fa-solid fa-arrow-up"
                        style={{ color: "#00c70d" }}
                      ></i>
                    </td>

                    <td>64,760.80 </td>
                    <td>Point rummy</td>
                  </tr> */}
                </tbody>
              </table>
              <div className="pagination row justify-content-between align-items-center">
                <div className="w-auto">
                  <div className="dataTables_info dataTables_length">
                    {pageNo} of {playerData && playerData?.totalPage}{" "}
                    <label>
                      Rows per page{" "}
                      <select
                        // name="DataTables_Table_0_length"
                        aria-controls="DataTables_Table_0"
                        name="tableLength"
                        value={input.tableLength}
                        onChange={handelChange}
                      >
                        <option value="10">10</option>
                        <option value="25" defaultValue={true}>
                          25
                        </option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div className="w-auto">
                  <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                    {pageNo > 1 && (
                      <a onClick={() => setPageNo(pageNo - 1)}>
                        <i className="fa-solid fa-chevron-left"></i>
                      </a>
                    )}
                    <span>
                      <a
                        className="paginate_button current"
                        aria-controls="DataTables_Table_0"
                        data-dt-idx="1"
                        tabIndex="0"
                      >
                        {pageNo}
                      </a>
                    </span>
                    {playerData && playerData.totalPage > pageNo && (
                      <a onClick={() => setPageNo(pageNo + 1)}>
                        <i className="fa-solid fa-chevron-right"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!------RIGHT SIDE CONTENT END-------> */}
        </div>
      {/* </div> */}
    </div>
  );
};

export default PlayerTransaction;
