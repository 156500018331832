import axios from "axios";
import { event } from "jquery";
import React from "react";
import { useState, useEffect, useNa } from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";


const AffiliateManagement = ({ affSignupURL }) => {


  const [data, setData] = useState([])
  const [edit, setEdit] = useState(false)
  const [collapsedRows, setCollapsedRows] = useState({});


  const navigate = useNavigate();
  const toggleCollapse = (index) => {
    setCollapsedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };


  useEffect(() => {

    fetchData()
  }, [])
  const fetchData = async () => {
    try {
      const oprCode = localStorage.getItem('opr_id');
      const response = await axios.get(
        process.env.REACT_APP_API_PATH + `get-headMember?opr_code=${oprCode}`,
        {
          headers: {
            admin_auth_token: localStorage.getItem("omsToken"),
            opr_auth_token: localStorage.getItem("sessionToken")
          }
        }
      );
      console.log("GetAlldata ", response);
      if (response?.data?.data) {
        setData(response.data.data)
        setCollapsedRows({})
      }

    } catch (error) {
      console.log("Error in GetAlldata ", error)
    }
  }


  async function getSubMember(id, level, index) {

    try {
      const response = await axios.get(
        process.env.REACT_APP_API_PATH + `get-subMember?id=${id}`,
        {
          headers: {
            admin_auth_token: localStorage.getItem("omsToken"),
            opr_auth_token: localStorage.getItem("sessionToken")
          }
        }
      );

      console.log("GetAllSubMembers ", response?.data?.data);


      if (response?.data?.data) {

        let subChild = [...data]
        subChild[index] = {
          ...subChild[index],
          Childata: response.data.data
        };
        console.log("Data", subChild)
        setData(subChild)
        console.log('subChild', data)
      }

    } catch (error) {
      console.log("Error in GetAllSubMembers ", error)
    }



  }
  //Add head Member logic
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordData, setPasswordData] = useState('');
  const [newPasswordData, setNewPasswordData] = useState('');
  const [idForPassword, setIdForPassword] = useState('')

  const [formData, setFormData] = useState({
    nick_name: '',
    login_id: '',
    level: '',
    mobile: '',
    email: '',
    percentage_share: '',
    opr_code: '',
    password: '',
    confirmPassword: '',
    id: ''

  });

  const handleClose = () => {
    setShow(false)
    setEdit(false)
    setErrors({})
    setFormData({
      name: '',
      level: '',
      share: '',
      id: ''
    });
  };
  const [parentShare, setParentShare] = useState(0)
  const handleShow = (level, Id, share) => {
    setParentShare(share)
    setShow(true);

    if (level) {
      formData.level = level + 1;
      formData.id = Id
    }
    else {
      formData.level = 1;
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleNumericChange = (e, maxLength) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, '').slice(0, maxLength);

    setFormData({
      ...formData,
      [name]: numericValue
    });
  };

  const copyToClipboard = (text) => {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = `${affSignupURL}?aff_code=${text}`;
    document.body.appendChild(textarea);
    textarea.select();

    try {
      // Attempt to copy the text
      const successful = document.execCommand('copy');
      if (successful) {
        alert("Copied to clipboard!");
      } else {
        console.error("Failed to copy text.");
        alert("Copy to clipboard failed.");
      }
    } catch (err) {
      console.error("Error copying text: ", err);
      alert("Copy to clipboard failed.");
    } finally {

      document.body.removeChild(textarea);
    }
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!formData.nick_name?.trim()) {
      errors.nick_name = 'Nick Name is required';
    }

    if (!formData.login_id?.trim()) {
      errors.login_id = 'Login ID is required';
    }

    if (!formData.level) {
      errors.level = 'Level is required';
    }

    const mobileRegex = /^[0-9]{10}$/;
    if (!formData?.mobile || !formData.mobile.match(mobileRegex)) {
      errors.mobile = 'Mobile number must be 10 digits';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !formData.email.match(emailRegex)) {
      errors.email = 'Email is invalid';
    }

    if (!formData.percentage_share) {
      errors.percentage_share = 'Percentage Share is required';
    } else if (isNaN(formData.percentage_share) || formData.percentage_share < 0 || formData.percentage_share > 100) {
      errors.percentage_share = 'Percentage Share must be a number between 0 and 100';
    } else if (formData.level !== 1 && (parentShare < formData.percentage_share)) {
      errors.percentage_share = 'Child Share is Cannot be greater than parent';
    }



    if (!formData.password) {
      errors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = 'Confirm password is required';
    } else if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    return errors;
  };


  async function addStateAgent(e) {


    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return;
    }




    const opr_code = localStorage.getItem('opr_id')
    console.log(formData, "Form data")
    setParentShare(0)
    try {
      let response;

      if (formData.id === "") {
        response = await axios.post(
          process.env.REACT_APP_API_PATH + `addStateAgent`, {
          nick_name: formData.nick_name,
          login_id: formData.login_id,
          level: formData.level,
          mobile: formData.mobile,
          email: formData.email,
          percentage_share: formData.percentage_share,
          operator_code: opr_code,
          password: formData.password,

        },
          {
            headers: {
              admin_auth_token: localStorage.getItem("omsToken"),
              opr_auth_token: localStorage.getItem("sessionToken")
            }
          }
        );
      }
      else {

        response = await axios.post(
          process.env.REACT_APP_API_PATH + `sub-member`, {
          nick_name: formData.nick_name,
          login_id: formData.login_id,
          level: formData.level,
          mobile: formData.mobile,
          email: formData.email,
          percentage_share: formData.percentage_share,
          operator_code: opr_code,
          password: formData.password,
          parent: formData.id
        },
          {
            headers: {
              admin_auth_token: localStorage.getItem("omsToken"),
              opr_auth_token: localStorage.getItem("sessionToken")
            }
          }
        );
      }


      console.log("State Agent Added ", response)
      if (response.data.data) {

        setFormData({
          name: '',
          level: '',
          share: '',
          id: ''
        });

        handleClose();
        fetchData()
        if (formData?.id) {
          setCollapsedRows({})
        }
      }



    } catch (error) {
      console.log("Error in GetAllSubMembers ", error)
    }

  }

  async function editData(currentName, level, share, id) {
    setEdit(true)

    setFormData((prevState) => ({
      ...prevState,
      name: currentName,
      level: level,
      share: share,
      id: id
    }));
    setShow(true);
  }
  async function updateData() {

    if (formData.name === '') { alert('Name is required'); return };
    if (formData.level === '') { alert('Level is required'); return };
    if (formData.share === '') { alert('Share is required'); return };

    setEdit(false)



    console.log(formData, "Edit data")
    try {
      const response = await axios.patch(
        process.env.REACT_APP_API_PATH + `updateAffilliate`, {
        id: formData.id,
        name: formData.name,
        share: formData.share,
      },
        {
          headers: {
            admin_auth_token: localStorage.getItem("omsToken"),
            opr_auth_token: localStorage.getItem("sessionToken")
          }
        }
      );




      console.log("Updated Sucessfully", response)

      if (response.data.data) {
        setFormData({
          name: '',
          level: '',
          share: '',
          id: ''
        });

        handleClose();


        fetchData()
      }

    } catch (error) {
      console.log("Error in Edit Data ", error)
    }

  }
  async function deleteAgent(id) {
    try {
      const res = await axios.delete(process.env.REACT_APP_API_PATH + `delete-agent/${id}`, {
        headers: {
          admin_auth_token: localStorage.getItem("omsToken"),
          opr_auth_token: localStorage.getItem("sessionToken")
        }
      });
      if (res) {

        handleClose();
        fetchData()
        setCollapsedRows({})
      }
    } catch (error) {
      console.log("Error while deleting agent")
    }
  }


  async function changePassword() {
    try {

      if (!newPasswordData || !passwordData || passwordData.trim() === '') {
        alert('password is required')
        return
      }
      if (newPasswordData !== passwordData) {
        alert('Password does not match');
        return;
      }

      const res = await axios.post(process.env.REACT_APP_API_PATH + `aff-change-password`, {

        operator_code: localStorage.getItem('opr_id'),
        affId: idForPassword,
        password: passwordData
      }, {

        headers: {
          admin_auth_token: localStorage.getItem("omsToken"),
          opr_auth_token: localStorage.getItem("sessionToken")
        }
      });

      alert(res.data.data.message)
      setShowPassword(false)
      setPasswordData('')

    } catch (error) {
      console.log("🚀 ~ changePassword ~ error:", error)

    }
  }


  const handleBlock = async (affId, identifier) => {

    try {


      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}aff-block`,
        {
          operator_code: localStorage.getItem('opr_id'),
          identifier: identifier,
          affId: affId,
        },
        {
          headers: {
            admin_auth_token: localStorage.getItem("omsToken"),
            opr_auth_token: localStorage.getItem("sessionToken")
          }
        }
      );



      alert(response.data.message);

      fetchData()

    } catch (error) {
      console.error('Error blocking/unblocking player:', error);
      alert('An error occurred while updating the block status.');
    }
  };

  return (
    <>
      <div className="right-sidebar">
        <div className="das-bg-block w-100">
          <div className="d-flex justify-content-between align-items-baseline">
            <h1
              className="gamehistoryheading title_h1 same_heading"
              style={{ width: "unset" }}
            >

              Manage Affiliates
            </h1>
            <a className="nav-link db-btn  " onClick={() => { handleShow() }} >
              <i className="fa-solid fa-circle-plus me-1"></i>
              Add Agent
            </a>
          </div>
          {/* <TableHeader onSelectedOptionsChange={handleSelectedOptionsChange}/> */}
          {/* <div className="container-fluid bg">
            <div className="row user-select mt-2">
              <div className="col-lg-4 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <select name="" id="" className="w-100">
                    <option value="">
                      All
                    </option>
                    <option value="">Active</option>
                    <option value="">Inactive</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <select name="" id="" className="w-100">
                    <option value="0" selected="">All</option>
                    <option value="4">State Agent</option>
                    <option value="3">Master</option>
                    <option value="2">Super Agent</option>
                    <option value="1">Agent</option>
                  </select>
                </div>
              </div>


              <div
                className="col-lg-4 ms-0 col-md-3 col-sm-6 col-12 col-xs-4 d-flex mt-lg-0 mt-md-0 mt-2"
                id="ml-33"
              >
                <div className="input-group  input-group-sm">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for...."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ height: "33.5px !important" }}
                    name="search"
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="basic-addon1"
                      style={{ padding: "9px !important" }}
                    >
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div
        id="DataTables_Table_0_wrapper"
        className="dataTables_wrapper no-footer"
      >
        <div className="table-player">
          <table className="table data-table table-accordion" id="firstTable">
            <thead>
              <tr>

                <th>Nick Name</th>
                <th>Login id</th>
                {/* <th>Affiliate Code</th> */}
                <th>Level</th>
                <th>Share</th>
                <th>Wallet</th>
                <th>Action</th>
                <th>Affiliated Code</th>
                <th>Signup URL</th>
                {/* <th>Sign Up URl</th> */}


              </tr>
            </thead>
            <tbody>
              {console.log("DATA", data)}
              {data?.length > 0 &&
                data.map((data, index) => (
                  <React.Fragment key={index}>
                    <tr className={`tr-row-main tr-row-bg-${index % 2 === 0 ? 'even' : 'odd'}`}>
                      <td>
                        {data?.children?.length > 0 && (
                          <i
                            className={`fa-solid fa-angle-${collapsedRows[index] ? 'down' : 'right'} accordion-button`}
                            onClick={() => {
                              toggleCollapse(index);
                              getSubMember(data._id, data?.level, index);
                            }}
                          ></i>
                        )}
                        {data.name}
                      </td>
                      <td>{data.login_id}</td>
                      <td>{data.level}</td>
                      <td>{data.share}</td>
                      <td className="text-end">{(data.affiliate_wallet) ? data?.affiliate_wallet : 0}</td>

                      <td className="position-relative dropdowncaret">
                        <div className="dropdown">
                          <button
                            className="action btn dropdown-toggle"
                            type="button"
                            id={`dropdownMenuButton_${index}`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton_${index}`}>
                            {/* <li><a className="dropdown-item" href="#">Edit</a></li> */}
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  handleShow(data?.level, data?._id, data?.share);
                                }}
                              >
                                Create
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => navigate("/aff-chipTransfer", { state: data })}
                              >
                                Chip Transfer
                              </a>
                            </li>

                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => navigate("/aff-claimBalance", { state: data })}
                              >
                                Claim Balance
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => { setShowPassword(true); setIdForPassword(data._id) }}
                              >
                                Change Password
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => { handleBlock(data?._id, (data?.is_blocked === true) ? "unblock" : "block") }}
                              >
                                {(data?.is_blocked === true) ? "unblock" : "block"}
                              </a>
                            </li>
                            {/* <button>
                              <i className="fa-solid fa-money-bill-transfer"></i>
                              <p
                                // data-bs-toggle="modal" data-bs-target="#chipTransferModal"
                                onClick={() => navigate("/transfer-chips", { state: playerAccountDetails })}
                              >Chips Transfer</p>
                            </button> */}


                            {/* <button>
                              <i className="fa-solid fa-arrow-rotate-left "></i>
                              <p
                                // data-bs-toggle="modal" data-bs-target="#chipTransferModal"
                                onClick={() => navigate("/claim-balance", { state: playerAccountDetails })}
                              >Claim Balance</p>
                            </button> */}
                            {/* <li>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  editData(data?.name, data?.level, data?.share, data?._id);
                                }}
                              >
                                Edit
                              </a>
                            </li> */}
                            {/* {(data?.children?.length === 0) && <li><a className="dropdown-item" onClick={() => { deleteAgent(data?._id); }}>Delete</a></li>} */}
                          </ul>
                        </div>
                      </td>
                      <td>{data.affiliate_code}</td>
                      <td>{affSignupURL ? <> {`${affSignupURL}?aff_code=${data.affiliate_code}`}
                        <i
                          className="fa-regular fa-copy btn  "
                          onClick={() => copyToClipboard(data.affiliate_code)}
                          style={{ cursor: 'pointer', marginLeft: '5px' }} // Optional: Styling for better UX
                        >
                        </i>
                      </> : '--'}
                      </td>
                      {/* <td>{data.signup_url/?aff_code=""}</td> */}
                    </tr>

                    {data?.Childata?.subChildren1?.length > 0 &&
                      collapsedRows[index] &&
                      data?.Childata?.subChildren1.map(
                        (data1, key) =>
                          data1.parent === data._id && (
                            <React.Fragment key={key}>

                              <tr id={`collapseOne_${index}`} className={`accordion-item accordion-collapse collapse show nested-row-tr tr-row-bg-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                <td className="border-start-0 border-top-0 border-bottom-0" style={{ paddingLeft: '20px' }}>
                                  {data1?.children?.length > 0 && (
                                    <i
                                      className="fa-solid fa-angle-right accordion-button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapseTwo_${index}_${key}`}
                                    ></i>
                                  )}
                                  {data1.name}
                                </td>
                                <td>{data1.login_id}</td>
                                <td>{data1.level}</td>
                                <td>{data1.share}</td>
                                <td>{(data1?.affiliate_wallet) ? data1?.affiliate_wallet : 0}</td>
                                <td className="position-relative dropdowncaret">
                                  <div className="dropdown">
                                    <button
                                      className="action btn dropdown-toggle"
                                      type="button"
                                      id={`dropdownMenuButton_${index}`}
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    ></button>
                                    <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton_${index}`}>
                                      {/* <li><a className="dropdown-item" href="#">Edit</a></li> */}
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => {
                                            handleShow(data1?.level, data1?._id, data1?.share);
                                          }}
                                        >
                                          Create
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => navigate("/aff-chipTransfer", { state: data1 })}
                                        >
                                          Chip Transfer
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => navigate("/aff-claimBalance", { state: data1 })}
                                        >
                                          Claim Balance
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => { setShowPassword(true); setIdForPassword(data1._id) }}
                                        >
                                          Change Password
                                        </a>
                                      </li>

                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => { handleBlock(data1?._id, (data1?.is_blocked === true) ? "unblock" : "block") }}
                                        >
                                          {(data1?.is_blocked === true) ? "unblock" : "block"}
                                        </a>
                                      </li>
                                      {/* {(data1?.children?.length === 0) && <li><a className="dropdown-item" onClick={() => { deleteAgent(data1?._id); }}>Delete</a></li>} */}
                                      {/* <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => {
                                            editData(data1?.name, data1?.level, data1?.share, data1?._id);
                                          }}
                                        >
                                          Edit
                                        </a></li> */}
                                    </ul>
                                  </div>
                                </td>
                                <td>{data1.affiliate_code}</td>
                                <td>{affSignupURL ? <>{`${affSignupURL}?aff_code=${data1.affiliate_code}`}
                                  <i
                                    className="fa-regular fa-copy btn  "
                                    onClick={() => copyToClipboard(data1.affiliate_code)}
                                    style={{ cursor: 'pointer', marginLeft: '5px' }} // Optional: Styling for better UX
                                  >
                                  </i></> : '--'}
                                </td>
                              </tr>

                              {data?.Childata?.subChildren2?.length > 0 && data?.Childata?.subChildren2.map((data2, key2) =>
                                data2.parent === data1._id && (
                                  <React.Fragment key={key2}>
                                    <tr
                                      id={`collapseTwo_${index}_${key}`}
                                      className={`accordion-item accordion-collapse collapse  nested-row-tr tr-row-bg-${index % 2 === 0 ? 'even' : 'odd'}`}
                                    >
                                      <td className="border-start-0" style={{ paddingLeft: '30px' }}>
                                        {data2?.children?.length > 0 && (
                                          <i
                                            className="fa-solid fa-angle-right accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapseThree_${index}_${key}`}
                                          ></i>
                                        )}
                                        {data2.name}
                                      </td>
                                      <td>{data2.login_id}</td>
                                      <td>{data2.level}</td>
                                      <td>{data2.share}</td>
                                      <td>{(data2.affiliate_wallet) ? data2?.affiliate_wallet : 0}</td>
                                      <td className="position-relative dropdowncaret">
                                        <div className="dropdown">
                                          <button
                                            className="action btn dropdown-toggle"
                                            type="button"
                                            id={`dropdownMenuButton_${index}`}
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          ></button>
                                          <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton_${index}`}>
                                            {/* <li><a className="dropdown-item" href="#">Edit</a></li> */}
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() => {
                                                  handleShow(data2?.level, data2?._id, data2?.share);
                                                }}
                                              >
                                                Create
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() => navigate("/aff-chipTransfer", { state: data2 })}
                                              >
                                                Chip Transfer
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() => navigate("/aff-claimBalance", { state: data2 })}
                                              >
                                                Claim Balance
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() => { setShowPassword(true); setIdForPassword(data2._id) }}
                                              >
                                                Change Password
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() => { handleBlock(data2?._id, (data2?.is_blocked === true) ? "unblock" : "block") }}
                                              >
                                                {(data2?.is_blocked === true) ? "unblock" : "block"}
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() => {
                                                  editData(data2?.name, data2?.level, data2?.share, data2?._id);
                                                }}
                                              >
                                                Edit
                                              </a></li> */}
                                            {/* {(data2?.children?.length === 0) && <li><a className="dropdown-item" onClick={() => { deleteAgent(data2?._id); }}>Delete</a></li>} */}
                                          </ul>
                                        </div>
                                      </td>
                                      <td>{data2.affiliate_code}</td>
                                      <td>{affSignupURL ? <>{`${affSignupURL}?aff_code=${data2.affiliate_code}`}
                                        <i
                                          className="fa-regular fa-copy btn  "
                                          onClick={() => copyToClipboard(data2.affiliate_code)}
                                          style={{ cursor: 'pointer', marginLeft: '5px' }} // Optional: Styling for better UX
                                        >
                                        </i></> : '--'}
                                      </td>
                                    </tr>

                                    {data?.Childata?.subChildren3?.length > 0 && data?.Childata?.subChildren3.map((data3, key3) =>
                                      data3.parent === data2._id && (
                                        <React.Fragment key={key3}>
                                          <tr
                                            id={`collapseThree_${index}_${key}`}
                                            className={`accordion-item accordion-collapse collapse nested-row-tr tr-row-bg-${index % 2 === 0 ? 'even' : 'odd'}`}
                                          >
                                            <td className="border-start-0 border-top-0 border-bottom-0" style={{ paddingLeft: '40px' }}>
                                              {data3?.children?.length > 0 && (
                                                <i
                                                  className="fa-solid fa-angle-right accordion-button"
                                                  data-bs-toggle="collapse"
                                                  data-bs-target={`#collapseThree_${index}_${key}`}
                                                ></i>
                                              )}
                                              {data3.name}
                                            </td>
                                            <td>{data3.login_id}</td>
                                            <td>{data3.level}</td>
                                            <td>{data3.share}</td>
                                            <td>{(data3?.affiliate_wallet) ? data3?.affiliate_wallet : 0}</td>
                                            <td className="position-relative dropdowncaret">
                                              <div className="dropdown">
                                                <button
                                                  className="action btn dropdown-toggle"
                                                  type="button"
                                                  id={`dropdownMenuButton_${index}`}
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                ></button>
                                                <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton_${index}`}>
                                                  {/* <li><a className="dropdown-item"onClick={() => {
                                                        editData(data3?.name, data3?.level, data3?.share, data3?._id);
                                                      }}>
                                                      Edit</a></li> */}
                                                  {/* {(data3?.children?.length === 0) && <li><a className="dropdown-item" onClick={() => { deleteAgent(data3?._id); }}>Delete</a></li>} */}
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      onClick={() => navigate("/aff-chipTransfer", { state: data3 })}
                                                    >
                                                      Chip Transfer
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      onClick={() => navigate("/aff-claimBalance", { state: data3 })}
                                                    >
                                                      Claim Balance
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      onClick={() => { setShowPassword(true); setIdForPassword(data3._id) }}
                                                    >
                                                      Change Password
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      onClick={() => { handleBlock(data3?._id, (data3?.is_blocked === true) ? "unblock" : "block") }}
                                                    >
                                                      {(data3?.is_blocked === true) ? "unblock" : "block"}
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                            <td>{data3.affiliate_code}</td>
                                            <td>{affSignupURL ? <>{`${affSignupURL}?aff_code=${data3.affiliate_code}`}
                                              <i
                                                className="fa-regular fa-copy btn  "
                                                onClick={() => copyToClipboard(data3.affiliate_code)}
                                                style={{ cursor: 'pointer', marginLeft: '5px' }} // Optional: Styling for better UX
                                              >
                                              </i></> : '--'}
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      )
                                    )}
                                  </React.Fragment>
                                )
                              )}
                            </React.Fragment>
                          )
                      )}
                  </React.Fragment>
                ))}




            </tbody>
          </table>






        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {edit ? 'Edit' : formData.level === 1 ? 'Add State Agent' : 'Add Sub Member'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form  >
            <Row >
              <Col md={6} className="mb-2">

                <Form.Group controlId="formNickName">
                  <Form.Label className="text-start d-block">Nick Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="nick_name"
                    value={formData.nick_name}
                    onChange={handleChange}
                    placeholder="Enter nick name"
                    isInvalid={!!errors.nick_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.nick_name}
                  </Form.Control.Feedback>
                </Form.Group></Col>
              <Col md={6} className="mb-2">
                <Form.Group controlId="formLoginId">
                  <Form.Label className="text-start d-block">Login ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="login_id"
                    value={formData.login_id}
                    onChange={handleChange}
                    placeholder="Enter login ID"
                    isInvalid={!!errors.login_id}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.login_id}
                  </Form.Control.Feedback>
                </Form.Group></Col>
            </Row>
            <Row >
              <Col md={6} className="mb-2">

                <Form.Group controlId="formLevel">
                  <Form.Label className="text-start d-block">Level</Form.Label>
                  <Form.Control className="form-control-disabled"
                    type="text"
                    name="level"
                    value={formData.level}
                    onChange={handleChange}
                    placeholder="Enter level"
                    isInvalid={!!errors.level}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.level}
                  </Form.Control.Feedback>
                </Form.Group></Col>
              <Col md={6} className="mb-2">
                <Form.Group controlId="formMobile">
                  <Form.Label className="text-start d-block">Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile"
                    value={formData.mobile}
                    onChange={(e) => handleNumericChange(e, 10)}
                    maxLength={10}
                    placeholder="Enter mobile number"
                    isInvalid={!!errors.mobile}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mobile}
                  </Form.Control.Feedback>
                </Form.Group></Col>
            </Row>
            <Row >
              <Col md={6} className="mb-2">
                <Form.Group controlId="formEmail">
                  <Form.Label className="text-start d-block">Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter email"
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group></Col>
              <Col md={6} className="mb-2">
                <Form.Group controlId="formPercentageShare">
                  <Form.Label className="text-start d-block">Percentage Share</Form.Label>
                  <Form.Control
                    type="text"
                    name="percentage_share"
                    value={formData.percentage_share}
                    onChange={(e) => handleNumericChange(e, 3)}
                    maxLength={3}
                    placeholder="Enter percentage share"
                    isInvalid={!!errors.percentage_share}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.percentage_share}
                  </Form.Control.Feedback>
                </Form.Group></Col></Row>
            <Row >
              {/* <Col md={6} className="mb-2">
                <Form.Group controlId="formOprCode">
                  <Form.Label className="text-start d-block">Operator Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="opr_code"
                    value={formData.opr_code}
                    onChange={handleChange}
                    placeholder="Enter operator code"
                    isInvalid={!!errors.opr_code}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.opr_code}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col> */}
              <Col md={6} className="mb-2">
                <Form.Group controlId="formPassword">
                  <Form.Label className="text-start d-block">Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter password"
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col> <Form.Group controlId="formConfirmPassword">
                <Form.Label className="text-start d-block">Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirm password"
                  isInvalid={!!errors.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group></Col></Row>


            {edit ? (
              <Button variant="primary" className="btnall mt-3" onClick={updateData}>
                Edit
              </Button>
            ) : (
              <Button variant="primary" className="btnall mt-3" onClick={addStateAgent}>
                Submit
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>


      <Modal className="change_password_modal change_password_modal_width" show={showPassword} onHide={() => { setPasswordData(''); setShowPassword(false) }} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            Change Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="change-password-form">
          <p className="mb-3">Set a strong password to prevent unauthorized access to your account.</p>
          <Form>
            <Row>
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label className="text-start d-block">New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={(e) => setNewPasswordData(e.target.value)}
                  placeholder="New Password"
                />
              </Form.Group>

              <Form.Group controlId="formPassword">
                <Form.Label className="text-start d-block">Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={(e) => setPasswordData(e.target.value)}
                  placeholder="Confirm Password"
                />
              </Form.Group>
            </Row>
            <div className="text-center change_password_btn_block">
              <Button
                variant="primary"
                className="mt-3 change_password_btn btn btn-primary"
                onClick={() => changePassword()}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AffiliateManagement