import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import LeftSideBar from './Components/LeftSideBar';
import Header from './Components/Header';
import jwt_decode from 'jwt-decode'
import Dashboard from './Dashboard';
import PlayerManagement from './Pages/PlayerManagement';
import VIPPlayerManagement from './Pages/VIPPlayerManagement';
// import Login from './Login';
import PlayerBankDetails from './Pages/PlayerBankDetails';
import WithdrawRequest from './Pages/WithdrawRequest';
import AllPlayersTransactions from './Pages/AllPlayersTransactions';
import Deposite from './Pages/Deposite';
import PlayerTurnover from './Pages/PlayerTurnover';
import OperatorTurnover from './Pages/OperatorTurnover';
import PlayerInfo from './Pages/PlayerInfo';
import Managekyc from './Pages/Managekyc';
import OperatorLogin from './Pages/OperatorLogin';
import OperatorAuth from './Pages/OperatorAuth';
import SubOperator from './Pages/SubOperator';
import AffiliateOperator from './Pages/AffiliateOperator';
import PlayerTransaction from './Pages/PlayerTransaction';
import GameTurnover from './Pages/GameTurnover';
import ErrorPage from './Components/ErrorPage';
import GameHistory from './Pages/GMS/GameHistory';

import ChipsTransfer from './Pages/ChipsTransfer';
import AffiliateManagement from './Pages/AffiliateManagement';
import AIManagement from './Pages/AIManagement';
import MessagePage from './Pages/MessagePage';
import Referral from './Pages/Referral';
import LoginLog from './Pages/LoginLog';
import SignupLog from './Pages/SignupLog';
import DeviceLog from './Pages/DeviceLog';
import ReferralReport from './Pages/ReferralReport';
import PokerTournament from './Pages/Tournament/PokerTournament';
import RummyTournament from './Pages/Tournament/RummyTournament';
import LudoTournament from './Pages/Tournament/LudoTournament';
import Promotions from './Pages/Promotions';
import FailureLog from './Pages/analytics/FailureLog';
import ErrorLog from './Pages/analytics/ErrorLog';
import WalletLog from './Pages/analytics/WalletLog';
import ContactDetails from './Pages/Support/ContactDetails';
import Maintenance from './Pages/Maintenance';
import TransferTransactions from './Pages/TransferTransactions';
import PlayStore from './Pages/AppStoreManagement/PlayStore';
import AppleStore from './Pages/AppStoreManagement/AppleStore';
import ChangePassword from './Pages/ChangePassword';
import PromoCode from './Pages/PromoCode';
import GiftRedemption from './Pages/GiftRedemption';
import ReferralInviteReport from './Pages/ManageCampaign/ReferralInviteReport';
import ManageCampaign from './Pages/ManageCampaign/ManageCampaign';
import AddCampaign from './Pages/ManageCampaign/AddCampaign';
import ManageBanner from './Pages/ManageCampaign/ManageBanner';
import AddBanner from './Pages/ManageCampaign/AddBanner';
import CampaignReport from './Pages/ManageCampaign/CampaignReport';
import { useState } from 'react';
import MakeVip from './Pages/MakeVip';

import ClaimBalance from './Pages/claimBalance';
import AffiliateChipTransfer from './Pages/AffiliateChipTransfer';
import AffiliateBalanceClaim from './Pages/AffiliateBalanceClaim';
import AffiliateTransactions from './Pages/AffiliateTransactions';
import PlayerChangePassword from './Pages/PlayerChangePassword';
import GoldProduction from './Pages/GoldProduction';
import CommissionLogs from './Pages/CommissionLogs';
import AffiliateTurnover from './Pages/AffiliateTurnover';
import AffiliatePlayerTurnover from './Pages/AffiliatePlayerTurnover';
import P2pTransactions from './Pages/P2pTrasactions';
import SubAdminLogin from './Pages/SubAdminLogin';
import SubAdminAuth from './Pages/SubAdminAuth';
import QrCodeGenerator from './Pages/QrCodeGenerator';
import SubAdmin from './Pages/SubAdmin';
import GameList from './Pages/GameList';
import GameIcon from './Pages/GameIcon';
import InGameTransitionOms from './Pages/InGameTransitionOms';
import CreateAdminAccount from './Pages/CreateAdminAccount';
import AllAdmin from './Pages/AllAdmin';
import AllRoleName from './Pages/AllRoleName';
import ThirdPartyLog from './Pages/analytics/ThirdPartyLog';
import ChatSupport from './Pages/ChatSupport';
import MessageSupport from './Pages/MessageSupport';
import PaymentLogs from './Pages/PaymentLogs';

function App() {

  const location = useLocation();
  const [affSignupURL, setAffSignupURL] = useState('');
  const [vipPlayers, setVipPlayers] = useState('');

  const ProtectedRoute = ({ element }) => {
    // const token = localStorage.getItem('sessionToken'); // Get the token from local storage

    if (localStorage.getItem('sessionToken')) {
      const token = localStorage.getItem('sessionToken');
      const decodedToken = jwt_decode(token);
      const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
      if (Date.now() >= expirationTime) {
        // Token has expired, remove it from local storage
        localStorage.removeItem('sessionToken');
        window.location.replace('/');
      } else {
        return element
      }
    } else {
      window.location.replace('/');
    }

  };

  const HideLeftSideRoute = ["/", "/auth", "/sub-operator-login", "/affiliate-operator", "/subAdminLogin", "/subadminlogin", "/subAdminAuth", "/subadminauth", "*"]

  return (
    <div className="App">
      {HideLeftSideRoute.includes(location.pathname || {}) ? null : <Header />}
      <div className={HideLeftSideRoute.includes(location.pathname || {}) ? null : "full-page"}>
        {/* <!------LEFT SIDE MENU-START-------> */}
        {HideLeftSideRoute.includes(location.pathname || {}) ? null : <LeftSideBar setAffSignupURL={setAffSignupURL} setVipPlayers={setVipPlayers} />}
        <Routes>
          {/* <Route index element={<OperatorLogin />}></Route> */}
          <Route path='/auth' element={<OperatorAuth />}></Route>
          <Route path="/sub-operator-login" element={<SubOperator />}></Route>
          <Route path="/affiliate-operator" element={<AffiliateOperator />}></Route>
          <Route path='/dashboard' element={<ProtectedRoute element={<Dashboard />} />}></Route>
          <Route path='/player-management' element={<ProtectedRoute element={<PlayerManagement vipPlayers={vipPlayers} />} />}></Route>
          <Route path='/VipPlayers' element={<ProtectedRoute element={<VIPPlayerManagement />} />}></Route>
          <Route path='/bank-details' element={<ProtectedRoute element={<PlayerBankDetails />} />}> </Route>
          <Route path='/withdraw-request' element={<ProtectedRoute element={<WithdrawRequest />} />}></Route>
          <Route path='/transaction-details' element={<ProtectedRoute element={<AllPlayersTransactions />} />}></Route>
          <Route path='/depositerequests' element={<ProtectedRoute element={<Deposite />} />}></Route>
          <Route path='/player-turnover' element={<ProtectedRoute element={<PlayerTurnover />} />}></Route>
          {/* <Route path='/playerInfo/:id' element={<ProtectedRoute element={<PlayerInfo/>}/>}></Route> */}
          <Route path='/managekyc' element={<ProtectedRoute element={<Managekyc />} />}></Route>
          <Route path='/transaction-details/:id' element={<ProtectedRoute element={<PlayerTransaction />} />}></Route>
          <Route path='/operator-turnover' element={<ProtectedRoute element={<OperatorTurnover />} />}></Route>
          <Route path='/game-turnover' element={<ProtectedRoute element={<GameTurnover />} />}></Route>
          <Route path='/transfer-chips' element={<ProtectedRoute element={<ChipsTransfer />} />}></Route>
          <Route path='/claim-balance' element={<ProtectedRoute element={<ClaimBalance />} />}></Route>
          <Route path='/aff-chipTransfer' element={<ProtectedRoute element={<AffiliateChipTransfer />} />}></Route>
          <Route path='/aff-claimBalance' element={<ProtectedRoute element={< AffiliateBalanceClaim />} />}></Route>
          <Route path='/player-changePassword' element={<ProtectedRoute element={< PlayerChangePassword />} />}></Route>
          <Route path='gms' element={<ProtectedRoute element={<GameHistory />} />}></Route>
          <Route path='*' element={<ProtectedRoute element={<ErrorPage />} />}></Route>
          {/* <Route path='/dashboard1' element={<ProtectedRoute element={<Dashboard1 />} />}></Route>*/}
          <Route path='/Affiliate-Management' element={<ProtectedRoute element={<AffiliateManagement affSignupURL={affSignupURL} />} />}></Route>
          <Route path='/ai-management' element={<ProtectedRoute element={<AIManagement />} />}></Route>
          <Route path='/message-centre' element={<ProtectedRoute element={<MessagePage />} />}></Route>
          <Route path='/referrals' element={<ProtectedRoute element={<Referral />} />}></Route>
          <Route path='/login-log' element={<ProtectedRoute element={<LoginLog />} />}></Route>
          <Route path='/signup-log' element={<ProtectedRoute element={<SignupLog />} />}></Route>
          <Route path='/device-log' element={<ProtectedRoute element={<DeviceLog />} />}></Route>
          <Route path='/referral-report' element={<ProtectedRoute element={<ReferralReport />} />}></Route>
          <Route path='/poker-tournament' element={<ProtectedRoute element={<PokerTournament />} />}></Route>
          <Route path='/rummy-tournament' element={<ProtectedRoute element={<RummyTournament />} />}></Route>
          <Route path='/ludo-tournament' element={<ProtectedRoute element={<LudoTournament />} />}></Route>
          <Route path='/promotions' element={<ProtectedRoute element={<Promotions />} />}></Route>
          <Route path='/failure-log' element={<ProtectedRoute element={<FailureLog />} />}></Route>
          <Route path='/error-log' element={<ProtectedRoute element={<ErrorLog />} />}></Route>
          <Route path='/wallet-log' element={<ProtectedRoute element={<WalletLog />} />}></Route>
          <Route path='/contact-details' element={<ProtectedRoute element={<ContactDetails />} />}></Route>
          <Route path='/maintenance' element={<ProtectedRoute element={<Maintenance />} />}></Route>
          <Route path='/transfer-transactions' element={<ProtectedRoute element={<TransferTransactions />} />}></Route>
          <Route path='/play-store' element={<ProtectedRoute element={<PlayStore />} />}></Route>
          <Route path='/apple-store' element={<ProtectedRoute element={<AppleStore />} />}></Route>
          <Route path='/gift-redemption' element={<ProtectedRoute element={<GiftRedemption />} />}></Route>
          <Route path='/promo-code' element={<ProtectedRoute element={<PromoCode />} />}></Route>
          {/* <Route path='/changepassword' element={<ProtectedRoute element={<ChangePassword />} />}></Route> */}
          <Route path='/referral-invite-report' element={<ProtectedRoute element={<ReferralInviteReport />} />}></Route>
          <Route path='/manage-campaign' element={<ProtectedRoute element={<ManageCampaign />} />}></Route>
          <Route path='/add-campaign' element={<ProtectedRoute element={<AddCampaign />} />}></Route>
          <Route path='/manage-banner' element={<ProtectedRoute element={<ManageBanner />} />}></Route>
          <Route path='/add-banner' element={<ProtectedRoute element={<AddBanner />} />}></Route>
          <Route path='/campaign-report' element={<ProtectedRoute element={<CampaignReport />} />}></Route>
          <Route path='/make-vip' element={<ProtectedRoute element={<MakeVip />} />}></Route>
          <Route path='/aff-transactions' element={<ProtectedRoute element={<AffiliateTransactions />} />}></Route>
          <Route path='/gold-production' element={<ProtectedRoute element={<GoldProduction />} />}></Route>
          <Route path='/commission-logs' element={<ProtectedRoute element={< CommissionLogs />} />}></Route>
          <Route path='/affiliate-turnover' element={<ProtectedRoute element={< AffiliateTurnover />} />}></Route>
          <Route path='/affiliate-player-turnover' element={<ProtectedRoute element={<  AffiliatePlayerTurnover />} />}></Route>
          <Route path='/p2p-transactions' element={<ProtectedRoute element={<  P2pTransactions />} />}></Route>
          <Route path='/qr-code-generator' element={<ProtectedRoute element={<QrCodeGenerator />} />}></Route>
          <Route path='/game-list' element={<ProtectedRoute element={<GameList />} />}></Route>
          <Route path='/sub-admin' element={<ProtectedRoute element={<SubAdmin />} />}></Route>

          <Route path="/" element={<SubAdminLogin />}></Route>
          <Route path="/subAdminAuth" element={<SubAdminAuth />}></Route>
          <Route path="/game-list-operator" element={<GameIcon />}></Route>
          <Route path="/oms-ingame-transactions" element={<InGameTransitionOms />}></Route>
          <Route path="/create-admin-account" element={<CreateAdminAccount />}></Route>
          <Route path="/admins" element={<AllAdmin />}></Route>
          <Route path="/roles" element={<AllRoleName />}></Route>
          <Route path="/third-party-log" element={<ThirdPartyLog />}></Route>
          <Route path="/chat-support" element={<ChatSupport />}></Route>
          <Route path="/message-support" element={<MessageSupport />}></Route>
          <Route path='/payment-logs' element={<PaymentLogs />}></Route>


        </Routes>
      </div>
    </div>
  );
}

export default App;
