import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import Skeleton from "react-loading-skeleton";

const PlayerBankDetails = () => {
  const [input, setInput] = useState({
    tableLength: "100",
    filterByStatus: "all",
    searchPlayer: "",
    remark: "",
  });

  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [playerData, setPlayerData] = useState();
  const [loading, setLoading] = useState(false)

  const [modelData, setModelData] = useState();

  const [hoveredRowData, setHoveredRowData] = useState("");
  const [dropdownActive, setDropdownActive] = useState(
    Array(playerData && playerData.data.length).fill(false)
  );

  const [modelToggle, setModelToggle] = useState(
    Array(modelData && modelData.fin_acc_details.length).fill(false)
  )

  const [bankDetails, setBankDetails] = useState();
  const [playerId, setPlayerId] = useState();

  const fetchData = async () => {
    setLoading(true);
    // if (localStorage.getItem("op
    // if (localStorage.getItem("opr_id")) {
    //   const response = await axios.get(
    //     process.env.REACT_APP_API_PATH +
    //       `bankDetails?limit=${
    //         input.tableLength
    //       }&pageNo=${pageNo}&opr_id=${localStorage.getItem(
    //         "opr_id"
    //       )}&filterByStatus=${input.filterByStatus}`,
    //     {
    //       headers: {
    //         oms_token: localStorage.getItem("omsToken"),
    //       },
    //     }
    //   );
    //   console.log(response.data);
    //   setPlayerData(response.data);
    // } else {
    const response = await axios.get(
      process.env.REACT_APP_API_PATH +
      `bankDetails?limit=${input.tableLength}&pageNo=${pageNo}&filterByStatus=${input.filterByStatus}&searchPlayer=${input.searchPlayer}`,
      {
        headers: {
          admin_auth_token: localStorage.getItem("omsToken"),
          opr_auth_token: localStorage.getItem("sessionToken"),
        },
      }
    );
    // console.log(response.data);
    setPlayerData(response.data);
    setTotalPage(response.data.totalPage);
    setLoading(false)
  };
  useEffect(() => {
    // };
    fetchData();
  }, [pageNo, input]);

  const handelChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setPageNo(1);
  };

  const handleModelData = (details) => {
    setModelData(details);
  };

  useEffect(() => {
    if (playerData) {
      setDropdownActive(Array(playerData.data.length).fill(false));
    }
  }, [playerData]);

  useEffect(() => {
    if (modelData) {
      setModelToggle(Array(modelData && modelData.fin_acc_details.length).fill(false))
    }
  }, [modelData])

  const handleAccountDetails = (data, index) => {
    setHoveredRowData(data);

    // const newDropdownActive = Array(playerData.length).fill(false);
    // console.log("🚀 ~ file: PlayerBankDetails.js:76 ~ handleAccountDetails ~ newDropdownActive:", newDropdownActive)

    // newDropdownActive[index] = !newDropdownActive[index];
    // setDropdownActive(newDropdownActive);
    setDropdownActive((dataa) => {
      console.log(dataa, "jjjj");

      const Toggledata = dataa.map((value, i) =>
        i === index ? !value : false
      );
      return Toggledata;
    });
  };

  const handlePlayerData = (kycDetails, id) => {
    setBankDetails(kycDetails);
    setPlayerId(id);
  };

  const handleApproveBtn = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_TRANSACTION_API + "confirm/bankDetails",
        {
          user_id: localStorage.getItem("login_id"),
          user_type: "123456",
          module: 'kyc',
          operator_code: localStorage.getItem("opr_id"),
          player_id: playerId,
          status: 1,
          acc_type: bankDetails.acc_type,
          updated_by: localStorage.getItem("role"),
          remarks: "",
        },
        {
          headers: {
            token: localStorage.getItem("sessionToken")
          }
        }
      );
      console.log(response.data, "approve reponse");
      if (response.data.status === true) {
        alert("Approved");
        window.location.reload();
      } else {
        alert("server timeout 404 !");
      }
    } catch {
      alert("server timeout ! Please try again")
    }

  };

  const handleDeclineBtn = async () => {
    try {
      if (input.remark !== "") {
        const response = await axios.post(
          process.env.REACT_APP_TRANSACTION_API + "reject/bankDetails",
          {
            user_id: localStorage.getItem("login_id"),
            user_type: "123456",
            module: 'kyc',
            operator_code: localStorage.getItem("opr_id"),
            player_id: playerId,
            status: 2,
            acc_type: bankDetails.acc_type,
            updated_by: localStorage.getItem("role"),
            remarks: "",
          },
          {
            headers: {
              token: localStorage.getItem("sessionToken")
            }
          }
        );
        console.log(response.data, "decline reponse");
        if (response.data.status === true) {
          alert("Decline");
          window.location.reload();
        } else {
          alert("server timeout 404 !");
        }
      } else {
        alert("plaese fill remark");
      }
    } catch (err) {
      alert("server timeout ! Please try again")
    }
  };

  const handleModelToggle = (data, index) => {
    setHoveredRowData(data);


    setModelToggle((dataa) => {

      const Toggle = dataa.map((value, i) =>
        i === index ? !value : false
      );
      return Toggle;
    });
  }

  const handleReset = async (details, id) => {
    try {
      console.log(details, id, "+++++++++++++")
      const response = await axios.post(
        process.env.REACT_APP_TRANSACTION_API + "reset/bankDetails",
        {
          user_id: localStorage.getItem("login_id"),
          user_type: "123456",
          module: 'kyc',
          operator_code: localStorage.getItem("opr_id"),
          player_id: id,
          status: 3,
          acc_type: details.acc_type,
          // updated_by: localStorage.getItem("role"),
          // remarks: "",
        },
        {
          headers: {
            token: localStorage.getItem("sessionToken")
          }
        }
      );
      console.log(response.data, "Reset reponse");
      if (response.data.status === true) {
        alert("Reset Successfully");
        window.location.reload();
      } else {
        alert("server timeout 404 !");
      }
    } catch (error) {
      console.log(error.message);
      alert("server timeout ! Please try again")
    }
  }

  // console.log(input,"input data")

  return (
    <div>
      {/* <Header />
      <div className="full-page">
        <LeftSideBar indexx={0} /> */}
      {/* <!------LEFT SIDE MENU-END------->
        <!------RIGHT SIDE CONTENT START-------> */}
      <div className="right-sidebar">
        <div className="das-bg-block w-">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <h1 className="gamehistoryheading title_h1 same_heading text-start">
                  Bank Details
                </h1>
              </div>
              <div className="col-md-6">
                <div className="row user-select">
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        id=""
                        className="w-100"
                        name="filterByStatus"
                        value={input.filterByStatus}
                        onChange={handelChange}
                      >
                        <option value="All">All</option>
                        <option value="Approved">Approved</option>
                        <option value="Pending">Pending</option>
                        <option value="Rejected">Rejected</option>
                        {/* <option value="">Reset</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <div className="input-group  input-group-sm">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search for...."
                        aria-describedby="basic-addon1"
                        style={{ height: "33.5px !important" }}
                        name="searchPlayer"
                        value={input.searchPlayer}
                        onChange={handelChange}
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                          style={{ padding: "9px !important" }}
                        >
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="DataTables_Table_0_wrapper"
          className="dataTables_wrapper no-footer"
        >
          <div className="table-player  bank-details">
            <table className="table data-table">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Login Id</th>
                  <th>Account Holder Name</th>
                  <th>Account type</th>
                  <th>Account Details</th>
                  <th>Ifsc Code</th>
                  <th>Country</th>
                  <th>Last Updated</th>
                  <th>Updated by</th>
                  <th>Action</th>
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {/* {playerData && playerData.data.map((doc)=>(<tr>
                    <td>
                      {doc.bank_detail.status === 0? (<label for="" className="approved">
                        Approved
                      </label>):(
                        <label id="auto-approved" >Pending</label>
                      )}
                    </td>
                    <td>{doc.player_id}</td>
                    <td>{doc.bank_detail.acc_holder_name}</td>
                    <td>{doc.bank_detail.acc_type}</td>
                    <td>{doc.bank_detail.upi_id}{doc.bank_detail.acc_number}</td>
                    <td>{doc.bank_detail.country}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <button className="btn-info reset-btn">Reset</button>
                    </td>
                  </tr>))} */}
                {
                  playerData?.data.map((doc, index) => (
                    <tr key={index}>
                      {loading ? <td><Skeleton /></td> : <td>
                        {doc.fin_acc_details[doc.fin_acc_details.length - 1].status === 1 ? (
                          <label htmlFor="" className="approved">
                            Approved
                          </label>
                        ) : doc.fin_acc_details[doc.fin_acc_details.length - 1].status === 0 ? (
                          <label id="auto-approved">Pending</label>
                        ) : (
                          <label className="unapproved">Rejected</label>
                        )}
                      </td>}
                      {loading ? <td><Skeleton /></td> : <td className="text-start">
                        <span className="d-flex align-items-center gap-1">
                          {doc.fin_acc_details.length === 1 ? (
                            ""
                          ) : (
                            <img src="img/file.png" className="opacity-75" width={16}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={() =>
                                handleModelData(doc)
                              } title={`${doc.fin_acc_details.length - 1} more`}
                              style={{ cursor: 'pointer' }} />

                          )}
                          {doc.mobile_number}</span>
                      </td>}
                      {loading ? <td><Skeleton /></td> : <td>{doc.fin_acc_details[doc.fin_acc_details.length - 1].acc_holder_name}</td>}
                      {loading ? <td><Skeleton /></td> : <td>{doc.fin_acc_details[doc.fin_acc_details.length - 1].acc_type}</td>}
                      {loading ? <td><Skeleton /></td> : <td
                        className="position-relative bankDetailsTd"
                        onClick={() =>
                          handleAccountDetails(doc.fin_acc_details[doc.fin_acc_details.length - 1], index)
                        }
                      // onMouseEnter={() =>
                      //   setHoveredRowData(doc.fin_acc_details[0])
                      // }
                      // onMouseLeave={() => setHoveredRowData("")}
                      >
                        {doc.fin_acc_details[doc.fin_acc_details.length - 1].upi_id}
                        {doc.fin_acc_details[doc.fin_acc_details.length - 1].acc_number}
                        {doc.fin_acc_details[doc.fin_acc_details.length - 1].phonepay}
                        <i class="fa-solid fa-file-invoice m-1"></i>
                        {dropdownActive[index] && (
                          <ul className="list-group position-absolute top-100 bankdetails-block">
                            {hoveredRowData && hoveredRowData.acc_type && (
                              <li className="list-group-item">
                                Type : {hoveredRowData.acc_type}
                              </li>
                            )}
                            {hoveredRowData &&
                              hoveredRowData.acc_holder_name && (
                                <li className="list-group-item">
                                  Name : {hoveredRowData.acc_holder_name}
                                </li>
                              )}
                            {hoveredRowData && hoveredRowData.acc_number && (
                              <li className="list-group-item">
                                Account No. : {hoveredRowData.acc_number}
                              </li>
                            )}
                            {hoveredRowData && hoveredRowData.ifsc_code && (
                              <li className="list-group-item">
                                Ifsc Code : {hoveredRowData.ifsc_code}
                              </li>
                            )}
                          </ul>
                        )}
                      </td>}
                      {loading ? <td><Skeleton /></td> : <td>{doc.fin_acc_details[doc.fin_acc_details.length - 1].ifsc_code}</td>}
                      {loading ? <td><Skeleton /></td> : <td>{doc.fin_acc_details[doc.fin_acc_details.length - 1].country}</td>}
                      {loading ? <td><Skeleton /></td> : <td>-</td>}
                      {loading ? <td><Skeleton /></td> : <td>{doc.fin_acc_details[doc.fin_acc_details.length - 1].updated_by}</td>}
                      {loading ? <td><Skeleton /></td> : <td>
                        {doc.fin_acc_details[doc.fin_acc_details.length - 1].status === 0 ? (
                          <button
                            className="btn-info reset-btn green-bg-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#review"
                            onClick={() =>
                              handlePlayerData(
                                doc.fin_acc_details[doc.fin_acc_details.length - 1],
                                doc._id
                              )
                            }
                          >
                            Approve
                          </button>
                        ) : doc.fin_acc_details[doc.fin_acc_details.length - 1].status === 1 ? (
                          <button className="btn-info reset-btn" onClick={() => handleReset(doc.fin_acc_details[doc.fin_acc_details.length - 1],
                            doc._id)}>
                            Reset
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>}
                      {/* {loading?<td><Skeleton/></td>:  <td>
                        {doc.fin_acc_details.length === 1 ? (
                          ""
                        ) : (
                          <button
                            className="btn-info reset-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() =>
                              handleModelData(doc)
                            }
                          >
                            {doc.fin_acc_details.length - 1} more
                          </button>
                        )}
                      </td>} */}
                    </tr>
                  ))
                }
                {/* <tr>
                    <td>
                      <label for="" className="approved">
                        Approved
                      </label>
                    </td>
                    <td>6205847732</td>
                    <td>Kapil Goyal</td>
                    <td>Phonepe</td>
                    <td>XXXXXXXX5458@ybl</td>
                    <td>India</td>
                    <td>30 May 15:13:50</td>
                    <td>-</td>
                    <td>
                      <button className="btn-info reset-btn">Reset</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="" className="approved">
                        Approved
                      </label>
                    </td>
                    <td>6205847732</td>
                    <td>Kapil Goyal</td>
                    <td>Paytm</td>
                    <td>XXXXXXXX5458@paytm</td>
                    <td>India</td>
                    <td>30 May 15:13:50</td>
                    <td>-</td>
                    <td>
                      <button className="btn-info reset-btn">Reset</button>
                    </td>
                  </tr> */}
              </tbody>
            </table>

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Bank Details
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <table className="table data-table">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Login Id</th>
                          <th>Account Holder Name</th>
                          <th>Account type</th>
                          <th>Account Details</th>
                          <th>Ifsc Code</th>
                          <th>Country</th>
                          <th>Last Updated</th>
                          <th>Updated by</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modelData &&
                          modelData.fin_acc_details.map((doc, ind) =>
                            ind === modelData.fin_acc_details.length - 1 ? null : (
                              <tr>
                                <td>
                                  {doc.status === 1 ? (
                                    <label htmlFor="" className="approved">
                                      Approved
                                    </label>
                                  ) : doc.status === 0 ? (
                                    <label id="auto-approved">Pending</label>
                                  ) : (
                                    <label className="unapproved">
                                      Rejected
                                    </label>
                                  )}
                                </td>
                                <td>{modelData?.mobile_number}</td>
                                <td>{doc.acc_holder_name}</td>
                                <td>{doc.acc_type}</td>
                                <td onClick={() => handleModelToggle(doc, ind)}>
                                  {doc.upi_id}
                                  {doc.acc_number}
                                  {doc.phonepay}
                                  <i class="fa-solid fa-file-invoice m-1"></i>
                                  {modelToggle[ind] && (
                                    <ul className="list-group position-absolute top-100 bankdetails-block modaldrowp">
                                      {hoveredRowData && hoveredRowData.acc_type && (
                                        <li className="list-group-item">
                                          Type : {hoveredRowData.acc_type}
                                        </li>
                                      )}
                                      {hoveredRowData &&
                                        hoveredRowData.acc_holder_name && (
                                          <li className="list-group-item">
                                            Name : {hoveredRowData.acc_holder_name}
                                          </li>
                                        )}
                                      {hoveredRowData && hoveredRowData.acc_number && (
                                        <li className="list-group-item">
                                          Account No. : {hoveredRowData.acc_number}
                                        </li>
                                      )}
                                      {hoveredRowData && hoveredRowData.ifsc_code && (
                                        <li className="list-group-item">
                                          Ifsc Code : {hoveredRowData.ifsc_code}
                                        </li>
                                      )}
                                    </ul>
                                  )}
                                </td>
                                <td>{doc.ifsc_code}</td>
                                <td>{doc.country}</td>
                                <td>-</td>
                                <td>{doc.updated_by}</td>
                                <td>
                                  {doc.status === 0 ? (
                                    <button
                                      className="btn-info reset-btn green-bg-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#review"
                                      onClick={() =>
                                        handlePlayerData(
                                          doc,
                                          modelData._id
                                        )
                                      }
                                    >
                                      Approve
                                    </button>
                                  ) : doc.status === 1 ? (
                                    <button className="btn-info reset-btn" onClick={() => handleReset(doc,
                                      modelData._id)}>
                                      Reset
                                    </button>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                {/* <td>
                                  <button
                                    className="btn-info reset-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                  >
                                    + {doc.fin_acc_details.length - 1} more
                                  </button>
                                </td> */}
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div> */}
                </div>
              </div>
            </div>
            {/* Action part Model */}
            <div
              className="modal fade add-btn-modal"
              id="review"
              data-bs-keyboard="true"
              tabIndex={-1}
              aria-labelledby="BackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div
                  className="modal-content manual_width "
                  style={{ borderRadius: 0 }}
                >
                  <div
                    className="modal-header p-2"
                    style={{ background: "#eceff4 !important" }}
                  >
                    <h5 className="modal-title">Bank Details</h5>
                    <button
                      className="close-btn"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span>x</span>
                    </button>
                  </div>
                  <div
                    className="modal-body container"

                  >
                    <div className="row">
                      <div className="docs">
                        {/* <div className="doc-img">
                            <img
                              data-bs-toggle="modal"
                              data-bs-target="#image"
                              onClick={() =>
                                setImage(
                                  kycDetails.user_kyc_details[kycDetails.user_kyc_details.length-1].front_img
                                )
                              }
                              src={
                                kycDetails
                                  ? kycDetails.user_kyc_details[kycDetails.user_kyc_details.length-1].front_img
                                  : "/img/kyc-dummy.png"
                              }
                              alt="front_img"
                            />
                          </div> */}
                        <div className="doc-details">
                          {/* <div className="d-flex align-items-center justify-content-between">
                              <label htmlFor="">Login Id :</label>
                              <span>
                                {bankDetails?.mobile_number}
                              </span>
                            </div> */}
                          <div className="d-flex align-items-center justify-content-between border-bottom pb-1 mb-1">
                            <label htmlFor="">Acc Type :</label>
                            <span>{bankDetails?.acc_type}</span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between border-bottom pb-1 mb-1">
                            <label htmlFor="">Name on Doc :</label>
                            <span>{bankDetails?.acc_holder_name}</span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between border-bottom pb-1 mb-1">
                            <label htmlFor="">Doc No :</label>
                            <span>{bankDetails?.upi_id}{bankDetails?.acc_number}{bankDetails?.phonepay}</span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <label htmlFor="">Remark</label>
                            <textarea
                              cols="22"
                              rows="2"
                              name="remark"
                              value={input.remark}
                              onChange={handelChange}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal-footer p-0 justify-content-center"
                    style={{ background: "#eceff4 !important" }}
                  >
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleApproveBtn}
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      // data-bs-toggle="modal"
                      // data-bs-target="#rejectModel"
                      onClick={handleDeclineBtn}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Action part model ended */}


            <div className="pagination pagination-text row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                  <select
                    aria-controls="DataTables_Table_0"
                    name="tableLength" value={input.tableLength}
                    onChange={handelChange}
                    className="pag-select-block me-1"
                  >
                    <option value="10">10</option>
                    <option value="25" defaultValue={true}>25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select> Records per page
                </div>
              </div>

              <div className="w-auto">
                Showing {pageNo}-{input?.tableLength}{" "}
                of {playerData?.data?.length} items
              </div>

              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                  <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                  <a onClick={() => setPageNo(pageNo - 1)}
                    style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    {/* <i className="fa-solid fa-chevron-left"></i> Previous */}
                    <img src="img/left-arrow.png" width="20" />
                  </a>
                  <span className="d-flex align-items-center">

                    <select className="pag-select-block"
                      aria-controls="DataTables_Table_0"
                      name="pagination" value={pageNo}
                      onChange={(e) => setPageNo(Number(e.target.value))}
                    >
                      {Array.from({ length: totalPage }, (_, index) => (
                        <option key={index + 1} value={index + 1} >
                          {index + 1}
                        </option>
                      ))}



                    </select> of {totalPage}
                  </span>

                  <a onClick={() => setPageNo(pageNo + 1)}
                    style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                    className={`${pageNo < totalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                    <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                  </a>
                  {/* } */}

                  <a onClick={() => setPageNo(totalPage)} style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                    className={`${pageNo < totalPage ? '' : 'opacity-25'} border-0`}>
                    {/* <i className="fa-solid fa-chevron-right"></i> */}
                    <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                </div>
              </div>
            </div>

            {/* <div className="pagination row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length">
                  {pageNo} of {playerData && playerData.TotalPage}{" "}
                  <label>
                    Rows per page{" "}
                    <select
                      aria-controls="DataTables_Table_0"
                      name="tableLength"
                      value={input.tableLength}
                      onChange={handelChange}
                    >
                      <option value="10">10</option>
                      <option value="25" defaultValue={true}>
                        25
                      </option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                  {pageNo > 1 && (
                    <a onClick={() => setPageNo(pageNo - 1)}>
                      <i className="fa-solid fa-chevron-left"></i>
                    </a>
                  )}
                  <span>
                    <a
                      className="paginate_button current"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="1"
                      tabIndex="0"
                    >
                      {pageNo}
                    </a>
                  </span>
                  {playerData && pageNo < playerData.TotalPage && (
                    <a onClick={() => setPageNo(pageNo + 1)}>
                      <i className="fa-solid fa-chevron-right"></i>
                    </a>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default PlayerBankDetails;
