import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import LoadingBar from "react-top-loading-bar";
import Skeleton from "react-loading-skeleton";
import moment from 'moment';
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import CreateRoleNameModal from "../Components/CreateRoleNameModal";

const AllRoleName = () => {
    const [permissions, setPermissions] = useState([]);
    const [selectMenuID, setSelectMenuID] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownVisiblePer, setDropdownVisiblePer] = useState(false);
    const [roleNameCreate, setRoleNameCreate] = useState("");
    const [permissionError, setPermissionError] = useState("");
    const [menuTitle, setMenuTitle] = useState([]);
    const [show, setShow] = useState(false);
    const [permissionShow, setPermissionShow] = useState(false);
    const [data, setData] = useState([]);
    const [roleName, setRoleName] = useState('base_role');
    const [permissionsAllowe, setPermissionsAllowe] = useState([]);

    const getRoleName = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}getRoleIDs`, {
                headers: {
                    opr_auth_token: localStorage.getItem("sessionToken")
                }
            });

            console.log(res?.data);
            setData(res?.data.data);
        } catch (error) {
            console.error("Error fetching role data:", error);

        }
    };

    useEffect(() => {
        getRoleName();
    }, [show])

    const getAdminPermission = async () => {
        try {

            const res = await axios.get(`${process.env.REACT_APP_API_PATH}getAdminPermission`, {
                params: {
                    role_name: roleName,
                },
                headers: {
                    opr_auth_token: localStorage.getItem("sessionToken"),
                }
            });


            setPermissions(res?.data?.permissions || []);

            console.log(res?.data, 'getAdminPermission');
        } catch (error) {
            console.error("Error fetching role data:", error);

        }
    };



    useEffect(() => {
        getAdminPermission();
    }, [roleName])

    const handlePermissionSelection = (permission) => {
        setPermissionsAllowe((prevSelected) => {
            if (prevSelected.includes(permission)) {
                return prevSelected.filter((item) => item !== permission);
            } else {
                return [...prevSelected, permission];
            }
        });
    };

    const handleMenuSelection = (menuId, menuTitleName) => {
        setSelectMenuID((prevSelected) => {
            if (prevSelected.includes(menuId)) {
                return prevSelected.filter((id) => id !== menuId);
            } else {
                return [...prevSelected, menuId];
            }
        });
        setMenuTitle((prevSelected) => {
            if (prevSelected.includes(menuTitleName)) {
                return prevSelected.filter((title) => title !== menuTitleName);
            } else {
                return [...prevSelected, menuTitleName];
            }
        });

    };

    const toggleDropdown = () => {
        setDropdownVisible((prevVisible) => !prevVisible);
        setDropdownVisiblePer(false)
    };
    const toggleDropdownPer = () => {
        setDropdownVisiblePer((prevVisible) => !prevVisible);
        setDropdownVisible(false)
    };

    const [allUserName, setAllUserName] = useState([]);
    const [allUserNameModal, setAllUserNameModal] = useState(false);

    const handleShowUser = (userName) => {
        setAllUserName(userName);
        setAllUserNameModal(true);
    }




    return (
        <>
            <div className="right-sidebar">
                <div className="das-bg-block w-">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            <div className="col-md-3">
                                <h1 className="gamehistoryheading title_h1 same_heading text-start">
                                    All Role Name
                                </h1>

                            </div>

                            <div className="w-auto">
                                <a className="nav-link db-btn ms-2 ms-md-0" onClick={() => { setShow(true); setRoleName('base_role'); }}>
                                    <i className="fa-solid fa-circle-plus me-1"></i>
                                    Create New Role Name
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

                <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer"
                >
                    <div className="table-player">
                        <div className="w-100 overflow-auto">
                            <table className="table data-table">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap">Role Name</th>
                                        <th className="text-nowrap">Subadmin Count</th>
                                        <th className="text-nowrap">Role ID</th>
                                        <th className="text-nowrap">Permission</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {data?.length > 0 ? (data?.map((e, idx) => (
                                        <tr key={idx}>

                                            <td className='text-start'>{e?.role_name}</td>
                                            <td className='text-center'>
                                                <span onClick={() => handleShowUser(e?.users)}>
                                                    <i className="fa-solid fa-user"></i> {e?.users?.length}</span></td>
                                            <td className='text-center'>{e?._id}</td>
                                            <td className='text-center'>
                                                <button className="nav-link db-btn "
                                                    onClick={() => { setRoleName(e?.role_name); setPermissionShow(true) }}>Permissions</button>
                                            </td>


                                        </tr>
                                    ))
                                    ) : (
                                        <tr>
                                            <td colSpan={12} className="text-center">No Data Found</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>



                    </div>
                </div>
            </div>

            <CreateRoleNameModal show={show} setShow={setShow} />



            <Modal show={permissionShow} onHide={() => setPermissionShow(false)} centered scrollable>
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Allowed Permission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-player">
                        <div className="w-100 overflow-auto">
                            <table className="table data-table">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap">Menu Title</th>
                                        <th className="text-nowrap">Read</th>
                                        <th className="text-nowrap">Write</th>
                                        <th className="text-nowrap">Create</th>
                                        <th className="text-nowrap">Delete</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {permissions?.length > 0 ? (
                                        permissions.map((e, idx) => (
                                            <tr key={idx}>
                                                <td className="text-start">{e?.menu_title}</td>
                                                <td className="text-center">
                                                    {e?.permission_type.includes("R") ? (
                                                        <i className="fa-solid fa-check" style={{ color: 'green' }}></i>
                                                    ) : (
                                                        <i className="fa-solid fa-xmark" style={{ color: 'red' }}></i>
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {e?.permission_type.includes("W") ? (
                                                        <i className="fa-solid fa-check" style={{ color: 'green' }}></i>
                                                    ) : (
                                                        <i className="fa-solid fa-xmark" style={{ color: 'red' }}></i>
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {e?.permission_type.includes("C") ? (
                                                        <i className="fa-solid fa-check" style={{ color: 'green' }}></i>
                                                    ) : (
                                                        <i className="fa-solid fa-xmark" style={{ color: 'red' }}></i>
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {e?.permission_type.includes("D") ? (
                                                        <i className="fa-solid fa-check" style={{ color: 'green' }}></i>
                                                    ) : (
                                                        <i className="fa-solid fa-xmark" style={{ color: 'red' }}></i>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                No Permission Allowed
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>



                    </div>


                </Modal.Body>
            </Modal>

            <Modal show={allUserNameModal} onHide={() => setAllUserNameModal(false)} centered scrollable>
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>All Subadmin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-player">
                        <div className="w-100 overflow-auto">
                            <table className="table data-table">

                                <thead>
                                    <tr>
                                        
                                            <th>Sr.</th>
                                            <th>Subadmin ID</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>

                                    {allUserName?.length > 0 ? (
                                        allUserName.map((name, idx) => (
                                            <tr key={idx}>
                                                <td className="text-center">{idx + 1}</td>
                                                <td className="text-center">{name}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                No Subadmin
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>



                    </div>


                </Modal.Body>
            </Modal>

        </>
    );
};

export default AllRoleName;
