import React, { useState } from "react";
import { Link } from "react-router-dom";


const CampaignReport = () => {
    const [activeToggle, setActiveToggle] = useState(false);
    const toggleButton = () => {
        setActiveToggle(!activeToggle);
    }
    return (
        <>

            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Campaign Report</h1>
                    </div>

                </div>
                <div>
                    <div className='container-fluid bg'>
                        <div className='row user-select mt-2'>

                            <div className="col-lg-3 col-md-3  col-6  col-xs-12">
                                <div className="form-group">
                                    <select name="" id="" className="w-100 ">
                                        <option value="5">Login</option>
                                        <option value="0" selected="selected">Signup</option>
                                        <option value="1">Referral</option>
                                        <option value="2">Deposit</option>
                                        <option value="3">Play/Spend/Win</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3  user-select user-selectinput col-md-3  col-6  col-xs-12">
                                <div className="form-group">
                                    <input type="date" />
                                </div>
                            </div>
                            <div className="col-lg-3  user-select user-selectinput col-md-3  col-6  col-xs-12">
                                <div className="form-group">
                                    <input type="date" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 user-select col-6 mt-lg-0  col-xs-1">
                                <div className="input-group  input-group-sm">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for...."
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        name="search"
                                        defaultValue=""
                                    />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </span>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>

                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <table className="table data-table   table-borderless">
                                <thead>
                                    <tr>
                                        <th>Campaign Name</th>
                                        <th>Promo Value</th>
                                        <th>Redeemed</th>
                                        <th>Total Paid</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tbody>
                            </table></div>

                    </div>

                </div>
            </div>

        </>
    );
};

export default CampaignReport;