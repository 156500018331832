import "../App.css";
import React, { useState } from "react";
import { useNavigate, Redirect } from "react-router-dom";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const AffiliateOperator = () => {
  const navigate = useNavigate();
  const [formValue, setformvalue] = useState({
    login_id: "",
    login_url: "operator2.oms.com",
    operator_id:"230101"
  });
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formValue.login_id !== "" && formValue.operator_id !== "") {
        const response = await axios.post(
          process.env.REACT_APP_API_PATH + "affiliateOperatorLogin",
          formValue
        );
        console.log(response);
        if (response.data.status === true) {
          // alert("ok")
          // localStorage.setItem("omsToken", response.data.token);
          localStorage.setItem("opr_id", formValue.opr_id)
          localStorage.setItem("role", "Affiliate Operator")
          localStorage.setItem("login_id",formValue.login_id)
          setAuthenticated(true);
          // navigate('/dashboard')
        } else {
          // alert("Invalid user")
          setError("Invalid details");
        }
      } else {
        setError("Please fill all the details");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    setformvalue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };
  if (authenticated) {
    // Redirect to the dashboard page if the user is authenticated
    return navigate("/auth");
  }
  return (
    <div className="App" style={{ padding: "0 " }}>
      <div className="container-fluid bg-hero">
        <div className="container">
          <div className="signin-container">
            <div className="signin_box">
              <div className="w-100">
                <img src="img/logo.png" className="mb-4" alt="logo" />
                <div className="signin_div w-100">
                  <form action="">
                    <div className="signin-title">
                      <span className="title">Affiliate Operator - Sign in</span>
                      {/* <!-- <p className="p-0">to access cliq</p> --> */}
                    </div>
                    <div className="filed-content">
                      <input
                        className="fill-input"
                        type="text"
                        name="login_id"
                        value={formValue.login_id}
                        placeholder="Email-address or mobile number"
                        onChange={handleChange}
                      />
                    </div>
                    {/* <div className="filed-content">
                      <input
                        className="fill-input"
                        type={isRevealPwd ? "text" : "password"}
                        name="password"
                        value={formValue.password}
                        placeholder="Enter Password"
                        onChange={handleChange}
                      />
                      <div className="icon">
                        {isRevealPwd ? (
                          <FaEyeSlash onClick={() => setIsRevealPwd(false)} />
                        ) : (
                          <FaEye onClick={() => setIsRevealPwd(true)} />
                        )}
                      </div>
                    </div> */}
                    <select name="operator_id" value={formValue.operator_id} onChange={handleChange} id="" class="select-box form-control">
                      <option value="230101">230101</option>
                      {/* <option value="11">11</option>
                      <option value="12">12</option> */}
                    </select>
                    <span style={{ color: "red" }}>{error}</span>

                    <div className="next-btn mt-4">
                      <button onClick={(e) => handleSubmit(e)}>Next</button>
                    </div>
                    {/* <!-- <div className="forgot-pass">
                                <a href="#">Forgot Password?</a>
                            </div> --> */}
                  </form>
                </div>
              </div>
            </div>
            <div className="rightside_box">
              <div className="login-img">
                <img src="img/login.png.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateOperator;
