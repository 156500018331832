import React from "react";


const ContactDetails = () => {
    return (
        <>
            
                <div className="right-sidebar">
                    <div className="das-bg-block d-flex justify-content-between pe-3">
                        <div className="das-title">
                            <h1 className="gamehistoryheading title_h1 same_heading">Contact Details</h1>
                        </div>
                        <div className="col-lg-3 col-md-3 user-select col-6 mt-lg-0  col-xs-1">
                                <div className="input-group  input-group-sm">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for...."
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        name="search"
                                        defaultValue=""
                                    />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </span>
                                    </div>
                                </div>

                            </div>
                    </div>
                    <div>

                        <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                        <table className="table data-table   table-borderless">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Ticket No</th>
                                <th>Submited By</th>
                                <th>Mobile</th>
                                <th>Email</th>
                                <th>Ticket Details</th>
                                <th>Assign To</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td> 
                                <td>--</td> 
                                <td>--</td> 
                                <td>
                                --
                                </td>
                            </tr>
                        </tbody>
                    </table></div>

                    

                        </div>

                    </div>
                </div>
            </>
    );
};

export default ContactDetails;