import React from "react";


const RummyTournament = () => {
    return (
        <>
            
                <div className="right-sidebar">
                    <div className="das-bg-block d-flex">
                        <div className="das-title">
                            <h1 className="gamehistoryheading title_h1 same_heading">Rummy Tournament</h1>
                        </div>
                        <div></div>
                    </div>
                    <div>
                        <div className="w-100 mb-3">
                            <h2 className="subTitleSection px-3">Tournament Details</h2>
                        </div>
                        <div className='container-fluid bg px-5'>
                            <div className='row user-select user-selectinput mt-2'>

                                <div className="col-lg-3 mb-2 col-md-3  col-6  col-xs-12  p-1">
                                    <div className="form-group">
                                        <label className="text-start d-block mb-1">Select Game</label>
                                        <select name="" id="" className="w-100 ">
                                            <option value="" selected="">
                                                Select Game
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3  col-6 mt-lg-0  col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Pot Type</label>
                                        <select name="" id="" className="w-100">
                                            <option value="" selected="">
                                                Pot Type
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3  col-6 mt-lg-0  col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Tournament Name</label>
                                        <select name="" id="" className="w-100">
                                            <option value="" selected="">
                                            Tournament Name
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Enter Fee</label>
                                        <input type="text" placeholder="Enter Fee" className="w-100" />
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Rake(%)</label>
                                        <input type="text" placeholder="Enter Fee" className="w-100" />
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Entry Using</label>
                                        <input type="text" placeholder="Enter Fee" className="w-100" />
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Tax(%)</label>
                                        <input type="text" placeholder="Enter Fee" className="w-100" />
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Enter Fee</label>
                                        <input type="text" placeholder="Enter Fee" className="w-100" />
                                    </div>
                                </div> */}

                            </div>
                        </div>

                        <div className="w-100 my-3">
                            <h2 className="subTitleSection px-3">Player Entry</h2>
                        </div>
                        <div className='container-fluid bg px-5'>
                            <div className='row user-select  user-selectinput mt-2'>

                                <div className="col-lg-3 mb-2 col-md-3  col-6  col-xs-12  p-1">
                                    <div className="form-group">
                                        <label className="text-start d-block mb-1">Per Table Max Player</label>
                                        <select name="" id="" className="w-100 ">
                                            <option value="" selected="">
                                            Per Table Max Player
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Max Winner</label>
                                        <input type="text" placeholder="Enter Fee" className="w-100" />
                                    </div>
                                </div>
                                

                            </div>
                        </div>

                        <div className="w-100 my-3">
                            <h2 className="subTitleSection px-3">Stakes</h2>
                        </div>
                        <div className='container-fluid bg px-5'>
                            <div className='row user-select user-selectinput mt-2'>

                                <div className="col-lg-3 mb-2 col-md-3  col-6  col-xs-12  p-1">
                                    <div className="form-group">
                                        <label className="text-start d-block mb-1">Select Game</label>
                                        <select name="" id="" className="w-100 ">
                                            <option value="" selected="">
                                                Select Game
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3  col-6 mt-lg-0  col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Pot Type</label>
                                        <select name="" id="" className="w-100">
                                            <option value="" selected="">
                                                Pot Type
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3  col-6 mt-lg-0  col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Tournament Name</label>
                                        <select name="" id="" className="w-100">
                                            <option value="" selected="">
                                            Tournament Name
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Enter Fee</label>
                                        <input type="text" placeholder="Enter Fee" className="w-100" />
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Enter Fee</label>
                                        <input type="text" placeholder="Enter Fee" className="w-100" />
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Enter Fee</label>
                                        <input type="text" placeholder="Enter Fee" className="w-100" />
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Enter Fee</label>
                                        <input type="text" placeholder="Enter Fee" className="w-100" />
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                    <div className="form-group">
                                    <label className="text-start d-block mb-1">Enter Fee</label>
                                        <input type="text" placeholder="Enter Fee" className="w-100" />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </>
    );
};

export default RummyTournament;