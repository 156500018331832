import React from 'react'
import { NavLink } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div class="d-flex align-items-center justify-content-center vh-100">
      <div class="text-center">
          {/* <h1 class="display-1 fw-bold">404</h1>
          <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p> */}
          <p class="lead">
              {/* The page you’re looking for doesn’t exist. */}
              Upcoming feature!
            </p>
          <NavLink to="/dashboard" className="btn btn-primary" >Go Home</NavLink>
      </div>
  </div>
  )
}

export default ErrorPage