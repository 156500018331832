const moneyformat = (x) => {
    let value = x < 0 ? -x : x;

    if (value >= 10000000) {
        return <> {(value / 10000000).toFixed(2) + ' Cr'}</>;
    } else if (value >= 100000) {
        return <> {(value / 100000).toFixed(2) + ' Lac'}</>;
    }
    // else if (value >= 1000) {
    //     return <> ({(value / 1000).toFixed(0) + 'K'})</>;
    // } 
    else {
        return <>{value?.toFixed(2)}</>;
    }
};

export default moneyformat