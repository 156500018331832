import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import moment from 'moment';
import Skeleton from "react-loading-skeleton";

// Calculate the date one year from now
const getOneYearAheadDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() + 1);
    // today.toISOString().split("T")[0]; // Format as yyyy-mm-dd
    return today.toISOString().split("T")[0]; // Format as yyyy-mm-dd
};
const PromoCode = () => {
    const [showModal, setShowModal] = useState(false);
    const [filter, setFilter] = useState('active');
    const [data, setdata] = useState([])
    const [formState, setFormState] = useState({
        count: "",
        validTill: getOneYearAheadDate(),
        isExclusive: false,
        playerId: "",
        promoType: "",
        campaignId: "",
        maxRedeem: "",
    });
    const [loading, setLoading] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [input, setInput] = useState({
        tableLength: "100",
        opr_id: "",
        from: "",
        to: ""
    })


    async function fetch() {
        setLoading(true)
        try {
            const operatorCode = localStorage.getItem('opr_id') || localStorage.getItem('opr_code')
            const response = await axios.get(
                `${process.env.REACT_APP_API_PATH}get-promoCode?opr_code=${operatorCode}&filter=${filter}&pageNo=${pageNo}&limit=${input.tableLength}`,
                {
                    headers: {
                        admin_auth_token: localStorage.getItem("omsToken"),
                        opr_auth_token: localStorage.getItem("sessionToken")
                    }
                }
            );
            console.log("GetAlldata ", response?.data?.data);
            if (response) {
                setdata(response?.data)
                setLoading(false)
                setTotalPage(response?.data?.totalPages);
            }

        } catch (error) {
            console.log("Error in GetAlldata ", error)
        }

    }
    useEffect(() => {
        fetch()
    }, [filter, input.tableLength, pageNo])

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formState.count <= 0) {
            alert("Count cannot be negative or Zero")
        }

        try {
            const operatorCode = localStorage.getItem('opr_id') || localStorage.getItem('opr_code')
            const response = await axios.post(
                `${process.env.REACT_APP_API_PATH}create-promoCode`,
                {
                    opr_code: operatorCode,
                    count: formState.count,
                    valid_till: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString() || formState.validTill,
                    is_exclusive: false,
                    player_id: "",
                    promo_type: "vip",
                    campaign_id: "1",
                    max_redeem: 1
                },
                {
                    headers: {
                        admin_auth_token: localStorage.getItem("omsToken"),
                        opr_auth_token: localStorage.getItem("sessionToken")
                    }
                }
            );



        } catch (error) {
            console.log("Error in Creating Promo Codes ", error)
        }
        fetch()
        setShowModal(false);
    };



    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    };



    const copyToClipboard = (text) => {
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();

        try {
            // Attempt to copy the text
            const successful = document.execCommand('copy');
            if (successful) {
                alert("Copied to clipboard!");
            } else {
                console.error("Failed to copy text.");
                alert("Copy to clipboard failed.");
            }
        } catch (err) {
            console.error("Error copying text: ", err);
            alert("Copy to clipboard failed.");
        } finally {

            document.body.removeChild(textarea);
        }
    };

    const handelChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
        setPageNo(1);
    }



    return (
        <>
            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">
                            Promo Code
                        </h1>
                    </div>
                    <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                        <button
                            className="nav-link db-btn"
                            onClick={() => setShowModal(true)}
                        >
                            <i className="fa-solid fa-circle-plus"></i> Create Promo Code
                        </button>
                    </div>
                </div>
                <div>
                    <div className="container-fluid bg">
                        <div className="row user-select mt-2">
                            <div className="col-md-3 col-12">
                                <div className="form-group">
                                    <select name="filter" className="w-100" value={filter} onChange={(e) => setFilter(e.target.value)}>
                                        <option value="active">Active</option>
                                        <option value="used">Used</option>
                                    </select>
                                </div>
                            </div>
                            {/* <div className="col-lg-3 col-md-3  col-6 mt-lg-0  col-xs-1">
                                <div className="form-group">
                                    <select name="" id="" className="w-100">
                                        <option value="" selected="">
                                            Player
                                        </option>
                                        <option value="">Player</option>
                                    </select>
                                </div>
                            </div> */}
                            {/* <div className="col-lg-3 col-md-3  col-6 mt-lg-0  col-xs-1">
                                <div className="form-group">
                                    <select name="" id="" className="w-100">
                                        <option value="" selected="">
                                            Normal
                                        </option>
                                        <option value="">Normal</option>
                                    </select>
                                </div>
                            </div> */}
                            {/* <div className="col-lg-3 col-md-3 user-select col-6 mt-lg-0  col-xs-1">
                                <div className="input-group  input-group-sm">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for...."
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        name="search"
                                        defaultValue=""
                                    />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div id="DataTables_Table_0_wrapper" className="data-table">
                        <div className="table-player">
                            <div className="table-list-mb">
                                <table className="table data-table   table-borderless">
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            {/* <th>Redeem Exclusive</th> */}

                                            <th>Promo Code</th>
                                            {(filter === "active") ? <th>Valid Till</th> : <th>Redeem On</th>}
                                            {(filter === "active") ? <th>Created At</th> : null}
                                            {(filter === "active") ? null : <th>Redeem By</th>}
                                            {(filter === "active") ? <th>Exclusive For </th> : <th>Player Id</th>}

                                            <th>Promo Type</th>
                                            <th>Campaign Id</th>
                                            {(filter === "active") ? <th>Max Redeem</th> : null}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.data?.length > 0 && data?.data?.map((ele, index) => (
                                            <tr key={index}>
                                                {/* Filter Column */}
                                                {loading ? (
                                                    <td><Skeleton /></td>
                                                ) : (
                                                    <td>{filter}</td>
                                                )}

                                                {/* Promo Code Column */}
                                                {loading ? (
                                                    <td><Skeleton /></td>
                                                ) : (
                                                    <td>
                                                        {ele.promo_code.length > 4
                                                            ? `${ele?.promo_code.slice(0, 1)}${'*'.repeat(ele?.promo_code.length - 2)}${ele?.promo_code.slice(-1)}`
                                                            : ele?.promo_code}
                                                        <i
                                                            className="fa-regular fa-copy btn"
                                                            onClick={() => copyToClipboard(ele.promo_code)}
                                                            style={{ cursor: 'pointer', marginLeft: '5px' }} // Optional: Styling for better UX
                                                        />
                                                    </td>
                                                )}

                                                {/* Date Column Based on Filter */}
                                                {loading ? (
                                                    <td><Skeleton /></td>
                                                ) : (
                                                    <td>
                                                        {filter === "active"
                                                            ? moment(ele?.valid_till).format('MMMM D, YYYY h:mm:ss A')
                                                            : moment(ele?.redeemed_on).format('MMMM D, YYYY h:mm:ss A')}
                                                    </td>
                                                )}

                                                {/* Created At Column (only for active filter) */}
                                                {loading ? (
                                                    <td><Skeleton /></td>
                                                ) : (
                                                    filter === "active" ? (
                                                        ele?.createdAt === undefined ? (
                                                            <td>--</td>
                                                        ) : (
                                                            <td>{moment(ele?.createdAt).format('MMMM D, YYYY h:mm:ss A')}</td>
                                                        )
                                                    ) : null
                                                )}

                                                {/* Redeemed By Column (only for non-active filter) */}
                                                {loading ? (
                                                    <td><Skeleton /></td>
                                                ) : (
                                                    filter !== "active" && (
                                                        <td>{ele?.redeemed_by}</td>
                                                    )
                                                )}

                                                {/* Player ID Column */}
                                                {loading ? (
                                                    <td><Skeleton /></td>
                                                ) : (
                                                    <td>{ele?.player_id || '--'}</td>
                                                )}

                                                {/* Promo Type Column */}
                                                {loading ? (
                                                    <td><Skeleton /></td>
                                                ) : (
                                                    <td>{ele?.promo_type}</td>
                                                )}

                                                {/* Campaign ID Column */}
                                                {loading ? (
                                                    <td><Skeleton /></td>
                                                ) : (
                                                    <td>{ele?.campaign_id}</td>
                                                )}

                                                {/* Max Redeem Column (only for active filter) */}
                                                {loading ? (
                                                    <td><Skeleton /></td>
                                                ) : (
                                                    filter === "active" && (
                                                        <td>{ele?.max_redeem}</td>
                                                    )
                                                )}
                                            </tr>
                                        ))}

                                        {/* {data?.length > 0 && data?.map((ele, index) => (
                                        <tr key={index}>

                                            <td>{filter}</td>
                                            


                                            <td> {ele.promo_code.length > 4
                                                ? `${ele?.promo_code.slice(0, 1)}${'*'.repeat(ele?.promo_code.length - 2)}${ele?.promo_code.slice(-1)}`
                                                : ele?.promo_code}
                                                <i
                                                    className="fa-regular fa-copy btn  "
                                                    onClick={() => copyToClipboard(ele.promo_code)}
                                                    style={{ cursor: 'pointer', marginLeft: '5px' }} 
                                                >
                                                </i></td>


                                            {(filter === "active") ? <td>{moment(ele?.valid_till).format('MMMM D, YYYY h:mm:ss A')}</td> : <td>{moment(ele?.redeemed_on).format('MMMM D, YYYY h:mm:ss A')}</td>}
                                            {(filter === "active") ? <>
                                                {(ele?.createdAt === undefined) ? <td>--</td> : <td>{moment(ele?.createdAt).format('MMMM D, YYYY h:mm:ss A')}</td>}</> : null}

                                            {(filter === "active") ? null : <td>{ele.redeemed_by}</td>}
                                            {(ele.player_id === '' ? <td>--</td> : <td>{ele?.player_id}</td>)}

                                            <td>{ele.promo_type}</td>
                                            <td>{ele?.campaign_id}</td>
                                            {(filter === "active") ? <td>{ele?.max_redeem}</td> : null}


                                        </tr>
                                    ))} */}
                                    </tbody>
                                </table>
                            </div>

                            <div className="pagination pagination-text row justify-content-between align-items-center">
                                <div className="w-auto">
                                    <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                                        <select
                                            aria-controls="DataTables_Table_0"
                                            name="tableLength" value={input.tableLength}
                                            onChange={handelChange}
                                            className="pag-select-block me-1"
                                        >
                                            <option value="10">10</option>
                                            <option value="25" defaultValue={true}>25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select> Records per page
                                    </div>
                                </div>

                                <div className="w-auto">
                                    Showing {pageNo}-{input?.tableLength}{" "}
                                    of {data?.data?.length} items
                                </div>

                                <div className="w-auto">
                                    <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                                        <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                            className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                            <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                                        </a>
                                        <a onClick={() => setPageNo(pageNo - 1)}
                                            style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                            className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>

                                            <img src="img/left-arrow.png" width="20" />
                                        </a>
                                        <span className="d-flex align-items-center">

                                            <select className="pag-select-block"
                                                aria-controls="DataTables_Table_0"
                                                name="pagination" value={pageNo}
                                                onChange={(e) => setPageNo(Number(e.target.value))}
                                            >
                                                {Array.from({ length: totalPage }, (_, index) => (
                                                    <option key={index + 1} value={index + 1} >
                                                        {index + 1}
                                                    </option>
                                                ))}



                                            </select> of {totalPage}
                                        </span>

                                        <a onClick={() => setPageNo(pageNo + 1)}
                                            style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                                            className={`${pageNo < totalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                                            <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                                        </a>


                                        <a onClick={() => setPageNo(totalPage)} style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                                            className={`${pageNo < totalPage ? '' : 'opacity-25'} border-0`}>
                                            <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="pagination row justify-content-between align-items-center">
                                <div className="w-auto">
                                    <div class="dataTables_info dataTables_length">
                                        {pageNo} of {data?.totalPages}{" "}
                                        <label>
                                            Rows per page{" "}
                                            <select
                                                aria-controls="DataTables_Table_0"
                                                name="tableLength" value={input.tableLength}
                                                onChange={handelChange}
                                            >
                                                <option value="10">10</option>
                                                <option value="25" defaultValue={true}>25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className="w-auto">
                                    <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                                        {pageNo > 1 && (<a onClick={() => setPageNo(pageNo - 1)}>
                                            <i className="fa-solid fa-chevron-left"></i>
                                        </a>)}
                                        <span>
                                            <a
                                                className="paginate_button current"
                                                aria-controls="DataTables_Table_0"
                                                data-dt-idx="1"
                                                tabIndex="0"
                                            >
                                                {pageNo}
                                            </a>
                                        </span>
                                        {data && pageNo < data?.totalPages &&
                                            <a onClick={() => setPageNo(pageNo + 1)}>
                                                <i className="fa-solid fa-chevron-right"></i>
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className="promo_modal modal-input-dis"
                show={showModal}
                onHide={() => {
                    setShowModal(false); setFormState({
                        count: "",
                        validTill: getOneYearAheadDate(),
                        isExclusive: false,
                        playerId: "",
                        promoType: "",
                        campaignId: "",
                        maxRedeem: "",
                    })
                }}
                centered
            >
                <Modal.Header
                    closeButton
                    onClick={() =>
                        setFormState({
                            count: "",
                            validTill: getOneYearAheadDate(),
                            isExclusive: false,
                            playerId: "",
                            promoType: "",
                            campaignId: "",
                            maxRedeem: "",
                        })
                    }
                >
                    <Modal.Title>Create Promo Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formCount">
                                    <Form.Label>Count</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="count"
                                        placeholder="Enter count"
                                        value={formState.count}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formValidTill">
                                    <Form.Label>Valid Till</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="validTill"
                                        placeholder="Select date"
                                        value={formState.validTill}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formIsExclusive">
                                    <Form.Label>Is Exclusive</Form.Label>
                                    <div className="d-flex align-items-center gap-3 form-label-input">
                                        <Form.Check
                                            type="radio"
                                            name="isExclusive"
                                            id="isExclusiveYes"
                                            label="Yes"
                                            value={true}
                                            checked={formState.isExclusive === true}
                                            onChange={handleChange}
                                            disabled
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="isExclusive"
                                            id="isExclusiveNo"
                                            label="No"
                                            value={false}
                                            checked={formState.isExclusive === false}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formPlayerId">
                                    <Form.Label>Player ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="playerId"
                                        placeholder="Enter player ID"
                                        value={formState.playerId}
                                        onChange={handleChange}
                                        required
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formPromoType">
                                    <Form.Label>Promo Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="promoType"
                                        placeholder="Enter promo type"
                                        value="vip"
                                        onChange={handleChange}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formCampaignId">
                                    <Form.Label>Campaign ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="campaignId"
                                        placeholder="Enter campaign ID"
                                        onChange={handleChange}
                                        value="1"
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formMaxRedeem">
                                    <Form.Label>Max Redeem</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="maxRedeem"
                                        placeholder="Enter max redeem"
                                        value="1"
                                        onChange={handleChange}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="text-center">
                            <Button type="submit" className="mt-2 btnall mt-3 btn btn-primary">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PromoCode;
