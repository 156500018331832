import React from "react";


const LudoTournament = () => {
    return (
        <>

            <div className="right-sidebar">
                <div className="das-bg-block d-flex">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Ludo Tournament</h1>
                    </div>
                    <div></div>
                </div>
                <div>
                    {/* <div className="w-100 mb-3">
                            <h2 className="subTitleSection px-3">Tournament Details</h2>
                        </div> */}
                    <div className='container-fluid bg px-5'>
                        <div className='row user-select user-selectinput mt-2'>

                            <div className="col-lg-3 mb-2 col-md-3  col-6  col-xs-12  p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Tournament Name</label>
                                    <input type="text" placeholder="" className="w-100" />
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3  col-6 mt-lg-0  col-xs-12 p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Tournament Description</label>
                                    <input type="text" placeholder="" className="w-100" />
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3  col-6 mt-lg-0  col-xs-12 p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Entry Fee</label>
                                    <input type="text" placeholder="Enter Fee" className="w-100" />
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Total Entries</label>
                                    <input type="text" placeholder="Enter Fee" className="w-100" />
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Table Size</label>
                                    <input type="text" placeholder="Enter Fee" className="w-100" />
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Minimum Entries</label>
                                    <input type="text" placeholder="" className="w-100" />
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Registration Start Date</label>
                                    <input type="date" className="w-100" />
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Registration End Date</label>
                                    <input type="date" className="w-100" />
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Tournament Start Date</label>
                                    <input type="date" className="w-100" />
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3 user-select col-6 mt-lg-0  mt-md-0 mt-1 col-xs-12 p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Tournament End Date</label>
                                    <input type="date" className="w-100" />
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3  col-6  col-xs-12  p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Allow Bots</label>
                                    <select name="" id="" className="w-100 ">
                                        <option value="" selected="">
                                            1
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3  col-6  col-xs-12  p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Rake</label>
                                    <select name="" id="" className="w-100 ">
                                        <option value="" selected="">
                                            0
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3  col-6  col-xs-12  p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Prize</label>
                                    <select name="" id="" className="w-100 ">
                                        <option value="" selected="">
                                            0
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3  col-6  col-xs-12  p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Allow Wait List</label>
                                    <select name="" id="" className="w-100 ">
                                        <option value="" selected="">
                                            Select Game
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-2 col-md-3  col-6  col-xs-12  p-1">
                                <div className="form-group">
                                    <label className="text-start d-block mb-1">Prize Pool</label>
                                    <select name="" id="" className="w-100 ">
                                        <option value="" selected="">
                                            0
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-center mt-4">
                            <button type="button" class="btn btn-secondary me-2" >Cancel</button>
                                <button type="button" class="btn btn-success text-white" style={{background:'#2d0512', borderColor:'#2d0512'}}>Save</button>
                            </div>

                        </div>
                    </div>



                </div>
            </div>
        </>
    );
};

export default LudoTournament;