import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import LoadingBar from "react-top-loading-bar";
import Skeleton from "react-loading-skeleton";

const OperatorTurnover = () => {
  //GG
  const [temp, setTemp] = useState([])
  const [data, setData] = useState();
  const [totalPageNum, setTotalPageNum] = useState(0)
  const [input, setInput] = useState({
    limit: "100",
    tableLength: "100",
    searchPlayer: "",
    dateFilter: "today",
    game: "All",
    from: "",
    to: ""
  });
  const [k, setK] = useState(0);
  const [pageNo, setPageNo] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPageItems, setCurrentPageItems] = useState(0)
  const [currentPageItemsStartingFrom, setCurrentPageItemsStartingFrom] = useState(0)

  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.post(process.env.REACT_APP_API_PATH + "operatorTurnover", {
          pageNo: pageNo,
          limit: input.tableLength,
          searchPlayer: input.searchPlayer,
          dateFilter: input.dateFilter,
          game: input.game,
          from: input.from,
          to: input.to
        },
          {
            headers: {
              admin_auth_token: localStorage.getItem("omsToken"),
              opr_auth_token: localStorage.getItem("sessionToken"),
            },
          })
        if (response.status === 200) {
          console.log(response.data)
          setData(response.data)
          setTemp(response.data.data)
          setTotalPageNum(response.data.TotalPage)
          setLoading(false)
          setK(k + 1);
          setTotalItems(response.data.totalItems)
          const currentPageItems = Math.min(pageNo * input.tableLength, response.data.totalItems)
          setCurrentPageItems(currentPageItems)
          const currentPageItemsStartingFrom = input.tableLength * (pageNo - 1) + 1
          setCurrentPageItemsStartingFrom(currentPageItemsStartingFrom)

        }
      }
      catch (err) {
        setLoading(false)
        alert("internal server error")
      }
    }
    fetchData();
  }, [input.dateFilter, input.to, pageNo, count, input.from, input.tableLength])

  useEffect(() => {
    if (input.game === 'All' || k < 2) return;
    console.log('dataaaaa', input.game, k);
    setData(prevData => {
      const newData = { ...prevData };
      newData?.data?.forEach(item => {
        let flag = false
        item.turnover_data.forEach((turnoverItem, key) => {
          if (turnoverItem.game_code === input.game) {

            item.total_credit_amt = turnoverItem.credit_amt;
            item.total_debit_amt = turnoverItem.debit_amt;
            flag = true;

          }
        });
        if (!flag) {
          item.total_credit_amt = 0;
          item.total_debit_amt = 0;
        }
      });
      return newData;
    });
  }, [k])
  const handleChange = (e, game) => {
    if (game) {
    }

    if (e.target.name === 'dateFilter') {
      setPageNo(1)
    }

    setInput({
      ...input,
      [e.target.name]: e.target.value,
      [e.target.dateFilter]: e.target.value
    })
    console.log("LL", e.target.value)
    console.log("LL", input.game)

    if (e.target.value === "All") {
      setCount(count + 1)
    }
    else {
      setData(prevData => {
        const newData = { ...prevData };
        newData.data.forEach(item => {
          let flag = false
          item.turnover_data.forEach((turnoverItem, key) => {
            if (turnoverItem.game_code === e.target.value) {
              console.log("gadafdsme", turnoverItem.game_code)
              item.total_credit_amt = turnoverItem.credit_amt;
              item.total_debit_amt = turnoverItem.debit_amt;
              flag = true;

            }

          });
          if (!flag) {
            item.total_credit_amt = 0;
            item.total_debit_amt = 0;
            console.log("KK");
          }

        });

        console.log("GG", newData)
        return newData;
      });
    }




  }


  let totalBet = 0;
  let totalWin = 0;

  if ((data && data?.data?.length > 0)) {
    for (const entry of data.data) {
      totalBet += entry.total_debit_amt;
      totalWin += entry.total_credit_amt;
    }
  }

  const DateTimeFormat = (inputDate) => {

    var parsedDate = new Date(inputDate);

    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const day = parsedDate.getDate();
    const month = monthNames[parsedDate.getMonth()];
    const year = parsedDate.getFullYear();
    const hours = parsedDate.getHours();
    const minutes = parsedDate.getMinutes();
    const seconds = parsedDate.getSeconds();
    const ampm = hours >= 12 ? 'pm' : 'am';

    const formattedDate = `${day} ${month} ${year}`;

    // console.log(formattedDate);
    return formattedDate
  }

  console.log(input, "inputData")
  const [gameList, setGameList] = useState([]);
  useEffect(() => {
    setLoading(true)
    const fetchGameApi = async () => {
      const response = await axios.get(process.env.REACT_APP_API_PATH + "gameList",
        {
          headers: {
            admin_auth_token: localStorage.getItem("omsToken"),
            opr_auth_token: localStorage.getItem("sessionToken"),
          },
        })
      if (response.status === 200) {

        console.log('setGameList(response.data)', response.data)

        setGameList(response.data)

      }
    }
    fetchGameApi()
  }, [])

  const formatValue = (value) => {
    if (value >= 10000000) {
      return <> {(value / 10000000).toFixed(1) + 'Cr'}</>;
    } else if (value >= 100000) {
      return <> {(value / 100000).toFixed(1) + 'Lac'}</>;
    }

    // else if (value >= 1000) {
    //   return <> ({(value / 1000).toFixed(1) + 'K'})</>;
    // } 
    else {
      return <></>;
    }
  };

  return (
    <div>


      <div className="right-sidebar">
        <div className="das-bg-block w-">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <h1 className="gamehistoryheading title_h1 same_heading text-start">
                  Operator Turnover
                </h1>
              </div>
              <div className="col-md-9">
                <div className="row user-select">
                  <div className="col-md-3">

                  </div>
                  <div className="col-md-3">

                  </div>
                  {/* <div className="col-md-3 form-group mt-3 mt-md-0">
                    <div className="input-group  input-group-sm">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search for...."
                        aria-describedby="basic-addon1"
                        name="searchPlayer"
                        onChange={handleChange}
                        value={input.searchPlayer}
                        style={{ height: "33.5px !important" }}
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                          style={{ padding: "9px !important" }}
                        >
                          <i className="fa-solid fa-magnifying-glass" />
                        </span>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-3 nt-3 mt-md-0">
                    <div className="form-group" >
                      <select name="game" value={input.game} onChange={handleChange}>
                        <option value="All">ALL</option>
                        {
                          gameList && gameList?.data?.map((doc) => (
                            <option value={doc.game_code}>{doc.game_name} ({doc.game_code})</option>
                          ))
                        }

                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 mt-3 mt-md-0">
                    <div className="form-group">
                      <select name="dateFilter" value={input.dateFilter} onChange={(e) => handleChange(e, input.game)} className="w-">
                        <option value="recent" defaultValue>
                          Recent
                        </option>
                        <option value="today">
                          Today
                        </option>
                        <option value="yesterday">Yesterday</option>
                        <option value="current_week">Current Week</option>
                        <option value="last_week">Last Week</option>
                        <option value="current_month">Current Month</option>
                        <option value="last_month">Last Month</option>
                        <option value="last_six_month">Last 6 Month</option>
                        <option value="date_range">Date Range</option>
                      </select>
                    </div>
                    {input.dateFilter === "date_range" && (<div style={{ marginRight: "100px" }} id="target">
                      <div className="date">
                        <p>
                          <label>From</label>
                          <input type="date" id="date" name="from" value={input.from} onChange={(e) => handleChange(e, input.game)} />
                          <label>To</label>
                          <input type="date" id="date" name="to" value={input.to} onChange={(e) => handleChange(e, input.game)} />
                        </p>
                      </div>
                    </div>)}
                  </div>



                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="DataTables_Table_0_wrapper"
          className="dataTables_wrapper no-footer top-table"
        >
          <div className="table-player">
            <table className="table data-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Total Bet</th>
                  <th>Total Win</th>
                  <th> Win/Lose</th>


                  <th>Net (%)</th>
                  <th>RTP (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {loading ? <td><Skeleton /></td> : <td>{(data?.data?.length === 0) ? <>N/A</> : <>{(data?.data[data.data.length - 1]?.updatedAt?.slice(0, 10))} To  {data?.data[0]?.updatedAt?.slice(0, 10)}</>}</td>}
                  {loading ? <td><Skeleton /></td> : <td><span >{totalBet < '100000' ? totalBet.toFixed(2) : formatValue(totalBet)}</span></td>}
                  {loading ? <td><Skeleton /></td> : <td><span >{totalWin < '100000' ? totalWin.toFixed(2) : formatValue(totalWin)}</span></td>}
                  {loading ? <td><Skeleton /></td> : <td><span style={{ color: (totalBet - totalWin).toFixed(2) >= 0 ? 'green' : 'red' }}>
                    {(totalBet - totalWin) < '100000' ? ((totalBet - totalWin).toFixed(2)) : (formatValue(totalBet - totalWin))}
                  </span>
                  </td>}

                  {loading ? <td><Skeleton /></td> : <td  > <span style={{ color: ((totalBet - totalWin) * 100 / (totalBet)).toFixed(2) ? 'green' : 'red' }}>{(totalBet === 0) ? <>0</> : ((totalBet - totalWin) * 100 / (totalBet)).toFixed(2)}%</span></td>}

                  {loading ? <td><Skeleton /></td> : <td><span style={{ color: ((totalWin * 100) / (totalBet)).toFixed(2) >= 0 ? 'green' : 'red' }}>{(totalWin) > 0 ? ((totalWin * 100) / (totalBet)).toFixed(2) : 0}%  </span></td>}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          id="DataTables_Table_0_wrapper"
          className="dataTables_wrapper no-footer"
        >
          <div className="table-player">
            <div className="table-list-mb">
              <table className="table data-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Currency</th>
                    <th>Total Bet</th>
                    <th>Total Win</th>
                    <th>Win/Loss</th>

                    <th>Net (%)</th>
                    <th>RTP (%) </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.data?.map((doc) => (
                    <tr>
                      {loading ? <td><Skeleton /></td> : <td>{doc?.updatedAt && DateTimeFormat(doc.updatedAt)}</td>}
                      {loading ? <td><Skeleton /></td> : <td>{doc?.summary_operator_currency?.operator_currency}</td>}
                      {loading ? <td><Skeleton /></td> : <td className="text-end"><span >{doc?.total_debit_amt < '100000' ? doc?.total_debit_amt?.toFixed(2) : formatValue(doc?.total_debit_amt)}</span></td>}
                      {loading ? <td><Skeleton /></td> : <td className="text-end"><span >{doc?.total_credit_amt < '100000' ? doc?.total_credit_amt?.toFixed(2) : formatValue(doc?.total_credit_amt)}</span></td>}
                      {loading ? <td><Skeleton /></td> : <td className="text-end">
                        <span style={{ color: (doc.total_debit_amt - doc.total_credit_amt)?.toFixed(2) >= 0 ? 'green' : 'red' }}>
                          {(doc.total_debit_amt - doc.total_credit_amt) < '100000' ? ((doc.total_debit_amt - doc.total_credit_amt)?.toFixed(2)) : (formatValue(doc.total_debit_amt - doc.total_credit_amt))}
                        </span>
                      </td>
                      }


                      {loading ? <td><Skeleton /></td> : <td className="text-end"><span style={{ color: ((doc.total_debit_amt - doc.total_credit_amt) * 100 / (doc.total_debit_amt))?.toFixed(2) >= 0 ? 'green' : 'red' }}>{(!((doc.total_debit_amt - doc.total_credit_amt) === 0)) ? ((doc.total_debit_amt - doc.total_credit_amt) * 100 / (doc.total_debit_amt))?.toFixed(2) : <>0 </>} %</span></td>}
                      {loading ? <td><Skeleton /></td> : (doc?.total_debit_amt === 0) ? <td className="text-end">  <span style={{ color: "red" }}>0 %</span> </td> : <td><span style={{ color: ((doc?.total_credit_amt) * 100 / (doc.total_debit_amt))?.toFixed(2) >= 0 ? 'green' : 'red' }}>{((doc.total_credit_amt) * 100 / (doc?.total_debit_amt))?.toFixed(2)}%</span></td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="pagination pagination-text row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                  <select
                    aria-controls="DataTables_Table_0"
                    name="tableLength" value={input.tableLength}
                    onChange={handleChange}
                    className="pag-select-block me-1"
                  >
                    <option value="10">10</option>
                    <option value="25" defaultValue={true}>25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select> Records per page
                </div>
              </div>

              <div className="w-auto">
              Showing {currentPageItemsStartingFrom}-{currentPageItems}{" "}of {totalItems} items
              </div>

              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                  <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                  <a onClick={() => setPageNo(pageNo - 1)}
                    style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    {/* <i className="fa-solid fa-chevron-left"></i> Previous */}
                    <img src="img/left-arrow.png" width="20" />
                  </a>
                  <span className="d-flex align-items-center">

                    <select className="pag-select-block"
                      aria-controls="DataTables_Table_0"
                      name="pagination" value={pageNo}
                      onChange={(e) => setPageNo(Number(e.target.value))}
                    >
                      {Array.from({ length: totalPageNum }, (_, index) => (
                        <option key={index + 1} value={index + 1} >
                          {index + 1}
                        </option>
                      ))}



                    </select> of {data?.TotalPage}
                  </span>

                  <a onClick={() => setPageNo(pageNo + 1)}
                    style={{ pointerEvents: `${data && pageNo < data?.TotalPage ? 'auto' : 'none'}` }}
                    className={`${data && pageNo < data?.TotalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                    <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                  </a>
                  {/* } */}

                  <a onClick={() => setPageNo(data?.TotalPage)} style={{ pointerEvents: `${data && pageNo < data?.TotalPage ? 'auto' : 'none'}` }}
                    className={`${data && pageNo < data?.TotalPage ? '' : 'opacity-25'} border-0`}>

                    <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                </div>
              </div>
            </div>

            {/* <div className="pagination row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length">
                  {pageNo} of {data && data.TotalPage}{" "}
                  <label>
                    Rows per page{" "}
                    <select
                      aria-controls="DataTables_Table_0"
                      name="tableLength"
                      value={input.tableLength}
                      onChange={handleChange}
                    >
                      <option value="10">10</option>
                      <option value="25" defaultValue={true}>
                        25
                      </option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                  {pageNo > 1 && (
                    <a onClick={() => setPageNo(pageNo - 1)}>
                      <i className="fa-solid fa-chevron-left"></i>
                    </a>
                  )}
                  <span>
                    <a
                      className="paginate_button current"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="1"
                      tabIndex="0"
                    >
                      {pageNo}
                    </a>
                  </span>
                  {data && pageNo < data.TotalPage && (
                    <a onClick={() => setPageNo(pageNo + 1)}>
                      <i className="fa-solid fa-chevron-right"></i>
                    </a>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperatorTurnover;
