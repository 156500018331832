import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import LoadingBar from "react-top-loading-bar";
import Skeleton from "react-loading-skeleton";
import moment from 'moment';
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const CreateRoleNameModal = ({ show, setShow }) => {
    const [permissions, setPermissions] = useState([]);
    const [roleNameCreate, setRoleNameCreate] = useState("");
    const [permissionError, setPermissionError] = useState("");
    const [selectedPermissions, setSelectedPermissions] = useState({});
    const [data, setData] = useState([]);
    const [roleName, setRoleName] = useState('base_role');
    const [roleNameDisabled, setRoleNameDisabled] = useState(false);

    const handleClose = () => {
        setShow(false);
        setRoleNameCreate('');
        setSelectedPermissions({});
    };
    const handleSaveRoleName = () => {
        setRoleNameDisabled((prevVisible) => !prevVisible);
    }



    const getAdminPermission = async () => {
        try {

            const res = await axios.get(`${process.env.REACT_APP_API_PATH}getAdminPermission`, {
                params: {
                    role_name: roleName,
                },
                headers: {
                    opr_auth_token: localStorage.getItem("sessionToken"),
                }
            });


            setPermissions(res?.data?.permissions || []);

            console.log(res?.data, 'getAdminPermission');
        } catch (error) {
            console.error("Error fetching role data:", error);

        }
    };



    useEffect(() => {
        getAdminPermission();
    }, [roleName])









    const handleSelectedPermission = (id, value) => {
        setSelectedPermissions((prev) => {
            const currentPermissions = prev[id] || [];

            if (currentPermissions.includes(value)) {

                const updatedPermissions = currentPermissions.filter((perm) => perm !== value);


                if (updatedPermissions.length === 0) {
                    const { [id]: _, ...rest } = prev;
                    return rest;
                }


                return {
                    ...prev,
                    [id]: updatedPermissions
                };
            } else {

                const updatedPermissions = [...currentPermissions, value];
                return {
                    ...prev,
                    [id]: updatedPermissions
                };
            }
        });
    };


    const handleSelectAllPermissions = (menuId, permissionTypes) => {
        setSelectedPermissions((prev) => {
            const currentPermissions = prev[menuId] || [];
            if (currentPermissions.length === permissionTypes.length) {

                return { ...prev, [menuId]: [] };
            } else {

                return { ...prev, [menuId]: [...permissionTypes] };
            }
        });
    };


    const handleCreatePermission = async (e) => {
        e.preventDefault();

        console.log(selectedPermissions, 'selectedPermissionsselectedPermissions');


        if (!roleNameCreate) {
            setPermissionError("All fields are required");
            return;
        }

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_PATH}createAdminPermission`,
                {
                    role_name: roleNameCreate,
                    menu_ids: selectedPermissions,
                },
                {
                    headers: {
                        opr_auth_token: localStorage.getItem("sessionToken"),
                    },
                }
            );

            if (res?.data?.message) {
                alert(res?.data?.message);
                setShow(false);
                setRoleNameCreate('');
                setSelectedPermissions({});

            } else {
                setPermissionError(res?.data?.message);
                setShow(false);

            }

        } catch (error) {
            console.error("Error updating admin account:", error);
            setPermissionError(error?.response?.data?.message);
        }
    };

    return (
        <>


            <Modal show={show} onHide={() => setShow(false)} size="xl" scrollable>
                <Modal.Header closeButton className="py-2">
                    <Modal.Title className="w-100 text-center">Create Role Name</Modal.Title>
                </Modal.Header>
                <Modal.Body className="position-relative pb-0">
                    <Form onSubmit={handleCreatePermission} className="">
                        <Row>

                            <Col md="6" lg="4" className="mx-auto">
                                <Form.Group className="mb-3 d-flex gap-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={roleNameCreate}
                                        onChange={(e) => setRoleNameCreate(e.target.value)}
                                        placeholder="Enter Role Name"
                                    //readOnly={roleNameDisabled}
                                    />
                                    {/* <Button
                                        variant="primary"
                                        className="mt-0 change_password_btn btn btn-primary disble-btn-all"
                                        type="button"
                                        onClick={handleSaveRoleName}
                                        disabled={roleNameCreate.length <= 3}
                                    >
                                        {roleNameDisabled ? 'Edit' : 'Save'}
                                    </Button> */}
                                </Form.Group>

                            </Col>
                            <Col md="12">
                                <Form.Group className="mb-3">
                                    <div className="position-relative px-3">
                                        {/* <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Menu Title</th>
                                                    <th>Permission</th>
                                                </tr>
                                            </thead>
                                            <tbody> */}
                                            <div className="row role-per-table">
                                            <div className="col-3 text-center">
                                                <strong className="py-2 d-block text-center">Menu Title</strong>
                                            </div>
                                            <div className="col-3 text-center">
                                                <strong className="py-2 d-block text-center">Permission</strong>
                                            </div>
                                            <div className="col-3 text-center">
                                                <strong className="py-2 d-block text-center">Menu Title</strong>
                                            </div>
                                            <div className="col-3 text-center">
                                                <strong className="py-2 d-block text-center">Permission</strong>
                                            </div>
                                                {permissions.map((permission) => (
                                                    // <tr key={permission.menu_id}>
                                                    <>
                                                        <div className="col-3">{permission.menu_title}</div>
                                                        <div className="col-3">
                                                            <div className="d-flex align-items-center gap-1">

                                                                {permission.permission_type.map((perm) => (
                                                                    <label key={`${permission?.menu_id}-${perm}`} className="d-flex align-items-center gap-1">
                                                                        <input
                                                                            type="checkbox"
                                                                            value={perm}
                                                                            checked={selectedPermissions[permission?.menu_id]?.includes(perm)}
                                                                            onChange={() => handleSelectedPermission(permission?.menu_id, perm)}
                                                                        />
                                                                        {perm}
                                                                    </label>
                                                                ))}
                                                                <label className="me-2 d-flex align-items-center gap-1">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedPermissions[permission.menu_id]?.length === permission?.permission_type?.length}
                                                                        onChange={() => handleSelectAllPermissions(permission?.menu_id, permission?.permission_type)}
                                                                    />
                                                                    All
                                                                </label>
                                                            </div>
                                                        </div>

                                                    {/* // </tr> */}
                                                        </>
                                                ))}
                                                </div>
                                            {/* </tbody>
                                        </table> */}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="w-100 text-center position-sticky py-2 bg-white border-top bottom-0 start-0 end-0">
                            <Button
                                variant="primary"
                                className="mt-0 change_password_btn btn btn-primary disble-btn-all"
                                type="submit"
                                disabled={!roleNameCreate || Object.values(selectedPermissions).every(permissions => permissions?.length === 0)}

                            >
                                Submit
                            </Button>
                            {permissionError && <p style={{ color: 'red' }} className="text-center m-0">{permissionError}</p>}
                        </div>
                    </Form>


                </Modal.Body>
            </Modal>



        </>
    );
};

export default CreateRoleNameModal;
