import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import LoadingBar from "react-top-loading-bar";
import Skeleton from "react-loading-skeleton";
import moment from 'moment';
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import CreateRoleNameModal from "../Components/CreateRoleNameModal";

const CreateAdminAccount = () => {
    const [roleID, setRoleID] = useState([]);
    const [selectRoleName, setSelectRoleName] = useState();
    const [selectMenuID, setSelectMenuID] = useState([]);
    const [show, setShow] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [roleNameCreate, setRoleNameCreate] = useState("");
    const [permissionError, setPermissionError] = useState("");
    const [input, setInput] = useState({
        login_id: '',
        password: '',
        role_name: '',
        confirm_password: '',
        opr_name: ''
    });
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const sessionToken = localStorage.getItem("sessionToken");
    


    const handleChange = (e) => {
        const { name, value } = e?.target;
        setInput({
            ...input,
            [name]: value
        });
    };

    const handleSubmit = async () => {
        setLoad(true);
        setError('');


        if (!input.login_id || !input.password || !input.role_name) {
            setError('All fields are required');
            setLoad(false);
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_PATH}createAdminAccount`, {
                login_id: input.login_id,
                password: input.password,
                role_name: input.role_name,
                opr_name: input.opr_name,
            }, {
                headers: {
                    opr_auth_token: sessionToken
                }
            });

            alert(res?.data?.message);
          setInput({
                login_id: '',
                password: '',
                role_name: '',
                confirm_password: '',
                opr_name: ''
            })
            console.log(res?.data);

        } catch (error) {
            const errorMessage = error?.response?.data?.message || "An error occurred while creating the admin account.";
            console.error("Error creating admin account:", error);
            setError(errorMessage);
        } finally {
            setLoad(false);
        }
    };


    const getRoleName = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}getRoleIDs`, {
                headers: {
                    opr_auth_token: sessionToken
                }
            });

            console.log(res?.data);
            setRoleID(res?.data.data);
        } catch (error) {
            console.error("Error fetching role data:", error);
            setError("Failed to fetch role data. Please try again.");
        }
    };

    useEffect(() => {
        getRoleName();
    }, [show]);

    const getAdminPermission = async () => {
        try {

            if (!selectRoleName) {
                console.log("Please select a role name.");
                return;
            }

            const res = await axios.get(`${process.env.REACT_APP_API_PATH}getAdminPermission`, {
                params: {
                    role_name: selectRoleName,
                },
                headers: {
                    opr_auth_token: localStorage.getItem("sessionToken"),
                }
            });


            setPermissions(res?.data?.data?.[0]?.permissions || []);

            console.log(res?.data, 'getAdminPermission');
        } catch (error) {
            console.error("Error fetching role data:", error);
            setError("Failed to fetch role data. Please try again.");
        }
    };



    useEffect(() => {
        getAdminPermission();
    }, [selectRoleName])

    const handleMenuSelection = (menuId) => {
        setSelectMenuID((prevSelected) => {
            if (prevSelected.includes(menuId)) {
                return prevSelected.filter((id) => id !== menuId);
            } else {
                return [...prevSelected, menuId];
            }
        });
    };

    const toggleDropdown = () => {
        setDropdownVisible((prevVisible) => !prevVisible);
    };

    
    


    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-sm-8 fund_transfer_form_block">
                    <ul className="nav nav-tabs new_tabs border-0" style={{ marginTop: 30 }}>
                        <li className="no_select active">
                            <a
                                data-bs-toggle="tab"
                                href="#home"
                                id="deposit_change"
                                className="tab_color active"
                                style={{
                                    background: "rgba(42, 53, 0, 0.44)",
                                    transition: "all 1s ease 0s",
                                    color: "rgb(255, 255, 255)"
                                }}
                                aria-expanded="true"
                            >
                                Create Subadmin Account
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div id="home" className="tab-pane fade in active show">
                            <div className="" style={{ padding: 0 }}>
                                <fieldset
                                    className="no_border_field p-2 fund_transfer_form"
                                    style={{ border: "1px solid #cecece", borderTop: "none" }}
                                >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-5  all_set">
                                            <div className="form-group">
                                                <label htmlFor="">Login ID</label>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group input-group-sm position-relative">
                                                    <input
                                                        type="text"
                                                        className="search form-control input-sm ui-autocomplete-input"
                                                        placeholder="Login ID"
                                                        name="login_id"
                                                        value={input.login_id}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-5 all_set">
                                            <div className="form-group">
                                                <label htmlFor="">Subadmin Name</label>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group input-group-sm position-relative">
                                                    <input
                                                        type="text"
                                                        className="search form-control input-sm ui-autocomplete-input"
                                                        placeholder="Subadmin Name"
                                                        name="opr_name"
                                                        value={input.opr_name}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <div className="col-md-6 col-sm-5  all_set">
                                            <div className="form-group">
                                                <label htmlFor="">Password</label>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group input-group-sm position-relative">
                                                    <input
                                                        type="password"
                                                        className="search form-control input-sm ui-autocomplete-input"
                                                        placeholder="Password"
                                                        name="password"
                                                        value={input.password}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6  responsive-clear">
                                            <div className="form-group">
                                                <label htmlFor="">
                                                    Role Name
                                                </label>
                                            </div>
                                            <div className="form-group position-relative">
                                                <select
                                                    className="form-control"
                                                    style={{
                                                        minHeight: '35px',
                                                        border: '1px solid #aeaeae',
                                                        color: '#333',
                                                        marginBottom: '8px',
                                                        borderRadius: '0'
                                                    }}
                                                    value={input.role_name}
                                                    onChange={handleChange}
                                                    name="role_name"
                                                >
                                                    <option value="">Select Role Name</option>
                                                    {roleID?.map((role, idx) => (
                                                        <option key={idx} value={role?.role_name}>
                                                            {role?.role_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <p className="m-0 text-end position-relative">
                                                    <button className="bg-transparent border-0 position-absolute end-0"
                                                    onClick={() => setShow(true)}
                                                    style={{
                                                        fontSize:'12px',
                                                        color:'blue',
                                                        bottom:'-10px'
                                                    }}>Create New Role Name</button>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-5  all_set">
                                            <div className="form-group">
                                                <label htmlFor="">Confirm Password</label>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group input-group-sm position-relative">
                                                    <input
                                                        type="password"
                                                        className="search form-control input-sm ui-autocomplete-input"
                                                        placeholder="Password"
                                                        name="confirm_password"
                                                        value={input.confirm_password}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

    
                                        <div className="col-sm-12 mt-2">
                                            <div className="border-box col-sm-12 text-center email">
                                                <div className="buttons m-t-1">
                                                    <button
                                                        type="button"
                                                        className="btn btn-warning my_color disble-btn-all"
                                                        onClick={handleSubmit}
                                                        disabled={input.password != input.confirm_password}
                                                    >
                                                        {load ? <Loader /> : 'Create Account'}
                                                    </button>
                                                   
                                                </div>
                                                {error && <p className="text-center mb-0 mt-2" style={{ color: 'red' }}>{error}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CreateRoleNameModal show={show} setShow={setShow} />

        </div>
    );
};

export default CreateAdminAccount;
