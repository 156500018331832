import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import LoadingBar from "react-top-loading-bar";
import Skeleton from "react-loading-skeleton";
import moment from 'moment';



const AffiliateTransactions = () => {

    const [data, setData] = useState();
    const [input, setInput] = useState({
        limit: "100",
        tableLength: "100",
        searchAffiliate: "",
        dateFilter: "recent",
        game: "",
        from: "",
        to: "",
        status: 0,
        remark: "Admin transfer"
    });
    const [pageNo, setPageNo] = useState(1);
    const [gameList, setGameList] = useState()
    const [loading, setLoading] = useState(false)
    const currentDate = new Date().toISOString().slice(0, 10);
    const [Search, setSearch] = useState("");




    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)

            try {
                const response = await axios.post(process.env.REACT_APP_API_PATH + "aff-transactions", {
                    opr_id: localStorage.getItem('opr_id'),
                    pageNo: pageNo,
                    limit: input.tableLength,
                    searchAffiliate: input.searchAffiliate,
                    dateFilter: input.dateFilter,
                    game: input.game,
                    from: input.from,
                    to: input.to,
                    status: Number(input.status),
                    remark: input.remark
                },
                    {
                        headers: {
                            admin_auth_token: localStorage.getItem("omsToken"),
                            opr_auth_token: localStorage.getItem("sessionToken"),
                        },
                    })

                console.log("🚀 ~ fetchData ~ Affiliate transaction response:", response.data.data)

                setData(response.data)


                setLoading(false)


            }
            catch (err) {
                alert("internal server error")
                setLoading(false)
            }
        }
        fetchData();
    }, [input, pageNo])


    const combineTurnoverData = (data) => {
        return data.map(d => {
            const combinedTurnoverData = {};

            d.turnover_data.forEach(entry => {
                const gameCode = entry.game_code;
                if (!combinedTurnoverData[gameCode]) {
                    combinedTurnoverData[gameCode] = {
                        ...entry,
                        debit_amt: 0,
                        credit_amt: 0,
                        rollback_amt: 0,
                        debit_count: 0,
                        credit_count: 0,
                        rollback_count: 0,
                        player_currency_data: {
                            ...entry.player_currency_data,
                            debit_amt: 0,
                            credit_amt: 0,
                            rollback_amt: 0
                        },
                        operator_currency_data: {
                            ...entry.operator_currency_data,
                            debit_amt: 0,
                            credit_amt: 0,
                            rollback_amt: 0
                        }
                    };
                }

                combinedTurnoverData[gameCode].debit_amt += entry.debit_amt;
                combinedTurnoverData[gameCode].credit_amt += entry.credit_amt;
                combinedTurnoverData[gameCode].rollback_amt += entry.rollback_amt;
                combinedTurnoverData[gameCode].debit_count += entry.debit_count;
                combinedTurnoverData[gameCode].credit_count += entry.credit_count;
                combinedTurnoverData[gameCode].rollback_count += entry.rollback_count;

                combinedTurnoverData[gameCode].player_currency_data.debit_amt += entry.player_currency_data.debit_amt;
                combinedTurnoverData[gameCode].player_currency_data.credit_amt += entry.player_currency_data.credit_amt;
                combinedTurnoverData[gameCode].player_currency_data.rollback_amt += entry.player_currency_data.rollback_amt;

                combinedTurnoverData[gameCode].operator_currency_data.debit_amt += entry.operator_currency_data.debit_amt;
                combinedTurnoverData[gameCode].operator_currency_data.credit_amt += entry.operator_currency_data.credit_amt;
                combinedTurnoverData[gameCode].operator_currency_data.rollback_amt += entry.operator_currency_data.rollback_amt;
            });

            return {
                ...d,
                turnover_data: Object.values(combinedTurnoverData)
            };
        });
    };
    const handleChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    const handleSearch = (e) => {
        console.log("GG", e)
        setInput({
            ...input,
            searchAffiliate: e
        })
    }
    console.log(input, "inputData")

    let totalBet = 0;
    let totalWin = 0;

    if ((input?.game === '' && data && data?.data?.length > 0)) {
        for (const entry of data?.data) {
            totalBet += entry?.total_debit_amount;
            totalWin += entry?.total_credit_amount;
        }
    }
    else if (input?.game && data && data?.length > 0) {
        for (const entry of data) {
            for (const turnoverData of entry?.turnover_data) {
                if (turnoverData.game_code === input.game) {
                    totalBet += turnoverData?.debit_amt;
                    totalWin += turnoverData?.credit_amt;
                } else {
                    totalBet += 0;
                    totalWin += 0;
                }
            }
        }
    }

    // const DateTimeFormat = (inputDate) => {

    //     var parsedDate = new Date(inputDate);

    //     const monthNames = [
    //         "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    //     ];

    //     const day = parsedDate.getDate();
    //     const month = monthNames[parsedDate.getMonth()];
    //     const year = parsedDate.getFullYear();
    //     const hours = parsedDate.getHours();
    //     const minutes = parsedDate.getMinutes();
    //     const seconds = parsedDate.getSeconds();
    //     const ampm = hours >= 12 ? 'pm' : 'am';

    //     const formattedDate = `${day} ${month} ${year}, ${hours}:${minutes}:${seconds} ${ampm}`;

    //     // console.log(formattedDate);
    //     return formattedDate
    // }

    const DateTimeFormat = (isoString) => {

        const date = new Date(isoString);

        // Extract UTC date components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');

        // Format as "YYYY-MM-DD HH:mm:ss"
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const getDateRange = (dateFilter) => {
        const today = moment();
        let fromDate, toDate;

        switch (dateFilter) {
            case 'today':
                fromDate = toDate = today;
                break;
            case 'yesterday':
                fromDate = toDate = today.subtract(1, 'days');
                break;
            case 'current_week':
                fromDate = today.clone().startOf('week');
                toDate = today.clone().endOf('week');
                break;
            case 'last_week':
                fromDate = today.clone().subtract(1, 'weeks').startOf('week');
                toDate = today.clone().subtract(1, 'weeks').endOf('week');
                break;
            case 'current_month':
                fromDate = today.clone().startOf('month');
                toDate = today.clone().endOf('month');
                break;
            case 'last_month':
                fromDate = today.clone().subtract(1, 'months').startOf('month');
                toDate = today.clone().subtract(1, 'months').endOf('month');
                break;
            case 'last_six_month':
                fromDate = today.clone().subtract(6, 'months').startOf('month');
                toDate = today.clone().endOf('month');
                break;
            case 'recent':
                fromDate = moment('2000-02-01');
                toDate = today;
                break;
            case 'date_range':
                fromDate = moment(input.from); // Assuming input.from and input.to are available in the scope
                toDate = moment(input.to);
                break;
            default:
                fromDate = toDate = null;
        }

        return { fromDate, toDate };
    };

    const showDate = (dateFilter) => {
        const { fromDate, toDate } = getDateRange(dateFilter);

        if (fromDate && toDate) {
            return `${fromDate.format('YYYY-MM-DD')} To ${toDate.format('YYYY-MM-DD')}`;
        } else {
            // Handle cases where specific date ranges are not applicable
            switch (dateFilter) {
                case 'today':
                    return moment().format('YYYY-MM-DD');
                case 'yesterday':
                    return moment().subtract(1, 'days').format('YYYY-MM-DD');
                case 'current_week':
                    return `${moment().startOf('week').format('YYYY-MM-DD')} To ${moment().endOf('week').format('YYYY-MM-DD')}`;
                case 'last_week':
                    return `${moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')} To ${moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')}`;
                case 'current_month':
                    return `${moment().startOf('month').format('YYYY-MM-DD')} To ${moment().endOf('month').format('YYYY-MM-DD')}`;
                case 'last_month':
                    return `${moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')} To ${moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')}`;
                case 'last_six_month':
                    return `${moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD')} To ${moment().format('YYYY-MM-DD')}`;
                case 'recent':
                    return `2000-02-01 To ${moment().format('YYYY-MM-DD')}`;
                default:
                    return 'N/A';
            }
        }
    };

    const formatValue = (value) => {
        if (value >= 10000000) {
            return <> ({(value / 10000000).toFixed(1) + 'Cr'})</>;
        } else if (value >= 100000) {
            return <> ({(value / 100000).toFixed(1) + 'Lac'})</>;
        }
        // else if (value >= 1000) {
        //   return <> ({(value / 1000).toFixed(1) + 'K'})</>;
        // } 
        else {
            return <></>;
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                handleSearch(Search);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [Search]);



    return (
        <div>
            {/* <Header />
      <div className="full-page">
        <LeftSideBar indexx={0}/> */}

            <div className="right-sidebar">
                <div className="das-bg-block w-">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3">
                                <h1 className="gamehistoryheading title_h1 same_heading text-start">
                                    Affiliate Transaction
                                </h1>
                            </div>
                            <div className="col-md-9">
                                <div className="row user-select">
                                    <div className="col-md-3">
                                        {/* <div className="form-group">
                        <select>
                          <option value={0} selected="">
                            All Agent
                          </option>
                        </select>
                      </div> */}
                                    </div>
                                    <div className="col-md-3 nt-3 mt-md-0">
                                        <div className="form-group" name="remark" value={input.game} onChange={handleChange}>
                                            <select name="remark" value={input?.remark} onChange={handleChange} className="w-">


                                                <option value={"Admin transfer"} defaultValue>Admin to Affiliate</option>
                                                <option value={"Affiliate to Affiliate Transfer"}>Affiliate To Affiliate</option>
                                                <option value={"Claimed by Affiliate from Player"}>Affiliate Claim From player</option>
                                                <option value={"Affiliate to Player Transfer"}>Affiliate To Player</option>



                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3 mt-3 mt-md-0">
                                        <div className="form-group">
                                            <select name="dateFilter" value={input?.dateFilter} onChange={handleChange} className="w-">
                                                <option value="recent" defaultValue>
                                                    Recent
                                                </option>
                                                <option value="today">
                                                    Today
                                                </option>
                                                <option value="yesterday">Yesterday</option>
                                                <option value="current_week">Current Week</option>
                                                <option value="last_week">Last Week</option>
                                                <option value="current_month">Current Month</option>
                                                <option value="last_month">Last Month</option>
                                                <option value="last_six_month">Last 6 Month</option>
                                                <option value="date_range">Date Range</option>
                                            </select>
                                        </div>
                                        {input.dateFilter === "date_range" && (<div id="target">
                                            <div className="date">
                                                <p>
                                                    <label>From</label>
                                                    <input type="date" id="date" name="from" value={input?.from} onChange={handleChange} />
                                                    <label>To</label>
                                                    <input type="date" id="date" name="to" value={input?.to} onChange={handleChange} />
                                                </p>
                                            </div>
                                        </div>)}
                                    </div> */}

                                    <div className="col-md-3 form-group mt-3 mt-md-0">
                                        <div className="form-group">
                                            <select name="status" value={input?.status} onChange={handleChange} className="w-">
                                                <option value={0} defaultValue>
                                                    All
                                                </option>

                                                <option value={1}>Send</option>
                                                <option value={2}>Claim</option>

                                            </select>
                                        </div>

                                        {input.dateFilter === "date_range" && (<div id="target">
                                            <div className="date">
                                                <p>
                                                    <label>From</label>
                                                    <input type="date" id="date" name="from" value={input?.from} onChange={handleChange} />
                                                    <label>To</label>
                                                    <input type="date" id="date" name="to" value={input?.to} onChange={handleChange} />
                                                </p>
                                            </div>
                                        </div>)}
                                    </div>

                                    <div className="col-md-3 form-group mt-3 mt-md-0">
                                        <div className="input-group  input-group-sm">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search for...."
                                                aria-describedby="basic-addon1"
                                                name="searchAffiliate"
                                                // onChange={handleChange}
                                                // value={input.searchPlayer}
                                                onChange={(e) => setSearch(e.target.value)}
                                                value={Search}
                                                style={{ height: "33.5px !important" }}
                                            />
                                            <div className="input-group-prepend">
                                                <span
                                                    className="input-group-text"
                                                    id="basic-addon1"
                                                    style={{ padding: "9px !important" }}
                                                >
                                                    <i className="fa-solid fa-magnifying-glass" onClick={() => handleSearch(Search)} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer top-table"
                >
                    {/* <div className="table-player">
                        <table className="table data-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Total Bet</th>
                                    <th>Total Win</th>
                                    <th> Win/Lose</th>
                                   
                                    <th>Net (%)</th>
                                    <th>RTP (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                {data && (<tr>
                  {loading ? <td><Skeleton /></td> : <td>{showDate(input.dateFilter)} </td>}
                  {loading ? <td><Skeleton /></td> : <td> <span >{totalBet?.toFixed(2)}{formatValue(totalBet)}</span></td>}
                  {loading ? <td><Skeleton /></td> : <td> <span >{totalWin?.toFixed(2)}{formatValue(totalWin)}</span></td>}
                  {loading ? <td><Skeleton /></td> : <td> <span style={{ color: (totalBet - totalWin).toFixed(2) >= 0 ? 'green' : 'red' }}> {(totalBet - totalWin).toFixed(2)} </span></td>}

                  {loading ? <td><Skeleton /></td> : <td><span style={{ color: ((totalWin * 100) / (totalBet)).toFixed(2) >= 0 ? 'green' : 'red' }}>{(totalWin) > 0 ? ((totalWin * 100) / (totalBet)).toFixed(2) : 0}%  </span></td>}

                  {loading ? <td><Skeleton /></td> : <td><span style={{ color: ((totalWin * 100) / (totalBet)).toFixed(2) >= 0 ? 'green' : 'red' }}>{(totalWin) > 0 ? ((totalWin * 100) / (totalBet)).toFixed(2) : 0}%  </span></td>}

                </tr>)}
                
              </tbody>
                        </table>
                    </div> */}
                </div>
                <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer"
                >
                    <div className="table-player">
                        <table className="table data-table">
                            <thead>
                                {(input.remark === 'Admin transfer') && <tr>

                                    <th>Date</th>
                                    <th>aff Name</th>
                                    <th>Affiliate Code</th>
                                    <th>Transaction Type</th>
                                    <th>Opening Balance</th>
                                    <th>Amount</th>
                                    <th>Closing Balance</th>
                                    <th>Remark</th>

                                </tr>}
                                {(input.remark === 'Claimed by Affiliate from Player') && <tr>

                                    <th>Date</th>
                                    <th>Claimed by</th>
                                    <th>Player Code</th>
                                    <th>Transaction Type</th>
                                    <th>Affiliate OB</th>
                                    <th>Affiliate CB</th>
                                    <th>Amount</th>
                                    <th>Player OB</th>
                                    <th>Player CB</th>

                                    <th>Remark</th>

                                </tr>}
                                {(input.remark === 'Affiliate to Player Transfer') && <tr>

                                    <th>Date</th>
                                    <th>Sender Name</th>
                                    <th>Player Code</th>
                                    <th>Transaction Type</th>
                                    <th>Sender OB</th>
                                    <th>Sender CB</th>
                                    <th>Amount</th>
                                    <th>Player OB</th>
                                    <th>Player CB</th>

                                    <th>Remark</th>

                                </tr>}     {(input.remark === 'Affiliate to Affiliate Transfer') && <tr>

                                    <th>Date</th>
                                    <th>Sender Name</th>
                                    <th>Receiver Name</th>
                                    <th>Transaction Type</th>
                                    <th>Sender OB</th>
                                    <th>Sender CB</th>
                                    <th>Amount</th>
                                    <th>Receiver OB</th>
                                    <th>Receiver CB</th>

                                    <th>Remark</th>

                                </tr>}

                            </thead>
                            <tbody>
                                {data?.data?.length === 0 && <tr> <td colSpan={8}>No data!</td></tr>}
                                {data?.data?.length > 0 && data?.data?.map((doc, index) => (


                                    <tr key={index}>

                                        {(input.remark === 'Admin transfer') && <>
                                            {loading ? <td><Skeleton /></td> : <td><span >
                                                {/* {moment(doc?.createdAt).format("DD/MM/YYYY HH-mm-ss")} */}
                                                {DateTimeFormat(doc?.createdAt)}
                                            </span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{doc?.receiver_name}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{doc?.receiver_affiliate_code}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{(doc?.transaction_type === 1) ? <>send</> : <>claim</>}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.receiver_opening_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{doc?.amount}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.receiver_closing_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.remark}</span></td>}
                                        </>}
                                        {(input.remark === 'Claimed by Affiliate from Player') && <>
                                            {loading ? <td><Skeleton /></td> : <td><span >
                                                {/* {moment(doc?.createdAt).format("DD/MM/YYYY HH-mm-ss")} */}
                                                {DateTimeFormat(doc?.createdAt)}
                                            </span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{doc?.sender_name}({doc?.sender_affiliate_code})</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{doc?.receiver_player_code}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{(doc?.transaction_type === 1) ? <>send</> : <>claim</>}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.sender_opening_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.sender_closing_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><strong>{doc?.amount}</strong></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.receiver_opening_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.receiver_closing_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.remark}</span></td>}
                                        </>}
                                        {(input.remark === 'Affiliate to Player Transfer') && <>
                                            {loading ? <td><Skeleton /></td> : <td><span >
                                                {/* {moment(doc?.createdAt).format("DD/MM/YYYY HH-mm-ss")} */}
                                                {DateTimeFormat(doc?.createdAt)}
                                            </span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{doc?.sender_name}({doc?.sender_affiliate_code})</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{doc?.receiver_player_code}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{(doc?.transaction_type === 1) ? <>send</> : <>claim</>}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.sender_opening_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.sender_closing_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><strong>{doc?.amount}</strong></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.receiver_opening_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.receiver_closing_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.remark}</span></td>}
                                        </>}
                                        {(input.remark === 'Affiliate to Affiliate Transfer') && <>
                                            {loading ? <td><Skeleton /></td> : <td><span >
                                                {/* {moment(doc?.createdAt).format("DD/MM/YYYY HH-mm-ss")} */}
                                                {DateTimeFormat(doc?.createdAt)}
                                            </span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{doc?.sender_name}({doc?.sender_affiliate_code})</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{doc?.receiver_name}({doc?.receiver_affiliate_code})</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span>{(doc?.transaction_type === 1) ? <>send</> : <>claim</>}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.sender_opening_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.sender_closing_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><strong>{doc?.amount}</strong></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.receiver_opening_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.receiver_closing_balance}</span></td>}
                                            {loading ? <td><Skeleton /></td> : <td><span >{doc?.remark}</span></td>}
                                        </>}
                                    </tr>
                                ))}


                            </tbody>
                        </table>

                        <div className="pagination pagination-text row justify-content-between align-items-center">
                            <div className="w-auto">
                                <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                                    <select
                                        aria-controls="DataTables_Table_0"
                                        name="tableLength" value={input?.tableLength}
                                        onChange={handleChange}
                                        className="pag-select-block me-1"
                                    >
                                        <option value="10">10</option>
                                        <option value="25" defaultValue={true}>25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> Records per page
                                </div>
                            </div>

                            <div className="w-auto">
                                Showing {pageNo}-{input?.tableLength}{" "}
                                of {data?.data?.length} items
                            </div>

                            <div className="w-auto">
                                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                                    <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                        className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                        <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                                    </a>
                                    <a onClick={() => setPageNo(pageNo - 1)}
                                        style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                        className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                        <img src="img/left-arrow.png" width="20" />
                                    </a>
                                    <span className="d-flex align-items-center">

                                        <select className="pag-select-block"
                                            aria-controls="DataTables_Table_0"
                                            name="pagination" value={pageNo}
                                            onChange={(e) => setPageNo(Number(e.target.value))}
                                        >
                                            {Array.from({ length: data?.TotalPage }, (_, index) => (
                                                <option key={index + 1} value={index + 1} >
                                                    {index + 1}
                                                </option>
                                            ))}



                                        </select> of {data?.TotalPage}
                                    </span>
                                    <a onClick={() => setPageNo(pageNo + 1)}
                                        style={{ pointerEvents: `${data && pageNo < data?.TotalPage ? 'auto' : 'none'}` }}
                                        className={`${data && pageNo < data?.TotalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                                        <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                                    </a>


                                    <a onClick={() => setPageNo(data?.TotalPage)} style={{ pointerEvents: `${data && pageNo < data?.TotalPage ? 'auto' : 'none'}` }}
                                        className={`${data && pageNo < data?.TotalPage ? '' : 'opacity-25'} border-0`}>
                                        <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* <div className="pagination row justify-content-between align-items-center">
                            <div className="w-auto">
                                <div className="dataTables_info dataTables_length">
                                    {pageNo} of {data && data.TotalPage}{" "}
                                    <label>
                                        Rows per page{" "}
                                        <select
                                            aria-controls="DataTables_Table_0"
                                            name="tableLength"
                                            value={input.tableLength}
                                            onChange={handleChange}
                                        >
                                            <option value="10">10</option>
                                            <option value="25" defaultValue={true}>
                                                25
                                            </option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="w-auto">
                                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                                    {pageNo > 1 && (
                                        <a onClick={() => setPageNo(pageNo - 1)}>
                                            <i className="fa-solid fa-chevron-left"></i>
                                        </a>
                                    )}
                                    <span>
                                        <a
                                            className="paginate_button current"
                                            aria-controls="DataTables_Table_0"
                                            data-dt-idx="1"
                                            tabIndex="0"
                                        >
                                            {pageNo}
                                        </a>
                                    </span>
                                    {data && pageNo < data.TotalPage && (
                                        <a onClick={() => setPageNo(pageNo + 1)}>
                                            <i className="fa-solid fa-chevron-right"></i>
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AffiliateTransactions;
