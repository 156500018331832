import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import LeftSideBar from "../Components/LeftSideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import LoadingBar from "react-top-loading-bar";
import Skeleton from "react-loading-skeleton";
import moment from 'moment';



const InGameTransitionOms = () => {

    const [data, setData] = useState();
    const [input, setInput] = useState({
        limit: "25",
        tableLength: "25",
        searchPlayer: "",
        dateFilter: "recent",
        game: "",
        from: "",
        to: ""
    });
    const [pageNo, setPageNo] = useState(1);
    const [gameList, setGameList] = useState()
    const [loading, setLoading] = useState(false)
    const currentDate = new Date().toISOString().slice(0, 10);
    const [Search, setSearch] = useState("");
    const [totalPages, setTotalPages] = useState(10);
    const [totalDocs, setTotalDocs] = useState(10);
    console.log(currentDate)

    const fetchData = async () => {
        const operatorCode = localStorage.getItem('opr_id');
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}getInGameTransactions?operator_code=${operatorCode}&dateFilter=${input.dateFilter}&search_query=${input.searchPlayer}&from_date=${input.from}&to_date=${input.to}&page=${pageNo}&limit=${input.tableLength}`);
            console.log(res, 'res data');
            if (res.data.status === 200) {
                setData(res?.data?.data);
                setTotalPages(res?.data?.totalPages);
                setTotalDocs(res?.data?.totalDocs);
                console.log(res?.data?.data, 'data____');
            } else {

            }
        } catch (error) {
            console.error("Error fetching data", error);

        }
    };

    const formatValue = (value) => {
        if (value === 0) return <>{'0'}</>;

        const absValue = Math.abs(value);

        if (absValue >= 10000000) {
            return <>{(value / 10000000).toFixed(1) + 'Cr'}</>;
        } else if (absValue >= 100000) {
            return <>{(value / 100000).toFixed(1) + 'Lac'}</>;
        }
        // else if (absValue >= 1000) {
        //   return <>{(value / 1000).toFixed(1) + 'K'}</>;
        // }

        else {
            return <>{value.toFixed(2)}</>;
        }
    };

    const handleChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    const handleSearch = (e) => {
        console.log("GG", e)
        setInput({
            ...input,
            searchPlayer: e
        })
    }
    console.log(input, "inputData")

    useEffect(() => {
        fetchData();
    }, [input, pageNo]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                handleSearch(Search);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [Search]);

    const DateTimeFormat = (isoString) => {

        const date = new Date(isoString);

        // Extract UTC date components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');

        // Format as "YYYY-MM-DD HH:mm:ss"
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }


    return (
        <div>


            <div className="right-sidebar">
                <div className="das-bg-block w-">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3">
                                <h1 className="gamehistoryheading title_h1 same_heading text-start">
                                    OMS In Game Transition
                                </h1>
                            </div>
                            <div className="col-md-9">
                                <div className="row user-select">
                                    <div className="col-md-3">

                                    </div>
                                    <div className="col-md-3 nt-3 mt-md-0">

                                    </div>
                                    <div className="col-md-3 mt-3 mt-md-0">
                                        <div className="form-group">
                                            <select name="dateFilter" value={input?.dateFilter} onChange={handleChange} className="w-">
                                                <option value="recent" defaultValue>
                                                    Recent
                                                </option>
                                                <option value="today">
                                                    Today
                                                </option>
                                                <option value="yesterday">Yesterday</option>
                                                <option value="current_week">Current Week</option>
                                                <option value="last_week">Last Week</option>
                                                <option value="current_month">Current Month</option>
                                                <option value="last_month">Last Month</option>
                                                <option value="last_six_month">Last 6 Month</option>
                                                <option value="date_range">Date Range</option>
                                            </select>
                                        </div>
                                        {input.dateFilter === "date_range" && (<div id="target">
                                            <div className="date">
                                                <p>
                                                    <label>From</label>
                                                    <input type="date" id="date" name="from" value={input?.from} onChange={handleChange} />
                                                    <label>To</label>
                                                    <input type="date" id="date" name="to" value={input?.to} onChange={handleChange} />
                                                </p>
                                            </div>
                                        </div>)}
                                    </div>
                                    <div className="col-md-3 form-group mt-3 mt-md-0">
                                        <div className="input-group  input-group-sm">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search for...."
                                                aria-describedby="basic-addon1"
                                                name="searchPlayer"
                                                // onChange={handleChange}
                                                // value={input.searchPlayer}
                                                onChange={(e) => setSearch(e.target.value)}
                                                value={Search}
                                                style={{ height: "33.5px !important" }}
                                            />
                                            <div className="input-group-prepend">
                                                <span
                                                    className="input-group-text"
                                                    id="basic-addon1"
                                                    style={{ padding: "9px !important" }}
                                                >
                                                    <i className="fa-solid fa-magnifying-glass" onClick={() => handleSearch(Search)} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer"
                >
                    <div className="table-player">
                        <div className="w-100 overflow-auto">
                        <table className="table data-table">
                            <thead>
                                <tr>
                                    <th className="text-nowrap">Date</th>
                                    <th className="text-nowrap">Game Code</th>
                                    <th className="text-nowrap">Operator Player ID</th>
                                    <th className="text-nowrap">OMS OB</th>
                                    <th className="text-nowrap">OMS CB</th>
                                    <th className="text-nowrap">OMS buy in</th>
                                    <th className="text-nowrap">Opening Balance</th>
                                    <th className="text-nowrap">Bet</th>
                                    <th className="text-nowrap">Win</th>
                                    <th className="text-nowrap">Closing Balance</th>
                                    <th className="text-nowrap">Session ID</th>
                                    <th className="text-nowrap">Room Id</th>
                                    <th className="text-nowrap">Master Room Id</th>
                                    <th className="text-nowrap">Player ID</th>

                                </tr>
                            </thead>
                            <tbody>

                                {data?.length > 0 ? (data?.map((e, idx) => (
                                    <tr key={idx}>
                                        <td className='text-center text-nowrap'>
                                            {/* {new Date(e?.createdAt).toLocaleString(
                                                "en-IN",
                                                {
                                                    timeZone: "Asia/Kolkata",
                                                }
                                            )} */}
                                            {DateTimeFormat(e?.createdAt)}
                                        </td>

                                        <td className='text-center'>{e?.game_code}</td>
                                        <td className='text-center'>{e?.operator_player_id}</td>

                                        <td className='text-end'>{formatValue(e?.oms_ob)}</td>
                                        <td className='text-end'>{formatValue(e?.oms_cb)}</td>
                                        <td className='text-end'>{formatValue(e?.oms_buy_in)}</td>

                                        <td className='text-end'>{formatValue(e?.wallet_open_balance)}</td>
                                        <td className='text-end'>{formatValue(e?.bet_amount)}</td>
                                        <td className='text-end'>{formatValue(e?.win_amount)}</td>
                                        <td className='text-end'>{formatValue(e?.wallet_close_balance)}</td>
                                        <td className='text-center'>{e?.session_id}</td>
                                        <td className='text-center'>{e?.room_id}</td>
                                        <td className='text-center'>{e?.master_room_id}</td>
                                        <td className='text-center'>{e?.player_id}</td>

                                    </tr>
                                ))
                                ) : (
                                    <tr>
                                        <td colSpan={12} className="text-center">No Data Found</td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                        </div>

                        <div className="pagination pagination-text row justify-content-between align-items-center">
                            <div className="w-auto">
                                <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                                    <select
                                        aria-controls="DataTables_Table_0"
                                        name="tableLength" value={input?.tableLength}
                                        onChange={handleChange}
                                        className="pag-select-block me-1"
                                    >
                                        <option value="10">10</option>
                                        <option value="25" defaultValue={true}>25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> Records per page
                                </div>
                            </div>

                            <div className="w-auto">
                                Showing {pageNo}-{input?.tableLength}{" "}
                                of {totalDocs} items
                            </div>

                            <div className="w-auto">
                                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                                    <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                        className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                        <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                                    </a>
                                    <a onClick={() => setPageNo(pageNo - 1)}
                                        style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                        className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                        <img src="img/left-arrow.png" width="20" />
                                    </a>
                                    <span className="d-flex align-items-center">

                                        <select className="pag-select-block"
                                            aria-controls="DataTables_Table_0"
                                            name="pagination" value={pageNo}
                                            onChange={(e) => setPageNo(Number(e.target.value))}
                                        >
                                            {Array.from({ length: totalPages }, (_, index) => (
                                                <option key={index + 1} value={index + 1} >
                                                    {index + 1}
                                                </option>
                                            ))}



                                        </select> of {totalPages}
                                    </span>
                                    <a onClick={() => setPageNo(pageNo + 1)}
                                        style={{ pointerEvents: `${data && pageNo < totalPages ? 'auto' : 'none'}` }}
                                        className={`${data && pageNo < totalPages ? '' : 'opacity-25'} ms-1 border-0`}>
                                        <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                                    </a>


                                    <a onClick={() => setPageNo(totalPages)} style={{ pointerEvents: `${data && pageNo < totalPages ? 'auto' : 'none'}` }}
                                        className={`${data && pageNo < totalPages ? '' : 'opacity-25'} border-0`}>
                                        <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default InGameTransitionOms;
