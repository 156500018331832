import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";


const ErrorLog = () => {
    const opr_id = localStorage.getItem("opr_id");
    const [pageNo, setPageNo] = useState(1);
    const [requestBodyModal, setRequestBodyModal] = useState(false);
    const [responseModal, setResponseModal] = useState(false);
    const [requestBodyData, setRequestBodyData] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [filter, setFilter] = useState('GMS');
    const [headerModal, setHeaderModal] = useState(false);
    const [headerData, setHeaderData] = useState(null);
    const [totalPage, setTotalPage] = useState('');

    const [data, setData] = useState([]);
    const [limit, setLimit] = useState('100');

    const fetchData = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_PATH}fetch-error-logs`, {
                opr_id: opr_id,
                pageNo: pageNo,
                limit: limit,
                filter: filter,
            },
                {
                    headers: {
                        //admin_auth_token: localStorage.getItem("omsToken"),
                        opr_auth_token: localStorage.getItem("sessionToken"),
                    }
                });

            console.log(res?.data, 'dsfgdsgsdfg');
            if (res?.data?.data?.status) {
                setTotalPage(res?.data?.data?.TotalPage)
                setData(res?.data?.data?.data);
            } else {
                console.log();

            }

        } catch (error) {
            console.error("Error fetching role data:", error);
        }
    };

    const handleRequestBody = (requestBodyData) => {
        setRequestBodyData(requestBodyData);
        setRequestBodyModal(true);
    };

    const handleResponse = (responseData) => {
        setResponseData(responseData);
        setResponseModal(true);
    };
    const handleHeader = (headerData) => {
        setHeaderData(headerData);
        setHeaderModal(true);
    };


    useEffect(() => {
        fetchData();
    }, [filter, pageNo, limit])

    return (
        <>

            <div>


                <div className="right-sidebar">
                    <div className="das-bg-block w-">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3">
                                    <h1 className="gamehistoryheading title_h1 same_heading text-start">
                                        Error Logs
                                    </h1>
                                </div>
                                <div className="col-md-9">
                                    <div className="row user-select justify-content-end">


                                        <div className="col-md-3 mt-md-0">
                                            <div className="form-group" >
                                                <select onChange={(e) => setFilter(e.target.value)}>
                                                    <option value="GMS">GMS</option>
                                                    <option value="OMS">OMS</option>

                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper no-footer top-table"
                    >

                    </div>
                    <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper no-footer"
                    >
                        <div className="table-player">
                            <div className='table-list-mb'>
                                <table className="table data-table">
                                    <thead>
                                        <tr>
                                            <th>Time stamp</th>
                                            <th>End Point</th>
                                            <th>Re Mark</th>
                                            <th>Header</th>
                                            <th>Request Body</th>
                                            <th>Response</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length === 0 ? (
                                            <tr>
                                                <td colSpan="6">No error logs available</td>
                                            </tr>
                                        ) : (
                                            data.map((e, idx) => (
                                                <tr key={idx}>
                                                    <td>{e.timestamp}</td>
                                                    <td>{e.endpoint}</td>
                                                    <td>{e.error}</td>
                                                    <td>
                                                        {e?.headers && Object.keys(e?.headers).length > 0 && (<button
                                                            className="nav-link db-btn"
                                                            type="button"
                                                            onClick={() => handleHeader(e?.headers)}
                                                        >
                                                            <i className="fa-regular fa-eye p-0 border-0 bg-transparent"></i> View
                                                        </button>)}
                                                    </td>
                                                    <td>
                                                        {e?.request_body && Object.keys(e?.request_body).length > 0 && (<button
                                                            className="nav-link db-btn"
                                                            type="button"
                                                            onClick={() => handleRequestBody(e?.request_body)}
                                                        >
                                                            <i className="fa-regular fa-eye p-0 border-0 bg-transparent"></i> View
                                                        </button>)}
                                                    </td>
                                                    <td>
                                                        {e?.response && Object.keys(e?.response).length > 0 && (<button
                                                            className="nav-link db-btn"
                                                            type="button"
                                                            onClick={() => handleResponse(e?.response)}
                                                        >
                                                            <i className="fa-regular fa-eye p-0 border-0 bg-transparent"></i> View
                                                        </button>)}
                                                    </td>

                                                    {/* <td>
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn action dropdown-toggle bg-transparent dropdown-toggle-custom"
                                                                type="button"
                                                                id={`dropdownMenuButton${idx}`}
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="fa-solid fa-caret-down"></i>
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-custom" aria-labelledby={`dropdownMenuButton${idx}`}>
                                                                {e?.request_body && Object.keys(e?.request_body).length > 0 && (<li>
                                                                    <span className="dropdown-item"
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => handleRequestBody(e?.request_body)}
                                                                    >
                                                                        Request Body
                                                                    </span>
                                                                </li>)}
                                                                {e?.response && Object.keys(e?.response).length > 0 && (<li>
                                                                    <span className="dropdown-item"
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => handleResponse(e?.response)}
                                                                    >
                                                                        Response
                                                                    </span>
                                                                </li>)}

                                                                {e?.headers && Object.keys(e?.headers).length > 0 && (<li>
                                                                    <span className="dropdown-item"
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => handleHeader(e?.headers)}
                                                                    >
                                                                        Header
                                                                    </span>
                                                                </li>)}

                                                            </ul>
                                                        </div>
                                                    </td> */}
                                                </tr>
                                            ))
                                        )}
                                    </tbody>

                                </table>
                            </div>

                            {data.length > 0 && <div className="pagination pagination-text row justify-content-between align-items-center">
                                <div className="w-auto">
                                    <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                                        <select
                                            aria-controls="DataTables_Table_0"
                                            name="tableLength" value={limit}
                                            onChange={(e) => setLimit(e.target.value)}
                                            className="pag-select-block me-1"
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select> Records per page
                                    </div>
                                </div>

                                <div className="w-auto">
                                    Showing {pageNo}{" "}of {limit} items
                                </div>

                                <div className="w-auto">
                                    <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                                        <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                            className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                            <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                                        </a>
                                        <a onClick={() => setPageNo(pageNo - 1)}
                                            style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                                            className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                                            <img src="img/left-arrow.png" width="20" />
                                        </a>
                                        <span className="d-flex align-items-center">

                                            <select className="pag-select-block"
                                                aria-controls="DataTables_Table_0"
                                                name="pagination" value={pageNo}
                                                onChange={(e) => setPageNo(Number(e.target.value))}
                                            >
                                                {Array.from({ length: totalPage }, (_, index) => (
                                                    <option key={index + 1} value={index + 1} >
                                                        {index + 1}
                                                    </option>
                                                ))}



                                            </select> of {totalPage}
                                        </span>

                                        <a onClick={() => setPageNo(pageNo + 1)}
                                            style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                                            className={`${pageNo < totalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                                            <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                                        </a>


                                        <a onClick={() => setPageNo(totalPage)} style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                                            className={`${pageNo < totalPage ? '' : 'opacity-25'} border-0`}>

                                            <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            }


                        </div>
                    </div>
                </div>

            </div>

            <Modal show={requestBodyModal} onHide={() => setRequestBodyModal(false)} centered scrollable>
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Request Body</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre>{JSON.stringify(requestBodyData, null, 2)}</pre>
                </Modal.Body>
            </Modal>

            <Modal show={responseModal} onHide={() => setResponseModal(false)} centered scrollable>
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Response Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre>{JSON.stringify(responseData, null, 2)}</pre>
                </Modal.Body>
            </Modal>

            <Modal show={headerModal} onHide={() => setHeaderModal(false)} centered scrollable>
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Header Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre>{JSON.stringify(headerData, null, 2)}</pre>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default ErrorLog;