import React, { useState, useEffect } from 'react';
import LeftSideBar from '../Components/LeftSideBar';
import Header from '../Components/Header';
import axios from 'axios';
import LoadingBar from "react-top-loading-bar";
import Loader from '../Components/Loader';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const GameTurnover = () => {
  const [data, setData] = useState([]);
  console.log("🚀 ~ data:", data)
  const [input, setInput] = useState({
    limit: "100",
    tableLength: "100",
    searchPlayer: "",
    dateFilter: "today",
    gameName: "",
    game: "ALL",
    from: "",
    to: ""
  });

  const [pageNo, setPageNo] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [gameList, setGameList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [totalBet, setTotalBet] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPageItems, setCurrentPageItems] = useState(0)
  const [currentPageItemsStartingFrom, setCurrentPageItemsStartingFrom] = useState(0)

  const [totalWin, setTotalWin] = useState(0)

  useEffect(() => {
    setLoading(true)
    const fetchGameApi = async () => {
      const response = await axios.get(process.env.REACT_APP_API_PATH + "gameList",
        {
          headers: {
            admin_auth_token: localStorage.getItem("omsToken"),
            opr_auth_token: localStorage.getItem("sessionToken"),
          },
        })
      if (response.status === 200) {

        console.log('setGameList(response.data)', response.data)

        setGameList(response.data)

      }
    }
    fetchGameApi()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        //  const response = await axios.post("http://192.168.0.144:6500/gameTurnover", {
        //  const response = await axios.post( "http://13.250.111.220:5500/gameTurnover", {
        const response = await axios.post(process.env.REACT_APP_API_PATH + "gameTurnover", {
          pageNo: pageNo,
          limit: input.tableLength,
          searchPlayer: input.searchPlayer,
          dateFilter: input.dateFilter,
          game: input.game,
          from: input.from,
          to: input.to
        },
          {
            headers: {
              admin_auth_token: localStorage.getItem("omsToken"),
              opr_auth_token: localStorage.getItem("sessionToken"),
            },
          })
        console.log("🚀 ~ fetchData ~ response:", response.data.data)
        if (response.status === 200) {
          console.log(response.data.data)
          const processedData = response.data.data
            .map(item => ({
              ...item,
              totalWin: item.total_debit_amt - item.total_credit_amt
            }))
            .sort((a, b) => a.totalWin - b.totalWin);
          setData(processedData)
          setTotalBet(response.data.total_debit_amt)
          setTotalWin(response.data.total_credit_amt)
          input.game === 'All' ? setTotalPage(response.data.TotalPage) : setTotalPage(1)
          input.game === 'All' ? setTotalItems(response.data.totalItems) : setTotalItems(1)
          const currentPageItems = input.game === 'All' ? Math.min(pageNo * input.tableLength, response.data.totalItems) : 1
          setCurrentPageItems(currentPageItems)
          const currentPageItemsStartingFrom = input.game === 'All' ? input.tableLength * (pageNo - 1) + 1 : 1
          setCurrentPageItemsStartingFrom(currentPageItemsStartingFrom)

          setLoading(false)
          console.log("🚀 ~ fetchData ~ data:", response.data.data)
        }
      }
      catch (err) {
        alert("internal server error")
        setLoading(false)
      }
    }
    fetchData();
  }, [input, input.to, pageNo])

  useEffect(() => {

    if (input.game !== 'All') {
      setTotalPage(1)
      setTotalItems(1)
      setCurrentPageItems(1)
      setCurrentPageItemsStartingFrom(1)
    }
  }, [input.game])

  const handleChange = (e, bool = false) => {

    if (e.target.name === 'dateFilter') {
      setPageNo(1)
    }

    console.log("🚀 ~ handleChange ~ e:", e.target.value)
    console.log("🚀 ~ handleChange ~e.target.name:", e.target.name)
    let game_name
    gameList?.data?.map(ele => {
      if (ele.game_code === e.target.value) {
        game_name = ele.game_name
      }
    })
    // console.log("🚀 ~ handleChange ~ gameDetails:", gameDetails)
    console.log("🚀 ~ handleChange ~ game_name:", game_name)


    setInput({
      ...input,
      [e.target.name]: e.target.value,
      gameName: game_name
    })

    // logic for filter
    const query = e.target.value.toLowerCase();


    const matches = Object.values(data).some(value =>
      value.toString().toLowerCase().includes(query)
    );
    console.log("🚀 ~ handleChange ~ matches:", matches)


  }


  // useEffect(()=>{

  //   const fetchData = async () => {
  //     setLoading(true)
  //     try {
  //       const response = await axios.post(process.env.REACT_APP_API_PATH + "gameTurnover", {
  //         pageNo: pageNo,
  //         limit: input.tableLength,
  //         dateFilter: input.dateFilter,
  //         game: "ALL",
  //         from: input.from,
  //         to: input.to
  //       },
  //         {
  //           headers: {
  //             admin_auth_token: localStorage.getItem("omsToken"),
  //             opr_auth_token: localStorage.getItem("sessionToken"),
  //           },
  //         })
  //       console.log("🚀 ~ fetchData ~ response:", response.data.data)
  //       if (response.status === 200) {

  //       }
  //     }
  //     catch (err) {
  //       alert("internal server error")
  //       setLoading(false)
  //     }
  //   }
  //   fetchData();
  // },[])
  // let totalBet = 0;
  // let totalWin = 0;


  // if (input.game && data && data.length > 0) {
  //   for (const entry of data) {
  //     console.log("🚀 ~ GameTurnover ~ entry:", entry)
  //     if (entry._id === input.game || input.game === 'ALL') {
  //       totalBet += entry.total_debit_amt;
  //       totalWin += entry.total_credit_amt;
  //     }

  //   }
  // }


  const DateTimeFormat = (inputDate) => {

    var parsedDate = new Date(inputDate);

    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const day = parsedDate.getDate();
    const month = monthNames[parsedDate.getMonth()];
    const year = parsedDate.getFullYear();
    const hours = parsedDate.getHours();
    const minutes = parsedDate.getMinutes();
    const seconds = parsedDate.getSeconds();
    const ampm = hours >= 12 ? 'pm' : 'am';


    const formattedDate = `${day} ${month} ${year}`;
    // const formattedDate = `${day} ${month} ${year}, ${hours}:${minutes}:${seconds} ${ampm}`;
    // console.log(formattedDate);
    return formattedDate
  }

  console.log(input, "inputData")
  const getNameFromGameCode = (gameCode) => {
    // console.log("🚀 ~ getNameFromGameCode ~ gameCode:", gameCode)

    let game_name = ''
    gameList?.data?.map(ele => {
      // console.log("🚀 ~ getNameFromGameCode ~ ele:", ele.game_code)
      if (ele.game_code === gameCode) {
        game_name = ele.game_name

      }
    })
    return game_name
  }

  const formatValue = (value) => {
    if (value === 0) return <>{'0'}</>;

    const absValue = Math.abs(value);

    if (absValue >= 10000000) {
      return <>{(value / 10000000).toFixed(1) + 'Cr'}</>;
    } else if (absValue >= 100000) {
      return <>{(value / 100000).toFixed(1) + 'Lac'}</>;
    }
    // else if (absValue >= 1000) {
    //   return <>{(value / 1000).toFixed(1) + 'K'}</>;
    // }

    else {
      return <>{value.toFixed(2)}</>;
    }
  };

  return (
    <div>
      {/* <Header />
        <div class="full-page">
          <LeftSideBar indexx={0}/> */}

      {/* {loading &&  <LoadingBar
        color='#f11946'
        progress={loading ? 50 : 0}
        onLoaderFinished={() => setLoading(false)}
      />}
      {loading ? <p>Loading...</p> : null} */}

      <div className="right-sidebar">
        <div className="das-bg-block w-">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <h1 className="gamehistoryheading title_h1 same_heading text-start">
                  Game Turnover {">"} {input?.gameName} ({input?.game})
                </h1>
              </div>
              <div className="col-md-9">
                <div className="row user-select justify-content-end">
                  {/* <div className="col-md-3">
                    <div className="form-group">
                          <select>
                            <option value={0} selected="">
                              All Agent
                            </option>
                          </select>
                        </div>
                  </div> */}

                  {/* <div className="col-md-3 form-group mt-3 mt-md-0">
                     <div className="input-group  input-group-sm">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search for...."
                        aria-describedby="basic-addon1"
                        name="searchPlayer"
                        onChange={handleChange}
                        value={input.searchPlayer}
                        style={{ height: "33.5px !important" }}
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                          style={{ padding: "9px !important" }}
                        >
                          <i className="fa-solid fa-magnifying-glass" />
                        </span>
                      </div>
                    </div> 
                  </div> */}

                  <div className="col-md-3 mt-md-0">
                    <div className="form-group" >
                      <select name="game" value={input.game} onChange={handleChange}>
                        <option value={input.game_code}>ALL</option>
                        {
                          gameList && gameList?.data?.map((doc) => (
                            <option value={doc.game_code}>{doc.game_name} ({doc.game_code})</option>
                          ))
                        }
                        {/* <option value="MZ010096">ABCD</option>
                            <option value="MZ020097">Tambola</option>
                            <option value="4">option2</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2 mt-md-0">
                    <div className="form-group">
                      <select name="dateFilter" value={input.dateFilter} onChange={(e) => handleChange(e, true)} className="w-">
                        <option value="recent" defaultValue>
                          Recent
                        </option>
                        <option value="today">
                          Today
                        </option>
                        <option value="yesterday">Yesterday</option>
                        <option value="last3days">Last 3 days</option>
                        <option value="current_week">Current Week</option>
                        <option value="last_week">Last Week</option>
                        <option value="current_month">Current Month</option>
                        <option value="last_month">Last Month</option>
                        <option value="last_six_month">Last 6 Month</option>
                        <option value="date_range">Date Range</option>
                      </select>
                    </div>
                    {input.dateFilter === "date_range" && (<div style={{ marginRight: "100px" }} id="target">
                      <div className="date">
                        <p>
                          <label>From</label>
                          <input type="date" id="date" name="from" value={input.from} onChange={handleChange} />
                          <label>To</label>
                          <input type="date" id="date" name="to" value={input.to} onChange={handleChange} />
                        </p>
                      </div>
                    </div>)}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="DataTables_Table_0_wrapper"
          className="dataTables_wrapper no-footer top-table"
        >
          <div className="table-player">
            <table className="table data-table">
              <thead>
                <tr>
                  <th>Game</th>
                  <th>Total Bet</th>
                  <th>Total Win</th>
                  <th> Win/Lose</th>
                  {/* <th>Rake/Commission</th> */}
                  <th>Net (%) </th>
                  <th>RTP (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {loading ? <td><Skeleton /></td> : <td>All</td>}

                  {loading ? <td><Skeleton /></td> : <td>  {(totalBet) > 0 ? <> {formatValue(totalBet)}</> : 0} </td>}

                  {loading ? <td><Skeleton /></td> : <td><span >{(totalWin > 0) ? <> {formatValue(totalWin)}</> : 0}</span></td>}

                  {loading ? <td><Skeleton /></td> : <td><span style={{ color: (totalBet - totalWin).toFixed(2) >= 0 ? 'green' : 'red' }} ><> {formatValue((totalBet - totalWin))}</> </span></td>}

                  {loading ? <td><Skeleton /></td> : <td><span style={{ color: ((totalBet - totalWin) * 100 / totalBet).toFixed(2) >= 0 ? 'green' : 'red' }} >{(totalWin) > 0 ? ((totalBet - totalWin) * 100 / totalBet).toFixed(2) : 0}% </span></td>}

                  {loading ? <td><Skeleton /></td> : <td><span style={{ color: ((totalWin * 100) / (totalBet)).toFixed(2) < 100 ? 'green' : 'red' }}>{(totalWin) > 0 ? ((totalWin * 100) / (totalBet)).toFixed(2) : 0}%  </span></td>}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          id="DataTables_Table_0_wrapper"
          className="dataTables_wrapper no-footer"
        >
          <div className="table-player">
            <div className='table-list-mb'>
              <table className="table data-table">
                <thead>
                  <tr>
                    <th>Game</th>
                    <th>Session Count D/C</th>
                    <th>Currency</th>
                    {/* <th>Affiliate ID</th> */}
                    <th>Total Bet</th>
                    <th>Total Win</th>
                    <th>Win/Loss</th>
                    <th> Net (%)</th>
                    {/* <th> Rake/Commission</th> */}

                    <th>RTP (%) </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (data?.length === 0 || (!data?.some(item => item._id === input.game) && input.game != "ALL")) ?
                      <><td> {(input.gameName) ? input.gameName : <>All</>}</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td></> :

                      data &&
                      data?.map((docs, ind) => {


                        if (input.game === docs?._id || input.game === 'ALL') {
                          return (<tr key={ind}>
                            {loading ? <td><Skeleton /></td> : <td className='text-start'>{getNameFromGameCode(docs?._id)} ({docs?._id})</td>}
                            {loading ? <td><Skeleton /></td> : <td>{docs?.debit_count}/{docs?.credit_count}</td>}
                            {loading ? <td><Skeleton /></td> : <td>{docs?.base_currency}</td>}
                            {loading ? <td><Skeleton /></td> : <td className='text-end'><span>{docs?.total_debit_amt < '100000' ? docs?.total_debit_amt?.toFixed(2) : formatValue(docs?.total_debit_amt)}</span></td>}
                            {loading ? <td><Skeleton /></td> : <td className='text-end'><span ><>{docs?.total_credit_amt < '100000' ? docs?.total_credit_amt?.toFixed(2) : formatValue(docs?.total_credit_amt)}</></span></td>}
                            {loading ? <td><Skeleton /></td> : <td className='text-end'><span style={{ color: (docs?.totalWin)?.toFixed(2) >= 0 ? 'green' : 'red' }}>
                              {(formatValue(docs?.totalWin))}
                            </span>
                            </td>
                            }

                            {loading ? <td><Skeleton /></td> : <td className='text-end'><span style={{ color: ((docs.total_debit_amt - docs?.total_credit_amt) * 100 / (docs.total_debit_amt)) >= 0 ? 'green' : 'red' }}> {docs?.total_debit_amt && docs?.total_credit_amt !== undefined && docs?.total_debit_amt !== 0
                              ? `${((docs.total_debit_amt - docs.total_credit_amt) * 100 / docs.total_debit_amt)?.toFixed(2)}%`
                              : 0}</span></td>}


                            {loading ? <td><Skeleton /></td> : <td className='text-end'><span style={{ color: ((docs?.total_credit_amt) * 100 / (docs.total_debit_amt)) < 100 ? 'green' : 'red' }}>{docs?.total_debit_amt && docs?.total_credit_amt !== undefined && docs?.total_debit_amt !== 0
                              ? `${((docs?.total_credit_amt * 100) / docs?.total_debit_amt)?.toFixed(2)}%`
                              : 0}
                            </span></td>}
                          </tr>)
                        }

                      }
                      )}
                </tbody>
              </table>
            </div>

            <div className="pagination pagination-text row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length ms-1 d-flex align-items-center">
                  <select
                    aria-controls="DataTables_Table_0"
                    name="tableLength" value={input.tableLength}
                    onChange={handleChange}
                    className="pag-select-block me-1"
                  >
                    <option value="10">10</option>
                    <option value="25" defaultValue={true}>25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select> Records per page
                </div>
              </div>

              <div className="w-auto">
                Showing {currentPageItemsStartingFrom}-{currentPageItems}{" "}of {totalItems} items
              </div>

              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                  <a onClick={() => setPageNo(1)} style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    <span className="prev-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                  <a onClick={() => setPageNo(pageNo - 1)}
                    style={{ pointerEvents: `${pageNo > 1 ? 'auto' : 'none'}` }}
                    className={`${pageNo > 1 ? '' : 'opacity-25'} border-0`}>
                    {/* <i className="fa-solid fa-chevron-left"></i> Previous */}
                    <img src="img/left-arrow.png" width="20" />
                  </a>
                  <span className="d-flex align-items-center">

                    <select className="pag-select-block"
                      aria-controls="DataTables_Table_0"
                      name="pagination" value={pageNo}
                      onChange={(e) => setPageNo(Number(e.target.value))}
                    >
                      {Array.from({ length: totalPage }, (_, index) => (
                        <option key={index + 1} value={index + 1} >
                          {index + 1}
                        </option>
                      ))}



                    </select> of {totalPage}
                  </span>

                  <a onClick={() => setPageNo(pageNo + 1)}
                    style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                    className={`${pageNo < totalPage ? '' : 'opacity-25'} ms-1 border-0`}>
                    <img src="img/left-arrow.png" className="next-img-rotate" width="20" />
                  </a>
                  {/* } */}

                  <a onClick={() => setPageNo(totalPage)} style={{ pointerEvents: `${pageNo < totalPage ? 'auto' : 'none'}` }}
                    className={`${pageNo < totalPage ? '' : 'opacity-25'} border-0`}>
                    {/* <i className="fa-solid fa-chevron-right"></i> */}
                    <span className="next-border"><img src="img/left-arrow.png" width="20" /></span>
                  </a>
                </div>
              </div>
            </div>

            {/* <div className="pagination row justify-content-between align-items-center">
              <div className="w-auto">
                <div className="dataTables_info dataTables_length">
                  {pageNo} of {totalPage && totalPage}{" "}
                  <label>
                    Rows per page{" "}
                    <select
                      aria-controls="DataTables_Table_0"
                      name="tableLength"
                      value={input.tableLength}
                      onChange={handleChange}
                    >
                      <option value="10">10</option>
                      <option value="25" defaultValue={true}>
                        25
                      </option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className="w-auto">
                <div className="dataTables_length dataTables_paginate paging_simple_numbers position-static">
                  {pageNo > 1 && (
                    <a onClick={() => setPageNo(pageNo - 1)}>
                      <i className="fa-solid fa-chevron-left"></i>
                    </a>
                  )}
                  <span>
                    <a
                      className="paginate_button current"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="1"
                      tabIndex="0"
                    >
                      {pageNo}
                    </a>
                  </span>
                  {data && pageNo < totalPage && (
                    <a onClick={() => setPageNo(pageNo + 1)}>
                      <i className="fa-solid fa-chevron-right"></i>
                    </a>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default GameTurnover