import React from "react";


const TransferTransactions = () => {
    return (
        <>

            <div className="right-sidebar">
                <div className="das-bg-block d-flex align-items-center justify-content-between">
                    <div className="das-title">
                        <h1 className="gamehistoryheading title_h1 same_heading">Transfer Transactions</h1>
                    </div>
                    <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                        
                    </div>
                </div>
                <div>
                    <div className='container-fluid bg'>
                        <div className='row user-select mt-2'>

                            <div className="col-lg-3 col-md-3 col-6 mb-2 mb-md-0 col-xs-12">
                                <div className="form-group">
                                    <select name="" id="" className="w-100 ">
                                        <option value="" selected="">
                                            All
                                        </option>
                                        <option value="">Option</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-6 mt-lg-0 mb-2 mb-md-0 col-xs-1">
                                <div className="form-group">
                                    <select name="" id="" className="w-100">
                                        <option value="" selected="">
                                            Ludo
                                        </option>
                                        <option value="">Rummy</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3  col-6 mt-lg-0  col-xs-1">
                                <div className="form-group">
                                    <select name="" id="" className="w-100">
                                        <option value="" selected="">
                                            Normal
                                        </option>
                                        <option value="">Normal</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 user-select col-6 mt-lg-0  col-xs-1">
                                <div className="input-group  input-group-sm">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for...."
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        name="search"
                                        defaultValue=""
                                    />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </span>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>

                    <div id="DataTables_Table_0_wrapper" className='data-table'>
                        <div className="table-player">
                            <div className="table-list-mb">
                            <table className="table data-table   table-borderless">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Transactions ID</th>
                                        <th>Player ID</th>
                                        <th>Login ID</th>
                                        <th>Game</th>
                                        <th>Player Code</th>
                                        <th>Opening Balance</th>
                                        <th>Transactions Type</th>
                                        <th>Amount</th>
                                        <th>Closing Balance</th>
                                        <th>Seesion Id</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>--</td>
                                        <td>
                                            --
                                        </td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                    </tr>
                                </tbody>
                            </table></div>
                            </div>

                    </div>

                </div>
            </div>
        </>
    );
};

export default TransferTransactions;